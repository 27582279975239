import React, { memo } from "react";
import styles from "./DashboardInput.module.css";

const DTextArea = ({
  label,
  value,
  placeholder,
  inputWrapperClass,
  onChange,
  error,
  errorMsg,
  containerClass,
  customClassName,
  size,
}) => {
  return (
    <div
      className={`${styles.d_input_container} ${containerClass}`}
      style={{ width: `${size}` }}
    >
      {label && <label>{label}</label>}
      <div
        className={`${styles.d_input_wrapper} ${inputWrapperClass} ${
          styles.d_textarea_container
        } ${error ? styles.error : ""}`}
      >
        <textarea
          value={value}
          onChange={onChange}
          className={`${customClassName} ${styles.textarea}`}
          placeholder={!placeholder ? `Enter ${label}` : placeholder}
        />
      </div>
      {error && <p className={styles.error_message}>{errorMsg}</p>}
    </div>
  );
};

export default memo(DTextArea);
