import { Check, Close } from '@mui/icons-material'
import { Fade, Modal } from '@mui/material'
import React from 'react'
import SignupBtn from '../HomeButtons/SignupBtn'
//css
import classes from './SignupModal.module.css';
import { useNavigate } from 'react-router-dom';

const SignupModal = ({ openSignupOptions, selectedRole, setOpenSignupOptions, handleRoleChange, handleCloseSignupOptions }) => {

    const navigate = useNavigate();

    return (
        <Modal
            open={openSignupOptions}
            closeAfterTransition
            slotProps={{
                backdrop: {
                    TransitionComponent: Fade,
                },
            }}
            sx={{
                overflow: "auto",
            }}
        >
            <Fade in={openSignupOptions}>
                <div className={classes.signupOptionsWrapper}>

                    <Close className={classes.closeButtonIcon} onClick={handleCloseSignupOptions} sx={{
                        fontSize: '2.4rem',
                        padding: '0.3rem'
                    }} />
                    <div className={classes.cardHeader}>
                        <span>Please Select Your Role</span>
                    </div>

                    <div className={classes.radioCardSection}>
                        <label className={`${classes.radioCard} ${selectedRole === 'buyer' && classes.active}`}>
                            <input
                                name="role"
                                className={classes.radio}
                                type="radio"
                                value={"buyer"}
                                id='buyerRadio'
                                checked={selectedRole === 'buyer'}
                                onChange={() => handleRoleChange('buyer')}
                            />
                            <span className={classes.cardHeading}>Buyer Organization</span>
                            <span className={classes.cardDesc}>
                                An individual or entity seeking to purchase goods or services
                            </span>
                            {<Check className={classes.checkIcon} sx={{
                                fontSize: '1.5rem',
                                color: selectedRole === 'buyer' ? '#fff' : "transparent",
                                padding: '0.1rem',
                                backgroundColor: selectedRole === 'buyer' ? '#3d4bd6' : "transparent",
                                border: selectedRole === 'buyer' ? "transparent" : '1px solid #adadad',
                            }} />}
                        </label>

                        <label className={`${classes.radioCard} ${selectedRole === 'vendor' && classes.active}`}>
                            <input
                                name="role"
                                className={classes.radio}
                                type="radio"
                                value={"vendor"}
                                id='vendorRadio'
                                checked={selectedRole === 'vendor'}
                                onChange={() => handleRoleChange('vendor')}
                            />
                            <span className={classes.cardHeading}>Vendor Organization</span>
                            <span className={classes.cardDesc}>
                                An individual or entity engaged in the sale of goods or services
                            </span>
                            {<Check className={classes.checkIcon} sx={{
                                fontSize: '1.5rem',
                                color: selectedRole === 'vendor' ? '#fff' : "transparent",
                                padding: '0.1rem',
                                backgroundColor: selectedRole === 'vendor' ? '#3d4bd6' : "transparent",
                                border: selectedRole === 'vendor' ? "transparent" : '1px solid #adadad',
                            }} />}
                        </label>
                    </div>

                    <div className={classes.continueFooter}>
                        <SignupBtn
                            onClick={() => {
                                setOpenSignupOptions(false)
                                selectedRole === "buyer" ?
                                    navigate("/buyer_organization_signup") :
                                    selectedRole === "vendor" ?
                                        navigate("/vendor_signup") : navigate("/")
                            }}
                            buttonText={"Continue"}
                            noIcon={true}
                            disabled={selectedRole === '' || !selectedRole}
                            customClassName={classes.continuePopupBtn}
                        />
                    </div>

                </div>
            </Fade>
        </Modal>

    )
}

export default SignupModal;