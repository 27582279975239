import React, { useEffect, useState } from "react";
//img
import bellIcon from "../../../assets/images/svg/Notification.svg";
//css
import classes from "./NotificationBell.module.css";
import { CircularProgress, Drawer } from "@mui/material";
import axios from "axios";
import { API_ENDPOINT } from "../../../services/api/resources";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import NotificationCard from "./NotificationCard";

const NotificationBell = () => {
  //getting data from use selector
  const loginData = useSelector((state) => state.loginData.value);

  const [auth_token, setAuthToken] = useState(loginData?.auth_token);
  const [unique_token, setUniqueToken] = useState(loginData?.user?.token);
  const [vendor_token, setVendorToken] = useState(
    loginData?.vendor_contact_token
  );
  const [loadingState, setLoadingState] = useState(false);

  // opensidebar
  const [openNotificationBar, setOpenNotificationBar] = useState(false);
  const handleOpenNotificationBar = () => setOpenNotificationBar(true);
  const handleCloseNotificationBar = () => setOpenNotificationBar(false);

  const [notificationsData, setNotificationData] = useState([]);
  const [viewOption, setViewOption] = useState("All");

  const handleOptionChange = (e) => {
    let val = e?.target?.value;
    setViewOption(val);
  };

  let org_buyer_access =
    loginData?.access_type === "Buyer" ||
    loginData?.access_type === "Admin" ||
    loginData?.access_type === "buyer" ||
    loginData?.access_type === "admin";

  let vendor_access =
    loginData?.access_type === "vendor admin" ||
    loginData?.access_type === "vendor contact";

  let vendor_admin_access = loginData?.access_type === "vendor admin";
  let vendor_contact_access = loginData?.access_type === "vendor contact";

  const getNotifications = async () => {
    const formData = new FormData();

    org_buyer_access && formData?.append("buyer_token", unique_token);
    vendor_access && formData?.append("vendor_token", unique_token);

    await axios({
      method: "POST",
      url: `${API_ENDPOINT.getNotifications}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setNotificationData(res?.data?.response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const notificationClick = async (notification_id, markAsAllReadKeys) => {
    const formData = new FormData();
    notification_id && formData.append("notify_id", notification_id);

    markAsAllReadKeys &&
      markAsAllReadKeys?.forEach((element) => {
        return formData.append(element.key, element.value);
      });

    await axios({
      method: "POST",
      url: `${API_ENDPOINT.unreadNotifications}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setLoadingState(false);
        getNotifications();
      })
      .catch((err) => {
        setLoadingState(false);
        console.log(err);
      });
  };

  const markAsAllRead = () => {
    setLoadingState(true);
    if (org_buyer_access) {
      notificationClick(null, [
        {
          key: "buyer_token",
          value: unique_token,
        },
      ]);
    }
    if (vendor_admin_access) {
      notificationClick(null, [
        {
          key: "vendor_token",
          value: vendor_token,
        },
      ]);
    }
    if (vendor_contact_access) {
      notificationClick(null, [
        {
          key: "vendor_token",
          value: unique_token,
        },
      ]);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div className={`${classes.notification_wrapper} ${classes.flex}`}>
      <div
        className={`${classes.notification_icon} ${
          notificationsData &&
          notificationsData?.some((notification) => !notification.status)
            ? `${classes.active}`
            : ""
        }`}
        onClick={handleOpenNotificationBar}
      >
        <img src={bellIcon} />
        {notificationsData &&
          notificationsData?.some((notification) => !notification.status) && (
            <span className={`${classes.notification_indicator}`}></span>
          )}
      </div>

      <Drawer
        open={openNotificationBar}
        anchor={"right"}
        className={classes.menuDraw}
      >
        <div className={`${classes.notification_panel}`}>
          <div className={`${classes.notificationHeader}`}>
            <span>Notifications</span>
            <Close
              sx={{ fontSize: "3rem", padding: "0.5rem", cursor: "pointer" }}
              className={classes.closeBtn}
              onClick={handleCloseNotificationBar}
            />
          </div>
          <div className={classes.filterOptions}>
            <div className={classes.view}>
              <select
                className={classes.filterDropdown}
                onChange={handleOptionChange}
                value={viewOption}
              >
                <option value="All">All</option>
                <option value="Read">Read</option>
                <option value="Unread">Unread</option>
              </select>
            </div>

            {notificationsData &&
              notificationsData
                ?.map((e) => e?.status)
                ?.filter((e) => e === false)?.length !== 0 && (
                <button
                  className={classes.markAsAllRead}
                  onClick={() => {
                    notificationsData &&
                      notificationsData
                        ?.map((e) => e?.status)
                        ?.filter((e) => e === false)?.length !== 0 &&
                      markAsAllRead();
                  }}
                >
                  {!loadingState ? (
                    "Mark as all read"
                  ) : (
                    <CircularProgress
                      size={"15px"}
                      thickness={6}
                      variant="indeterminate"
                      disableShrink
                      sx={{
                        color: "#fff",
                        animationDuration: "450ms",
                      }}
                    />
                  )}
                </button>
              )}
          </div>

          <NotificationCard
            notificationsData={notificationsData}
            filter={viewOption}
            notificationClick={notificationClick}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default NotificationBell;
