/////old //////////////////////

// import { React } from "react";
// import { HashRouter as Router, Routes, Route } from "react-router-dom";
// //css
// import "./App.css";
// //components
// import LoginPage from "./pages/LoginPage/LoginPage";
// import HomePage from "./pages/HomePage/HomePage";
// import BuyerSignup from "./pages/BuyerSignup/BuyerSignup";
// import VendorSignup from "./pages/VendorSignup/VendorSignup";
// import ErrorPage from "./components/ErrorPage";
// import OrganisationDashboard from "./pages/OrganisationDashboard/OrganisationDashboard";
// import BuyerDashboard from "./pages/BuyerDashboard/BuyerDashboard";
// import PrivateRoute from "./services/PrivateRoute/PrivateRoute";
// import BuyersTab from "./tabs/OrganisationTabs/BuyersTab/BuyersTab";
// import VendorsTab from "./tabs/OrganisationTabs/VendorsTab/VendorsTab";
// import BuyerVendorsTab from "./tabs/BuyerTabs/VendorsTab/VendorsTab";
// import MyProfile from "./tabs/OrganisationTabs/MyProfile/MyProfile";
// import VendorDashboard from "./pages/VendorDashboard/VendorDashboard";
// import VendorProfile from "./tabs/VendorsTabs/VendorProfile/VendorProfile";
// import VendorRFQ from "./tabs/VendorsTabs/VendorRFQTab/VendorRFQTab";
// import VendorRFQTab from "./tabs/VendorsTabs/VendorRFQTab/VendorRFQTab";

// function App() {
//   return (
//     <div className="main">
//       <Router>
//         <Routes>
//           <Route path="*" element={<ErrorPage />} />

//           {/* login page routes */}

//           <Route path="/login" element={<LoginPage />} />

//           {/* organisation dashboards routes */}

//           <Route
//             path="/Organization_dashboard"
//             element={<PrivateRoute Element={OrganisationDashboard} />}
//           >
//             {/* <Route index element={<DashboardTab />} />
//                         <Route path="/Organization_dashboard/" element={<DashboardTab />} /> */}
//             <Route index element={<BuyersTab />} />
//             <Route
//               path="/Organization_dashboard/buyers"
//               element={<BuyersTab />}
//             />
//             <Route
//               path="/Organization_dashboard/vendors"
//               element={<VendorsTab />}
//             />
//             {/* <Route path="/Organization_dashboard/RFQ" element={<RFQTab />} />
//                         <Route path="/Organization_dashboard/items" element={<ItemsTab />} /> */}
//             <Route
//               path="/Organization_dashboard/My_profile"
//               element={<MyProfile />}
//             />
//           </Route>

//           {/* Buyer dashboards routes */}

//           <Route
//             path="/Buyer_dashboard"
//             element={<PrivateRoute Element={BuyerDashboard} />}
//           >
//             {/* <Route index element={<BuyerDashboardTab />} />
//                         <Route path="/Buyer_dashboard/" element={<BuyerDashboardTab />} /> */}
//             <Route index element={<BuyerVendorsTab />} />
//             <Route
//               path="/Buyer_dashboard/vendors"
//               element={<BuyerVendorsTab />}
//             />
//             <Route
//               path="/Buyer_dashboard/My_profile"
//               element={<MyProfile />}
//             />
//             {/* <Route path="/Buyer_dashboard/RFQ" element={<BuyerRFQTab />} />
//                         <Route path="/Buyer_dashboard/Comparison" element={<ComparisonTab />} />
//                         <Route path="/Buyer_dashboard/Comparison_analytics" element={<CompAnalyticsTab />} /> */}
//           </Route>

//           {/* vendor dashboards routes */}

//           <Route path="/Vendor_dashboard" element={<PrivateRoute Element={VendorDashboard} />}>
//             {/* <Route path="/Vendor_dashboard/" element={<DashboardTab />} /> */}
//             <Route path="/Vendor_dashboard/RFQ" element={<VendorRFQTab />} />
//             <Route
//               path="/Vendor_dashboard/My_profile"
//               element={<VendorProfile />}
//             />
//             <Route
//               index
//               element={<VendorProfile />}
//             />
//           </Route>

//           {/* home page routes */}

//           <Route path="/home" element={<HomePage />}></Route>

//           <Route path="/" element={<HomePage />}></Route>

//           {/* signup routes */}

//           <Route path="/organisation_signup" element={<BuyerSignup />}></Route>

//           <Route path="/vendor_signup" element={<VendorSignup />}></Route>
//         </Routes>
//       </Router>
//     </div>
//   );
// }

// export default App;

/////new////
import React, { Suspense, lazy } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./services/PrivateRoute/PrivateRoute";
import ErrorElement from "./components/common/ErrorElement/ErrorElement";
import AppLoader from "./components/common/AppLoader/AppLoader";
import ItemsTab from "./tabs/OrganisationTabs/ItemsTab/ItemsTab";
import BuyerItemsTab from "./tabs/BuyerTabs/ItemsTab/ItemsTab";
import RFQTab from "./tabs/OrganisationTabs/RFQTab/RFQTab";
import BuyerRFQTab from "./tabs/BuyerTabs/RFQTab/RFQTab";
import BuyerRFQDraftTab from "./tabs/BuyerTabs/RFQDraftTab/RFQDraftTab";
import PrivacyPolicyLayout from "./pages/HomePage/Legals/PrivacyPolicy/PrivacyPolicyLayout";
import CookiePolicyLayout from "./pages/HomePage/Legals/CookiePolicy/CookiePolicyLayout";
import DashboardTab from "./tabs/OrganisationTabs/DashboardTab/DashboardTab";
import BuyerDashboardTab from "./tabs/BuyerTabs/DashboardTab/BuyerDashboardTab";
import VendorDashboardTab from "./tabs/VendorsTabs/VendorDashboardTab/VendorDashboardTab";
import ComparisonTab from "./tabs/BuyerTabs/ComparisonTab/ComparisonTab";
import Prompt from "./tabs/OrganisationTabs/Prompt/Prompt";
import PromptCreation from "./tabs/OrganisationTabs/Prompt/PromptCreation";
import PoTab from "./tabs/BuyerTabs/PoTab/PoTab";

// Lazy-loaded components
const LoginPage = lazy(() => import("./pages/LoginPage/LoginPage"));
const AdminLoginPage = lazy(() => import("./pages/AdminLogin/AdminLogin"));
const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const BuyerSignup = lazy(() => import("./pages/BuyerSignup/BuyerSignup"));
const VendorSignup = lazy(() => import("./pages/VendorSignup/VendorSignup"));
const AdminDashboard = lazy(() =>
  import("./pages/AdminDashboard/AdminDashboard")
);
const OrganisationDashboard = lazy(() =>
  import("./pages/OrganisationDashboard/OrganisationDashboard")
);
const BuyerDashboard = lazy(() =>
  import("./pages/BuyerDashboard/BuyerDashboard")
);
const BuyersTab = lazy(() =>
  import("./tabs/OrganisationTabs/BuyersTab/BuyersTab")
);
const VendorsTab = lazy(() =>
  import("./tabs/OrganisationTabs/VendorsTab/VendorsTab")
);
const BuyerVendorsTab = lazy(() =>
  import("./tabs/BuyerTabs/VendorsTab/VendorsTab")
);
const MyProfile = lazy(() =>
  import("./tabs/OrganisationTabs/MyProfile/MyProfile")
);
const VendorDashboard = lazy(() =>
  import("./pages/VendorDashboard/VendorDashboard")
);
const VendorProfile = lazy(() =>
  import("./tabs/VendorsTabs/VendorProfile/VendorProfile")
);
const VendorRFQTab = lazy(() =>
  import("./tabs/VendorsTabs/VendorRFQTab/VendorRFQTab")
);
const VendorUsersTab = lazy(() =>
  import("./tabs/VendorsTabs/VendorUsersTab/VendorUsersTab")
);

function App() {
  return (
    <div className="main">
      <Router>
        <Suspense fallback={<AppLoader />}>
          <Routes>
            <Route path="*" element={<ErrorElement />} />

            <Route path="/login" element={<LoginPage />} />
            <Route path="/ai_dialogue" element={<AdminLoginPage />} />

            <Route path="/privacy" element={<PrivacyPolicyLayout />} />
            <Route path="/cookie" element={<CookiePolicyLayout />} />

            <Route
              path="/Dialogue_list"
              element={<PrivateRoute Element={AdminDashboard} />}
            >
              <Route index element={<Prompt />} />
              <Route path="Prompt" element={<Prompt />} />
              <Route path="Prompt_creation" element={<PromptCreation />} />
            </Route>

            <Route
              path="/Organization_dashboard"
              element={<PrivateRoute Element={OrganisationDashboard} />}
            >
              <Route index element={<DashboardTab />} />
              <Route path="Dashboard" element={<DashboardTab />} />
              <Route path="buyers" element={<BuyersTab />} />
              <Route path="vendors" element={<VendorsTab />} />
              <Route path="RFQ" element={<RFQTab />} />
              <Route path="items" element={<ItemsTab />} />
              <Route path="My_profile" element={<MyProfile />} />
            </Route>

            <Route
              path="/Buyer_dashboard"
              element={<PrivateRoute Element={BuyerDashboard} />}
            >
              <Route index element={<BuyerDashboardTab />} />
              <Route
                path="/Buyer_dashboard/Dashboard"
                element={<BuyerDashboardTab />}
              />
              <Route path="vendors" element={<BuyerVendorsTab />} />
              <Route
                path="/Buyer_dashboard/Items"
                element={<BuyerItemsTab />}
              />
              <Route path="/Buyer_dashboard/RFQ" element={<BuyerRFQTab />} />
              <Route
                path="/Buyer_dashboard/RFQ_Draft"
                element={<BuyerRFQDraftTab />}
              />
              <Route path="/Buyer_dashboard/PO" element={<PoTab />} />
              <Route
                path="/Buyer_dashboard/Comparison"
                element={<ComparisonTab />}
              />
              {/* <Route path="/Buyer_dashboard/Comparison_analytics" element={<ComparisonAnalyticsTab />} /> */}
              <Route path="My_profile" element={<MyProfile />} />
            </Route>

            <Route
              path="/Vendor_dashboard"
              element={<PrivateRoute Element={VendorDashboard} />}
            >
              <Route index element={<VendorDashboardTab />} />
              <Route path="Dashboard" element={<VendorDashboardTab />} />
              <Route path="RFQ" element={<VendorRFQTab />} />
              <Route path="Users" element={<VendorUsersTab />} />
              <Route path="My_profile" element={<VendorProfile />} />
            </Route>

            <Route path="/home" element={<HomePage />} />
            <Route path="/" element={<HomePage />} />
            <Route
              path="/buyer_organization_signup"
              element={<BuyerSignup />}
            />

            {/* vendor signup will accept the Referral url when its available as ref_code
            --- otherwise it will not ---  */}

            <Route path="/vendor_signup" element={<VendorSignup />} />
            <Route path="/vendor_signup/:ref_code" element={<VendorSignup />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}
export default App;
