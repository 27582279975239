import React, { useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Box,
  Fade,
  Modal,
  Skeleton,
  ThemeProvider,
  Autocomplete,
  TextField,
  createTheme,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

import { API_ENDPOINT } from "../../../services/api/resources";
import SuccessPopupDashboard from "../../../components/Popups/PopupFrame/SuccessPopupDashboard";
import DMultipleFileUpload from "../../../components/Dashboard/DashboardInputs/DMultipleFileUpload";
import HeaderTitle from "../../../components/common/HeaderTitle/HeaderTitle";
import DateBar from "../../../components/common/DateBar/DateBar";
import NotificationBell from "../../../components/common/NotificationBell/NotificationBell";
import ProfileCard from "../../../components/common/ProfileCard/ProfileCard";
import DTextInput from "../../../components/Dashboard/DashboardInputs/DTextInput";
import DTextArea from "../../../components/Dashboard/DashboardInputs/DTextArea";
import DFileUpload from "../../../components/Dashboard/DashboardInputs/DFileUpload";
import DefaultLabel from "../../../components/Dashboard/DefaultLabel/DefaultLabel";
import DRadioInput from "../../../components/Dashboard/DashboardInputs/DRadioInput";
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import SearchBar from "../../../components/common/SearchBar/SearchBar";
import PaginantionComponent from "../../../components/common/Pagination/PaginationComponent";
import {
  emptyCheck,
  validateFile,
  validateMultipleFiles,
} from "../../../utils/validations";
import { nextPageClick, previouPageClick } from "../../../utils/pagination";
//css
import classes from "./ComparisonTab.module.css";
import styles from "../../../components/Dashboard/DashboardInputs/DashboardInput.module.css";

//img
import somthingWrongImg from "../../../assets/images/png/warning.png";
import successImg from "../../../assets/images/png/Success Rate 2@2x.png";
import { flattenNestedObjects } from "../../../utils/compareObjectFormater";
import ComparedTable from "../../../components/common/Comparison/ComparedTable";
const theme = createTheme({
  typography: {
    // fontSize: "1.4rem"
    htmlFontSize: 9.8,
  },
});
const filter = createFilterOptions();
function compareReducer(state, action) {
  switch (action.type) {
    case "newcompare":
      return {
        ...state,
        isNewComparison: true,
        isHistoryComparison: false,
        showCompareTable: false,
      };
    case "backtocompare":
      return {
        ...state,
        isNewComparison: false,
        isHistoryComparison: true,
        showCompareTable: false,
        comparedData: [],
      };
    case "viewcomparedhistory":
      return {
        ...state,
        isNewComparison: false,
        isHistoryComparison: false,
        showCompareTable: true,
        comparedData: action.payload["tableData"],
        comparedNameDate: action.payload["headerTitle"],
        comparedJsonData: action.payload["fileJsonHistory"],
      };
    // case "comparecompleted":
    //   return {...state, isNewComparison: false, isHistoryComparison: false, showCompareTable: true, comparedData: action.payload}
    case "comparefailed":
      return {
        ...state,
        isNewComparison: true,
        isHistoryComparison: false,
        showCompareTable: false,
        comparedData: [],
      };
    case "view_compared_history_by_mail_url":
      return {
        ...state,
        isNewComparison: false,
        isHistoryComparison: false,
        showCompareTable: true,
        showBackButton: true,
        comparedData: action.payload,
      };
    default:
      return state;
  }
}
function ComapareHistoryCard({ historyData, viewHistory, isLoading }) {
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const parts = formatter.formatToParts(date);

    const month = parts.find((part) => part.type === "month").value;
    const day = parts.find((part) => part.type === "day").value;
    const year = parts.find((part) => part.type === "year").value;
    const hour = parts.find((part) => part.type === "hour").value;
    const minute = parts.find((part) => part.type === "minute").value;

    const ampm = date.getHours() >= 12 ? "PM" : "AM";
    const formattedDateString = `${day} ${month} ${year} - ${hour}:${minute} ${ampm}`;

    return formattedDateString;
  }
  return (
    <>
      {!isLoading ? (
        historyData?.length > 0 ? (
          historyData?.map((item, index) => (
            <div className={classes.cardItem} key={index}>
              <div className={classes.cardHeader}>
                <h4>PRN: {item.prn_number}</h4>
              </div>
              <div className={classes.cardFooter}>
                <div className={classes.comparedDateWrapper}>
                  <p className={classes.comparedDateTitle}>Compared On</p>
                  <p className={classes.comparedDate}>
                    {item.created_date ? formatDate(item.created_date) : ""}
                  </p>
                </div>
                <button
                  className={classes.compareViewBtn}
                  onClick={() => viewHistory(item)}
                >
                  VIEW DETAIL
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className={classes.historyNotAvailable}>
            <h2>No result found!</h2>
          </div>
        )
      ) : (
        <>
          {[...Array(4)].map((_, index) => (
            <div
              className={`${classes.HistorySkeleton} ${classes.cardItem}`}
              style={{ maxHeight: "120px" }}
            >
              <span>
                <Skeleton height={"20px"} />
              </span>
              <br />
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Skeleton
                  width={"65%"}
                  height={"60px"}
                  sx={{ transform: "scale(1)" }}
                />
                <Skeleton
                  width={"20%"}
                  height={"20px"}
                  sx={{ transform: "scale(1)" }}
                />
              </span>
            </div>
          ))}
        </>
      )}
    </>
  );
}
const ComparisonTab = () => {
  //location
  let location = useLocation();
  //get data from use selector
  const data = useSelector((state) => state.loginData.value);
  //regex for validations
  const regexForAlphaNumeric = /[^a-zA-Z0-9\s]+/g;
  const failedStatus = {
    popupHeroText: "Comparison Failed!",
    popupDescription:
      "An error occurred during the intelligent comparison process.",
    warningImg: somthingWrongImg,
  };
  //api call cancel
  const abortController = new AbortController();
  const signal = abortController.signal;
  //get url key value
  const queryParams = new URLSearchParams(location.search);
  const urlPath = queryParams?.get("token");

  //credentials
  const [auth_token, setAuthToken] = useState(data?.auth_token);
  const [user_token, setUserToken] = useState(data?.user?.user_token);
  const [buyer_token, setBuyerToken] = useState(data?.user?.token);
  //popup state
  const [openModalPopup, setOpenModalPopup] = useState(false);
  //failed state
  const [popupText, setPopupTexts] = useState({
    popupHeroText: "",
    popupDescription: "",
  });
  //validation error state
  const [btnError, setBtnError] = useState({
    isError: false,
    errorMsg: "",
  });
  // ======= Compare input states start ======== //
  const [PurchaseReqNumber, setPurchaseReqNumber] = useState({
    value: "",
    isValid: true,
  });
  const [RFQCategory, setRFQCategory] = useState({
    value: "",
    id: "",
    categoryList: [],
    isValid: true,
  });
  const [vendorQuotationDocument, setVendorQuotationDocument] = useState([
    {
      value: "",
      isValid: true,
      errorMessage: "",
    },
  ]);
  const [VendorQuotationDescription, setVendorQuotationDescription] = useState({
    value: "",
    isValid: true,
  });
  const [VendorQuotationUploadDocument, setVendorQuotationFileType] = useState({
    uploadFile: true,
    uploadDescripton: false,
  });
  const [BuyerReqUploadDocument, setBuyerReqUploadDocument] = useState({
    uploadFile: true,
    uploadDescripton: false,
  });
  const [BuyerReqDocument, setBuyerReqDocument] = useState({
    value: "",
    isValid: true,
  });
  const [BuyerReqDescription, setBuyerReqDescription] = useState({
    value: "",
    isValid: true,
  });
  const [isCompareLoading, setIsCompareLoading] = useState(false);
  // ======= Compare input states end ======== //

  // ======= After completed states start ======== //
  const [chatGptToken, setchatGptToken] = useState("");
  // ======= After completed states end ======== //

  // ======= History states start ======== //
  const [componentMounted, setComponentMounted] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [comparedHistory, setComparedHistory] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationData, setPaginationData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  // ======= History states end ======== //

  const itemsPerPage = paginationData?.current_page_count;
  const totalItems = paginationData?.page_count;
  //compare reducer initial
  const compareInitial = {
    isNewComparison: false,
    isHistoryComparison: true,
    isComparing: false,
    isComparingSuccess: false,
    showCompareTable: false,
    comparedData: [],
    comparedJsonData: [],
    comparedNameDate: {},
    showBackButton: true,
  };
  const [compareState, compareDispatch] = useReducer(
    compareReducer,
    compareInitial
  );

  //reset comapare states
  const resetForm = () => {
    setBtnError({
      isError: false,
      errorMsg: "",
    });
    setPopupTexts({
      popupHeroText: "",
      popupDescription: "",
    });
    setPurchaseReqNumber({
      value: "",
      isValid: true,
    });
    setRFQCategory({
      value: "",
      categoryList: {
        tokens: [],
        visibleOption: [],
      },
      isValid: true,
    });
    setVendorQuotationDocument([
      {
        value: "",
        isValid: true,
      },
    ]);
    setVendorQuotationDescription({
      value: "",
      isValid: true,
    });
    setVendorQuotationFileType({
      uploadFile: true,
      uploadDescripton: false,
    });
    setBuyerReqUploadDocument({
      uploadFile: true,
      uploadDescripton: false,
    });
    setBuyerReqDocument({
      value: "",
      isValid: true,
    });
    setBuyerReqDescription({
      value: "",
      isValid: true,
    });
  };
  useEffect(() => {}, [RFQCategory]);
  //compare inprogress & complete and failed state handling
  useEffect(() => {
    const sendComparitionToken = async () => {
      let token = chatGptToken;
      if (!token) {
        return;
      }
      await axios({
        method: "POST",
        url: `${API_ENDPOINT.response_data}`,
        data: { success_token: token },
      })
        .then((res) => {
          setchatGptToken("");
        })
        .catch((err) => {
          compareFailedStatusUpdate(); //modal popup status update to failed content
        });
    };
    if (chatGptToken) {
      sendComparitionToken(); // initial call
    }
  }, [chatGptToken]);

  // Define an array to store interval IDs
  const intervalIds = [];
  useEffect(() => {
    setComponentMounted(true); // Mark the component as mounted after initial render
    if (pageNumber && componentMounted) {
      GetCompareHistoryData();
      const intervalId = setInterval(GetCompareHistoryData, 60 * 1000); // one min
      intervalIds.push(intervalId); // Store interval ID
    }
    return () => {
      intervalIds.forEach((id) => clearInterval(id)); // Clear all intervals
      // Abort the request only if the request has been initiated
      if (componentMounted) {
        abortController.abort();
      }
    };
  }, [pageNumber, searchQuery, location, componentMounted, compareState]); // Run the cleanup function when the component is unmounted or componentMounted changes
  useEffect(() => {
    intervalIds.forEach((id) => clearInterval(id)); // Clear all intervals
  }, [searchQuery, pageNumber]);
  //check come from mail link
  useEffect(() => {
    if (urlPath) {
      getCompareHistoryUsingUrlToken(urlPath);
    }
  }, [urlPath]);

  //get RFQ category list
  const getCategoryList = () => {
    axios({
      method: "GET",
      url: `${API_ENDPOINT.list_of_prompt_category}`,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((response) => {
        let promptList = response.data.response;
        setRFQCategory({
          ...RFQCategory,
          categoryList: promptList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get compared history
  const GetCompareHistoryData = (param) => {
    setisLoading(true);
    const formData = new FormData();
    formData.append("buyer_token", buyer_token);
    searchQuery && formData.append("search", searchQuery);
    pageNumber && formData.append("page", pageNumber);
    axios({
      method: "POST",
      url: `${API_ENDPOINT.comparison_history}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
      signal: signal,
    })
      .then((response) => {
        setisLoading(false);
        let history = response.data.response;
        let paginationData = response.data.pagination;
        if (history) {
          setComparedHistory(history);
          setPaginationData(paginationData);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.log("Other error occurred:", error);
        }
        setisLoading(false);
        intervalIds.forEach((id) => clearInterval(id)); // Clear all intervals
      });
  };
  //get mail url history
  const getCompareHistoryUsingUrlToken = (token) => {
    setisLoading(true);
    const formData = new FormData();
    formData.append("buyer_token", buyer_token);
    formData.append("compare_history", token);
    searchQuery && formData.append("search", searchQuery);
    pageNumber && formData.append("page", pageNumber);
    axios({
      method: "POST",
      url: `${API_ENDPOINT.comparison_history}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
      signal: signal,
    })
      .then((response) => {
        setisLoading(false);
        let history = response.data.response;
        if (history) {
          compareDispatch({
            type: "view_compared_history_by_mail_url",
            payload: history,
          });
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.log("Other error occurred:", error);
        }
        setisLoading(false);
      });
  };
  //comapare modal colse handle
  const handleCloseComparePopup = () => {
    setOpenModalPopup(false);
    resetForm();
    compareDispatch({ type: "backtocompare" });
    setPageNumber(1);
  };

  //comapare modal open handle
  const handleOpenComparePopup = () => {
    setOpenModalPopup(true);
    setPopupTexts({
      popupHeroText: "Comparison in Progress.",
      popupDescription:
        "Our Bidz.ai automation engine is currently analyzing technical specifications and commercial details to generate a comprehensive techno-commercial comparison chart. The details will be available on the comparison analytics page soon.",
    });
  };

  //compare form validation
  const validateForm = () => {
    const isPurchaseRequestNumber = PurchaseReqNumber?.value.trim() !== "";
    const isBuyerDescription = BuyerReqDescription?.value.trim() !== "";
    const isVendorDescription = VendorQuotationDescription?.value.trim() !== "";
    const isRFQCategorySelectOption = RFQCategory?.value.trim() !== "";
    const isBuyerDocument = validateFile(
      BuyerReqDocument.value,
      false,
      ["pdf"],
      5
    ).isValid;
    const updatedData = vendorQuotationDocument.map((file) => {
      return validateMultipleFiles(
        file.value,
        false, // Assuming files are required
        ["pdf"], // Allowed formats
        5, // Max file size in MB
        "Quotation Document"
      );
    });
    const isVendorQuotationDocument = updatedData.every((file) => file.isValid);
    setPurchaseReqNumber((prev) => ({
      ...prev,
      isValid: isPurchaseRequestNumber,
    }));
    setRFQCategory((prev) => ({
      ...prev,
      isValid: isRFQCategorySelectOption,
    }));
    setBuyerReqDocument((prev) => ({
      ...prev,
      isValid: isBuyerDocument,
    }));
    setBuyerReqDescription((prev) => ({
      ...prev,
      isValid: isBuyerDescription,
    }));
    setVendorQuotationDocument((prev) => updatedData);
    setVendorQuotationDescription((prev) => ({
      ...prev,
      isValid: isVendorDescription,
    }));

    let buyerDoc = BuyerReqUploadDocument.uploadFile
      ? isBuyerDocument
      : BuyerReqUploadDocument.uploadDescripton && isBuyerDescription;
    let vendorDoc = VendorQuotationUploadDocument.uploadFile
      ? isVendorQuotationDocument
      : VendorQuotationUploadDocument.uploadDescripton && isVendorDescription;
    return (
      buyerDoc &&
      isPurchaseRequestNumber &&
      isRFQCategorySelectOption &&
      vendorDoc
    );
  };

  //compare failed popup state handling
  function compareFailedStatusUpdate() {
    compareDispatch({ type: "compareFailed", payload: failedStatus });
    setchatGptToken("");
    setOpenModalPopup(true);
    setPopupTexts(failedStatus);
  }

  const getVendorQuotationDocument = (vendorFiles) => {
    return vendorFiles.map((file) => file.value && file.value);
  };
  //get chatgpt compare token
  const getComapreToken = async () => {
    // setLatestStatus("");
    setIsCompareLoading(true);
    let isQuotationFile = VendorQuotationUploadDocument.uploadFile;
    let isReqFileUpload = BuyerReqUploadDocument.uploadFile;
    const vendorQuotationFilesData = getVendorQuotationDocument(
      vendorQuotationDocument
    ); //vendor file array
    const formData = new FormData();
    isReqFileUpload
      ? formData.append("file1", BuyerReqDocument.value)
      : formData.append("data1", BuyerReqDescription.value);
    if (isQuotationFile) {
      //check is choosed File Upload
      if (vendorQuotationFilesData.length > 0) {
        for (let i = 0; i < vendorQuotationFilesData.length; i++) {
          vendorQuotationFilesData[i] &&
            formData.append(`file${i + 2}`, vendorQuotationFilesData[i]);
        }
      }
    }
    formData.append("purchase_req_number", PurchaseReqNumber.value);
    formData.append("category_compare", RFQCategory.id);
    formData.append("buyer_token", buyer_token);
    formData.append("file_number", vendorQuotationFilesData?.length + 1);
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.newversion_chatgpt}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setIsCompareLoading(false);
        let websocketRoomId = res.data.response;
        setchatGptToken(websocketRoomId);
        handleOpenComparePopup(); //enable compare loader
      })
      .catch((err) => {
        console.log("error", err);
        setIsCompareLoading(false);
        compareFailedStatusUpdate(); //modal popup status update to failed content
      });
  };
  //handle compare
  const handleSubmit = (value) => {
    const isValidated = validateForm();

    if (isValidated) {
      const formData = new FormData();
      formData.append("number_prn", PurchaseReqNumber.value);
      axios({
        method: "POST",
        url: `${API_ENDPOINT.check_prn_number_unic}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      })
        .then((res) => {
          getComapreToken();
          setBtnError({
            isError: false,
            errorMsg: "",
          });
        })
        .catch((error) => {
          const errorResponse = error.response;
          console.log("errorResponse", errorResponse);
          if (errorResponse.status === 400) {
            setBtnError({
              isError: true,
              errorMsg: errorResponse.data.response,
            });
          }
        });
    } else {
      setBtnError({
        isError: true,
        errorMsg: "Please fill out all fields",
      });
      setBuyerReqDocument((prev) => ({
        value: prev.value,
        isValid: prev.isValid,
      }));
      setBuyerReqDescription((prev) => ({
        value: prev.value,
        isValid: prev.isValid,
      }));
      setVendorQuotationDescription((prev) => ({
        value: prev.value,
        isValid: prev.isValid,
      }));
    }
  };

  // ================ add or remove multiple file upload start ===============//
  const handleAddUpload = () => {
    // if (vendorQuotationDocument.length < 5) {
    setVendorQuotationDocument((prevUploads) => [
      ...prevUploads,
      { value: "", isValid: true },
    ]);
    // }
  };
  const handleRemoveUpload = (index) => {
    const newUploads = [...vendorQuotationDocument];
    newUploads.splice(index, 1);
    setVendorQuotationDocument(newUploads);
  };
  const handleFileUpload = (file, index) => {
    let isValidFile = validateFile(
      file,
      false,
      ["pdf"],
      5,
      "Quotation Document"
    ).isValid;
    let isValidFileMessage = validateFile(
      file,
      false,
      ["pdf"],
      5,
      "Quotation Document"
    ).errorMessage;
    setVendorQuotationDocument((prevUploads) => {
      const newUploads = [...prevUploads];
      newUploads[index] = {
        value: file,
        isValid: isValidFile,
        errorMessage: isValidFileMessage,
      };
      return newUploads;
    });
  };
  // ================ add or remove multiple file upload end ===============//

  // ================ history compare end ===============//
  const handleNewCompare = () => {
    resetForm();
    compareDispatch({ type: "newcompare" });
    getCategoryList(); //get prompt rfq category list
    intervalIds.forEach((id) => clearInterval(id));
  };

  const handleViewHistory = (selectedHistoryData) => {
    try {
      const quoteDetails = selectedHistoryData.json_data; //JSON.parse(selectedHistoryData.json_data);
      const flattenedData = flattenNestedObjects(quoteDetails);
      const headerdata = {
        prn_number: selectedHistoryData.prn_number,
        created_date: selectedHistoryData.created_date,
      };
      const fileJsonHistory = selectedHistoryData.datas;
      compareDispatch({
        type: "viewcomparedhistory",
        payload: {
          tableData: flattenedData,
          headerTitle: headerdata,
          fileJsonHistory: fileJsonHistory,
        },
      });
    } catch (error) {
      console.log(error);
      compareFailedStatusUpdate(); //modal popup status update to failed content
    }
  };

  const handleBackToHistory = () => {
    // Remove the "get data" portion from the URL
    var url = window.location.href.replace(
      `?${location.search.split("?")[1]}`,
      ""
    );
    // Navigate to the updated URL
    window.location.href = url;
    compareDispatch({ type: "backtocompare" });
    setPageNumber(1);
  };

  const countries = [
    { code: "AD", label: "Andorra", phone: "376" },
    {
      code: "AE",
      label: "United Arab Emirates",
      phone: "971",
    },
    { code: "AF", label: "Afghanistan", phone: "93" },
    {
      code: "AG",
      label: "Antigua and Barbuda",
      phone: "1-268",
    },
  ];
  return (
    <div className={classes.comparison_tab_wrapper}>
      <div className={classes.users_topHeader}>
        <div className={`${classes.lhs} flex`}>
          <HeaderTitle titleText="Comparison" customclass={classes.dashHead} />
        </div>

        <div className={`${classes.rhs} flex`}>
          <DateBar />
          <NotificationBell />
          <ProfileCard
            buyerBtn={true}
            buyerBtnOutline={true}
            profilePath={"/Buyer_dashboard/My_profile"}
          />
        </div>
      </div>

      <>
        {/* Compare history UI */}
        {compareState.isHistoryComparison && (
          <div className={classes.compareHistory}>
            {
              <div className={classes.searchHistory}>
                <SearchBar
                  value={searchQuery}
                  onChange={(history) => {
                    setSearchQuery(history);
                    setPageNumber(1);
                  }}
                  customClass={classes.historyInputWrapper}
                />
                <DButton
                  iconWidth="1rem"
                  iconHeight="1rem"
                  label={"ADD COMPARISON"}
                  btnHeight="4rem"
                  buyerBtn={true}
                  onClick={handleNewCompare}
                  customWrapperClass={classes.comapreBtnWrapper}
                  customClass={classes.comapreBtn}
                />
              </div>
            }
            <div className={classes.cardMain}>
              <ComapareHistoryCard
                historyData={comparedHistory}
                viewHistory={handleViewHistory}
                isLoading={isLoading}
              />
            </div>
            {!isLoading && (
              <div className={classes.paginationWrapper}>
                <PaginantionComponent
                  pageNumber={pageNumber}
                  currentPage={currentPage || 0}
                  itemsPerPage={itemsPerPage || 0}
                  totalItems={totalItems || 0}
                  setPageNumber={setPageNumber}
                  previouPageClick={() => {
                    previouPageClick(pageNumber, setPageNumber);
                  }}
                  nextPageClick={() => {
                    nextPageClick(pageNumber, setPageNumber, paginationData);
                  }}
                  disablePrevious={
                    parseInt(pageNumber) === 1 || pageNumber === ""
                  }
                  disableNext={
                    parseInt(pageNumber) === paginationData?.page_count ||
                    !paginationData ||
                    pageNumber === ""
                  }
                />
              </div>
            )}
          </div>
        )}
        {/* New compare UI */}
        {compareState.isNewComparison && (
          <div className={classes.tabsScreens} style={{ position: "relative" }}>
            <DButton
              label={"BACK"}
              btnHeight="4rem"
              btnWidth={"12rem"}
              buyerBtn={true}
              onClick={handleBackToHistory}
              customWrapperClass={classes.positionTopRight}
            />
            <DTextInput
              customClassName={classes.fields}
              label="Purchase Requisition Number"
              value={PurchaseReqNumber.value}
              onChange={(e) => {
                let val = e?.target?.value.replace(regexForAlphaNumeric, "");
                setPurchaseReqNumber({
                  value: val,
                  isValid: emptyCheck(e.target.value),
                });
              }}
              error={!PurchaseReqNumber.isValid}
              errorMsg={"Purchase Requisition number is required"}
              size={"400px"}
            />
            <ThemeProvider theme={theme}>
              <div
                className={`${styles.select_container}`}
                style={{ width: "400px" }}
              >
                <label>Quotation Category</label>
                <Autocomplete
                  value={RFQCategory.value}
                  onChange={(event, newValue) => {
                    setRFQCategory((state) => ({
                      ...state,
                      value: newValue?.category ? newValue?.category : "",
                      id: newValue?.id ? newValue?.id : "",
                      isValid: newValue?.category ? true : false,
                    }));
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.category
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  options={RFQCategory.categoryList}
                  // placeholder="Select Quotation Category"
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.category) {
                      return option.category;
                    }
                    // Regular option
                    return option.category;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.category}</li>
                  )}
                  freeSolo
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      value={RFQCategory.value}
                      error={!RFQCategory.isValid}
                      placeholder="Quotation Category"
                    />
                  )}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="quotation_category"
                  size="small"
                  className={`${styles.select_wrapper_multiSelect} ${
                    !RFQCategory.isValid ? styles.error : ""
                  }`}
                  sx={{
                    minHeight: "4.2rem",
                    height: "auto",
                    fontSize: "1.4rem",
                    transition: "0",
                    ".Mui-expanded": {
                      transition: "none",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "0",
                    },
                    ".MuiAutocomplete-input": {
                      fontSize: "1.4rem",
                      fontFamily: "GilroyMedium",
                      height: "2.6rem",
                    },
                    ".MuiFormLabel-root": {
                      display: "none",
                    },
                    ".MuiChip-root": {
                      height: "30px",
                      padding: "5px",
                    },
                  }}
                  componentsProps={{
                    popper: {
                      sx: {
                        fontFamily: "gilroyMedium",
                      },
                      modifiers: [
                        {
                          name: "flip",
                          enabled: false,
                        },
                        {
                          name: "preventOverflow",
                          enabled: true,
                        },
                      ],
                    },
                  }}
                />
                {!RFQCategory.isValid && (
                  <p className={styles.error_message}>
                    Quotation Category is required
                  </p>
                )}
              </div>
            </ThemeProvider>
            {/* <DSelectInput
              customClass={classes.fields}
              multiselect={false}
              label="Select RFQ Category"
              value={RFQCategory.value}
              getIDValue={true}
              options={
                RFQCategory.categoryList > 0
                  ? {
                      visibleOption:
                        RFQCategory.categoryList.visibleOption?.map(
                          (el) => el.category
                        ),
                    }
                  : { visibleOption: ["No states available"] }
              }
              // setIDValue={setVendorID}
              onChange={(value) => {
                // setRFQCategory((state) => ({
                //   ...state,
                //   value: value,
                //   isValid: value?.length !== 0,
                // }));
              }}
              error={!RFQCategory.isValid}
              errorMsg={"RFQ Category is required"}
              size={"400px"}
            /> */}
            <div className={classes.uploadSection}>
              <div className={classes.uploadInput}>
                {/* <span className={classes.fileHeading}>
                    Buyer Requirement Document
                  </span> */}
                <div className={classes.input_container}>
                  <DefaultLabel label={"Upload RFQ/Requirement document"} />

                  <div className={classes.util}>
                    <DRadioInput
                      label={"File Upload"}
                      id={"file"}
                      value={"BuyerReqUploadDocument"}
                      checked={BuyerReqUploadDocument.uploadFile}
                      customClass={classes.radioTag}
                      onChange={(val) => {
                        setBuyerReqUploadDocument({
                          uploadFile: val,
                          uploadDescripton: false,
                        });
                        setBuyerReqDescription({
                          value: "",
                          isValid: true,
                        });
                      }}
                      name={"BuyerReqUploadDocument"}
                    />

                    <DRadioInput
                      label={"Enter Description"}
                      id={"document"}
                      checked={BuyerReqUploadDocument.uploadDescripton}
                      customClass={classes.radioTag}
                      value={"BuyerReqDescription"}
                      onChange={(val) => {
                        setBuyerReqUploadDocument({
                          uploadFile: false,
                          uploadDescripton: val,
                        });
                        setBuyerReqDocument({
                          value: "",
                          isValid: true,
                        });
                      }}
                      name={"BuyerReqUploadDocument"}
                    />
                  </div>

                  {BuyerReqUploadDocument.uploadDescripton && (
                    <DTextArea
                      placeholder={"Enter description"}
                      type="text"
                      value={BuyerReqDescription.value}
                      onChange={(e) => {
                        let val = e?.target?.value;
                        setBuyerReqDescription({
                          value: val,
                          isValid: val?.trim() !== "",
                        });
                      }}
                      containerClass={classes.proposal_desc}
                      error={!BuyerReqDescription.isValid}
                      errorMsg={"Requirement Description is required"}
                      size={"100%"}
                    />
                  )}

                  {BuyerReqUploadDocument.uploadFile && (
                    <DFileUpload
                      fileTypes={".pdf"}
                      customFileTypeText={"(Supported format PDF)"}
                      value={BuyerReqDocument.value}
                      onFileUpload={(file) => {
                        let isValidFile = validateFile(
                          file,
                          false,
                          ["pdf"],
                          5
                        ).isValid;
                        setBuyerReqDocument({
                          value: file,
                          isValid: isValidFile,
                        });
                      }}
                      error={!BuyerReqDocument.isValid}
                      errorMsg={
                        validateFile(
                          BuyerReqDocument.value,
                          false,
                          ["pdf"],
                          5,
                          "Requirement Document"
                        ).errorMessage
                      }
                      size={"100%"}
                    />
                  )}
                </div>
              </div>

              <div className={classes.uploadInput}>
                <span className={classes.fileHeading}>
                  Vendor Quotation document
                </span>
                <div className={classes.input_container}>
                  <DefaultLabel label={"Upload Quotation document"} />
                  {VendorQuotationUploadDocument.uploadFile && (
                    <>
                      <div className={classes.fileUploadWrapperOuterMain}>
                        {Array.isArray(vendorQuotationDocument) &&
                          vendorQuotationDocument?.map((upload, index) => (
                            <div
                              key={index}
                              className={classes.fileUploadWrapperMain}
                            >
                              <DMultipleFileUpload
                                fileTypes={".pdf"}
                                value={upload.value}
                                onFileUpload={(file) =>
                                  handleFileUpload(file, index)
                                }
                                error={!upload.isValid}
                                errorMsg={upload.errorMessage}
                                size={"100%"}
                              />
                              {index > 0 && (
                                <button
                                  className={classes.removeUploadButton}
                                  onClick={() => handleRemoveUpload(index)}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          ))}
                      </div>
                      {/* {vendorQuotationDocument.length !== 5 && ( */}
                      <button
                        disabled={isCompareLoading}
                        className={classes.addUploadButton}
                        id="add_document"
                        onClick={handleAddUpload}
                      >
                        Add Document
                      </button>
                      {/* )} */}
                    </>
                  )}
                </div>
              </div>
            </div>

            <DButton
              iconWidth="1rem"
              iconHeight="1rem"
              disabled={false}
              label={"Compare"}
              btnHeight="4rem"
              btnWidth="13.2rem"
              buyerBtn={true}
              onClick={() => {
                handleSubmit();
              }}
              error={btnError.isError}
              errorMsg={btnError.errorMsg}
              loadingState={isCompareLoading}
              customWrapperClass={classes.compareBtnWrapper}
              customClass={classes.compareBtn}
            />
          </div>
        )}
        {/* Compared table UI */}
        {compareState.showCompareTable && (
          <>
            {compareState.showBackButton && (
              <DButton
                label={"BACK"}
                btnHeight="4rem"
                btnWidth={"12rem"}
                buyerBtn={true}
                onClick={handleBackToHistory}
                customWrapperClass={classes.comparedTableBack}
              />
            )}
            <ComparedTable
              data={compareState.comparedData}
              compareTitle={compareState.comparedNameDate}
              compareJsonFile={compareState.comparedJsonData}
              backButtonStatus={!compareState.showBackButton}
              isRefetchData={true}
              handleRefetchData={GetCompareHistoryData}
              isLoading={isLoading}
              isAutoRefresh={false}
            />
          </>
        )}
      </>
      {/* comparison modal popup*/}
      <Modal open={openModalPopup}>
        <Fade in={openModalPopup}>
          <Box>
            <SuccessPopupDashboard
              popupImage={
                popupText?.warningImg ? popupText?.warningImg : successImg
              }
              popupHeroText={popupText.popupHeroText}
              popupDescription={popupText.popupDescription}
              loadingPrimaryBtn={false}
              popBtn={true}
              vendorBtn={true}
              vendorBtnOutlined={true}
              btnText={"Okay"}
              btnClick={handleCloseComparePopup}
              customClass={classes.compareModal}
              customHeroImgClass={classes.compareModalImgWrapper}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ComparisonTab;
