import React, { useEffect, useState } from "react";

//img
import profileImg from "../../../assets/images/png/Profile.png";
import btnImg from "../../../assets/images/svg/Search.svg";
import totalPOIMg from "../../../assets/images/svg/Total PO Value.svg";
import totalPOIMg2 from "../../../assets/images/svg/Total PO Value Copy 3.svg";
import totalPOIMg3 from "../../../assets/images/svg/Total PO Value Copy 2.svg";

//compnents
import DateBar from "../../../components/common/DateBar/DateBar";
import NotificationBell from "../../../components/common/NotificationBell/NotificationBell";
import ProfileCard from "../../../components/common/ProfileCard/ProfileCard";
import HeaderTitle from "../../../components/common/HeaderTitle/HeaderTitle";

//css
import classes from "../../TabStyles/DashboardTab.module.css";

//components
import DSelectInput from "../../../components/Dashboard/DashboardInputs/DSelectInput";
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DDateInput from "../../../components/Dashboard/DashboardInputs/DDateInput";

//redux toolkit
import { useSelector } from "react-redux/es/hooks/useSelector";
//charts and metics
import Reportcard from "../../../components/Dashboard/ReportCards/ReportCard";
import DonutChart from "../../../components/Dashboard/Charts/DonutChart/DonutChart";
import LineChart from "../../../components/Dashboard/Charts/LineChart/LineChart";
import BarChart from "../../../components/Dashboard/Charts/BarChart/BarChart";
import HorizontalBarChart from "../../../components/Dashboard/Charts/BarChart/HorizontalBarChart";
import { API_ENDPOINT } from "../../../services/api/resources";
import axios from "axios";
import { emptyCheck } from "../../../utils/validations";
import { ContactsOutlined } from "@mui/icons-material";
import { weeklyReportObjFormate } from "../../../utils/chartKeyFormat";

const BuyerDashboardTab = () => {
  //get data from use selector
  const data = useSelector((state) => state.loginData.value);

  //credentials
  const [auth_token, setAuthToken] = useState(data?.auth_token);
  const [buyer_token, setBuyerToken] = useState(data?.user?.token);
  const [org_token, setOrgToken] = useState(data?.user?.token);
  const [loadingState, setLoadingState] = useState(false);

  // input data
  const [vendorList, setVendorsList] = useState({
    values: {
      tokens: [],
      visibleOption: [],
    },
  });

  const [vendorDetails, setVendorDetails] = useState({
    vendorName: "",
    vendorID: "",
  });
  const [itemList, setItemList] = useState({
    values: {
      tokens: [],
      visibleOption: [],
    },
  });
  const [vendorNameDetails, setVendorNameDetails] = useState({
    buyerName: "",
    buyerID: "",
  });
  const [vendornameList, setVendornameList] = useState({
    values: {
      tokens: [],
      visibleOption: [],
    },
  })
  const [itemCode, setItemCode] = useState({
    item_code: "",
    item_token: "",
  });
  const [duration, setDuration] = useState();
  const [fromDate, setFromDate] = useState({
    value: "",
    isValid: true,
  });
  const [toDate, setToDate] = useState({
    value: "",
    isValid: true,
  });

  const [analyticsData, setAnalyticsData] = useState({
    totalPOValue: "",
    costSavingsMetric1: "",
    costSavingsMetric2: "",
    repeatPurchaseCostSavings: "",
    pendingVsCloseRFQ: [],
    submittedVsSelectedRFQ: [],
    totalRFQCreatedValueMonthWise: [],
    totalRFQCreatedLabelMonthWise: [],
    totalRFQCreatedValueWeekWise: [],
    totalRFQCreatedLabelWeekWise: [],
    totalPurchaseOrdersValueMonthWise: [],
    totalPurchaseOrdersLabelMonthWise: [],
    totalPurchaseOrdersValueWeekWise: [],
    totalPurchaseOrdersLabelWeekWise: [],
    totalSpentByItems: {
      labels: [],
      values: [],
    },
    vendorResponseTime: {
      labels: [],
      values: {
        minTime: [],
        maxTime: [],
      },
    },
  });

  const [RFQCreationOption, setRFQCreationOption] = useState('monthly')
  const [totalPOOption, setTotalPOOption] = useState('monthly')

  const getAnalyticsValues = async () => {
    const formData = new FormData();

    // vendorDetails.vendorID &&
    //   vendorDetails.vendorID.length !== 0 &&
    //   vendorDetails.vendorID !== "" &&
    //   vendorDetails.vendorID !== null &&
    //   formData.append("vendor_token", vendorDetails.vendorID);
    if(vendorNameDetails.buyerID && vendorNameDetails.buyerID.length !== 0) {
      formData.append("vendor_token", vendorNameDetails.buyerID);
    } else if (vendorDetails.vendorID && vendorDetails.vendorID.length !== 0) {
      formData.append("company_token", vendorDetails.vendorID);
    }

    formData.append("buyer_token", buyer_token);
    itemCode.item_token &&
      itemCode.item_token !== "" &&
      itemCode.item_token !== null &&
      itemCode.item_token.length !== 0 &&
      formData.append("item_token", itemCode.item_token);
    duration && formData.append("month_period", duration);
    fromDate.value && !duration && formData.append("from_date", fromDate.value);
    toDate.value && !duration && formData.append("to_date", toDate.value);

    setLoadingState(true);
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.new_buyer_dashbord}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setLoadingState(false);
        let responseData = res?.data;
        setAnalyticsData({
          totalPOValue: responseData && responseData["total po value"],
          costSavingsMetric1:
            responseData && responseData["first cost saving metric 1"],
          costSavingsMetric2:
            responseData && responseData["first cost saving metric 2"],
          repeatPurchaseCostSavings:
            responseData && responseData["repeat purchase cost saving"],
          pendingVsCloseRFQ:
            responseData &&
            Object.values(responseData["pending vs closed rfq"]),
          submittedVsSelectedRFQ:
            responseData &&
            Object.values(responseData["submitted vs selected rfq"]),
          totalRFQCreatedValueMonthWise:
            responseData && Object.values(responseData["rfq monthly count"]).reverse(),
          totalRFQCreatedLabelMonthWise:
            responseData && Object.keys(responseData["rfq monthly count"]),
          totalRFQCreatedValueWeekWise:
            responseData && Object.values(weeklyReportObjFormate(responseData["rfq weekly count"])),
          totalRFQCreatedLabelWeekWise:
            responseData && Object.keys(weeklyReportObjFormate(responseData["rfq weekly count"])),
          totalPurchaseOrdersValueMonthWise:
            responseData && Object.values(responseData["po value monthwise"]).reverse(),
          totalPurchaseOrdersLabelMonthWise:
            responseData && Object.keys(responseData["po value monthwise"]),
          totalPurchaseOrdersValueWeekWise:
            responseData && Object.values(weeklyReportObjFormate(responseData["po weekly count"])),
          totalPurchaseOrdersLabelWeekWise:
            responseData && Object.keys(weeklyReportObjFormate(responseData["po weekly count"])),
          totalSpentByItems: {
            labels: responseData && Object.keys(responseData["item_spent"]),
            values: responseData && Object.values(responseData["item_spent"]),
          },
          vendorResponseTime: {
            labels:
              responseData &&
              Object.keys(responseData["vendor_response_report"]),
            values: {
              maxTime: [
                responseData &&
                Object.values(responseData["vendor_response_report"])?.map(
                  (e) => e?.max_response_time_hours
                ),
              ],

              minTime: [
                responseData &&
                Object.values(responseData["vendor_response_report"])?.map(
                  (e) => e?.min_response_time_hours
                ),
              ],
            },
          },
        });
      })
      .catch((err) => {
        setLoadingState(false);
        console.log(err);
      });
  };

  const validateFilters = () => {
    const isfromDate = emptyCheck(fromDate?.value);

    const isToDate = emptyCheck(toDate?.value);

    setFromDate((prev) => ({ ...prev, isValid: isfromDate }));
    setToDate((prev) => ({ ...prev, isValid: isToDate }));

    return isfromDate && isToDate;
  };

  const handleFilterSearch = () => {
    let validateForm = !duration ? validateFilters() : true;
    if (validateForm) {
      getAnalyticsValues();
    } else {
      setFromDate((prev) => ({ ...prev, isValid: prev.isValid }));
      setToDate((prev) => ({ ...prev, isValid: prev.isValid }));
    }
  };

  useEffect(() => {
    getAllVendorsData();
    getAllItems();
    getAnalyticsValues();
  }, []);

  //getAll vendor names
  const getAllVendorsData = async () => {
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.get_all_vendor}`,
      data: {
        org_token: buyer_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        let uniqueDatas;
        if (Array.isArray(res?.data?.users)) {
          const uniqueValue = Array.from(new Set(res?.data?.users.map(item => item.company_name))).map(
            companyName => res?.data?.users.find(item => item.company_name === companyName)
          );
          uniqueDatas = uniqueValue;
        } else {
          console.error("The 'bidsData' is not an array or is undefined.");
          uniqueDatas = []; // Set a default value or handle it accordingly based on your use case
        }

        let vendor_list_shallow = new Set(
          res?.data?.users?.map((e) => {
            return e?.company_name + "";
          })
        );
        setVendorsList({
          values: {
            tokens: uniqueDatas.map((e) => {
              return e?.token;
            }),
            visibleOption: [...vendor_list_shallow],
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetSearch = ()=>{ 
    // reset customer data
    setVendorNameDetails({
      buyerName: "",
      buyerID: "",
    })
    setVendornameList({
      values: {
        tokens: [null],
        visibleOption: ["No options"],
      },
    });
    document.addEventListener('DOMContentLoaded', function() {
      setTimeout(() => {
        document.getElementById('vendorName').value = ''
      }, 200);
    });
  }
  const getvendorName = async (companyToken)=> {
    resetSearch()
    if(!companyToken || companyToken.length === 0) return
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.vendor_company_list}`,
      data: {
        buyer_token: buyer_token,
        vendor_token: companyToken,
      },
      headers: {
        Authorization: `Bearer ${auth_token} `,
      },
    })
    .then((response) => {
      resetSearch()
      let customerList = response.data.response;
      let uniqueDatas;
      if (Array.isArray(customerList)) {
        const uniqueValue = Array.from(new Set(customerList.map(item => item.full_name))).map(
          companyName => customerList.find(item => item.full_name === companyName)
        );
        uniqueDatas = uniqueValue;
      } else {
        console.error("The 'bidsData' is not an array or is undefined.");
        uniqueDatas = []; // Set a default value or handle it accordingly based on your use case
      }

      let customer_list_shallow = new Set(
        customerList?.map((e) => {
          return e?.full_name + "";
        })
      );
      setVendornameList({
        values: {
          tokens: uniqueDatas.map((e) => {
            return e?.token;
          }),
          visibleOption: [...customer_list_shallow],
        },
      })
    })
    .catch((error)=> {
      console.log(error)
      setVendornameList({
        values: {
          tokens: [null],
          visibleOption: ["No options"],
        },
      });
    })
  }

  const durationList = ["last month", "last 3 months", "last 6 months"];

  const getAllItems = async () => {
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.view_all_item}`,
      data: {
        org_token: org_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token} `,
      },
    })
      .then((res) => {
        setItemList({
          values: {
            tokens: res?.data?.response?.map((e) => {
              return e?.token;
            }),
            visibleOption: res?.data?.response?.map((e) => {
              return e?.item_code;
            }),
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setItemList({
          values: {
            tokens: [null],
            visibleOption: ["No options"],
          },
        });
      });
  };

  let tempData = [
    Math.random() * 100,
    Math.random() * 100,
    Math.random() * 100,
    Math.random() * 100,
    Math.random() * 100,
    Math.random() * 100,
    Math.random() * 100,
    Math.random() * 100,
    Math.random() * 100,
    Math.random() * 100,
  ];
  const RFQCreatedDataValues = RFQCreationOption === "monthly" ? analyticsData?.totalRFQCreatedValueMonthWise : analyticsData?.totalRFQCreatedValueWeekWise; //check if month wise report
  const RFQCreatedDataLabel = RFQCreationOption === "monthly" ? analyticsData?.totalRFQCreatedLabelMonthWise : analyticsData?.totalRFQCreatedLabelWeekWise; //check if month wise report
  
  const RFQCreatedDataSets = {
    cardHeading: "Total RFQ Created",
    chartLabels: ["RFQ Created"],
    chartIndicators: [
      {
        value: "RFQ Created",
        color: "#6977F3",
      },
    ],
    barColor: ["#6977F3"],
  };

  const submittedAndSelectedDataValue =
    analyticsData?.submittedVsSelectedRFQ || [0, 0];
  let rfqTotal = submittedAndSelectedDataValue[1] + submittedAndSelectedDataValue[2];
  const submittedAndSelectedDataSets = {
    cardHeading: "Submitted and Selected RFQ",
    chartText: (
      <span>
        Total RFQ <br />{" "}
        {rfqTotal}
        {/* {analyticsData?.submittedVsSelectedRFQ?.length > 1 &&
          analyticsData?.submittedVsSelectedRFQ?.reduce((a, b) => a + b)} */}
      </span>
    ),
    chartLabels: ["Submitted RFQ", "Selected RFQ", "Un Selected RFQ"],
    barColors: ["#B4D6C5", "#EBF4F5", "#EEF4D5"],
    chartIndicators: [
      {
        value: "Submitted",
        color: "#B4D6C5",
      },
      {
        value: "Selected",
        color: "#EBF4F5",
      },
      {
        value: "Un Selected",
        color: "#EEF4D5",
      },
    ],
    progressLines: [
      {
        label: `Submitted RFQ - ${submittedAndSelectedDataValue[0] || 0}`,
        color: "#B4D6C5",
        value: submittedAndSelectedDataValue[0],
      },
      {
        label: `Selected RFQ - ${submittedAndSelectedDataValue[1] || 0}`,
        color: "#EBF4F5",
        value: submittedAndSelectedDataValue[1] || 0,
      },
      {
        label: `Un Selected RFQ - ${submittedAndSelectedDataValue[2] || 0}`,
        color: "#EEF4D5",
        value: submittedAndSelectedDataValue[2] || 0,
      },
    ],
  };

  const totalPODataValues = totalPOOption === "monthly" ? analyticsData?.totalPurchaseOrdersValueMonthWise : analyticsData?.totalPurchaseOrdersValueWeekWise;
  const totalPODataValuesLabel = totalPOOption === "monthly" ? analyticsData?.totalPurchaseOrdersLabelMonthWise : analyticsData?.totalPurchaseOrdersLabelWeekWise;

  const totalPODataSets = {
    cardHeading: "Total Purchase Orders",
    chartLabels: ["Purchase Order"],
    chartIndicators: [
      {
        value: "Purchase Orders",
        color: "#EF737F",
      },
    ],
    barColor: ["#EF737F"],
  };

  const totalSpentDataValue = analyticsData?.totalSpentByItems;
  const totalSpentDataSetDataSets = {
    cardHeading: "Total Spend By Items",
    chartLabels: ["Submitted RFQ", "Selected RFQ"],
    barColors: ["#BF86BD"],
    chartIndicators: [
      {
        value: "Total Spend",
        color: "#BF86BD",
      },
    ],
  };

  const vendorResponseTimeDataSet = {
    chartData: [
      {
        label: "Average Response Time",
        backgroundColor: "#F8E4AC",
        barPercentage: 0.98,
        data: analyticsData?.vendorResponseTime?.values?.maxTime[0],
      },
      {
        label: "Minimum Response Time",
        backgroundColor: "#6199B3",
        barPercentage: 0.98,
        data: analyticsData?.vendorResponseTime?.values?.minTime[0],
      },
    ],
    chartYAxisLabels: analyticsData?.vendorResponseTime?.labels,
    cardHeading: "Vendor Response Time",
    chartLabels: ["Average Response Time", "Minimum Response Time"],
    barColors: ["#F8E4AC", "#6199B3"],
    chartIndicators: [
      {
        value: "Average Response Time (ART)",
        color: "#F8E4AC",
      },
      {
        value: "Minimum Response Time (MRT)",
        color: "#6199B3",
      },
    ],
  };

  const pendingAndClosedRFQDataValues = analyticsData?.pendingVsCloseRFQ || [
    0, 0,
  ];


  //  cardHeading, chartIndicators, progressLines, barColors, chartLabels, chartText
  const pendingAndClosedRFQDataSets = {
    cardHeading: "Pending and Closed RFQ",
    chartText: (
      <span>
        Total RFQ <br />{" "}
        {pendingAndClosedRFQDataValues[0] + pendingAndClosedRFQDataValues[1]}
        {/* {analyticsData?.totalRFQCreatedValueMonthWise?.length > 1 &&
          analyticsData?.totalRFQCreatedValue?.reduce((a, b) => a + b)} */}
      </span>
    ),
    chartLabels: ["Pending RFQ", "Closed RFQ"],
    barColors: ["#EF737F", "#52497C"],
    chartIndicators: [
      {
        value: "Pending",
        color: "#EF737F",
      },
      {
        value: "Closed",
        color: "#52497C",
      },
    ],
    progressLines: [
      {
        label: `Pending RFQ - ${pendingAndClosedRFQDataValues[0] || 0}`,
        color: "#EF737F",
        value: pendingAndClosedRFQDataValues[0] || 0,
      },
      {
        label: `Closed RFQ - ${pendingAndClosedRFQDataValues[1] || 0}`,
        color: "#52497C",
        value: pendingAndClosedRFQDataValues[1] || 0,
      },
    ],
  };

  //report card data
  const reportCardData = [
    {
      cardName: "Total PO Value",
      cardImg: totalPOIMg,
      cardValue: `Rs.${analyticsData?.totalPOValue}`,
    },
    {
      cardName: "First time Purchase Cost Savings V1",
      cardImg: totalPOIMg3,
      cardValue: `Rs.${analyticsData?.costSavingsMetric1.toLocaleString()}`,
    },
    {
      cardName: "First time Purchase Cost Savings V2",
      cardImg: totalPOIMg,
      cardValue: `Rs.${analyticsData?.costSavingsMetric2.toLocaleString()}`,
    },
    {
      cardName: "Repeat Purchase Cost Savings",
      cardImg: totalPOIMg2,
      cardValue: `Rs.${analyticsData?.repeatPurchaseCostSavings.toLocaleString()}`,
    },
  ];
  // chart option handling
  const handleChooseRFQDuration = (option)=> {
    setRFQCreationOption(option)
  }
  const handleChoosePODuration = (option)=> {
    setTotalPOOption(option)
  }
  return (
    <div className={`${classes.dashboard_tab_wrapper}`}>
      <div className={`${classes.topHeader}`}>
        <HeaderTitle titleText={"Buyer Dashboard"} />

        <div className={`${classes.rhs} ${classes.flex}`}>
          <DateBar />
          <NotificationBell />
          <ProfileCard
            imageSrc={profileImg}
            buyerBtn={true}
            buyerBtnOutline={true}
            profilePath={"/Buyer_dashboard/My_profile"}
          />
        </div>
      </div>

      <div className={`${classes.heading_inputs}`}>
        <div className={`${classes.filters} ${classes.flex}`}>
          <DSelectInput
            options={vendorList.values}
            getIDValue={true}
            customClass={classes.filterFields}
            label={"Vendor Company Name"}
            setIDValue={(e) => {
              setVendorDetails((prev) => ({
                ...prev,
                vendorID: e,
              }));
              getvendorName(e) // get customer details
            }}
            onChange={(e) => {
              setVendorDetails((prev) => ({
                ...prev,
                vendorName: e,
              }));
            }}
          />

          <DSelectInput
            id="vendorName"
            options={vendornameList.values}
            getIDValue={true}
            customClass={classes.filterFields}
            label={"Vendor Name"}
            disabled={!vendorDetails?.vendorID || vendorDetails?.vendorID?.length === 0}
            setIDValue={(e) => {
              setVendorNameDetails((prev) => ({
                ...prev,
                buyerID: e,
              }));
            }}
            onChange={(e) => {
              setVendorNameDetails((prev) => ({
                ...prev,
                buyerName: e,
              }));
            }}
          />

          <DSelectInput
            options={itemList.values}
            customClass={classes.filterFields}
            getIDValue={true}
            label={"Item Code"}
            setIDValue={(e) => {
              setItemCode((prev) => ({
                ...prev,
                item_token: e,
              }));
            }}
            onChange={(e) => {
              setItemCode((prev) => ({
                ...prev,
                item_code: e,
              }));
            }}
          />

          <DSelectInput
            options={durationList}
            customClass={classes.filterFields}
            label={"Duration"}
            onChange={(e) => {
              setDuration(e);
              setFromDate({
                value: "",
                isValid: true,
              });
              setToDate({
                value: "",
                isValid: true,
              });
            }}
          />

          <DDateInput
            customClass={classes.filterFields}
            type={"date"}
            label={"From Date"}
            disableFuture={true}
            onChange={(e) => {
              setFromDate({
                isValid: emptyCheck(e),
                value: e,
              });
            }}
            disabledInput={duration}
            value={fromDate}
            error={!fromDate?.isValid}
            errorMsg={"From date is required"}
          />

          <div className={`${classes.flex} ${classes.filterSearch}`}>
            <DDateInput
              customClass={classes.filterFields}
              type={"date"}
              label={"To Date"}
              disableFuture={true}
              onChange={(e) => {
                setToDate({
                  isValid: emptyCheck(e),
                  value: e,
                });
              }}
              disabledInput={duration}
              value={toDate}
              error={!toDate?.isValid}
              errorMsg={"To date is required"}
            />

            <DButton
              buyerBtn={true}
              labelImg={btnImg}
              customWrapperClass={classes.filterBtn}
              customClass={classes.button}
              iconWidth={"2rem"}
              iconHeight={"2rem"}
              onClick={handleFilterSearch}
            />
          </div>
        </div>
      </div>

      <div className={classes.mainContent}>
        <div className={classes.reports}>
          {reportCardData?.map((e, i) => (
            <Reportcard
              key={i}
              title={e.cardName}
              value={e.cardValue}
              reportImg={e.cardImg}
              customClass={classes.reportCard}
              loadingState={loadingState}
            />
          ))}
        </div>

        <div className={classes.metrics}>
          <div className={classes.topFour}>
            <DonutChart
              chartValues={pendingAndClosedRFQDataValues}
              customCardClass={classes.chartfirstRow}
              chartDataSet={pendingAndClosedRFQDataSets}
              loadingState={loadingState}
            />

            <DonutChart
              chartValues={submittedAndSelectedDataValue}
              customCardClass={classes.chartfirstRow}
              chartDataSet={submittedAndSelectedDataSets}
              loadingState={loadingState}
            />

            <LineChart
              dataLabel={RFQCreatedDataLabel}
              dataValues={RFQCreatedDataValues}
              chartDataSet={RFQCreatedDataSets}
              customCardClass={classes.ChartSecondRow}
              loadingState={loadingState}
              durationValue={RFQCreationOption}
              chooseChartDuration={handleChooseRFQDuration}
              disableDuration={false}
            />

            <LineChart
              dataLabel={totalPODataValuesLabel}
              dataValues={totalPODataValues}
              chartDataSet={totalPODataSets}
              vendorChart={true}
              customCardClass={classes.ChartSecondRow}
              loadingState={loadingState}
              durationValue={totalPOOption}
              chooseChartDuration={handleChoosePODuration}
              disableDuration={false}
            />
          </div>

          <BarChart
            mainData={totalSpentDataValue?.values}
            labels={totalSpentDataValue?.labels}
            customCardClass={classes.chartThirdRow}
            chartDataSet={totalSpentDataSetDataSets}
            loadingState={loadingState}
          />

          <HorizontalBarChart
            chartData={vendorResponseTimeDataSet.chartData}
            customCardClass={classes.chartFourthRow}
            chartDataSet={vendorResponseTimeDataSet}
            loadingState={loadingState}
          />
        </div>
      </div>
    </div>
  );
};

export default BuyerDashboardTab;
