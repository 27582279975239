import React, { useEffect, useState } from "react";
import html2pdf, { f } from "html2pdf.js";
import DocViewer from "@cyntler/react-doc-viewer";
import { Close } from "@mui/icons-material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

//img
import plusIcon from "../../../assets/images/svg/plusIcon.png";
import successPopupImg from "../../../assets/images/png/Group 2 2@2x.png";
import deletePopupImg from "../../../assets/images/png/Group 2@2x.png";
import bidSelectedImg from "../../../assets/images/png/like@2x.png";
import bidUnSelectedImg from "../../../assets/images/png/dislike@2x.png";
import comparingImg from "../../../assets/images/png/Group 93@2x.png";
import somthingWrongImg from "../../../assets/images/png/warning.png";
import brLogo from "../../../assets/images/png/Logo@2x.png";
//components
import SearchBar from "../../../components/common/SearchBar/SearchBar";
import DateBar from "../../../components/common/DateBar/DateBar";
import NotificationBell from "../../../components/common/NotificationBell/NotificationBell";
import ProfileCard from "../../../components/common/ProfileCard/ProfileCard";
import HeaderTitle from "../../../components/common/HeaderTitle/HeaderTitle";
import PaginantionComponent from "../../../components/common/Pagination/PaginationComponent";
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DataTable from "../../../components/common/DataTable/DataTableSorting";
import {
  Box,
  CircularProgress,
  Fade,
  Modal,
  ThemeProvider,
  TextField,
  createTheme,
} from "@mui/material";
import DTextInput from "../../../components/Dashboard/DashboardInputs/DTextInput";
import DButtonOutlined from "../../../components/Dashboard/DashboardButtons/dButtonOutlined";
import DSelectInput from "../../../components/Dashboard/DashboardInputs/DSelectInput";
import InfoLabel from "../../../components/common/InfoLabel/InfoLabel";
import DetailsTab from "../../../components/Dashboard/DetailsTab/DetailsTab";
import DTextArea from "../../../components/Dashboard/DashboardInputs/DTextArea";
import DRadioInput from "../../../components/Dashboard/DashboardInputs/DRadioInput";
import DefaultLabel from "../../../components/Dashboard/DefaultLabel/DefaultLabel";
import BrandLogo from "../../../components/common/BrandLogo/BrandLogo";
//api and redux
import { API_ENDPOINT } from "../../../services/api/resources";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import SuccessPopupDashboard from "../../../components/Popups/PopupFrame/SuccessPopupDashboard";
import DFileUpload from "../../../components/Dashboard/DashboardInputs/DFileUpload";
import DDateInput from "../../../components/Dashboard/DashboardInputs/DDateInput";
import { nextPageClick, previouPageClick } from "../../../utils/pagination";
import { setBuyerRFQData } from "../../../features/BuyerRFQDataSlice";
import { MoreHoriz } from "@mui/icons-material";
import {
  emptyCheck,
  validateEmail,
  validateFile,
  validateSelect,
} from "../../../utils/validations";
import DPhoneNumberInput from "../../../components/Dashboard/DashboardInputs/DPhoneNumberInput";
import dayjs from "dayjs";
import DownloadFileButton from "../../../components/Dashboard/DownloadFileButton/DownloadFileButton";
import DaysReminder from "../../../components/Dashboard/DaysReminder/DaysReminder";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import TextArea from "../../../components/common/TextArea/TextArea";
//css
import classes from "./RFQTab.module.css";
import vendorClasses from "../VendorsTab/VendorsTab.module.css";
import tableStyles from "../../../components/common/DataTable/DataTable.module.css";
import styles from "../../../components/Dashboard/DashboardInputs/DashboardInput.module.css";

import { saveAs } from "file-saver";

//custom hooks
import useFetchData from "../../../hooks/useFetchData";
import { getUniqueData } from "../../../utils/getUniqueData";
import DropdownBtnOutlined from "../../../components/Dashboard/DropdownButtons/DropdownBtnOutlined/DropdownBtnOutlined";
import { downloadFile } from "../../../utils/downloadFile";
import DropdownBtn from "../../../components/Dashboard/DropdownButtons/DropdownBtn/DropdownBtn";
import PDFView from "../../../components/Dashboard/PDFView/PDFView";
import PDFViewAllFileType from "../../../components/Dashboard/PDFView/PdfViewer";
import { flattenNestedObjects } from "../../../utils/compareObjectFormater";

//sample files
import RFQSampleItemsFile from "../../../assets/files/RFQSampleItemsList.csv";
import DFreeSoloInput from "../../../components/Dashboard/DashboardInputs/DFreeSoloInput";
import PopupFrame from "../../../components/Popups/PopupFrame/PopupFrame";
import DSearchInputDropdown from "../../../components/Dashboard/DashboardInputs/DSearchInputDropdown";
import ComparedTable from "../../../components/common/Comparison/ComparedTable";
const theme = createTheme({
  typography: {
    // fontSize: "1.4rem"
    htmlFontSize: 9.8,
  },
});
const filter = createFilterOptions();

const BuyerRFQTab = () => {
  //dispatcher
  const dispatch = useDispatch();

  //get data from use selector
  const data = useSelector((state) => state.loginData.value);
  const RFQData = useSelector((state) => state.buyerRFQData.value);
  const AdminData = useSelector((state) => state.adminData.value);

  //pages
  const [RFQMain, setRFQMain] = useState(true);
  const [sendMessage, setSendMessage] = useState(false);
  const [textAreaSendMessage, setTextAreaSendMessage] = useState(false);

  const [viewRFQ, setViewRFQ] = useState(false);
  const [requestRequote, setRequestQuote] = useState(false);
  const [enableSelect, setEnableSelect] = useState(false);
  const [newRFQForm, setNewRFQForm] = useState(false);
  const [viewTimeLine, setViewTimeLine] = useState(false);
  const [viewTimeLineData, setViewTimeLineData] = useState([]);
  const [openForm, setOpenForm] = useState({
    open: false,
    loading: false,
    errorMessage: "",
  });
  const [viewRFQComparedTable, setViewRFQComparedTable] = useState();
  // date filters

  const [startDateSearchQuery, setStartDateSearchQuery] = useState({
    value: "",
    isValid: true,
  });

  const [endDateSearchQuery, setEndDateSearchQuery] = useState({
    value: "",
    isValid: true,
  });
  const [rfqToken, setRFQToken] = useState();
  const [tableProps, setTableProps] = useState({
    isLoading: false,
    errorMessage: "",
  });
  const [loading, setLoading] = useState(false);
  const [openComparisonTable, setOpenComparisonTable] = useState(false);
  const [popupText, setPopupTexts] = useState({
    popupHeroText: "",
    popupDescription: "",
  });
  const [btnError, setBtnError] = useState({
    isError: false,
    errorMsg: "",
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowForComparison, setSelectedRowForComparison] = useState([]);
  const [sendErrorMsg, setSendErrorMsg] = useState(false);

  // user address state
  const [shippingAddress, setShippingAddress] = useState([]);

  //pagination data
  const [paginationData, setPaginationData] = useState();
  const [itemList, setItemList] = useState({
    values: {
      tokens: [],
      visibleOption: [],
    },
  });
  const [itemNameList, setItemNameList] = useState({
    values: {
      tokens: [],
      visibleOption: [],
    },
  });
  const [vendorsList, setVendorsList] = useState({
    values: {
      tokens: [],
      visibleOption: [],
    },
  });
  const [vendorId, setVendorID] = useState([]);
  const [btnPrimaryLabel, setBtnPrimaryLabel] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [loadingStateDwnload, setLoadingStateDwnload] = useState(false);
  //rfq form states
  const [editRFQForm, setEditRFQForm] = useState(false);
  //credentials
  const [auth_token, setAuthToken] = useState(data?.auth_token);
  const [user_token, setUserToken] = useState(data?.user?.user_token);
  const [buyer_token, setBuyerToken] = useState(data?.user?.token);
  const [org_token, setOrgToken] = useState(data?.user?.token);
  const [bidsData, setBidsData] = useState();
  const [itemsListDataViewRFQ, setItemsListDataViewRFQ] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [bulkBidsData, setBulkBidsData] = useState();
  const [popupMessage, setPopupMessage] = useState("");
  const [btnErrorState, setBtnErrorState] = useState({
    error: false,
    errorMsg: "",
  });
  const [iterationNumber, setIterationNumber] = useState();
  const [bidToken, setBidToken] = useState("");
  const [fileName, setFileName] = useState();
  const [uploadItemDocument, setUploadItemDocument] = useState({
    uploadFile: true,
    inputs: false,
  });
  const [deletedItemsToken, setDeletedItemsToken] = useState([]);
  const [itemsInput, setItemsInput] = useState([
    {
      itemToken: "",
      itemTokenisValid: false,

      itemMappedToken: "",

      itemCode: "",
      itemCodeisValid: false,

      itemName: "",
      itemNameisValid: false,

      itemQuantity: "",
      itemQuantityisValid: false,

      itemUnit: "",
      itemUnitisValid: false,

      itemDescription: "",
      itemDescriptionisValid: false,

      itemPartNumber: "",
    },
  ]);
  const [itemCSV, setItemCSV] = useState({
    value: "",
    hasFile: false,
    isValid: true,
  });

  const [uploadDocument, setUploadDocument] = useState({
    uploadFile: true,
    uploadDescripton: false,
  });

  const [proposalDocument, setProposalDocument] = useState({
    value: "",
    isValid: true,
    hasFile: true,
  });
  const [proposalDescription, setProposalDescription] = useState({
    value: "",
    isValid: true,
  });

  const [requestNumber, setRequestNumber] = useState({
    value: "",
    isChanged: false,
    isValid: true,
  });

  const [RFQCategory, setRFQCategory] = useState({
    value: "",
    id: "",
    categoryList: [],
    isValid: true,
  });

  const [bidType, setBidType] = useState({
    value: true,
    isValid: true,
  });

  const [bidStatus, setBidStatus] = useState({
    isValid: true,
    isChanged: false,
    value: "",
  });

  const [fromDate, setFromDate] = useState({
    value: "",
    isValid: true,
  });

  const [toDate, setToDate] = useState({
    value: "",
    isValid: true,
  });

  const [time, setTime] = useState({
    value: null,
    isChanged: false,
    isValid: true,
  });

  const [vendor, setVendor] = useState({
    value: [],
    id: [],
    isValid: true,
  });
  const [existingVendorList, setExistingVendorList] = useState([]);
  const [CompanyName, setCompanyName] = useState({
    value: "",
    errorMsg: "",
    isValid: true,
  });
  const [companyList, setCompanyList] = useState([]);
  const [VendorRegisteredEmail, setVendorRegisteredEmail] = useState({
    value: "",
    errorMsg: "",
    isValid: true,
  });

  const [primaryBtnError, setPrimaryBtnError] = useState({
    error: false,
    errorMsg: "",
  });
  const [address1, setAddress1] = useState({
    value: "",
    isValid: true,
  });

  const [address2, setAddress2] = useState({
    value: "",
    isValid: true,
  });

  const [city, setCity] = useState({
    value: "",
    cityList: [],
    isValid: true,
  });

  const [stateName, setStateName] = useState({
    value: "",
    stateList: [],
    isValid: true,
  });

  const [country, setCountry] = useState({
    value: "",
    countryList: [],
    isValid: true,
  });

  const [countryToken, setCountryToken] = useState(0);
  const [stateToken, setstateToken] = useState(0);

  const [pincode, setPincode] = useState({
    value: "",
    isValid: true,
  });

  const [mobileNumber, setMobileNumber] = useState({
    value: "",
    format: "",
    isValid: true,
  });

  //comparision module state
  const [latestStatus, setLatestStatus] = useState("");
  const [intervalIds, setIntervalIds] = useState([]);
  const [timeOutIds, setTimeOutIds] = useState([]);
  const [compairedData, setCompairedData] = useState([]);
  const [fileJsonHistory, setFileJsonHistory] = useState([]);
  const [comparedNameDate, setComparedNameDate] = useState({});
  // const [chatGptToken, setchatGptToken] = useState('')

  const [openBidConfirmation, setOpenBidConfirmation] = useState({
    selectBid: false,
    unSelectBid: false,
  });

  const [openViewQuotesPopup, setOpenViewQuotesPopup] = useState(false);
  const [openComparisonPopup, setOpenComparisonPopup] = useState(false);

  const [textAreaVlaue, setTextAreaVlaue] = useState({
    send_message: "",
  });
  const [charsLeft, setCharsLeft] = useState(0);
  const [errorMessages, setErrorMessages] = useState(false);
  const [organizationDetailspdfUI, setOrganizationDetailspdfUI] =
    useState(false);

  // modal states
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  //delete
  const [openDltConfirmation, setOpenDltConfirmation] = useState(false);
  const [openDlt, setOpenDlt] = useState(false);

  const [bidSelection, setBidSelection] = useState({
    select: false,
    unselect: false,
  });

  // new rfq tabs
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);

  //popuptext
  const [popupHeroText, setPopupHeroText] = useState("");
  //search filtered data
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [searchQuery3, setSearchQuery3] = useState("");
  const [sortingColumnName, setSortingColumnName] = useState(null);
  const [sortingOrder, setSortingOrder] = useState(null);
  //unique user
  const [uniqueData, setUniqueData] = useState();
  //pagenumber
  const [pageNumber, setPageNumber] = useState(1);

  // chat dialog state
  const [timeLineVendorData, setTimeLineVendorData] = useState({});
  const [chatConfirmationDialog, setChatConfirmationDialog] = useState({
    open: false,
    isClosedChat: true,
  });

  // send message state
  const [rfqVendorList, setRfqVendorList] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [messagePageNumber, setMessagePageNumber] = useState(1);
  const [messageSearchQuery, setMessageSearchQuery] = useState("");
  const [messagePaginationData, setMessagePaginationData] = useState();
  const [messageTableProps, setMessageTableProps] = useState({
    isLoading: false,
    errorMessage: "",
  });
  const GetAllMessageData = () => {
    useFetchData({
      pageNumber: messagePageNumber,
      endpoint: `${API_ENDPOINT.list_of_msg}`,
      idName: "rfq_token",
      idValue: rfqToken,
      searchQuery: messageSearchQuery,
      auth_token: auth_token,
      ...(sortingColumnName && { sortingColumnName: sortingColumnName }),
      ...(sortingOrder && { sortingOrder: sortingOrder }),
      setData: setChatHistory,
      setPaginationData: setMessagePaginationData,
      setTableProps,
    });
  };
  useEffect(() => {
    if (viewRFQ) {
      axios({
        method: "POST",
        url: `${API_ENDPOINT.enable_vendor_list}`,
        data: { rfq_token: rfqToken },
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      })
        .then((response) => {
          const vendorList = response.data.vendor_detail;
          setRfqVendorList(vendorList);
        })
        .catch((err) => {
          console.log(err);
        });

      // axios({
      //   method: "POST",
      //   url: `${API_ENDPOINT.list_of_msg}`,
      //   data: { rfq_token: rfqToken },
      //   headers: {
      //     Authorization: `Bearer ${auth_token}`,
      //   },
      // })
      //   .then((response) => {
      //     const vendorList = response.data.vendor_detail;
      //     setChatHistory(vendorList);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    }
  }, [viewRFQ]);

  const handleSeletedRowsList = (rowList, row) => {
    const uniqueList = [...new Set(row?.map((e, i) => e?.vendor_token))];
    setSelectedRows(uniqueList);
    setSelectedRowForComparison(row || []);
  };
  const handleSeletedRowsListMessage = (rowList, row) => {
    const uniqueList = [...new Set(row?.map((e, i) => e?.token))];
    setSelectedRows(uniqueList);
    setSelectedRowForComparison(row || []);
  };
  const storedValue = localStorage.getItem("rfq_page_number");
  // Convert the string to a number using parseInt or parseFloat
  const pageNumbers = parseInt(storedValue); // or parseFloat(storedValue) for floating point numbers

  const openRFQMain = () => {
    setRFQMain(true);
    setRequestQuote(false);
    setEnableSelect(false);
    setOpenForm({
      loading: false,
      errorMessage: "",
    });
    setViewRFQ(false);
    resetForm();
    setUniqueData();
    handleClose();
    tab1Click();
    setBtnErrorState({
      error: false,
      errorMsg: "",
    });

    setPageNumber(pageNumbers);
    setBidsData();
    setPaginationData();
    setNewRFQForm(false);
    setEditRFQForm(false);
    setSortingColumnName(null);
    setSortingOrder(null);
    setViewTimeLine(false);
    setSearchQuery2("");
  };
  const openRFQMainTimeLine = () => {
    setRFQMain(false);
    setViewRFQ(true);
    setViewTimeLine(false);
  };
  const openViewIndividualRFQ = () => {
    setRFQMain(false);
    setViewRFQ(false);
    setViewTimeLine(false);
    setViewRFQComparedTable(true);
  };
  const backViewIndividualRFQ = () => {
    setCompairedData([]);
    setFileJsonHistory([]);
    setComparedNameDate({});
    setRFQMain(false);
    setViewRFQ(true);
    setViewTimeLine(false);
    setViewRFQComparedTable(false);
  };
  const openRFQMainCancel = () => {
    setRFQMain(true);
    setRequestQuote(false);
    setEnableSelect(false);
    setOpenForm({
      loading: false,
      errorMessage: "",
    });
    setViewRFQ(false);
    resetForm();
    setUniqueData();
    handleClose();
    tab1Click();
    setBtnErrorState({
      error: false,
      errorMsg: "",
    });

    setPageNumber(pageNumbers);
    setBidsData();
    setPaginationData();
    setNewRFQForm(false);
    setEditRFQForm(false);
    setSortingColumnName(null);
    setSortingOrder(null);
    setViewTimeLine(false);
    setSelectedRows([]);
    setSearchQuery2("");
  };

  const sendMessageReset = () => {
    setSendMessage(false);
    setTextAreaSendMessage(false);
    setErrorMessages(false);
    setOpen2(false);
  };

  const openNewRFQ = () => {
    setOpenForm({
      open: true,
      loading: false,
      errorMessage: "",
    });
    setEditRFQForm(false);
    setViewRFQ(false);
    setRFQMain(false);
    viewAllItem(org_token);
    setPageNumber(1);
    setNewRFQForm(true);
    setViewTimeLine(false);
    getshippingAddress();
    getCategoryList();
  };

  const openViewRFQ = (unique_id, rowData) => {
    getIndividualData(unique_id);

    getEveryBidsData(unique_id);
    setPaginationData();
    setSortingColumnName(null);
    setSortingOrder(null);
    GetAllBidsData(pageNumber, unique_id);

    setOpenForm({
      open: false,
      loading: false,
      errorMessage: "",
    });

    setViewRFQ(true);
    setRFQToken(unique_id);
    setRFQMain(false);
    setPageNumber(1);
    setNewRFQForm(false);
    setEditRFQForm(false);
    setViewTimeLine(false);
  };

  const openEditRFQ = () => {
    setOpenForm({
      open: true,
      loading: false,
      errorMessage: "",
    });

    setEditRFQForm(true);
    setViewRFQ(false);
    setRFQMain(false);
    getAllVendorsData();
    setNewRFQForm(false);
    // viewAllItem(org_token);
    setSortingColumnName(null);
    setSortingOrder(null);
  };

  const tab1Click = () => {
    setTab1(true);
    setTab2(false);
  };

  const tab2Click = () => {
    setTab1(false);
    setTab2(true);

    //gets states list when the shipping address tab opens
    let filterCountry = country.countryList?.filter(
      (el) => el.name === country.value
    );

    let country_token = filterCountry && filterCountry[0]?.token;

    filterCountry && setCountryToken(country_token);

    if (country_token && country_token !== null && country_token !== "") {
      getStatesList(country_token);
    }
  };

  const onLocaleStoreg = (data) => {
    localStorage.setItem(
      "rfq_page_number",
      `${data === "prev" ? parseInt(pageNumber) - 1 : parseInt(pageNumber) + 1}`
    );
  };
  useEffect(() => {
    // Clear the local storage value when the page is reloaded
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("rfq_page_number", 1);
    });
  }, []);

  // Ensure value is defined and is an array
  // let uniqueDatas;
  // if (Array.isArray(bidsData)) {
  //   const uniqueValue = Array.from(
  //     new Set(bidsData.map((item) => item.vendor_token))
  //   ).map((vendorToken) =>
  //     bidsData.find((item) => item.vendor_token === vendorToken)
  //   );
  //   uniqueDatas = uniqueValue;
  // } else {
  //   // console.error("The 'bidsData' is not an array or is undefined.");
  //   uniqueDatas = []; // Set a default value or handle it accordingly based on your use case
  // }

  // console.log("uniqueDatas", uniqueDatas);
  // console.log("rfqVendorList", rfqVendorList);
  // console.log("bulkBidsData", bulkBidsData);

  //pagination logics
  const itemsPerPage = paginationData?.current_page_count;
  const totalItems = paginationData?.page_count;

  //pagination logics
  const messageItemsPerPage = messagePaginationData?.current_page_count;
  const messageTotalItems = messagePaginationData?.page_count;

  //if selected bid logic
  const checkIsBidSelected = bulkBidsData && bulkBidsData?.map((e) => e.status);
  const hasSelectedBid =
    checkIsBidSelected &&
    checkIsBidSelected?.map((e) => e === "selected")?.filter((e) => e === true)
      ?.length !== 0;

  //bid iteration logics
  const bidsIteration = bulkBidsData?.map((e) => e?.bid_version_int);
  const uniqueBitIterationArr = [...new Set(bidsIteration)];
  // let maxBidIterationCount = Math.max(...uniqueBitIterationArr);
  let maxBidIterationCount = 0;

  const GetAllData = () => {
    useFetchData({
      pageNumber: pageNumber,
      endpoint: `${API_ENDPOINT.get_all_buyer_RFQ}`,
      idName: "org_token",
      idValue: buyer_token,
      ...(searchQuery && { searchQuery: searchQuery }),
      ...(startDateSearchQuery.value && {
        startDateQuery: startDateSearchQuery.value,
      }),
      ...(endDateSearchQuery.value && {
        endDateQuery: endDateSearchQuery.value,
      }),
      ...(sortingColumnName && { sortingColumnName: sortingColumnName }),
      ...(sortingOrder && { sortingOrder: sortingOrder }),
      auth_token: auth_token,
      setData: setBuyerRFQData,
      setPaginationData: setPaginationData,
      dispatch: dispatch,
      tableProps,
      setTableProps,
    });
  };

  let delay = 300;
  let idParams = [
    {
      key: "rfq_token",
      value: rfqToken && rfqToken,
    },
  ];

  if (rfqToken && iterationNumber) {
    if (iterationNumber === "default") {
      idParams.push({
        key: "default",
        value: iterationNumber,
      });
    } else {
      idParams.push({
        key: "quote_no",
        value: iterationNumber,
      });
    }
  }

  useEffect(() => {
    if (viewRFQ) {
      GetAllBidsData(pageNumber, rfqToken);
      getEveryBidsData(rfqToken);
    }
  }, [iterationNumber]);

  useEffect(() => {
    if (openForm.open) {
      getCountriesList();
    }
    if (editRFQForm) {
      setUploadItemDocument({
        inputs: true,
        uploadFile: false,
      });
    }
  }, [editRFQForm, openForm.open, newRFQForm]);

  const GetAllBidsData = (pageNumber, rfq_code) => {
    useFetchData({
      pageNumber: pageNumber,
      endpoint: `${API_ENDPOINT.get_org_bid_view}`,
      idParams: idParams,
      searchQuery: searchQuery2,
      auth_token: auth_token,
      ...(sortingColumnName && { sortingColumnName: sortingColumnName }),
      ...(sortingOrder && { sortingOrder: sortingOrder }),
      setData: setBidsData,
      setPaginationData: setPaginationData,
      tableProps,
      setTableProps,
    });
  };
  const getEveryBidsData = async (rfq_code) => {
    const formData = new FormData();
    formData.append("rfq_token", rfq_code);
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.get_org_bid_view}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setBulkBidsData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //get RFQ category list
  const getCategoryList = () => {
    axios({
      method: "GET",
      url: `${API_ENDPOINT.list_of_prompt_category}`,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((response) => {
        let promptList = response.data.response;
        setRFQCategory({
          ...RFQCategory,
          categoryList: promptList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setBtnPrimaryLabel(editRFQForm ? "Update" : "Save");

    let timeoutId = null;

    if (RFQMain) {
      timeoutId = setTimeout(() => {
        if (pageNumber) {
          GetAllData();
        }
      }, delay);
    } else if (viewRFQ) {
      getEveryBidsData(rfqToken);

      timeoutId = setTimeout(() => {
        if (pageNumber) {
          GetAllBidsData(pageNumber, rfqToken);
          GetAllMessageData();
        }
      }, delay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    pageNumber,
    messagePageNumber,
    searchQuery,
    messageSearchQuery,
    RFQMain,
    startDateSearchQuery.value,
    endDateSearchQuery.value,
    sortingColumnName,
    sortingOrder,
    searchQuery2,
  ]);

  //get shipping address
  const getshippingAddress = async () => {
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.buyer_shipping_address}`,
      data: {
        buyer_token: data.user.user_token, //loged user token
      },
    })
      .then((res) => {
        let shippingAddress = res.data.response;
        setShippingAddress(shippingAddress);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //get individual data
  const getIndividualData = async (unique_id, query) => {
    setOpenForm({
      open: false,
      loading: true,
      errorMessage: "",
    });

    setLoadingState(true);

    await axios({
      method: "POST",
      url: `${API_ENDPOINT.get_individual_RFQ}${unique_id}`,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setLoadingState(false);

        let responseData = res?.data?.response[0];
        let itemsData = res?.data?.items;

        setItemsListDataViewRFQ(itemsData);
        setUniqueData(responseData);

        let fileObj =
          res?.data?.response &&
          res.data.response[0]?.proposal_document?.split("/");
        let fileName = fileObj && fileObj[fileObj?.length - 1];
        setFileName(fileName);

        setRFQToken(res.data.response[0].token);

        setOpenForm({
          open: true,
          loading: false,
          errorMessage: "",
        });

        setRFQToken(responseData?.token);

        if (query === "update inputs") {
          let itemsData = res?.data?.items;

          let mappedItemsList = itemsData?.map((el, i) => {
            return {
              itemToken: el.item_token,
              itemTokenisValid: false,

              itemMappedToken: el.item_mapped_token,

              itemCode: el.item_code,
              itemCodeisValid: false,

              itemName: el.item_name,
              itemNameisValid: false,

              itemQuantity: el.quantity,
              itemQuantityisValid: false,

              itemUnit: el.unit,
              itemUnitisValid: false,

              itemDescription: el.description,
              itemDescriptionisValid: false,

              itemPartNumber: el.part_number,
            };
          });

          setItemsInput(mappedItemsList);

          setRequestNumber({
            value: responseData?.purchase_request_number,
            isValid: true,
          });
          setBidType({
            value: responseData?.bidding_type,
            isValid: true,
          });
          setBidStatus({
            value: responseData?.bid_status,
            isValid: true,
          });

          // changing format to 8 August 2023 to 2023-08-08 12:32:09.021329
          let startDate = dayjs(
            responseData?.bid_from_date,
            "DD MMMM YYYY"
          ).format("YYYY-MM-DD HH:mm:ss.SSSSSS");
          let endDate = dayjs(responseData?.bid_to_date, "DD MMMM YYYY").format(
            "YYYY-MM-DD HH:mm:ss.SSSSSS"
          );

          const endTime = dayjs
            .utc(responseData?.bid_time)
            .local()
            .format("YYYY-MM-DD HH:mm:ss.SSSSSS");

          setFromDate({
            value: startDate,
            isValid: true,
          });
          setToDate({
            value: endDate,
            isValid: true,
          });
          setTime({
            value: endTime,
            isChanged: false,
            isValid: true,
          });

          let finalVendorArray = responseData?.select_vendor?.split(",");

          setVendor({
            value: finalVendorArray,
            isValid: true,
          });

          setExistingVendorList(finalVendorArray);

          setAddress1({
            value: responseData?.shipping_address1,
            isValid: true,
          });
          setAddress2({
            value: responseData?.shipping_address2,
            isValid: true,
          });
          setCountry((prev) => ({
            ...prev,
            value: responseData?.country,
            countryList: [],
            isValid: true,
          }));
          setStateName((prev) => ({
            ...prev,
            value: responseData?.state,
            stateList: [],
            isValid: true,
          }));
          setCity((prev) => ({
            ...prev,
            value: responseData?.city,
            cityList: [],
            isValid: true,
          }));

          setMobileNumber({
            value: responseData?.mobile_number,
            isValid: true,
          });

          setUploadDocument({
            uploadDescripton: responseData?.proposal_description ? true : false,
            uploadFile: responseData?.proposal_document ? true : false,
          });

          setProposalDescription({
            value: responseData?.proposal_description,
            isValid: true,
          });

          setProposalDocument({
            value: responseData?.proposal_document,
            isValid: true,
            hasFile: true,
          });

          setPincode({
            value: responseData?.pincode,
            isValid: true,
          });
        }
      })
      .catch((err) => {
        setLoadingState(false);
        console.log(err);
        setOpenForm({
          open: false,
          loading: false,
          errorMessage:
            err?.response?.status !== 500
              ? Object.values(err?.response?.data)
              : "Something went wrong, please try again later.",
        });
      });
  };

  const viewAllItem = async (org_token) => {
    setOpenForm({
      open: false,
      loading: true,
      errorMessage: "",
    });
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.view_all_item}`,
      data: {
        org_token: org_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token} `,
      },
    })
      .then((res) => {
        setOpenForm({
          open: true,
          loading: false,
          errorMessage: "",
        });
        getAllVendorsData();
        setItemList({
          values: {
            tokens: res?.data?.response?.map((e, i) => {
              return e?.token;
            }),
            visibleOption: res?.data?.response?.map((e, i) => {
              return e?.item_code;
            }),
          },
        });
        setItemNameList({
          values: {
            tokens: res?.data?.response?.map((e, i) => {
              return e?.token;
            }),
            visibleOption: res?.data?.response?.map((e, i) => {
              return e?.item_name;
            }),
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setOpenForm({
          open: false,
          loading: false,
          errorMessage: Object.values(err?.response?.data),
        });
      });
  };

  //get all data
  const getAllVendorsData = async () => {
    setOpenForm({
      open: false,
      loading: true,
      errorMessage: "",
    });
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.get_all_vendor}`,
      data: {
        org_token: buyer_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setOpenForm({
          open: true,
          loading: false,
          errorMessage: "",
        });
        setVendorsList({
          values: {
            tokens: res?.data?.users?.map((e) => {
              return e?.token;
            }),
            visibleOption: res?.data?.users?.map((e) => {
              return e?.company_name + " (" + e?.company_official_email + ")";
            }),
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setOpenForm({
          open: false,
          loading: false,
          errorMessage: Object.values(err?.response?.data),
        });
      });
  };

  const getItemDetailsByItemCode = async (item_token, index) => {
    await axios({
      method: "GET",
      url: `${API_ENDPOINT.get_individual_item_by_item_code}${item_token}`,
      headers: {
        Authorization: `Bearer ${auth_token} `,
      },
    })
      .then((res) => {
        let itemName = res?.data?.Item[0]?.item_name;
        handleItemInputChanges(itemName, "itemName", index);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getItemDetailsByNameCode = async (item_token, index) => {
    await axios({
      method: "GET",
      url: `${API_ENDPOINT.get_individual_item_by_item_code}${item_token}`,
      headers: {
        Authorization: `Bearer ${auth_token} `,
      },
    })
      .then((res) => {
        let itemCode = res?.data?.Item[0]?.item_code;
        handleItemInputChanges(itemCode, "itemCode", index);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const openUpdateRFQModal = (rfq_token) => {
    openEditRFQ();
    getIndividualData(rfq_token, "update inputs");
    setEditRFQForm(true);
    setRFQToken(rfq_token);
    viewAllItem(org_token);
  };

  // data for the datatable
  const headings = [
    { label: "RFQ Code", field: "rfq_code" },
    { label: "Item Name", field: "item_name" },
    { label: "Item Code", field: "item_code" },
    { label: "Quantity", field: "quantity" },
    { label: "Bid Start Date", field: "bid_from_date" },
    { label: "Bid End Date", field: "bid_to_date" },
    { label: "Status", field: "bid_status" },
  ];

  //send bid types
  const getBidType = (_, optionValue) => {
    const matches = optionValue?.match(/\d+/);
    const number = matches ? parseInt(matches[0]) : null;

    if (optionValue === "All") {
      setIterationNumber();
    } else if (optionValue === "Initial") {
      setIterationNumber("0");
    } else if (optionValue === "Default") {
      setIterationNumber("default");
    } else {
      setIterationNumber(number);
    }
  };
  const getBidTypeSendMessg = (_, optionValue) => {
    const matches = optionValue?.match(/\d+/);
    const number = matches ? parseInt(matches[0]) : null;

    if (optionValue === "All") {
      setIterationNumber();
    } else if (optionValue === "Initial") {
      setIterationNumber("0");
    } else if (optionValue === "Default") {
      setIterationNumber("default");
    } else {
      setIterationNumber(number);
    }
  };

  let uniqueVersions = bulkBidsData?.map((e) => e.bid_version);

  let quoteIterationOptions = ["All", "Default", ...new Set(uniqueVersions)];

  // data for the view datatable
  const headingsForViewRFQ = [
    { label: "Vendor", field: "vendor" },
    { label: "Quantity", field: "quantity" },
    { label: "Unit Price", field: "unit_price" },
    { label: "Total Price in Global Currency", field: "total_price" },
    { label: "Total Price in Local Currency", field: "converted_currency" },
    {
      label: "Quote Iteration",
      field: "bid_version",
      selectable_header: true,
      DropdownOptions: quoteIterationOptions,
      onClick: getBidType,
    },
    {
      label: "Created Date",
      field: "created_date",
    },
  ];
  // data for the sent message view datatable
  const headingsForSentMessage = [
    { label: "Vendor", field: "company_name" },
    { label: "Company Email", field: "company_official_email" },
    { label: "Company Phone Number", field: "company_phone_number" },
  ];

  const RFQitemsListMapped = itemsListDataViewRFQ?.map((e, i) => {
    return {
      item_name: e?.item_name,
      item_code: e?.item_code,
      hsn_code: e?.hsn_code?.split("-")[0],
      unspsc_code: e?.unspsc_code?.split("-")[0],
      unit: e?.unit,
      quantity: e?.quantity,
      description: e?.description,
    };
  });

  // data for the view datatable
  const headingsForViewItemsRFQ = [
    { label: "Item Name", field: "item_name", col_width: "150px" },
    { label: "Item Code", field: "item_code", col_width: "150px" },
    { label: "HSN Code", field: "hsn_code", col_width: "150px" },
    { label: "UNSPSC Code", field: "unspsc_code", col_width: "150px" },
    { label: "Unit", field: "unit", col_width: "100px" },
    { label: "Quantity", field: "quantity", col_width: "100px" },
    {
      label: "Description",
      field: "description",
      cellType: "view",
      col_width: "150px",
    },
  ];

  const headingMessage = [
    { label: "Vendor Name", field: "company_name" },
    // {
    //   label: "Quote Iteration",
    //   field: "bid_version",
    //   selectable_header: true,
    //   DropdownOptions: quoteIterationOptions,
    //   onClick: getBidTypeSendMessg,
    // },
  ];

  const regexForAlphabets = /[^a-zA-Z\s]/g;
  const regexForAlphaNumeric = /[^a-zA-Z0-9\s]+/g;
  const regexForNumber = /[^\d\s]/g;
  const numberOnlyRegex = /^\d+$/;

  const addItemsInputs = () => {
    setItemsInput([
      ...itemsInput,
      {
        itemToken: "",
        itemTokenisValid: false,

        itemMappedToken: "",

        itemCode: "",
        itemCodeisValid: false,

        itemName: "",
        itemNameisValid: false,

        itemQuantity: "",
        itemQuantityisValid: false,

        itemUnit: "",
        itemUnitisValid: false,

        itemDescription: "",
        itemDescriptionisValid: false,

        itemPartNumber: "",
      },
    ]);
  };

  const removeItemsInput = (index) => {
    let newInputs = [...itemsInput];
    newInputs.splice(index, 1);
    setItemsInput(newInputs);
  };

  const handleItemInputChanges = (e, elementName, index) => {
    const newInputs = [...itemsInput];
    newInputs[index][elementName] = e;
    setItemsInput(newInputs);
  };

  const validateForm = () => {
    let isItemListIsValid = true;

    itemsInput.forEach((el, index) => {
      if (!emptyCheck(el.itemCode)) {
        itemsInput[index].itemCodeisValid = true;
        isItemListIsValid = false;
      }
      if (!emptyCheck(el.itemName)) {
        itemsInput[index].itemNameisValid = true;
        isItemListIsValid = false;
      }
      if (!emptyCheck(el.itemQuantity)) {
        itemsInput[index].itemQuantityisValid = true;
        isItemListIsValid = false;
      }
      if (!emptyCheck(el.itemUnit)) {
        itemsInput[index].itemUnitisValid = true;
        isItemListIsValid = false;
      }
      if (
        !el?.itemDescription ||
        el.itemDescription.trim() === "" ||
        el.itemDescription.length > 2550
      ) {
        itemsInput[index].itemDescriptionisValid = true;
        isItemListIsValid = false;
      }
      if (
        emptyCheck(el.itemCode) &&
        emptyCheck(el.itemName) &&
        emptyCheck(el.itemQuantity) &&
        emptyCheck(el.itemUnit) &&
        el?.itemDescription &&
        (el.itemDescription.trim() !== "") & (el.itemDescription.length <= 2550)
      ) {
        isItemListIsValid = true;
      } else {
        isItemListIsValid = false;
      }
    });
    setItemsInput([...itemsInput]);

    const isItemCSVIsValid = itemCSV.value !== null && itemCSV.value !== "";
    const isRequestNumber = emptyCheck(requestNumber.value);
    let isRFQCategory = true;
    if (!editRFQForm) {
      //not for edit
      isRFQCategory = emptyCheck(RFQCategory.value);
    }
    const isBidType = bidType.value !== "";
    const isDescription =
      proposalDescription.value && proposalDescription.value?.trim() !== "";
    const isProposalDocument = validateFile(
      proposalDocument.value,
      true,
      ["pdf", "docx", "xlsx", "xls", "ppt", "pptx"],
      5
    ).isValid;
    const isfromDate = fromDate.value !== null && fromDate.value !== "";
    const istoDate = toDate.value !== null && toDate.value !== "";
    const istime = time.value !== "" && time?.value !== null;
    const isVendorList =
      vendor.value && vendor.value?.length > 0 && vendorId?.length > 0;
    const isAddress1 = emptyCheck(address1.value);
    const isCountry = emptyCheck(country.value);
    const isState = emptyCheck(stateName.value);
    const isCity = emptyCheck(city.value);
    const isMobileNumber =
      emptyCheck(mobileNumber.value) && mobileNumber.isValid;
    const isPincode = emptyCheck(pincode.value);

    setItemCSV((prev) => ({ ...prev, isValid: isItemCSVIsValid }));
    setRequestNumber((prev) => ({ ...prev, isValid: isRequestNumber }));
    !editRFQForm && //not for edit
      setRFQCategory((prev) => ({ ...prev, isValid: isRFQCategory }));
    setBidType((prev) => ({ ...prev, isValid: isBidType }));
    setFromDate((prev) => ({ ...prev, isValid: isfromDate }));
    setToDate((prev) => ({ ...prev, isValid: istoDate }));
    setTime((prev) => ({ ...prev, isValid: istime, isChanged: false }));
    setVendor((prev) => ({ ...prev, isValid: isVendorList }));
    setAddress1((prev) => ({ ...prev, isValid: isAddress1 }));
    setCountry((prev) => ({ ...prev, isValid: isCountry }));
    setStateName((prev) => ({ ...prev, isValid: isState }));
    setCity((prev) => ({ ...prev, isValid: isCity }));
    setMobileNumber((prev) => ({ ...prev, isValid: isMobileNumber }));
    setProposalDocument((prev) => ({ ...prev, isValid: isProposalDocument }));
    setPincode((prev) => ({ ...prev, isValid: isPincode }));

    let itemType = uploadItemDocument.uploadFile
      ? isItemCSVIsValid
      : uploadItemDocument.inputs && isItemListIsValid;

    let proposalDoc;

    if (itemCSV.value || itemsInput.length > 1) {
      proposalDoc = true;
      // uploadDocument.uploadFile
      //   ? isProposalDocument
      //   : uploadDocument.uploadDescripton && true;
      setProposalDescription((prev) => ({ ...prev, isValid: true }));
    } else {
      proposalDoc = true;
      // uploadDocument.uploadFile
      //   ? isProposalDocument
      //   : uploadDocument.uploadDescripton && isDescription;
      setProposalDescription((prev) => ({
        ...prev,
        isValid: isDescription,
      }));
    }

    return (
      itemType &&
      isRequestNumber &&
      isRFQCategory &&
      isBidType &&
      isfromDate &&
      istoDate &&
      istime &&
      isVendorList &&
      isAddress1 &&
      isCountry &&
      isState &&
      isCity &&
      isMobileNumber &&
      proposalDoc &&
      isPincode
    );
  };

  const handleOpen = () => setOpen(true);
  const handleOpen2 = () => setOpen2(true);
  const handleOpen1 = () => setOpen1(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
    setVendorRegisteredEmail({
      value: "",
      errorMsg: "",
      isValid: true,
    });
    setCompanyName({
      value: "",
      errorMsg: "",
      isValid: true,
    });
  };

  // formdata
  const formData = new FormData();

  !editRFQForm && formData.append("buyer_token", buyer_token);

  if (typeof proposalDocument?.value === "object") {
    formData.append(
      "proposal_document",
      proposalDocument.value ? proposalDocument.value : ""
    );
  }

  if (editRFQForm) {
    requestNumber.isChanged &&
      formData.append("purchase_request_number", requestNumber.value);
  } else {
    formData.append("purchase_request_number", requestNumber.value);
  }
  if (!editRFQForm) {
    //Submit the parameter as a new RFQ requst.
    RFQCategory.value && formData.append("category_compare", RFQCategory.id);
  }
  formData.append("bidding_type", bidType.value);

  editRFQForm &&
    bidStatus.value !== "open" &&
    bidStatus.value !== "closed" &&
    bidStatus.isChanged &&
    formData.append("bid_status", bidStatus.value);

  !editRFQForm && formData.append("bid_from_date", fromDate.value);
  formData.append("bid_to_date", toDate.value);

  if (editRFQForm) {
    if (time.isChanged) {
      formData.append("bid_time", time.value);
    }
  }
  if (newRFQForm) {
    formData.append("bid_time", time.value);
  }

  const selectedIdsList =
    vendorId && vendorId?.length === 1 ? [vendorId[0]] : vendorId;
  formData.append("select_vendor", selectedIdsList);

  formData.append("shipping_address1", address1.value);
  formData.append("shipping_address2", address2.value);
  formData.append("country", country.value);
  formData.append("city", city.value);
  formData.append("pincode", pincode.value);
  formData.append("state", stateName.value);
  formData.append("mobile_number", mobileNumber.value);

  let itemsListFormatted =
    itemsInput.length >= 1 &&
    itemsInput?.map((e, i) => {
      return {
        item_token: e.itemToken,
        quantity: e.itemQuantity,
        unit: e.itemUnit,
        part_number: e.itemPartNumber,
        description: e?.itemDescription,
      };
    });

  if (!editRFQForm) {
    uploadItemDocument.inputs
      ? formData.append("item_code", JSON.stringify(itemsListFormatted))
      : formData.append("item_code_xlsx", itemCSV.value);
  }
  let itemsListFormattedForUpdate =
    itemsInput.length >= 1 &&
    itemsInput?.map((e, i) => {
      return {
        item_token: e.itemToken,
        quantity: e.itemQuantity,
        item_mapped_token: e.itemMappedToken,
        unit: e.itemUnit,
        description: e.itemDescription,
        part_number: e.itemPartNumber,
      };
    });
  if (editRFQForm) {
    formData.append(
      "items_data_list",
      JSON.stringify(itemsListFormattedForUpdate)
    );
    deletedItemsToken.length >= 1 &&
      formData.append("delete_item", deletedItemsToken);
  }

  //check array has duplicates
  function hasDuplicates(array) {
    const valueSet = new Set();

    for (const item of array) {
      if (valueSet.has(item)) {
        return true; // Duplicate found
      }

      valueSet.add(item);
    }

    return false; // No duplicates found
  }

  //RFQ submit click
  const handleSubmit = async () => {
    let biddingDetails =
      bidType.value &&
      fromDate.value &&
      toDate.value &&
      time.value &&
      vendorId.length > 0;

    let shippingDetails =
      address1.value &&
      country.value &&
      stateName.value &&
      city.value &&
      mobileNumber.value &&
      pincode.value;

    const validation = validateForm();
    const newVendorEmails = vendorId.filter((item) => item === undefined);
    if (validation) {
      setLoadingState(true);
      if (newVendorEmails.length !== 0) {
        let res = await submitNewVendors();
        if (res && Boolean(res?.length)) {
          formData.delete("select_vendor");
          formData.append("select_vendor", [
            ...vendorId.filter(Boolean),
            ...res,
          ]);
        }
      }
      if (editRFQForm) {
        await submitUpdateRFQ(rfqToken, formData);
      } else {
        setLoadingState(true);
        await submitNewRFQ(formData);
      }
    } else if (biddingDetails && !shippingDetails) {
      tab2Click();
    } else if (!biddingDetails && shippingDetails) {
      tab1Click();
    } else {
      setBtnErrorState({
        error: true,
        errorMsg: "Please fill out all fields.",
      });
    }

    // else if (hasDuplicates(item_codes)){
    //   setBtnErrorState({
    //     error: true,
    //     errorMsg: "",
    //   });
    // }
  };

  const handleSubmit1 = (e) => {
    e.stopPropagation();

    const isEmailValid = validateEmail(VendorRegisteredEmail.value);
    const isCompanyValid = emptyCheck(CompanyName.value);

    setCompanyName((prev) => ({ ...prev, isValid: isCompanyValid }));
    setVendorRegisteredEmail((prev) => ({ ...prev, isValid: isEmailValid }));

    if (isEmailValid && isCompanyValid) {
      setLoading(true);
      axios({
        method: "POST",
        url: `${API_ENDPOINT.rfq_vendor_email_verification}`,
        data: {
          email: VendorRegisteredEmail.value,
          buyer_token: buyer_token,
        },
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setVendor((prev) => ({
              ...prev,
              isValid: true,
              value: [
                ...prev.value,
                `${CompanyName.value} (${VendorRegisteredEmail.value})`,
              ],
            }));
          }
          handleClose1();
          setLoading(false);
        })
        .catch((e) => {
          setVendorRegisteredEmail((prev) => ({
            ...prev,
            message: e.response.data.response,
            isValid: false,
          }));
          setLoading(false);
        });
    }
  };

  const sendNewRFQToken = async (token) => {
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.rfq_newversion_chatgpt}`,
      data: {
        rfq_token: token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitNewRFQ = async (formData) => {
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.new_RFQ} `,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token} `,
      },
    })
      .then((res) => {
        handleOpen();
        setPopupHeroText("RFQ Created!");
        setPopupMessage(res?.data?.response);
        GetAllData();
        setLoadingState(false);
        setBtnErrorState({
          error: false,
          errorMsg: "",
        });
        //chatgpt call
        if (res?.data?.item_status) {
          sendNewRFQToken(res?.data?.rfq_token);
        }
      })
      .catch((err) => {
        handleClose();
        setBtnErrorState({
          error: true,
          errorMsg:
            Object.values(err?.response?.data)[0] ||
            "Please fill out all fields",
        });
        setLoadingState(false);
      });
  };

  const submitUpdateRFQ = async (rfq_token, formData) => {
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.edit_RFQ}${rfq_token}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        handleOpen();
        setPopupHeroText("RFQ Updated!");
        setPopupMessage(res?.data?.response);
        setLoadingState(false);
        GetAllData();
        setBtnErrorState({
          error: false,
          errorMsg: "",
        });
      })
      .catch((err) => {
        handleClose();
        setBtnErrorState({
          error: true,
          errorMsg:
            Object.values(err?.response?.data)[0] ||
            "Please fill out all fields",
        });
        setLoadingState(false);
      });
  };
  const submitNewVendors = async () => {
    let vendor_details = [];
    vendorId.forEach((itm, i) => {
      if (itm === undefined) {
        let detailStr = vendor.value[i]?.split("(");
        vendor_details.push(
          `${detailStr[0]?.trim()}|${detailStr[1].replace(")", "")}`
        );
      }
    });
    return axios({
      method: "POST",
      url: API_ENDPOINT.rfq_add_new_vendor,
      data: {
        vendor_details,
        buyer_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((response) => {
        // setVendorID((prev) => ({
        //   ...prev,
        //   value: [
        //     ...prev?.value?.filter(Boolean),
        //     ...response?.data?.response?.map((item) => item.token),
        //   ],
        // }));
        return response?.data?.response?.map((item) => item.token);
      })
      .catch((e) => {});
  };
  const resetForm = () => {
    setItemCSV({
      value: "",
      hasFile: false,
      isValid: true,
    });
    setUploadItemDocument({
      uploadFile: true,
      inputs: false,
    });
    setDeletedItemsToken([]);
    setItemsInput([
      {
        itemToken: "",
        itemTokenisValid: false,

        itemCode: "",
        itemCodeisValid: false,

        itemName: "",
        itemNameisValid: false,

        itemQuantity: "",
        itemQuantityisValid: false,

        itemUnit: "",
        itemUnitisValid: false,

        itemPartNumber: "",
      },
    ]);
    setRequestNumber({ value: "", isChanged: false, isValid: true });
    setRFQCategory({
      value: "",
      id: "",
      categoryList: [],
      isValid: true,
    });
    setBidType({ value: true, isValid: true });
    setFromDate({ value: "", isValid: true });
    setToDate({ value: "", isValid: true });
    setTime({ value: "", isValid: true, isChanged: false });
    setVendor({ value: [], isValid: true });
    setAddress1({ value: "", isValid: true });
    setAddress2({ value: "", isValid: true });
    setCountry((prev) => ({ ...prev, value: "", isValid: true }));
    setStateName({ value: "", isValid: true });
    setCity({ value: "", isValid: true });
    setUploadDocument({ uploadFile: true, uploadDescripton: false });
    setMobileNumber({ value: "", format: "", isValid: true });
    setProposalDescription({ value: "", isValid: true });
    setProposalDocument({ value: "", isValid: true });
    setPincode({ value: "", isValid: true });
    setBidStatus((prev) => ({ ...prev, isChanged: false, isValid: true }));
  };

  //units select options
  const unitsOptions = [
    "Assemblies",
    "Dozen",
    "Each",
    "Feet",
    "Gallons",
    "Kilo",
    "Liters",
    "Metric Tons",
    "Meters",
    "Pieces",
    "Pounds",
    "Sets",
    "Tons",
    "Yards  ",
  ];

  //view RFQ data
  const org_details = [
    {
      label: "RFQ Code",
      value: uniqueData?.rfq_code,
      size: "20rem",
    },
    {
      label: "Bid Type",
      value: !uniqueData?.bidding_type ? "Closed" : "Auction",
      size: "10rem",
    },
    {
      label: "Proposal Document",
      value: uniqueData?.proposal_document ? "Yes" : "No",
      size: "14rem",
    },
    {
      label: "Purchase Request Number",
      value: uniqueData?.purchase_request_number,
      size: "20rem",
    },
  ];

  const proposal_description = [
    {
      label: `Document`,
      value: uniqueData?.proposal_document ? (
        <DownloadFileButton
          file={uniqueData?.proposal_document}
          fileName={fileName}
        />
      ) : (
        "-"
      ),
    },
  ];

  const shippingDetails = [
    {
      label: "Shipping Details",
      value: `${uniqueData?.shipping_address1}, ${`${
        uniqueData?.shipping_address2 && uniqueData?.shipping_address2 + ","
      }`} ${uniqueData?.city + ","} ${uniqueData?.state + " - "}  ${
        uniqueData?.pincode + ","
      } ${uniqueData?.country}`,
    },
  ];

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);

  const localTime = dayjs.utc(uniqueData?.bid_time).local().format("h:mm A");

  const bidding_details = [
    {
      label: "Bid Start Date",
      value: uniqueData?.bid_from_date,
    },
    {
      label: "Bid End Date",
      value: uniqueData?.bid_to_date,
    },
    {
      label: "Bid End Time",
      value: localTime,
    },
  ];

  //delete data
  const handleOpenDlt = () => setOpenDlt(true);
  const handleCloseDlt = () => {
    setOpenDlt(false);
  };

  const handleOpenDltConfirmation = (deletion_id) => {
    let rowData = getUniqueData(RFQData, "token", deletion_id);

    let rfqCode = rowData?.rfq_code?.split("/")[0];

    setPopupHeroText(`Are you sure want to delete "${rfqCode}"?`);

    setRFQToken(deletion_id);
    setOpenDltConfirmation(true);
    setPopupMessage("Heads up! this action can't be undone.");
  };
  const handleCloseDltConfirmation = () => {
    setOpenDltConfirmation(false);
  };
  const handleDeleteRFQ = () => {
    deleteRFQ();
    handleOpenDlt();
    handleCloseDltConfirmation();
  };

  // delete function
  const deleteRFQ = async () => {
    setLoadingState(true);
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.delete_RFQ}${rfqToken}`,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setLoadingState(false);
        GetAllData();
        setPopupHeroText("RFQ Deleted!");
        setPopupMessage(res?.data?.response);
        handleOpenDlt();
        handleCloseDltConfirmation();
      })
      .catch((err) => {
        setLoadingState(false);
      });
  };
  async function createPurchaseOrder() {
    await axios({
      url: `${API_ENDPOINT.create_selected_PO}`,
      method: "POST",
      data: {
        rfq_token: rfqToken,
        buyer_token: buyer_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        // console.log(res); PO-created successfuly
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const selectBid = async (bid_token) => {
    setLoadingState(true);
    await axios({
      url: `${API_ENDPOINT.select_bid}`,
      method: "POST",
      data: {
        bid_token: bid_token,
        bid_status: "selected",
        buyer_token: buyer_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setLoadingState(false);
        setOpenBidConfirmation({
          selectBid: false,
          unSelectBid: false,
        });
        setBidSelection({
          select: true,
          unselect: false,
        });
        GetAllBidsData(pageNumber, rfqToken);
        createPurchaseOrder();
      })
      .catch((err) => {
        setLoadingState(false);
        setOpenBidConfirmation({
          selectBid: false,
          unSelectBid: false,
        });
        GetAllBidsData(pageNumber, rfqToken);
      });
  };

  const unSelectBid = async (bid_token) => {
    setLoadingState(true);
    await axios({
      url: `${API_ENDPOINT.select_bid}`,
      method: "POST",
      data: {
        bid_token: bid_token,
        bid_status: "unselected",
        buyer_token: buyer_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setLoadingState(false);
        GetAllBidsData(pageNumber, rfqToken);
        localStorage.setItem("hasSelectedBid", false);
        setOpenBidConfirmation({
          selectBid: false,
          unSelectBid: false,
        });
        setBidSelection({
          select: false,
          unselect: true,
        });
      })
      .catch((err) => {
        setLoadingState(false);
        GetAllBidsData(pageNumber, rfqToken);
        setOpenBidConfirmation({
          selectBid: false,
          unSelectBid: false,
        });
      });
  };

  const closeSelectBid = () => {
    getEveryBidsData(rfqToken);
    // GetAllBidsData(pageNumber, rfqToken);
    setBidToken("");
    setOpenBidConfirmation({
      selectBid: false,
      unSelectBid: false,
    });
    setBidSelection({
      select: false,
      unselect: false,
    });
  };

  const handleCloseComparePopup = () => {
    setOpenComparisonPopup(false);
  };
  const handleOpenComparePopup = () => {
    setOpenComparisonPopup(true);
  };

  const submitRequote = async () => {
    const formData = new FormData();

    formData.append("rfq_token", uniqueData?.token);
    formData.append("vendor_list", selectedRows);
    setLoadingState(true);
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.requote_bid}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setLoadingState(false);

        handleOpen();
        setPopupHeroText("Resubmission Request Sent!");
        setPopupMessage(Object.values(res?.data));
        cancelRequestRequote();
      })
      .catch((err) => {
        setLoadingState(false);
        setBtnErrorState({
          error: true,
          errorMsg: Object.values(err?.response?.data),
        });
        handleClose();
      });
  };

  const somethingWentWrong = () => {
    setLoadingState(false);
    handleOpenComparePopup();
    setPopupTexts({
      popupHeroText: "Something went wrong!",
      popupDescription: "Please check your files and try again later.",
      warningImg: somthingWrongImg,
    });
  };
  const callCompare = async () => {
    setLoadingState(true);
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.comparison_rfq_history}`,
      data: {
        rfq_token: uniqueData.token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        try {
          const response = res.data.response;
          const quoteDetails = response.json_data;
          const jsonHistory = response.datas;
          if (quoteDetails) {
            const flattenedData = flattenNestedObjects(quoteDetails);
            const headerdata = {
              prn_number: response.prn_number,
              created_date: response.created_date,
            };
            setCompairedData(flattenedData);
            setFileJsonHistory(jsonHistory);
            setComparedNameDate(headerdata);

            openViewIndividualRFQ();
            setLoadingState(false);
          } else {
            somethingWentWrong();
          }
        } catch (err) {
          somethingWentWrong();
        }
      })
      .catch((err) => {
        setLoadingState(false);
        handleOpenComparePopup();
        setPopupTexts({
          popupHeroText: "Something went wrong!",
          popupDescription: "Please check your files and try again later.",
          warningImg: somthingWrongImg,
        });
      });
  };

  const openRequote = () => {
    setRequestQuote(true);
  };

  const cancelRequestRequote = () => {
    setRequestQuote(false);
    setSelectedRows([]);
    setPageNumber(1);
    GetAllBidsData(pageNumber, rfqToken);
  };

  const getCountriesList = async () => {
    axios({
      method: "POST",
      url: API_ENDPOINT.view_countries,
    })
      .then((res) => {
        setCountry((prev) => ({
          ...prev,
          countryList: res?.data?.response,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStatesList = async (country_token) => {
    axios({
      method: "POST",
      url: API_ENDPOINT.view_state,
      data: {
        country_token: country_token,
      },
    })
      .then((res) => {
        setStateName((prev) => ({
          ...prev,
          stateList: res?.data?.response,
        }));
        if (editRFQForm) {
          let filterStates = res?.data?.response?.filter(
            (el, i) => el.name === stateName.value
          );

          let state_token = filterStates && filterStates[0]?.token;
          filterStates && setstateToken(filterStates[0]?.token);

          if (state_token && state_token !== null && state_token !== "") {
            getCityList(state_token);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStatesListAutoFillCall = async (country_token) => {
    try {
      const response = await axios({
        method: "POST",
        url: API_ENDPOINT.view_state,
        data: {
          country_token: country_token,
        },
      });

      setStateName((prev) => ({
        ...prev,
        stateList: response?.data?.response,
      }));

      if (editRFQForm) {
        let filterStates = response?.data?.response?.filter(
          (el, i) => el.name === stateName.value
        );

        let state_token = filterStates && filterStates[0]?.token;
        filterStates && setstateToken(filterStates[0]?.token);

        if (state_token && state_token !== null && state_token !== "") {
          getCityList(state_token);
        }
      }

      return response.data; // Return the response data
    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error to handle it outside this function if needed
    }
  };

  const getCityList = async (state_token) => {
    axios({
      method: "POST",
      url: API_ENDPOINT.view_cities,
      data: {
        state_token: state_token,
      },
    })
      .then((res) => {
        setCity((prev) => ({
          ...prev,
          cityList: res?.data?.response,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCompanyList = async (searchValue) => {
    let abortController = new AbortController();
    try {
      abortController.abort();

      const newAbortController = new AbortController();
      abortController = newAbortController;

      const formData = new FormData();
      formData.append("buyer_token", org_token);
      formData.append("search", searchValue);

      const response = await axios({
        method: "POST",
        url: `${API_ENDPOINT.getCompanyList}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
        signal: abortController.signal,
      });

      let responseData = response?.data?.response;
      setCompanyList(responseData?.map((e) => e?.company_name));
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted:", error.message);
      } else {
        console.log(error);
      }
    }
  };

  const vendorsTabInputs = [
    <DFreeSoloInput
      label="Company Name"
      value={CompanyName.value}
      options={companyList}
      onChange={(value) => {
        if (open1) {
          setCompanyName({
            value: value,
            isValid: emptyCheck(value),
          });
        }
        if (value?.length > 2) {
          getCompanyList(value);
        } else {
          setCompanyList([]);
        }
      }}
      error={!CompanyName.isValid}
      errorMsg={"Company name is required"}
      size={"100%"}
    />,
    <DTextInput
      label={"Email"}
      value={VendorRegisteredEmail.value}
      onChange={(e) => {
        let val = e.target.value;
        const arr = [];
        vendorId.forEach((item, i) => {
          if (item === undefined) {
            //extracting mail id from vendor
            arr.push(vendor.value[i]?.split("(")[1]?.replace(")", ""));
          }
        });
        //checking if the vendor mail exists
        if (arr.some((item) => item === val)) {
          setVendorRegisteredEmail({
            value: val,
            isValid: false,
            message: "vendor already selected",
          });
        } else {
          setVendorRegisteredEmail({
            value: val,
            isValid: validateEmail(val),
          });
        }
      }}
      error={!VendorRegisteredEmail.isValid}
      errorMsg={
        !emptyCheck(VendorRegisteredEmail.value)
          ? "Email is required"
          : VendorRegisteredEmail?.message
          ? VendorRegisteredEmail?.message
          : !VendorRegisteredEmail.isValid
          ? "Invalid email format"
          : "" || ""
      }
      customClassName={vendorClasses.addVendorPopupInput}
      size={"100%"}
    />,
  ];
  const vendorsTabHeading = (
    <HeaderTitle
      titleText={"Add Vendors"}
      customclass={vendorClasses.popupHeading}
    />
  );
  const popupActions = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={handleClose1}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      buyerBtn={true}
    />,
    <DButton
      label={"Add"}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      buyerBtn={true}
      error={primaryBtnError.error}
      errorMsg={primaryBtnError.errorMsg}
      loadingState={loading}
      onClick={handleSubmit1}
    />,
  ];

  // poppup
  const handleCloseViewItemPopup = () => {
    setSendMessage(false);
    setTextAreaSendMessage(false);
    setSendErrorMsg(false);
    setSelectedRows([]);
  };
  const handleCloseSendMessage = () => {
    setSendMessage(true);
    setTextAreaSendMessage(false);
    setErrorMessages(false);
    setTextAreaVlaue({ ...textAreaVlaue, send_message: "" });
    setCharsLeft(0);
    setSelectedRows([]);
    setSendErrorMsg(false);
  };
  const sendMessages = () => {
    setSendMessage(true);
  };
  const handleContinue = () => {
    if (selectedRows.length > 0) {
      setTextAreaSendMessage(true);
    } else {
      setSendErrorMsg(true);
    }
  };

  const handleSendMessage = async () => {
    if (textAreaVlaue.send_message.length <= 0) {
      setErrorMessages(true);
    } else {
      await axios({
        method: "POST",
        url: `${API_ENDPOINT.rfq_chat}`,
        data: {
          sender_token: AdminData?.user_token,
          sender_role: AdminData?.role,
          receiver_token: selectedRows.join(", "),
          receiver_role: `${
            AdminData?.role == "Buyer" ? "vendor contact" : "buyer"
          }`,
          rfq_token: rfqToken,
          message: textAreaVlaue.send_message,
        },
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      })
        .then((res) => {
          handleOpen2();
          setPopupHeroText("Message sent successfully ");
          setPopupMessage(res?.data?.response);
          setLoadingState(false);
          setBtnErrorState({
            error: false,
            errorMsg: "",
          });
          setSendMessage(false);
          setErrorMessages(false);
          setTextAreaVlaue({ ...textAreaVlaue, send_message: "" });
          setCharsLeft(0);
        })
        .catch((err) => {
          handleClose2();
          setBtnErrorState({
            error: true,
            errorMsg:
              Object.values(err?.response?.data)[0] ||
              "Please fill out all fields",
          });
          setLoadingState(false);
        });
    }
  };

  const openViewTimeLineModal = async (unique_id, rowData) => {
    setTimeLineVendorData(() => rowData);
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.view_rfq_chat}`,
      data: {
        buyer_token: AdminData?.user_token,
        vendor_token: rowData?.token, //rowData.token(come from click timeline table), timeLineVendorData (come from chat status change)
        rfq_token: rfqToken,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setOpenForm({
          open: true,
          loading: false,
          errorMessage: "",
        });
        setViewTimeLineData(res?.data?.response);
      })
      .catch((err) => {
        console.log(err);
        setOpenForm({
          open: false,
          loading: false,
          errorMessage: Object.values(err?.response?.data),
        });
      });
    // vendor chat status API call
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.vendor_msg_box}`,
      data: {
        vendor_token: rowData?.token, //rowData.token(come from click timeline table), timeLineVendorData (come from chat status change)
        rfq_token: rfqToken,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((response) => {
        let chatStatus = response?.data?.response;
        setChatConfirmationDialog((prevStatus) => ({
          open: false,
          isClosedChat: chatStatus, //true-> chat closed , false-> chat open
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    setViewRFQ(false);
    setRFQMain(false);
    setPageNumber(1);
    setNewRFQForm(false);
    setEditRFQForm(false);
    setViewTimeLine(true);
  };

  const handleWordCount = (event) => {
    const inputValue = event.target.value;
    const charCount = inputValue.length;
    const maxChar = 2000;
    const truncatedValue = inputValue.slice(0, maxChar);
    setTextAreaVlaue({ ...textAreaVlaue, send_message: truncatedValue });
    const charLength = Math.min(maxChar, charCount); // Ensures the count doesn't exceed 200
    setCharsLeft(charLength);
  };

  // viewItemsActionButtons
  const viewItemsActionButtons = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={handleCloseViewItemPopup}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
    />,
    <DButton
      label={"Continue"}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      onClick={handleContinue}
    />,
  ];

  const viewTextActionButtons = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={handleCloseSendMessage}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
    />,
    <DButton
      label={"Send Message"}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      onClick={handleSendMessage}
    />,
  ];

  const viewUserChildren = [
    <>
      <p className={classes.heading_sub_text}>
        You can send messages to multiple vendors
      </p>
      ,
      <DataTable
        headings={headingMessage}
        tableData={rfqVendorList}
        bulkData={bulkBidsData}
        tableSize={"100%"}
        errorMessage={tableProps.errorMessage}
        isLoading={tableProps.isLoading}
        selection={true}
        // maxBidIterationCount={maxBidIterationCount}
        customClass={classes.fixedHeader}
        hasBidIteration={true}
        selectedRowsList={handleSeletedRowsListMessage}
        setSortingColumnName={setSortingColumnName}
        sortingColumnName={sortingColumnName}
        setSortingOrder={setSortingOrder}
        sortingOrder={sortingOrder}
      />
      {sendErrorMsg && (
        <p className={classes.error_msg}>Please select the vendors</p>
      )}
    </>,
  ];
  const viewTextChildren = [
    <div>
      <p className={classes.heading_sub_text}>Please enter your message</p>
      <TextArea
        maxLength="2000"
        style={{ width: "100 %" }}
        title="send_message"
        name="send_message"
        rows={7}
        value={textAreaVlaue.send_message}
        placeholder="Type your message hear..."
        onChange={handleWordCount}
      />
      {charsLeft === 2000 && (
        <p className={classes.error_msg}>
          Message should be less than 2000 characters only
        </p>
      )}
      {errorMessages ? (
        <p className={classes.error_msg}>Please fill above message field</p>
      ) : (
        ""
      )}
    </div>,
  ];

  const viewUserHeading = (
    <HeaderTitle
      titleText={"Send Message" || <MoreHoriz sx={{ fontSize: "30px" }} />}
      customclass={classes.heading_pop_pup}
    />
  );

  let downloadPdf = () => {
    setLoadingStateDwnload(true);
    setOrganizationDetailspdfUI(true);
    const element = document.getElementById("organization_details");
    let options = {
      margin: [10, 10], // Set the top and bottom margins to 10mm for the first page
      jsPDF: {
        // unit: 'mm',
        format: "a4",
        orientation: "portrait",
        margins: {
          top: 10,
          bottom: 10,
          left: 10,
          right: 10,
        },
      },
      // Callback function to modify the jsPDF object before rendering the PDF
      onBeforeSendPDF: function (pdf, renderer, options) {
        // Set the top and bottom margins to 20mm for the second page and beyond
        pdf.setPage(options.pageNumber - 1);
        if (options.pageNumber > 1) {
          pdf.setPageMargins(10, 50, 10);
        }
      },
      filename: `organizationdetails.pdf`,
    };
    setTimeout(() => {
      html2pdf().set(options).from(element).save();
      setLoadingStateDwnload(false);
      setOrganizationDetailspdfUI(false);
    }, 2000);
  };
  const handleSelectedAddress = async (addressData) => {
    let { city, pincode, shipping_address1, shipping_address2, state } =
      addressData;
    setAddress1((prev) => ({
      ...prev,
      value: shipping_address1 ? shipping_address1 : "",
      isValid: validateSelect("Select", shipping_address1),
    }));
    setAddress2({
      value: shipping_address2 ? shipping_address2 : "",
    });
    setCountry((prev) => ({
      ...prev,
      value: addressData.country ? addressData.country : "",
      isValid: validateSelect("Select", addressData.country),
    }));
    setStateName((prev) => ({
      ...prev,
      value: state ? state : "",
      isValid: validateSelect("Select", state),
    }));
    setCity((prev) => ({
      ...prev,
      value: city ? city : "",
      isValid: validateSelect("Select", city),
    }));
    setPincode({
      value: pincode ? pincode : "",
      isValid: emptyCheck(pincode),
    });
    // setMobileNumber({
    //   value: splitCodeMobileNumber(e?.address?.mobile_number).mobile,
    //   format: splitCodeMobileNumber(e?.address?.mobile_number).code,
    //   isValid:
    //     emptyCheck(splitCodeMobileNumber(e?.address?.mobile_number).code),
    // });
    let filterObj = country?.countryList?.filter(
      (el) => el?.name === addressData.country
    );
    filterObj && setCountryToken(filterObj[0]?.token);
    getStatesList(filterObj[0]?.token);

    try {
      const countryToken = filterObj[0]?.token;
      const statesListResponse = await getStatesListAutoFillCall(countryToken);
      let filterObj1 = statesListResponse.response.filter(
        (el) => el.name === state
      );
      if (filterObj1 && filterObj1?.length > 0) {
        filterObj1 && setstateToken(filterObj1[0]?.token);
        getCityList(filterObj1[0]?.token);
      }
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };
  const handleChatStatusConfirmation = (chatState) => {
    setChatConfirmationDialog((pre) => ({
      ...pre,
      open: true,
    }));
  };
  const closeChatModule = () => {
    getEveryBidsData(rfqToken);
    setChatConfirmationDialog((pre) => ({
      ...pre,
      open: false,
    }));
  };

  const chatStateChage = async (chatState, token) => {
    setLoadingState(true);
    await axios({
      url: `${API_ENDPOINT.rfq_chat_box}`,
      method: "POST",
      data: {
        disable: !chatState,
        rfq_token: token,
        vendor_token: timeLineVendorData?.token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        openViewTimeLineModal("", timeLineVendorData);
        setLoadingState(false);
        //reset state
        setChatConfirmationDialog((prevStatus) => ({
          isClosedChat: false,
          open: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        setLoadingState(false);
        //maintain btn state close modal
        setChatConfirmationDialog((prevStatus) => ({
          ...prevStatus,
          open: false,
        }));
      });
  };
  return (
    <div className={classes.users_tab_wrapper}>
      <div className={classes.users_topHeader}>
        <div className={`${classes.lhs} flex`}>
          <HeaderTitle titleText="RFQ" customclass={classes.dashHead} />
        </div>

        <div className={`${classes.rhs} flex`}>
          <DateBar />
          <NotificationBell />
          <ProfileCard
            buyerBtn={true}
            buyerBtnOutline={true}
            profilePath={"/Buyer_dashboard/My_profile"}
          />
        </div>
      </div>

      <div className={classes.search_add_wrapper}>
        {viewRFQ || RFQMain || viewTimeLine ? (
          <div
            className={`${classes.flex} ${classes.flex_align_end}  ${classes.search_add_wrapper_content_left}`}
          >
            {RFQMain && (
              <SearchBar
                value={searchQuery}
                onChange={(query) => {
                  setSearchQuery(query);
                  setPageNumber(1);
                }}
              />
            )}
            {/* {viewRFQ && (
              <SearchBar
                value={searchQuery2}
                onChange={(query) => {
                  setSearchQuery2(query);
                  setPageNumber(1);
                }}
              />
            )} */}
            {viewTimeLine && (
              <SearchBar
                value={searchQuery3}
                onChange={(query) => {
                  setSearchQuery3(query);
                  setPageNumber(1);
                }}
              />
            )}

            {RFQMain && (
              <div className={`${classes.flex}`}>
                <DDateInput
                  customClass={classes.dateFilters}
                  type={"date"}
                  label={"Start Date"}
                  disableFuture={true}
                  onChange={(e) => {
                    setPageNumber(1);
                    setStartDateSearchQuery({
                      isValid: emptyCheck(e),
                      value: e,
                    });
                  }}
                  value={startDateSearchQuery}
                  error={!startDateSearchQuery?.isValid}
                  errorMsg={"From date is required"}
                />
                <DDateInput
                  customClass={classes.dateFilters}
                  type={"date"}
                  label={"End Date"}
                  disableFuture={true}
                  onChange={(e) => {
                    setPageNumber(1);
                    setEndDateSearchQuery({
                      isValid: emptyCheck(e),
                      value: e,
                    });
                  }}
                  value={endDateSearchQuery}
                  error={!endDateSearchQuery?.isValid}
                  errorMsg={"To date is required"}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            {viewTimeLine ? (
              ""
            ) : (
              <HeaderTitle
                titleText={`${editRFQForm ? "Edit RFQ" : "New RFQ"}`}
                customclass={classes.dashHead}
              />
            )}
          </>
        )}

        {(viewRFQ || newRFQForm || editRFQForm) && (
          <DButton
            label={"BACK"}
            btnHeight="4rem"
            btnWidth={"12rem"}
            buyerBtn={true}
            onClick={() => {
              openRFQMain();
            }}
            // customWrapperClass={(viewTimeLine ? classes.flex_end : "")}
          />
        )}

        {viewTimeLine && (
          <DButton
            label={"BACK"}
            btnHeight="4rem"
            btnWidth={"12rem"}
            buyerBtn={true}
            onClick={() => {
              openRFQMainTimeLine();
            }}
          />
        )}

        {viewRFQComparedTable && (
          <DButton
            label={"BACK"}
            btnHeight="4rem"
            btnWidth={"12rem"}
            buyerBtn={true}
            onClick={() => {
              backViewIndividualRFQ();
            }}
          />
        )}

        {RFQMain && (
          <div className={classes.rhsButtons}>
            <DropdownBtnOutlined
              label="DOWNLOAD AS"
              btnHeight="4rem"
              btnWidth="18rem"
              loadingState={loadingStateDwnload}
              disabled={RFQData?.length === 0}
              onClick={(fileFormat) => {
                downloadFile({
                  key: "org_token",
                  value: buyer_token,
                  fileNameProp: "RFQ Data",
                  outputFormat: fileFormat,
                  API_ENDPOINT: API_ENDPOINT.buyer_rfq_csv,
                  authToken: auth_token,
                  setLoadingState: setLoadingStateDwnload,
                });
              }}
              options={["CSV", "PDF"]}
            />

            <DButton
              labelImg={plusIcon}
              iconWidth="1rem"
              iconHeight="1rem"
              label={"NEW RFQ"}
              btnHeight="4rem"
              buyerBtn={true}
              onClick={openNewRFQ}
            />
          </div>
        )}
      </div>

      {RFQMain && (
        <>
          <div className={`${classes.d_dataTable} `}>
            <DataTable
              headings={headings}
              tableData={RFQData}
              tableSize={RFQData?.length === 0 ? "100%" : "130vw"}
              actions={true}
              errorMessage={tableProps.errorMessage}
              isLoading={tableProps.isLoading}
              viewBtn={true}
              editBtn={true}
              deleteBtn={true}
              sorting={true}
              viewClick={openViewRFQ}
              editClick={openUpdateRFQModal}
              deleteClick={handleOpenDltConfirmation}
              setSortingColumnName={setSortingColumnName}
              sortingColumnName={sortingColumnName}
              setSortingOrder={setSortingOrder}
              sortingOrder={sortingOrder}
            />

            <PaginantionComponent
              pageNumber={pageNumber}
              currentPage={currentPage || 0}
              itemsPerPage={itemsPerPage || 0}
              totalItems={totalItems || 0}
              previouPageClick={() => {
                previouPageClick(pageNumber, setPageNumber);
                onLocaleStoreg("prev");
              }}
              localStorageKey={"rfq_page_number"}
              nextPageClick={() => {
                nextPageClick(pageNumber, setPageNumber, paginationData);
                onLocaleStoreg("next");
              }}
              setPageNumber={setPageNumber}
              disablePrevious={parseInt(pageNumber) === 1 || pageNumber === ""}
              disableNext={
                parseInt(pageNumber) === paginationData?.page_count ||
                !paginationData ||
                pageNumber === ""
              }
            />
          </div>
        </>
      )}

      {viewRFQ && (
        <>
          <div className={classes.tabsScreens}>
            <div className={classes.infoSection}>
              <HeaderTitle
                customclass={classes.tabHeading}
                titleText={"RFQ Details"}
              />

              <div className={classes.info}>
                {org_details.map((e, i) => (
                  <InfoLabel
                    key={i}
                    isLoading={loadingState}
                    label={e.label}
                    value={e.value}
                    size={e.size}
                  />
                ))}
              </div>
            </div>

            <div className={`${classes.d_dataTable_items}`}>
              <DataTable
                headings={headingsForViewItemsRFQ}
                stickyHead={true}
                tableFixedHeight={"400px"}
                tableData={RFQitemsListMapped}
                tableSize={"100%"}
                customClass={classes.dTable}
                setSortingColumnName={setSortingColumnName}
                sortingColumnName={sortingColumnName}
                setSortingOrder={setSortingOrder}
                sortingOrder={sortingOrder}
              />
            </div>

            {shippingDetails.map((e, i) => (
              <InfoLabel
                key={i}
                isLoading={loadingState}
                // customClass={classes.desc}
                label={e.label}
                value={e.value}
                size={"98%"}
                maxLength={"350"}
              />
            ))}

            <div className={classes.infoSection}>
              {proposal_description.map((e, i) => (
                <InfoLabel
                  key={i}
                  isLoading={loadingState}
                  // customClass={classes.desc}
                  label={e.label}
                  value={e.value}
                  size={"98%"}
                  maxLength={"350"}
                />
              ))}
            </div>

            <div className={`${classes.infoSection}`}>
              <HeaderTitle
                customclass={classes.tabHeading}
                titleText={"Bidding Details"}
              />

              <div className={`${classes.compareSection} ${classes.info}`}>
                <div className={`${classes.flex}`}>
                  {bidding_details.map((e, i) => {
                    return (
                      <InfoLabel
                        key={i}
                        isLoading={loadingState}
                        label={e.label}
                        value={e.value}
                        size={"20rem"}
                      />
                    );
                  })}
                </div>

                <div
                  className={classes.flex}
                  style={{
                    alignItems: "flex-start",
                  }}
                >
                  {requestRequote === false && enableSelect === false && (
                    <DButton
                      iconWidth="1rem"
                      iconHeight="1rem"
                      label={"Send Message"}
                      disabled={false}
                      btnHeight="4rem"
                      btnWidth="14rem"
                      buyerBtn={true}
                      onClick={sendMessages}
                    />
                  )}

                  {requestRequote === false && enableSelect === false ? (
                    <>
                      {
                        <DButton
                          iconWidth="1rem"
                          iconHeight="1rem"
                          label={"Request Revised Quote"}
                          disabled={
                            !bidsData ||
                            bidsData?.length === 0 ||
                            hasSelectedBid === true ||
                            uniqueData?.bid_status?.toLowerCase() ===
                              "closed" ||
                            uniqueData?.bid_status?.toLowerCase() ===
                              "cancelled" ||
                            uniqueData?.bid_status?.toLowerCase() === "failed"
                          }
                          btnHeight="4rem"
                          btnWidth="20.2rem"
                          buyerBtn={true}
                          onClick={openRequote}
                        />
                      }

                      {uniqueData?.item_status !== "False" && (
                        <DButton
                          iconWidth="1rem"
                          iconHeight="1rem"
                          disabled={!bidsData || bidsData?.length === 0}
                          label={"View Compared Result"}
                          btnHeight="4rem"
                          btnWidth="20rem"
                          buyerBtn={true}
                          loadingState={loadingState}
                          onClick={() => {
                            callCompare("json");
                          }}
                        />
                      )}

                      <DButton
                        iconWidth="1rem"
                        iconHeight="1rem"
                        disabled={!bidsData || bidsData?.length === 0}
                        label={"View Quotes"}
                        btnHeight="4rem"
                        btnWidth="13.2rem"
                        buyerBtn={true}
                        onClick={() => {
                          setEnableSelect(true);
                        }}
                      />
                    </>
                  ) : enableSelect ? (
                    <>
                      <DButtonOutlined
                        iconWidth="1rem"
                        iconHeight="1rem"
                        label={"Cancel"}
                        btnHeight="4rem"
                        btnWidth="15.2rem"
                        buyerBtn={true}
                        onClick={() => {
                          setEnableSelect(false);
                          setSelectedRowForComparison([]);
                          setSelectedRows([]);
                        }}
                      />

                      <DButton
                        iconWidth="1rem"
                        iconHeight="1rem"
                        label={"View"}
                        disabled={
                          !selectedRows ||
                          selectedRows?.length === 0 ||
                          // selectedRowForComparison?.length < 0
                          // ||
                          selectedRowForComparison?.filter(
                            (el, i) =>
                              el?.document === "" || el?.document === null
                          ).length > 0
                        }
                        btnHeight="4rem"
                        btnWidth="10.2rem"
                        loadingState={loadingState}
                        buyerBtn={true}
                        error={
                          // selectedRowForComparison?.length > 3 ||
                          selectedRowForComparison?.filter(
                            (el, i) =>
                              el?.document === "" || el?.document === null
                          ).length > 0
                        }
                        errorMsg={
                          selectedRowForComparison?.filter(
                            (el, i) =>
                              el?.document === "" || el?.document === null
                          ).length > 0
                            ? "Only files quotes can be viewed."
                            : selectedRowForComparison?.length > 3
                            ? "Only 3 files can be viewed at a time."
                            : ""
                        }
                        onClick={() => {
                          setEnableSelect(false);
                          let hasDoc = selectedRowForComparison?.filter(
                            (el, i) =>
                              el?.document === "" || el?.document === null
                          );
                          setOpenViewQuotesPopup(true);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <DButtonOutlined
                        iconWidth="1rem"
                        iconHeight="1rem"
                        label={"Cancel"}
                        btnHeight="4rem"
                        btnWidth="15.2rem"
                        buyerBtn={true}
                        onClick={cancelRequestRequote}
                      />

                      <DButton
                        iconWidth="1rem"
                        iconHeight="1rem"
                        label={"Submit Requote"}
                        disabled={!selectedRows || selectedRows?.length === 0}
                        btnHeight="4rem"
                        btnWidth="20.2rem"
                        loadingState={loadingState}
                        buyerBtn={true}
                        onClick={submitRequote}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <DataTable
              headings={headingsForViewRFQ}
              tableData={bidsData}
              bulkData={bulkBidsData}
              getTokenType={"vendor token"}
              tableSize={"100%"}
              errorMessage={tableProps.errorMessage}
              isLoading={tableProps.isLoading}
              selection={requestRequote || enableSelect}
              maxBidIterationCount={maxBidIterationCount}
              customClass={classes.dTable}
              file={true}
              buyer={true}
              // timeline={true}
              hasBidIteration={true}
              // disableSelectAll={enableSelect}
              // viewTimeLineClick={openViewTimeLineModal}
              selectedRowsList={handleSeletedRowsList}
              selectBidBtn={
                uniqueData?.bid_status?.toLowerCase() !== "cancelled" &&
                uniqueData?.bid_status?.toLowerCase() !== "failed"
              }
              clearAllSelectedRows={
                !selectedRows ||
                selectedRows?.length === 0 ||
                !selectedRowForComparison ||
                selectedRowForComparison?.length === 0
              }
              onSelectBid={(bid_token) => {
                setOpenBidConfirmation({
                  selectBid: true,
                  unSelectBid: false,
                });
                setBidToken(bid_token);
              }}
              onUnSelectBid={(bid_token) => {
                setOpenBidConfirmation({
                  selectBid: false,
                  unSelectBid: true,
                });
                setBidToken(bid_token);
              }}
              setSortingColumnName={setSortingColumnName}
              sortingColumnName={sortingColumnName}
              setSortingOrder={setSortingOrder}
              sortingOrder={sortingOrder}
            />

            <PaginantionComponent
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              currentPage={currentPage || 0}
              itemsPerPage={itemsPerPage || 0}
              totalItems={totalItems || 0}
              previouPageClick={() => {
                previouPageClick(pageNumber, setPageNumber);
              }}
              nextPageClick={() => {
                nextPageClick(pageNumber, setPageNumber, paginationData);
              }}
              disablePrevious={pageNumber === 1}
              disableNext={
                pageNumber === paginationData?.page_count || !paginationData
              }
            />
            <HeaderTitle
              customclass={classes.tabHeading}
              titleText={"Messages"}
            />
            <DataTable
              headings={headingsForSentMessage}
              tableData={chatHistory}
              bulkData={bulkBidsData}
              tableSize={"100%"}
              errorMessage={tableProps.errorMessage}
              isLoading={tableProps.isLoading}
              selection={requestRequote || enableSelect}
              maxBidIterationCount={maxBidIterationCount}
              customClass={classes.dTable}
              hasBidIteration={true}
              setSortingColumnName={setSortingColumnName}
              sortingColumnName={sortingColumnName}
              setSortingOrder={setSortingOrder}
              sortingOrder={sortingOrder}
              timeline={true}
              viewTimeLineClick={openViewTimeLineModal}
            />

            <PaginantionComponent
              pageNumber={messagePageNumber}
              setPageNumber={setMessagePageNumber}
              currentPage={currentPage || 0}
              itemsPerPage={messageItemsPerPage || 0}
              totalItems={messageTotalItems || 0}
              previouPageClick={() => {
                previouPageClick(messagePageNumber, setMessagePageNumber);
              }}
              nextPageClick={() => {
                nextPageClick(
                  messagePageNumber,
                  setMessagePageNumber,
                  messagePaginationData
                );
              }}
              disablePrevious={messagePageNumber === 1}
              disableNext={
                messagePageNumber === messagePaginationData?.page_count ||
                !messagePaginationData
              }
            />
          </div>
          {/* <div className={`${classes.d_dataTable_sm}`}>
            <div className={`${classes.infoSection}`}></div>
          </div> */}
        </>
      )}

      {openForm.errorMessage ? (
        <div className={` ${classes.formErrorWrap} ${classes.flex}`}>
          <p className={classes.infoText}> {openForm.errorMessage} </p>
        </div>
      ) : openForm.errorMessage && newRFQForm ? (
        <div className={` ${classes.formErrorWrap} ${classes.flex}`}>
          <p className={classes.infoText}>
            {" "}
            {openForm.errorMessage} <br /> contact your organisation to add an
            item to create an RFQ.
          </p>
        </div>
      ) : newRFQForm || (editRFQForm && openForm.open) ? (
        <>
          <div className={classes.tabsScreens}>
            {/* <HeaderTitle
              titleText={`${editRFQForm ? "Edit RFQ" : "New RFQ"}`}
              customclass={classes.dashHead}
            /> */}

            <div className={classes.docField}>
              {!editRFQForm && (
                <div className={classes.input_container}>
                  <DefaultLabel label={"Create an RFQ"} />

                  <div className={classes.util}>
                    <DRadioInput
                      label={"Attach RFQ document"}
                      id={"itemCSVFile"}
                      value={"itemCSVFile"}
                      checked={uploadItemDocument.uploadFile}
                      customClass={classes.radioTag}
                      onChange={() => {
                        setUploadItemDocument({
                          uploadFile: true,
                          inputs: false,
                        });
                      }}
                      name={"itemDoc"}
                    />

                    <DRadioInput
                      label={"Type details of RFQ"}
                      id={"itemInputs"}
                      checked={uploadItemDocument.inputs}
                      customClass={classes.radioTag}
                      value={"itemInputs"}
                      onChange={() => {
                        setItemCSV((prev) => ({
                          ...prev,
                          value: "",
                        }));
                        setUploadItemDocument({
                          uploadFile: false,
                          inputs: true,
                        });
                      }}
                      name={"itemDoc"}
                    />
                  </div>

                  {uploadItemDocument.inputs && (
                    <div className={`${classes.multiForm}`}>
                      {itemsInput?.length >= 1 &&
                        itemsInput?.map((el, index) => {
                          return (
                            <div
                              className={`${classes.items_form_wrapper}`}
                              key={index}
                            >
                              <div className={`${classes.itemCount}`}>
                                <span>Item #{index + 1}</span>
                              </div>
                              <div
                                className={
                                  classes.input_fields_wrapper_multi_line1
                                }
                              >
                                <DSelectInput
                                  customClass={classes.fields}
                                  label="Item Code"
                                  value={el.itemCode}
                                  options={itemList.values.visibleOption}
                                  onChange={(e) => {
                                    handleItemInputChanges(
                                      e,
                                      "itemCode",
                                      index
                                    );

                                    let itemToken =
                                      itemList.values.tokens[
                                        itemList.values.visibleOption.indexOf(e)
                                      ];
                                    itemToken !== null &&
                                      itemToken !== undefined &&
                                      itemToken !== "" &&
                                      getItemDetailsByItemCode(
                                        itemToken,
                                        index
                                      );
                                    handleItemInputChanges(
                                      itemToken,
                                      "itemToken",
                                      index
                                    );

                                    let isValid = !emptyCheck(el.itemCode);
                                    handleItemInputChanges(
                                      isValid,
                                      "itemCodeisValid",
                                      index
                                    );

                                    handleItemInputChanges(
                                      false,
                                      "itemNameisValid",
                                      index
                                    );

                                    if (!e) {
                                      handleItemInputChanges(
                                        "",
                                        "itemName",
                                        index
                                      );
                                      handleItemInputChanges(
                                        true,
                                        "itemNameisValid",
                                        index
                                      );
                                    }
                                  }}
                                  error={el.itemCodeisValid}
                                  errorMsg={"Item Code is required"}
                                  size={"100%"}
                                />
                                <DSelectInput
                                  customClass={classes.fields}
                                  label="Item Name"
                                  value={el.itemName}
                                  options={itemNameList.values.visibleOption}
                                  onChange={(e) => {
                                    handleItemInputChanges(
                                      e,
                                      "itemName",
                                      index
                                    );

                                    let itemToken =
                                      itemNameList.values.tokens[
                                        itemNameList.values.visibleOption.indexOf(
                                          e
                                        )
                                      ];
                                    itemToken !== null &&
                                      itemToken !== undefined &&
                                      itemToken !== "" &&
                                      getItemDetailsByNameCode(
                                        itemToken,
                                        index
                                      );
                                    handleItemInputChanges(
                                      itemToken,
                                      "itemToken",
                                      index
                                    );

                                    let isValid = !emptyCheck(el.itemCode);
                                    handleItemInputChanges(
                                      isValid,
                                      "itemNameisValid",
                                      index
                                    );

                                    handleItemInputChanges(
                                      false,
                                      "itemCodeisValid",
                                      index
                                    );

                                    if (!e) {
                                      handleItemInputChanges(
                                        "",
                                        "itemCode",
                                        index
                                      );
                                      handleItemInputChanges(
                                        true,
                                        "itemCodeisValid",
                                        index
                                      );
                                    }
                                  }}
                                  error={el.itemCodeisValid}
                                  errorMsg={"Item Name is required"}
                                  size={"100%"}
                                />
                                {/* <DTextInput
                                  customClass={classes.fields}
                                  label="Item Name"
                                  value={el.itemName}
                                  onChange={(e) => {
                                    let val = e.target.value;
                                    handleItemInputChanges(
                                      val,
                                      "itemName",
                                      index
                                    );

                                    let isValid = !emptyCheck(el.itemName);
                                    handleItemInputChanges(
                                      isValid,
                                      "itemNameisValid",
                                      index
                                    );
                                  }}
                                  error={el.itemNameisValid}
                                  errorMsg={"Item Name is required"}
                                  size={"100%"}
                                /> */}
                              </div>
                              <div
                                className={`${classes.input_fields_wrapper_multi_line2}`}
                              >
                                <DTextInput
                                  customClassName={classes.fields}
                                  label="Quantity"
                                  maxLength={250}
                                  type="text"
                                  value={el.itemQuantity}
                                  onChange={(e) => {
                                    let val = e?.target?.value?.replace(
                                      regexForNumber,
                                      ""
                                    );
                                    let isValid = !emptyCheck(e?.target?.value);
                                    handleItemInputChanges(
                                      isValid,
                                      "itemQuantityisValid",
                                      index
                                    );
                                    handleItemInputChanges(
                                      val,
                                      "itemQuantity",
                                      index
                                    );
                                  }}
                                  error={el.itemQuantityisValid}
                                  errorMsg={"Quantity is required"}
                                  size={"100%"}
                                />

                                <DSelectInput
                                  customClass={classes.fields}
                                  label="Unit"
                                  value={el.itemUnit}
                                  options={unitsOptions}
                                  onChange={(e) => {
                                    let val = e;
                                    let isValid = !emptyCheck(val);
                                    handleItemInputChanges(
                                      isValid,
                                      "itemUnitisValid",
                                      index
                                    );
                                    handleItemInputChanges(
                                      val,
                                      "itemUnit",
                                      index
                                    );
                                  }}
                                  error={el.itemUnitisValid}
                                  errorMsg={"Unit is required"}
                                  size={"100%"}
                                />

                                <DTextInput
                                  customClass={classes.fields}
                                  label="Part Number (Optional)"
                                  value={el.itemPartNumber}
                                  onChange={(e) => {
                                    let val = e.target.value?.replace(
                                      regexForAlphaNumeric,
                                      ""
                                    );
                                    // setPartNumber({
                                    //   value: val,
                                    // });
                                    handleItemInputChanges(
                                      val,
                                      "itemPartNumber",
                                      index
                                    );
                                  }}
                                  size={"100%"}
                                />
                              </div>

                              <DTextArea
                                label="Item Description"
                                type="text"
                                placeholder={"description"}
                                value={el.itemDescription}
                                onChange={(e) => {
                                  let val = e?.target?.value;
                                  let isValid;
                                  if (val && val.length > 2550) {
                                    isValid = true;
                                  } else if (val?.trim() === "") {
                                    isValid = true;
                                  } else {
                                    isValid = false;
                                  }
                                  handleItemInputChanges(
                                    isValid,
                                    "itemDescriptionisValid",
                                    index
                                  );
                                  handleItemInputChanges(
                                    val,
                                    "itemDescription",
                                    index
                                  );
                                }}
                                containerClass={classes.itemDescription}
                                customClassName={
                                  classes.itemDescriptionTextArea
                                }
                                inputWrapperClass={classes.textAreaWrapper}
                                error={el.itemDescriptionisValid}
                                errorMsg={
                                  el?.itemDescription &&
                                  el?.itemDescription.length > 2550
                                    ? "Item Description should be less than 2550 characters"
                                    : el.itemDescriptionisValid
                                    ? "Item Description is required"
                                    : ""
                                }
                                size={"95.5%"}
                              />
                              {index !== 0 && (
                                <button
                                  onClick={() => {
                                    removeItemsInput(index);
                                  }}
                                  className={`${classes.removeFormButton}`}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          );
                        })}

                      <div className={`${classes.addNewForm}`}>
                        <button
                          onClick={addItemsInputs}
                          className={`${classes.addNewFormButton}`}
                        >
                          Add Item
                        </button>
                      </div>
                    </div>
                  )}

                  {uploadItemDocument.uploadFile && !editRFQForm && (
                    <>
                      <DFileUpload
                        fileTypes={".csv,.xlsx"}
                        fileName={editRFQForm && fileName}
                        customFileTypeText={"(Supported format CSV/Excel)"}
                        onFileUpload={(file) => {
                          let isValidFile = validateFile(
                            file,
                            false,
                            ["csv", "xlsx"],
                            5
                          ).isValid;
                          setItemCSV({
                            value: file,
                            hasFile: false,
                            isValid: isValidFile,
                          });
                        }}
                        error={!itemCSV.isValid}
                        errorMsg={
                          validateFile(
                            itemCSV.value,
                            false,
                            ["csv"],
                            5,
                            "Items CSV Document"
                          ).errorMessage
                        }
                        size={"100%"}
                      />
                      <p
                        className={classes.linkBtn}
                        onClick={() => {
                          saveAs(RFQSampleItemsFile, "Sample CSV.csv");
                        }}
                      >
                        Sample CSV
                      </p>
                    </>
                  )}
                </div>
              )}

              {editRFQForm && (
                <div className={classes.itemsListWrapper}>
                  {editRFQForm && (
                    <span className={classes.headingSm}>Items</span>
                  )}
                  <div className={`${classes.multiForm}`}>
                    {itemsInput?.length >= 1 &&
                      itemsInput?.map((el, index) => {
                        return (
                          <div
                            className={`${classes.items_form_wrapper}`}
                            key={index}
                          >
                            <div className={`${classes.itemCount}`}>
                              <span>Item #{index + 1}</span>
                            </div>
                            <div
                              className={
                                classes.input_fields_wrapper_multi_line1
                              }
                            >
                              <DSelectInput
                                customClass={classes.fields}
                                label="Item Code"
                                value={el.itemCode}
                                options={itemList.values.visibleOption}
                                onChange={(e) => {
                                  handleItemInputChanges(e, "itemCode", index);

                                  let itemToken =
                                    itemList.values.tokens[
                                      itemList.values.visibleOption.indexOf(e)
                                    ];

                                  itemToken !== null &&
                                    itemToken !== undefined &&
                                    itemToken !== "" &&
                                    getItemDetailsByItemCode(itemToken, index);
                                  handleItemInputChanges(
                                    itemToken,
                                    "itemToken",
                                    index
                                  );

                                  let isValid =
                                    !emptyCheck(el.itemCode) &&
                                    el?.itemCode === null;
                                  handleItemInputChanges(
                                    isValid,
                                    "itemCodeisValid",
                                    index
                                  );

                                  handleItemInputChanges(
                                    false,
                                    "itemNameisValid",
                                    index
                                  );

                                  if (!e) {
                                    handleItemInputChanges(
                                      "",
                                      "itemName",
                                      index
                                    );
                                    handleItemInputChanges(
                                      true,
                                      "itemNameisValid",
                                      index
                                    );
                                  }
                                }}
                                error={el.itemCodeisValid}
                                errorMsg={"Item Code is required"}
                                size={"100%"}
                              />
                              <DSelectInput
                                customClass={classes.fields}
                                label="Item Name"
                                value={el.itemName}
                                options={itemNameList.values.visibleOption}
                                onChange={(e) => {
                                  handleItemInputChanges(e, "itemName", index);

                                  let itemToken =
                                    itemNameList.values.tokens[
                                      itemNameList.values.visibleOption.indexOf(
                                        e
                                      )
                                    ];
                                  itemToken !== null &&
                                    itemToken !== undefined &&
                                    itemToken !== "" &&
                                    getItemDetailsByNameCode(itemToken, index);
                                  handleItemInputChanges(
                                    itemToken,
                                    "itemToken",
                                    index
                                  );

                                  let isValid = !emptyCheck(el.itemCode);
                                  handleItemInputChanges(
                                    isValid,
                                    "itemNameisValid",
                                    index
                                  );

                                  handleItemInputChanges(
                                    false,
                                    "itemCodeisValid",
                                    index
                                  );

                                  if (!e) {
                                    handleItemInputChanges(
                                      "",
                                      "itemCode",
                                      index
                                    );
                                    handleItemInputChanges(
                                      true,
                                      "itemCodeisValid",
                                      index
                                    );
                                  }
                                }}
                                error={el.itemCodeisValid}
                                errorMsg={"Item Name is required"}
                                size={"100%"}
                              />
                              {/* <DTextInput
                                customClass={classes.fields}
                                label="Item Name"
                                value={el.itemName}
                                onChange={(e) => {
                                  let val = e.target.value;
                                  handleItemInputChanges(
                                    val,
                                    "itemName",
                                    index
                                  );

                                  let isValid = !emptyCheck(el.itemName);
                                  handleItemInputChanges(
                                    isValid,
                                    "itemNameisValid",
                                    index
                                  );
                                }}
                                error={el.itemNameisValid}
                                errorMsg={"Item Name is required"}
                                size={"100%"}
                              /> */}
                            </div>
                            <div
                              className={`${classes.input_fields_wrapper_multi_line2}`}
                            >
                              <DTextInput
                                customClassName={classes.fields}
                                label="Quantity"
                                maxLength={250}
                                type="text"
                                value={el.itemQuantity}
                                onChange={(e) => {
                                  let val = e?.target?.value?.replace(
                                    regexForNumber,
                                    ""
                                  );
                                  let isValid = !emptyCheck(e?.target?.value);
                                  handleItemInputChanges(
                                    isValid,
                                    "itemQuantityisValid",
                                    index
                                  );
                                  handleItemInputChanges(
                                    val,
                                    "itemQuantity",
                                    index
                                  );
                                }}
                                error={el.itemQuantityisValid}
                                errorMsg={"Quantity is required"}
                                size={"100%"}
                              />

                              <DSelectInput
                                customClass={classes.fields}
                                label="Unit"
                                value={el.itemUnit}
                                options={unitsOptions}
                                onChange={(e) => {
                                  let val = e;
                                  let isValid = !emptyCheck(val);
                                  handleItemInputChanges(
                                    isValid,
                                    "itemUnitisValid",
                                    index
                                  );
                                  handleItemInputChanges(
                                    val,
                                    "itemUnit",
                                    index
                                  );
                                }}
                                error={el.itemUnitisValid}
                                errorMsg={"Unit is required"}
                                size={"100%"}
                              />

                              <DTextInput
                                customClass={classes.fields}
                                label="Part Number (Optional)"
                                value={el.itemPartNumber}
                                onChange={(e) => {
                                  let val = e.target.value?.replace(
                                    regexForAlphaNumeric,
                                    ""
                                  );
                                  // setPartNumber({
                                  //   value: val,
                                  // });
                                  handleItemInputChanges(
                                    val,
                                    "itemPartNumber",
                                    index
                                  );
                                }}
                                size={"100%"}
                              />
                            </div>

                            <DTextArea
                              label="Item Description"
                              type="text"
                              placeholder={"description"}
                              value={el.itemDescription}
                              onChange={(e) => {
                                let val = e?.target?.value;
                                let isValid;
                                if (val && val.length > 2550) {
                                  isValid = true;
                                } else if (val?.trim() === "") {
                                  isValid = true;
                                } else {
                                  isValid = false;
                                }
                                handleItemInputChanges(
                                  isValid,
                                  "itemDescriptionisValid",
                                  index
                                );
                                handleItemInputChanges(
                                  val,
                                  "itemDescription",
                                  index
                                );
                              }}
                              containerClass={classes.itemDescription}
                              customClassName={classes.itemDescriptionTextArea}
                              inputWrapperClass={classes.textAreaWrapper}
                              error={el.itemDescriptionisValid}
                              errorMsg={
                                el?.itemDescription &&
                                el?.itemDescription.length > 2550
                                  ? "Item Description should be less than 2550 characters"
                                  : el.itemDescriptionisValid
                                  ? "Item Description is required"
                                  : ""
                              }
                              size={"95.5%"}
                            />

                            {index !== 0 && (
                              <button
                                onClick={() => {
                                  // let dlt= deletedItemsToken.push(el.itemToken);
                                  if (
                                    el.itemToken !== null &&
                                    el.itemToken !== "" &&
                                    el.itemToken &&
                                    el.itemMappedToken !== null &&
                                    el.itemMappedToken !== "" &&
                                    el.itemMappedToken
                                  ) {
                                    setDeletedItemsToken([
                                      ...deletedItemsToken,
                                      el.itemMappedToken,
                                    ]);
                                  }
                                  removeItemsInput(index);
                                }}
                                className={`${classes.removeFormButton}`}
                              >
                                Remove
                              </button>
                            )}
                          </div>
                        );
                      })}

                    <div className={`${classes.addNewForm}`}>
                      <button
                        onClick={addItemsInputs}
                        className={`${classes.addNewFormButton}`}
                      >
                        Add Item
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={classes.docField}>
              <div className={classes.input_container}>
                <DefaultLabel label={"Other documents related to RFQ"} />
                {/* {uploadDocument.uploadFile && ( */}
                <DFileUpload
                  fileTypes={".pdf,.docx,.xlsx,.xls,.ppt,.pptx"}
                  fileName={editRFQForm && fileName}
                  customFileTypeText={
                    "(Supported Format docx, ppt, pdf, xls, xlsx, pptx)"
                  }
                  onFileUpload={(file) => {
                    let isValidFile = validateFile(
                      file,
                      true,
                      ["pdf", "docx", "xlsx", "xls", "ppt", "pptx"],
                      5
                    ).isValid;
                    setProposalDocument({
                      value: file,
                      hasFile: true,
                      isValid: isValidFile,
                    });
                  }}
                  error={!proposalDocument.isValid}
                  errorMsg={
                    validateFile(
                      proposalDocument.value,
                      true,
                      ["pdf", "docx", "xlsx", "xls", "ppt", "pptx"],
                      5,
                      "Proposal document"
                    ).errorMessage
                  }
                  size={"100%"}
                />
                {/* )} */}
              </div>
              <div className={classes.unitsInputs}>
                <DTextInput
                  customClass={classes.uniqueSelect}
                  label="Request Number"
                  value={requestNumber.value}
                  onChange={(e) => {
                    let val = e?.target?.value;
                    setRequestNumber({
                      value: val,
                      isChanged: editRFQForm,
                      isValid: emptyCheck(e.target.value),
                    });
                  }}
                  error={!requestNumber.isValid}
                  errorMsg={"Request Number is required"}
                  size={"100%"}
                />
                {!editRFQForm && (
                  <ThemeProvider theme={theme}>
                    <div
                      className={`${styles.select_container}`}
                      style={{ width: "400px" }}
                    >
                      <label>Select Quotation Category</label>
                      <Autocomplete
                        value={RFQCategory.value}
                        onChange={(event, newValue) => {
                          setRFQCategory((state) => ({
                            ...state,
                            value: newValue?.category ? newValue?.category : "",
                            id: newValue?.id ? newValue?.id : "",
                            isValid: newValue?.category ? true : false,
                          }));
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some(
                            (option) => inputValue === option.category
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              title: `Add "${inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        options={RFQCategory.categoryList}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.category) {
                            return option.category;
                          }
                          // Regular option
                          return option.category;
                        }}
                        renderOption={(props, option) => (
                          <li {...props}>{option.category}</li>
                        )}
                        freeSolo
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            placeholder="Select quotation category"
                          />
                        )}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="quotation_category"
                        size="small"
                        className={`${styles.select_wrapper_multiSelect} ${
                          !RFQCategory.isValid ? styles.error : ""
                        }`}
                        sx={{
                          minHeight: "4.2rem",
                          height: "auto",
                          fontSize: "1.4rem",
                          transition: "0",
                          ".Mui-expanded": {
                            transition: "none",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "0",
                          },
                          ".MuiAutocomplete-input": {
                            fontSize: "1.4rem",
                            fontFamily: "GilroyMedium",
                            height: "2.6rem",
                          },
                          ".MuiFormLabel-root": {
                            display: "none",
                          },
                          ".MuiChip-root": {
                            height: "30px",
                            padding: "5px",
                          },
                        }}
                        componentsProps={{
                          popper: {
                            sx: {
                              fontFamily: "gilroyMedium",
                            },
                            modifiers: [
                              {
                                name: "flip",
                                enabled: false,
                              },
                              {
                                name: "preventOverflow",
                                enabled: true,
                              },
                            ],
                          },
                        }}
                      />
                      {!RFQCategory.isValid && (
                        <p className={styles.error_message}>
                          Quotation Category is required
                        </p>
                      )}
                    </div>
                  </ThemeProvider>
                )}
              </div>
            </div>

            <div className={classes.ViewVendorHeaderSM}>
              <DetailsTab
                small={true}
                activeColor={"#3B86C7"}
                tabName={"Bidding Details"}
                currentTab={tab1}
                clickFn={tab1Click}
              />
              <DetailsTab
                small={true}
                activeColor={"#3B86C7"}
                tabName={"Shipping Address"}
                currentTab={tab2}
                clickFn={tab2Click}
              />
            </div>

            {tab1 && (
              <>
                {!editRFQForm && (
                  <div className={classes.bidType}>
                    <div className={classes.input_container}>
                      <DefaultLabel label={"Bid Type"} />

                      <div className={classes.util}>
                        <DRadioInput
                          label={"Auction"}
                          id={"open"}
                          value={"open"}
                          checked={
                            bidType.value === true || bidType.value === "open"
                          }
                          customClass={classes.radioTag}
                          onChange={() => {
                            setBidType({
                              value: true,
                            });
                          }}
                          name={"bidType"}
                        />

                        <DRadioInput
                          label={"Closed"}
                          id={"close"}
                          checked={
                            bidType.value === false ||
                            bidType.value === "closed"
                          }
                          customClass={classes.radioTag}
                          value={"close"}
                          onChange={() => {
                            setBidType({
                              value: false,
                            });
                          }}
                          name={"bidType"}
                        />
                      </div>
                    </div>

                    <div className={classes.bidStatus}>
                      <DDateInput
                        customClassName={classes.bidInputs}
                        label={"From Date1"}
                        value={fromDate}
                        onChange={(e) => {
                          setFromDate({
                            value: e,
                            isValid:
                              e !== null ||
                              e !== null ||
                              e !== "" ||
                              e !== undefined ||
                              fromDate.value !== "",
                          });
                          setToDate({
                            value: "",
                            isValid: true,
                          });
                        }}
                        disablePast={true}
                        error={!fromDate.isValid}
                        errorMsg={"From Date is required"}
                        // disabled={!bidType.value || !bidType.value === ""}
                        size={"26rem"}
                      />

                      <DDateInput
                        customClassName={classes.bidInputs}
                        label={"To Date"}
                        value={toDate}
                        disabledInput={!fromDate.value}
                        fromDate={fromDate.value}
                        onChange={(e) => {
                          setToDate({
                            value: e,
                            isValid:
                              e !== null ||
                              e !== "" ||
                              e !== undefined ||
                              fromDate.value !== "",
                          });
                          setTime({
                            value: null,
                            isValid: true,
                          });
                        }}
                        error={!toDate.isValid}
                        disablePast={true}
                        errorMsg={"To Date is required"}
                        size={"26rem"}
                      />

                      <DTextInput
                        customClassName={classes.bidInputs}
                        label={"End Time"}
                        value={time.value}
                        disabled={!toDate.value}
                        endDate={toDate.value}
                        onChange={(e) => {
                          setTime({
                            value: e,
                            // isChanged: true,
                            isValid:
                              e !== null ||
                              e !== "" ||
                              e !== undefined ||
                              time.value !== "",
                          });
                        }}
                        error={!time.isValid}
                        errorMsg={"Time is required"}
                        type={"time"}
                        size={"26rem"}
                      />
                    </div>

                    {fromDate.value && toDate.value && time.value && (
                      <DaysReminder
                        startDate={fromDate.value}
                        endDate={toDate.value}
                        endTime={time.value}
                      />
                    )}

                    <DSelectInput
                      customClass={classes.fields}
                      multiselect={true}
                      label="Select Vendor"
                      value={vendor.value}
                      options={vendorsList.values}
                      setIDValue={setVendorID}
                      onChange={(value) => {
                        setVendor({
                          value: value,
                          isValid: value?.length !== 0,
                        });
                      }}
                      error={!vendor.isValid}
                      errorMsg={"Vendor is required"}
                      size={"100%"}
                    />
                  </div>
                )}

                {editRFQForm && (
                  <>
                    <div className={classes.bidType}>
                      <div className={classes.statusSelect}>
                        <div className={classes.input_container}>
                          <DefaultLabel label={"Bid Type"} />

                          <div className={classes.util}>
                            <DRadioInput
                              label={"Auction"}
                              id={"open"}
                              value={"open"}
                              checked={bidType.value === true}
                              customClass={classes.radioTag}
                              onChange={() => {
                                setBidType({
                                  value: true,
                                });
                              }}
                              name={"bidType"}
                            />

                            <DRadioInput
                              label={"Closed"}
                              id={"close"}
                              checked={bidType.value === false}
                              customClass={classes.radioTag}
                              value={"close"}
                              onChange={() => {
                                setBidType({
                                  value: false,
                                });
                              }}
                              name={"bidType"}
                            />
                          </div>
                        </div>
                        <div className={classes.input_container}>
                          <DefaultLabel label={"Bid Status"} />

                          <div className={classes.util}>
                            <DRadioInput
                              label={"Cancelled"}
                              id={"cancelled"}
                              value={"cancelled"}
                              checked={bidStatus.value === "cancelled"}
                              customClass={classes.radioTag}
                              onChange={() => {
                                setBidStatus({
                                  value: "cancelled",
                                  isChanged: true,
                                });
                              }}
                              name={"bidStatus"}
                            />

                            <DRadioInput
                              label={"Failed"}
                              id={"failed"}
                              checked={bidStatus.value === "failed"}
                              customClass={classes.radioTag}
                              value={"failed"}
                              onChange={() => {
                                setBidStatus({
                                  value: "failed",
                                  isChanged: true,
                                });
                              }}
                              name={"bidStatus"}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={classes.bidStatus}>
                        <DDateInput
                          customClassName={classes.bidInputs}
                          label={"From Date2"}
                          value={fromDate}
                          disabledInput={editRFQForm}
                          onChange={(e) => {
                            setFromDate({
                              value: e,
                              isValid:
                                e !== null ||
                                e !== null ||
                                e !== "" ||
                                e !== undefined ||
                                fromDate.value !== "",
                            });
                          }}
                          disablePast={true}
                          error={!fromDate.isValid}
                          errorMsg={"From Date is required"}
                          size={"26rem"}
                        />

                        <DDateInput
                          customClassName={classes.bidInputs}
                          label={"To Date"}
                          value={toDate}
                          fromDate={
                            fromDate.value !== ""
                              ? uniqueData?.bid_from_date
                              : fromDate.value
                          }
                          onChange={(e) => {
                            setToDate({
                              value: e,
                              isValid:
                                e !== null ||
                                e !== "" ||
                                e !== undefined ||
                                fromDate.value !== "",
                            });
                            setTime({
                              value: null,
                              isValid: true,
                            });
                          }}
                          disablePast={true}
                          error={!toDate.isValid}
                          errorMsg={"To Date is required"}
                          size={"26rem"}
                        />

                        <DTextInput
                          customClassName={classes.bidInputs}
                          label={"End Time"}
                          value={time.value}
                          endDate={toDate.value}
                          onChange={(e) => {
                            setTime({
                              value: e,
                              isChanged: true,
                              isValid:
                                e !== null ||
                                e !== "" ||
                                e !== undefined ||
                                time.value !== "",
                            });
                          }}
                          error={!time.isValid}
                          errorMsg={"Time is required"}
                          type={"time"}
                          size={"26rem"}
                        />
                      </div>

                      {fromDate.value && toDate.value && time.value && (
                        <DaysReminder
                          startDate={fromDate.value}
                          endDate={toDate.value}
                          endTime={time.value}
                        />
                      )}

                      <DSelectInput
                        isEdit={true}
                        customClass={classes.fields}
                        multiselect={true}
                        label="Select Vendor"
                        value={vendor.value}
                        existingVendorList1={existingVendorList}
                        options={vendorsList.values}
                        setIDValue={setVendorID}
                        onChange={(value) => {
                          setVendor({
                            value: value,
                            isValid: value?.length !== 0,
                          });
                        }}
                        error={!vendor.isValid}
                        errorMsg={"Vendor is required"}
                        size={"100%"}
                      />
                    </div>
                  </>
                )}
                {!editRFQForm && (
                  <DButton
                    labelImg={plusIcon}
                    iconHeight={"15px"}
                    iconwidth={"15px"}
                    label="ADD VENDOR"
                    btnHeight="4rem"
                    buyerBtn={true}
                    onClick={() => {
                      handleOpen1();
                    }}
                  />
                )}
              </>
            )}

            {tab2 && (
              <>
                <div className={classes.input_fields_wrapper}>
                  {!shippingAddress ? (
                    <DTextInput
                      customClassName={classes.fields}
                      label="Address Line 1"
                      type="text"
                      value={address1.value}
                      onChange={(e) => {
                        let val = e.target.value;
                        setAddress1({
                          value: val,
                          isValid: emptyCheck(val),
                        });
                      }}
                      error={!address1.isValid}
                      errorMsg={"Address Line 1 is required"}
                      size={"100%"}
                    />
                  ) : (
                    <DSearchInputDropdown
                      addresses={shippingAddress}
                      selectHandler={handleSelectedAddress}
                      hanlderSearch={(e) => {
                        let val = e;
                        setAddress1({
                          value: val,
                          isValid: emptyCheck(val),
                        });
                      }}
                      searchTerm={address1}
                    />
                  )}

                  <DTextInput
                    customClassName={classes.fields}
                    label="Address Line 2 (Optional)"
                    type="text"
                    value={address2.value}
                    onChange={(e) => {
                      let val = e.target.value;
                      setAddress2({
                        value: val,
                      });
                    }}
                    size={"100%"}
                  />

                  <DSelectInput
                    label="Country"
                    defaultDropdown={true}
                    value={country.value}
                    noInit={true}
                    onChange={(e) => {
                      setCountry((prev) => ({
                        ...prev,
                        value: e,
                        isValid: validateSelect("Select", e),
                      }));
                      setStateName({
                        value: "",
                        stateList: [],
                        isValid: true,
                      });
                      setCity({
                        value: "",
                        cityList: [],
                        isValid: true,
                      });
                      setstateToken();
                      let filterObj =
                        e && country.countryList?.filter((el) => el.name === e);
                      filterObj && setCountryToken(filterObj[0]?.token);
                      e && getStatesList(filterObj[0]?.token);
                    }}
                    options={
                      country.countryList?.map((el) => el.name) || ["Select"]
                    }
                    error={!country.isValid}
                    errorMsg={country.isValid ? "" : "Country is required"}
                    customClassName={classes.field}
                  />

                  <DSelectInput
                    label="State/Province/Region"
                    defaultDropdown={true}
                    value={stateName.value}
                    disabled={!countryToken && !editRFQForm}
                    noInit={true}
                    onChange={(e) => {
                      setStateName((prev) => ({
                        ...prev,
                        value: e,
                        isValid: validateSelect("Select", e),
                      }));
                      setCity({
                        value: "",
                        cityList: [],
                        isValid: true,
                      });
                      let filterObj =
                        e && stateName.stateList?.filter((el) => el.name === e);
                      filterObj && setstateToken(filterObj[0]?.token);
                      e && getCityList(filterObj[0]?.token);
                    }}
                    options={
                      stateName.stateList?.length >= 1
                        ? stateName.stateList?.map((el) => el.name)
                        : ["No states available"]
                    }
                    error={!stateName.isValid}
                    errorMsg={
                      stateName.isValid
                        ? ""
                        : "State/Province/Region is required"
                    }
                    customClassName={classes.field}
                  />

                  <DSelectInput
                    label="City"
                    noInit={true}
                    defaultDropdown={true}
                    value={city.value}
                    disabled={!stateToken && !editRFQForm}
                    onChange={(e) => {
                      setCity((prev) => ({
                        ...prev,
                        value: e,
                        isValid: validateSelect("Select", e),
                      }));
                    }}
                    options={
                      city.cityList?.length >= 1
                        ? city.cityList?.map((el) => el.name)
                        : ["No cities available"]
                    }
                    error={!city.isValid}
                    errorMsg={city.isValid ? "" : "City is required"}
                    customClassName={classes.field}
                  />

                  <DTextInput
                    customClassName={classes.fields}
                    label="Postal/Zipcode"
                    type="text"
                    maxLength={50}
                    value={pincode.value}
                    onChange={(e) => {
                      let val = e.target.value?.replace(regexForNumber, "");
                      setPincode({
                        value: val,
                        isValid: emptyCheck(e.target.value),
                      });
                    }}
                    error={!pincode.isValid}
                    errorMsg={"Postal/Zipcode is required"}
                    size={"100%"}
                  />

                  <DPhoneNumberInput
                    label="Mobile Number"
                    value={mobileNumber.value}
                    placeholder={mobileNumber.value}
                    onChange={(
                      value,
                      data,
                      isValidMobileNumber,
                      formattedValue
                    ) => {
                      // checking the value only for numbers
                      if (
                        (editRFQForm &&
                          (value === "" ||
                            value?.includes("+") ||
                            numberOnlyRegex.test(value))) ||
                        (newRFQForm &&
                          (value === "" || numberOnlyRegex.test(value)))
                      ) {
                        setMobileNumber({
                          value: formattedValue,
                          format: data?.dialCode,
                          isValid:
                            emptyCheck(formattedValue) && isValidMobileNumber,
                        });
                      }
                    }}
                    error={!mobileNumber.isValid}
                    errorMsg={
                      !emptyCheck(mobileNumber.value)
                        ? "Mobile Number is required"
                        : !mobileNumber.isValid
                        ? "Invalid phone number"
                        : ""
                    }
                    size={"100%"}
                  />
                </div>
              </>
            )}

            <div className={classes.actions}>
              <DButtonOutlined
                buyerBtn={true}
                label="Cancel"
                btnHeight="4rem"
                btnWidth={"14.4rem"}
                onClick={openRFQMainCancel}
              />
              <DButton
                buyerBtn={true}
                label={btnPrimaryLabel}
                loadingState={loadingState}
                error={btnErrorState.error}
                errorMsg={btnErrorState.errorMsg}
                btnHeight="4rem"
                btnWidth={"14.4rem"}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </>
      ) : (
        !openForm.open &&
        openForm.loading && (
          <div className={classes.tabsScreens}>
            <span className={classes.loader}>
              <CircularProgress
                thickness={6}
                variant="indeterminate"
                disableShrink
                sx={{
                  animationDuration: "350ms",
                  color: "#55555580",
                }}
                size={25}
              />
            </span>
          </div>
        )
      )}

      {viewTimeLine && (
        <div className={classes.tabsScreens}>
          <div className={classes.width__100}>
            <h1 className={classes.view__time_line_titel}>
              Organization Details
            </h1>
            <div className={classes.view__time_line}>
              {org_details.map((e, i) => (
                <InfoLabel
                  key={i}
                  isLoading={loadingState}
                  label={e.label}
                  value={e.value}
                  size={e.size}
                />
              ))}
              <div className={classes.chatActionBntWrapper}>
                <DropdownBtnOutlined
                  label="DOWNLOAD"
                  btnHeight="4rem"
                  btnWidth="17rem"
                  custom={classes.bgcolor}
                  loadingState={loadingStateDwnload}
                  disabled={RFQData?.length === 0}
                  onClick={(fileFormat) => {
                    downloadPdf();
                  }}
                  options={["PDF"]}
                />
                <DButton
                  label={
                    chatConfirmationDialog.isClosedChat
                      ? "Enable chat"
                      : "Disable Chat"
                  }
                  btnHeight="4rem"
                  btnWidth={"15rem"}
                  buyerBtn={true}
                  onClick={() => handleChatStatusConfirmation()}
                  customWrapperClass={classes.chatBtn}
                />
              </div>
            </div>
            <div className={classes.overflow_hadle}>
              {" "}
              {/* id="organization_details"*/}
              {viewTimeLineData.map((item, index) => (
                <div className={classes.rfq_time_line_contant} key={index}>
                  <div className={classes.time_line_box}>
                    <div className={classes.time_line_box_loyout}>
                      <div className={classes.box_loyout_left}>
                        <div className={classes.padding_box}>
                          <p className={classes.left_titel}>
                            {item.sender_role === "vendor admin" ||
                            item.sender_role === "vendor admin"
                              ? "Vendor"
                              : item.sender_role}
                          </p>
                        </div>
                      </div>
                      <div className={classes.box_loyout_right}>
                        <div className={classes.padding_box}>
                          <p className={classes.left_titel}>Message</p>
                          <p className={classes.right_progrop}>
                            {" "}
                            {item.message}
                          </p>
                          <p className={classes.right_progrop_date_time}>
                            {item.created_date}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {viewRFQComparedTable && (
        <>
          <ComparedTable
            data={compairedData}
            compareJsonFile={fileJsonHistory}
            compareTitle={comparedNameDate}
            backButtonStatus={true}
            showHeader={true}
            isRefetchData={true}
            handleRefetchData={callCompare}
            isLoading={loadingState}
            isAutoRefresh={true}
          />
        </>
      )}

      {/* success modal popup */}
      <Modal open={open}>
        <Fade in={open}>
          <Box>
            <SuccessPopupDashboard
              popupImage={successPopupImg}
              popupHeroText={popupHeroText}
              popupDescription={popupMessage}
              popBtn={true}
              vendorBtn={true}
              btnText={"Okay"}
              btnClick={openRFQMainCancel}
            />
          </Box>
        </Fade>
      </Modal>

      <Modal open={open2}>
        <Fade in={open2}>
          <Box>
            <SuccessPopupDashboard
              popupImage={successPopupImg}
              popupHeroText={popupHeroText}
              popupDescription={popupMessage}
              popBtn={true}
              vendorBtn={true}
              btnText={"Okay"}
              btnClick={sendMessageReset}
            />
          </Box>
        </Fade>
      </Modal>

      {/* add vendor modal popup */}
      <Modal
        open={open1}
        closeAfterTransition
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
        sx={{
          overflow: "auto",
        }}
      >
        <Fade in={open1}>
          <div
            className={classes.flex}
            style={{
              height: "100vh",
            }}
          >
            <PopupFrame
              customClass={vendorClasses.InviteVendorPopup}
              styleCenterDiv={classes.centerDiv}
              inputChildren={vendorsTabInputs}
              poupHeading={vendorsTabHeading}
              actionButtons={popupActions}
            />
          </div>
        </Fade>
      </Modal>

      {/* delete error modal two states - confirmation and deletion */}
      {/* confirmation */}
      <Modal open={openDltConfirmation}>
        <Fade in={openDltConfirmation}>
          <Box>
            <SuccessPopupDashboard
              popupImage={deletePopupImg}
              popupHeroText={popupHeroText}
              popupDescription={popupMessage}
              popBtn={true}
              btnText={"Delete"}
              loadingPrimaryBtn={loadingState}
              secondaryBtn={true}
              vendorBtn={true}
              vendorBtnOutlined={true}
              btnTextSecondary={"Cancel"}
              btnClick={handleDeleteRFQ}
              btnClickSecondary={handleCloseDltConfirmation}
            />
          </Box>
        </Fade>
      </Modal>

      {/* deletion */}
      <Modal open={openDlt}>
        <Fade in={openDlt}>
          <Box>
            <SuccessPopupDashboard
              popupImage={deletePopupImg}
              popupHeroText={popupHeroText}
              popupDescription={popupMessage}
              popBtn={true}
              vendorBtn={true}
              loadingPrimaryBtn={loadingState}
              vendorBtnOutlined={true}
              btnText={"Okay"}
              btnClick={handleCloseDlt}
            />
          </Box>
        </Fade>
      </Modal>

      {/* bid select or unselect confirmation popup */}
      <Modal
        open={openBidConfirmation.selectBid || openBidConfirmation.unSelectBid}
      >
        <Box>
          <SuccessPopupDashboard
            popupHeroText={
              openBidConfirmation.selectBid
                ? "Are you sure want to select this Bid ?"
                : "Are you sure want to unselect this Bid ?"
            }
            customClass={classes.confimationPopup}
            popBtn={true}
            buyerBtn={true}
            buyerBtnOutlined={true}
            btnText={"Yes"}
            loadingPrimaryBtn={loadingState}
            btnClick={() => {
              openBidConfirmation.selectBid
                ? selectBid(bidToken)
                : unSelectBid(bidToken);
            }}
            secondaryBtn={true}
            btnTextSecondary={"No"}
            btnClickSecondary={closeSelectBid}
          />
        </Box>
      </Modal>

      {/* bid Selection */}
      <Modal open={bidSelection.select || bidSelection.unselect}>
        <Box>
          <SuccessPopupDashboard
            popupImage={bidSelection.select ? bidSelectedImg : bidUnSelectedImg}
            popupHeroText={
              bidSelection.select ? "Bid Selected!" : "Bid Unselected!"
            }
            popupDescription={
              bidSelection.select
                ? "The vendor will be notified via email."
                : "The vendor will be notified this outcome via email."
            }
            popBtn={true}
            vendorBtn={true}
            vendorBtnOutlined={true}
            btnText={"Okay"}
            btnClick={closeSelectBid}
          />
        </Box>
      </Modal>

      {/* comparison */}
      <Modal open={openComparisonPopup}>
        <Fade in={openComparisonPopup}>
          <Box>
            <SuccessPopupDashboard
              popupImage={somthingWrongImg}
              popupHeroText={popupText.popupHeroText}
              popupDescription={popupText.popupDescription}
              loadingPrimaryBtn={loadingState}
              popBtn={true}
              vendorBtn={true}
              vendorBtnOutlined={true}
              btnText={"Okay"}
              customClass={classes.compareModal}
              btnClick={handleCloseComparePopup}
            />
          </Box>
        </Fade>
      </Modal>

      {/* selected quatation */}
      <Modal open={openViewQuotesPopup}>
        <Fade in={openViewQuotesPopup}>
          <Box>
            <div className={classes.quotationWrapper}>
              <Close
                onClick={() => {
                  setSelectedRowForComparison([]);
                  setSelectedRows([]);
                  setOpenViewQuotesPopup(false);
                }}
                sx={{
                  position: "absolute",
                  right: "30px",
                  top: "20px",
                  fontSize: "35px",
                  cursor: "pointer",
                  borderRadius: "50%",
                  padding: "5px",
                  border: "1px solid #eee",
                }}
              />
              <span>View Quotes</span>
              <div className={`${classes.pdfWrapper}`}>
                {uniqueData?.item_description_doc && (
                  <div className={`${classes.buyerPDFView}`}>
                    <span className={`${classes.buyerPDFHeading}`}>
                      Buyer requirement document
                    </span>
                    <PDFView
                      fileSrc={uniqueData?.item_description_doc}
                      customWrapperClass={classes.buyerReqPDF}
                    />
                  </div>
                )}
                <div className={`${classes.vendorPDFView}`}>
                  <div className={`${classes.vendorDocumentsWrapper}`}>
                    {selectedRowForComparison
                      ?.map((item) => [
                        {
                          uri: item.document?.replace(
                            "https://teckost.s3.amazonaws.com",
                            process.env.REACT_APP_CLOUDFRONT_URL
                          ),
                          fileName: item?.vendor,
                        },
                      ])
                      ?.map((item) => (
                        <div className={`${classes.pdfcodeviewr}`}>
                          <DocViewer documents={item} />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={sendMessage}
        closeAfterTransition
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={sendMessage}>
          <div
            className={classes.flex}
            style={{
              height: "130vh",
            }}
          >
            {textAreaSendMessage ? (
              <PopupFrame
                poupHeading={viewUserHeading}
                inputChildren={viewTextChildren}
                actionButtons={viewTextActionButtons}
                closePopup={handleCloseViewItemPopup}
                width={"auto"}
                customClass={classes.addPopup}
                styleCenterDiv={classes.centerDiv}
              />
            ) : (
              <PopupFrame
                poupHeading={viewUserHeading}
                inputChildren={viewUserChildren}
                actionButtons={viewItemsActionButtons}
                closePopup={handleCloseViewItemPopup}
                width={"auto"}
                customClass={classes.addPopup}
                styleCenterDiv={classes.centerDiv}
              />
            )}
          </div>
        </Fade>
      </Modal>

      {/* Chat state change confirmation popup */}
      <Modal open={chatConfirmationDialog.open}>
        <Box>
          <SuccessPopupDashboard
            popupHeroText={
              chatConfirmationDialog.isClosedChat
                ? "Are you sure want to enable the chat ?"
                : "Are you sure want to disable the chat ?"
            }
            customClass={classes.confimationPopup}
            popBtn={true}
            buyerBtn={true}
            buyerBtnOutlined={true}
            btnText={"Yes"}
            loadingPrimaryBtn={loadingState}
            btnClick={() =>
              chatStateChage(chatConfirmationDialog.isClosedChat, rfqToken)
            }
            secondaryBtn={true}
            btnTextSecondary={"No"}
            btnClickSecondary={closeChatModule}
          />
        </Box>
      </Modal>

      {/* Download PDF UI for donload HTML to PDF */}
      <div className={organizationDetailspdfUI ? classes.mp100vh : ""}>
        <div
          className={`${classes.downloadreportViewMain} ${classes.width__100} ${
            organizationDetailspdfUI ? classes.show : ""
          }`}
          id="organization_details"
        >
          <BrandLogo
            imgSrc={brLogo}
            imgAlt={"BrandLogo"}
            customClass={"brLogo"}
            imgSize={"14rem"}
          />
          <h1 className={classes.view__time_line_titel}>
            Organization Details
          </h1>
          <div className={`${classes.view__time_line} ${classes.mb20}`}>
            {org_details.map((e, i) => (
              <InfoLabel
                key={i}
                isLoading={loadingState}
                label={e.label}
                value={e.value}
                size={e.size}
              />
            ))}
          </div>
          {viewTimeLineData.map((item, index) => (
            <div className={classes.rfq_time_line_contant} key={index}>
              <div className={classes.time_line_box}>
                <div className={classes.time_line_box_loyout}>
                  <div className={classes.box_loyout_left}>
                    <div className={classes.padding_box}>
                      <p className={classes.left_titel}>
                        {item.sender_role === "vendor admin" ||
                        item.sender_role === "vendor admin"
                          ? "Vendor"
                          : item.sender_role}
                      </p>
                    </div>
                  </div>
                  <div className={classes.box_loyout_right}>
                    <div className={classes.padding_box}>
                      <p className={classes.left_titel}>Message</p>
                      <p className={classes.right_progrop}> {item.message}</p>
                      <p className={classes.right_progrop_date_time}>
                        {item.created_date}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BuyerRFQTab;
