import axios from "axios";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import imgData from "../assets/images/png/Logo@2x.png";

const downloadFile = async ({
  key,
  value,
  outputFormat,
  API_ENDPOINT,
  authToken,
  fileNameProp,
  setLoadingState,
}) => {
  try {
    setLoadingState(true);

    const formData = new FormData();

    formData.append(`${key}`, value);
    formData.append("output_format", outputFormat);

    const response = await axios.post(API_ENDPOINT, formData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    setLoadingState(false);

    const fileExtension = outputFormat === "pdf" ? "pdf" : "csv";
    const timestamp = dayjs().format("DD-MM-YYYY");
    const filename = `${fileNameProp} - ${timestamp}.${fileExtension}`;

    if (outputFormat === "pdf") {
      const pdf = createPdf(response.data.response, fileNameProp);
      pdf.save(`${fileNameProp} - ${timestamp}.pdf`);
    } else {
      const blob = new Blob([response.data], {
        type: `application/${fileExtension}`,
      });
      saveAs(blob, filename);
    }
  } catch (error) {
    console.error(error);
    setLoadingState(false);
  }
};

const createPdf = (data, fileNameProp) => {
  const doc = new jsPDF();

  doc.addImage(imgData, "png", 12, 3, 22, 8);
  doc.setFontSize(12);
  doc.text(`${fileNameProp}`, 14, 16); //Bidz.ai -

  const headerStyles = {
    fillColor: "#3B86C7",
    textColor: [255, 255, 255],
    overflow: "linebreak",
    cellWidth: "wrap",
  };

  const fieldNames = Object.keys(data[0]);
  const columns = fieldNames.map((fieldName) => ({
    header: fieldName,
    dataKey: fieldName,
  }));

  doc.autoTable({
    theme: "grid",
    headStyles: headerStyles,
    columns: columns,
    body: data,
    // columnWidth: columnWidth,
    margin: { top: 20 },
    tableWidth: 200,
    // showHead: 'firstPage',
    horizontalPageBreak: true,
  });

  return doc;
};

export { downloadFile };
