import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import IntlTelInput from "react-intl-tel-input";
import { useForm, Controller } from "react-hook-form";
import { PDFDocument } from "pdf-lib";
import {
  Box,
  CircularProgress,
  Fade,
  Modal,
  MenuItem,
  Select,
  Button,
  Backdrop,
} from "@mui/material";
import ReactQuill from "react-quill";
import axios from "axios";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

import "react-intl-tel-input/dist/main.css";
import DateBar from "../../../components/common/DateBar/DateBar";
import HeaderTitle from "../../../components/common/HeaderTitle/HeaderTitle";
import NotificationBell from "../../../components/common/NotificationBell/NotificationBell";
import ProfileCard from "../../../components/common/ProfileCard/ProfileCard";
import SearchBar from "../../../components/common/SearchBar/SearchBar";
import PaginantionComponent from "../../../components/common/Pagination/PaginationComponent";
import { nextPageClick, previouPageClick } from "../../../utils/pagination";
import DataTable from "../../../components/common/DataTable/DataTableSorting";
import useFetchData from "../../../hooks/useFetchData";
import { API_ENDPOINT } from "../../../services/api/resources";
import SuccessPopupDashboard from "../../../components/Popups/PopupFrame/SuccessPopupDashboard";
//ui
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DButtonOutlined from "../../../components/Dashboard/DashboardButtons/dButtonOutlined";
import { validateSelect } from "../../../utils/validations";
//css
import "react-quill/dist/quill.snow.css";
import classes from "./PoTab.module.css";
import styles from "../../../components/Signup/SignupInputs/SInputs.module.css";
import "./quilEditor.css";
//image
import successPopupImg from "../../../assets/images/png/Group 2 2@2x.png";
import deletePopupImg from "../../../assets/images/png/Group 2@2x.png";
import PoPdfView from "../../../components/common/HtmlToPdf/PoPdfView";

function PoTab() {
  const pdfRef = useRef();
  //get data from use selector
  const data = useSelector((state) => state.loginData.value);

  //Component state
  const auth_token = data?.auth_token;
  const buyer_token = data?.user?.token;

  //api param
  let idParams = [
    {
      key: "buyer_token",
      value: buyer_token ? buyer_token : "",
    },
  ];
  const currentPage = 1;
  const sortingColumnName = null;
  const sortingOrder = null;

  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationData, setPaginationData] = useState();

  const [poData, setPoData] = useState([]);
  const [tableProps, setTableProps] = useState({
    isLoading: false,
    errorMessage: "",
  });
  const [openForm, setOpenForm] = useState({
    open: false,
    loading: false,
    errorMessage: "",
  });
  const [poEdit, setPoEdit] = useState(false);
  // selected PO item
  const [selectedPo, setSelectedPo] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [open, setOpen] = useState(false);
  const [successModalContent, setSuccessModalContent] = useState({
    title: "",
    description: "",
  });
  // deletion PO item
  const [deletionPo, setDeletionPo] = useState({});
  const [openDltConfirmation, setOpenDltConfirmation] = useState(false);
  const [isPoDeleting, setIsPoDeleting] = useState(false);
  // view PO item
  const [viewPO, setViewPo] = useState({});
  const [pdfData, setPdfData] = useState(null);
  const [openPoPdfModal, setOpenPoPdfModal] = useState(false);
  const [generateViewPdfFlag, setGenerateViewPdfFlag] = useState(false);
  // download PO item
  const [generateDownloadPdfFlag, setGenerateDownloadPdfFlag] = useState(false);
  // send PO item
  const [generateSendPdfFlag, setGenerateSendPdfFlag] = useState(false);

  // shipping address state
  const [countryToken, setCountryToken] = useState(0);
  const [stateToken, setstateToken] = useState(0);
  const [city, setCity] = useState({
    value: "",
    label: "",
    cityList: [],
    isValid: true,
  });
  const [stateName, setStateName] = useState({
    value: "",
    label: "",
    stateList: [],
    isValid: true,
  });
  const [country, setCountry] = useState({
    value: "",
    label: "",
    countryList: [],
    isValid: true,
  });
  const {
    register,
    handleSubmit,
    formState,
    getValues,
    reset,
    setValue,
    watch,
    control,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: selectedPo,
  });

  const { errors } = formState;
  const issuerMobileNumber = watch("issuerMobileNumber");
  const vendorMobileNumber = watch("vendorMobileNumber");
  const billingMobileNumber = watch("billingMobileNumber");
  const shippingMobileNumber = watch("shippingMobileNumber");
  const shippingCountry = watch("shippingCountry");
  const shippingState = watch("shippingState");
  const shippingCity = watch("shippingCity");

  // effect hook
  useEffect(() => {
    GetAllPoData(1);
  }, [pageNumber, searchQuery, open, openDltConfirmation]);
  //Item list default value assigning
  useEffect(() => {
    if (itemList.length > 0) {
      itemList.map((item, index) => {
        setValue(
          `item_list[${index}].itemMappedToken`,
          item.itemMappedToken || ""
        );
        setValue(`item_list[${index}].itemCode`, item.itemCode || "");
        setValue(`item_list[${index}].itemQuantity`, item.itemQuantity || "");
        setValue(`item_list[${index}].itemPrice`, item.itemPrice || "");
        setValue(
          `item_list[${index}].itemDescription`,
          item.itemDescription || ""
        );
      });
    }
  }, [itemList, setValue]);
  //country select side effect
  useEffect(() => {
    const selectedCountry = shippingCountry;
    if (!selectedCountry) return;
    setCountry((prev) => ({
      ...prev,
      value: selectedCountry,
      isValid: validateSelect("Select", selectedCountry),
    }));
    setStateName({
      value: "",
      label: "",
      stateList: [],
      isValid: true,
    });
    setCity({
      value: "",
      label: "",
      cityList: [],
      isValid: true,
    });
    setstateToken();

    let filterObj =
      selectedCountry &&
      country.countryList?.filter((el) => el.name === selectedCountry);
    if (filterObj.length === 0) return;
    setCountryToken(filterObj[0]?.token);
    selectedCountry && getStatesList(filterObj[0]?.token);
  }, [shippingCountry, country.countryList]);
  //state select side effect
  useEffect(() => {
    const selectState = shippingState;
    if (!selectState) return;
    setStateName((prev) => ({
      ...prev,
      value: selectState,
      isValid: validateSelect("Select", selectState),
    }));
    setCity({
      value: "",
      label: "",
      cityList: [],
      isValid: true,
    });
    let filterObj =
      selectState &&
      stateName.stateList?.filter((el) => el.name === selectState);
    setstateToken(filterObj[0]?.token);
    selectState && getCityList(filterObj[0]?.token);
  }, [shippingState, stateName.stateList]);
  //city select side effect
  useEffect(() => {
    let selectedCity = shippingCity;
    setCity((prev) => ({
      ...prev,
      value: selectedCity,
      isValid: validateSelect("Select", selectedCity),
    }));
  }, [shippingCity]);
  //generate PDF
  useEffect(() => {
    if (generateViewPdfFlag) {
      generateViewPdf();
    }
  }, [generateViewPdfFlag, viewPO]);
  useEffect(() => {
    if (generateDownloadPdfFlag) {
      generateDownloadPOPdf();
    }
  }, [generateDownloadPdfFlag]);
  // send PO with PDF
  useEffect(() => {
    if (generateSendPdfFlag) {
      SendPO();
    }
  }, [generateSendPdfFlag]);
  useEffect(
    (_) => {
      if (poData && poData.length === 0) {
        setTableProps((pre) => ({
          ...pre,
          errorMessage: "No data found.",
        }));
      }
    },
    [poData]
  );
  //Global variables
  //table header
  const headings = [
    { label: "PO Number", field: "PO_number" },
    { label: "Item Name", field: "out_side_item_names" },
    { label: "Item Price", field: "item_price" },
    { label: "Vendor Name", field: "vendor_name" },
    { label: "Source", field: "source" },
  ];
  // helper variables
  const itemsPerPage = paginationData?.current_page_count;
  const totalItems = paginationData?.page_count;
  const { PO_number, PO_token } = viewPO;
  // functions
  function openPOCancel() {
    reset();
    setPoEdit(false);
    setOpen(false);
  }
  async function openViewPo(token, row) {
    if (!row) return;
    setViewPo(row);
    setGenerateViewPdfFlag(true);
    setOpenPoPdfModal(true);
  }
  const generateViewPdf = async () => {
    const input = pdfRef.current;

    // A4 size dimensions in points (assuming 96 DPI)
    const a4Width = 595.28;
    const a4Height = 841.89;
    const margin = 10;

    // First render the full content to a single canvas
    const fullCanvas = await html2canvas(input, {
      scale: 1,
    });

    const contentHeight = fullCanvas.height;
    const pageHeight = a4Height - margin * 2;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });

    let remainingHeight = contentHeight;
    let position = 0;

    while (remainingHeight > 0) {
      const canvasHeight = Math.min(remainingHeight, pageHeight);
      const canvas = await html2canvas(input, {
        scale: 2,
        y: position,
        height: canvasHeight,
      });

      const pageImgData = canvas.toDataURL("image/JPEG");
      pdf.addImage(
        pageImgData,
        "JPEG",
        margin,
        margin,
        a4Width - margin * 2,
        canvasHeight
      );

      remainingHeight -= canvasHeight;
      position += canvasHeight;

      if (remainingHeight > 0) {
        pdf.addPage();
      }
    }

    // Output the PDF as a blob
    const pdfBlob = pdf.output("blob");

    // Set PDF data for preview
    setPdfData(URL.createObjectURL(pdfBlob));
    setGenerateViewPdfFlag(false); // Reset the flag
  };
  const downloadPOPdf = async (token, row) => {
    setViewPo(row);
    setGenerateDownloadPdfFlag(true);
  };
  const generateDownloadPOPdf = async () => {
    const input = pdfRef.current;

    // A4 size dimensions in points (assuming 96 DPI)
    const a4Width = 595.28;
    const a4Height = 841.89;
    const margin = 10;

    // First render the full content to a single canvas
    const fullCanvas = await html2canvas(input, { scale: 1 });

    const contentHeight = fullCanvas.height;
    const pageHeight = a4Height - margin * 2;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });

    let remainingHeight = contentHeight;
    let position = 0;

    while (remainingHeight > 0) {
      const canvasHeight = Math.min(remainingHeight, pageHeight);
      const canvas = await html2canvas(input, {
        scale: 2,
        y: position,
        height: canvasHeight,
      });

      const pageImgData = canvas.toDataURL("image/JPEG"); // Use JPEG format with 0.5 compression factor
      pdf.addImage(
        pageImgData,
        "JPEG",
        margin,
        margin,
        a4Width - margin * 2,
        canvasHeight
      );

      remainingHeight -= canvasHeight;
      position += canvasHeight;

      if (remainingHeight > 0) {
        pdf.addPage();
      }
    }

    // Convert to Blob
    const pdfBlob = pdf.output("blob");

    // Load the existing PDF into pdf-lib
    const existingPdfBytes = await pdfBlob.arrayBuffer();
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Optionally, you can remove metadata, compress images, etc.
    pdfDoc.setTitle("Compressed PDF");
    pdfDoc.setAuthor("Your Name");

    // Save the compressed PDF
    const compressedPdfBytes = await pdfDoc.save({
      useObjectStreams: false, // Disable object streams for better compression
      updateFieldAppearances: false, // Reduce the file size by not updating appearances
    });

    // Convert the compressed PDF bytes to a Blob
    const compressedPdfBlob = new Blob([compressedPdfBytes], {
      type: "application/pdf",
    });

    // Trigger the download
    saveAs(compressedPdfBlob, `PO_#${PO_number}.pdf`);
    setGenerateDownloadPdfFlag(false);
  };
  function openUpdatePo(token, row) {
    let {
      issuer_details,
      vendor_details,
      billing_details,
      shipping_details,
      terms,
      item_list,
    } = row;
    const issuer = {
      issuerName: issuer_details.issuer_details_name,
      issuerMobileNumber: issuer_details.issuer_details_phone_number,
      issuerAddressLine1:
        issuer_details.issuer_details_address.buyer_address_line,
      // issuerAddressLine2: "",
      issuerCountry: issuer_details.issuer_details_address.buyer_country,
      issuerState: issuer_details.issuer_details_address.buyer_state,
      issuerCity: issuer_details.issuer_details_address.buyer_city,
      issuerPostalCode: issuer_details.issuer_details_address.buyer_pincode,
      issuerEmail: issuer_details.issuer_details_email,
      issuerPan: issuer_details.issuer_details_PAN,
      issuerGSTIn: issuer_details.issuer_details_GSTIN,
      issuerCINNo: issuer_details.issuer_details_CIN,
      issuerPurchaseOrderNumber:
        issuer_details.issuer_details_purchase_order_no,
      issuerPurchaseOrderDate:
        issuer_details.issuer_details_purchase_order_date,
    };
    const vendor = {
      vendorName: vendor_details.vendor_details_name,
      vendorMobileNumber: vendor_details.vendor_details_phone_number,
      vendorAddressLine1:
        vendor_details.vendor_details_address.vendor_company_address,
      vendorAddressLine2: "",
      vendorCountry: vendor_details.vendor_details_address.vendor_country,
      vendorState: vendor_details.vendor_details_address.vendor_state,
      vendorCity: vendor_details.vendor_details_address.vendor_city,
      vendorPostalCode:
        vendor_details.vendor_details_address.vendor_postal_code,
    };
    const billing = {
      billingName: billing_details.billing_details_name,
      billingMobileNumber: billing_details.billing_details_phone_number,
      billingAddressLine1:
        billing_details.billing_details_address.buyer_address_line,
      // billingAddressLine2: "",
      billingCountry: billing_details.billing_details_address.buyer_country,
      billingState: billing_details.billing_details_address.buyer_state,
      billingCity: billing_details.billing_details_address.buyer_city,
      billingPostalCode: billing_details.billing_details_address.buyer_pincode,
    };
    const shipping = {
      shippingName: shipping_details.shipping_details_name,
      shippingMobileNumber: shipping_details.shipping_details_phone_number,
      shippingAddressLine1:
        shipping_details.shipping_details_address.shipping_address1,
      shippingAddressLine2:
        shipping_details.shipping_details_address.shipping_address2,
      shippingCountry:
        shipping_details.shipping_details_address.shipping_country,
      shippingState: shipping_details.shipping_details_address.shipping_state,
      shippingCity: shipping_details.shipping_details_address.shipping_city,
      shippingPostalCode:
        shipping_details.shipping_details_address.shipping_pincode,
    };
    const items = item_list?.map((item) => {
      return {
        itemMappedToken: item.item_mapped_token,
        itemCode: item.item_code,
        itemQuantity: item.quantity,
        itemPrice: row.item_price,
        itemDescription: item.description,
      };
    });
    items.length > 0 && setItemList(items);
    const others = {
      paymentTerms: terms.payment_terms,
      validity: terms.validity,
      deliveryTerms: terms.delivery_terms,
      modeOfTransportation: terms.mode_of_transportation,
      terms_and_condition: terms.terms_and_conditions_of_purchase,
    };

    const finalFormData = {
      ...issuer,
      ...vendor,
      ...billing,
      ...shipping,
      ...others,
      poToken: row.PO_token,
    };
    Object.keys(finalFormData).forEach((key) => {
      setValue(key, finalFormData[key]);
    });
    setSelectedPo({ ...finalFormData });
    setPoEdit(true);
    getCountriesList();
  }
  function handleOpenDltConfirmation(deletionId, row) {
    setOpenDltConfirmation(true);
    setDeletionPo(row);
  }
  function handleCloseDltConfirmation() {
    setOpenDltConfirmation(false);
  }
  function handleDeletePO() {
    let poToken = deletionPo.PO_token;
    setIsPoDeleting(true);
    axios({
      method: "POST",
      url: API_ENDPOINT.detele_purchase_order,
      data: { PO_token: poToken },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        if (res.data.response === "Purchase_order is deleted successfully") {
          setOpenDltConfirmation(false);
        }
        setIsPoDeleting(false);
      })
      .catch((error) => {
        setIsPoDeleting(false);
      });
  }
  function sendPoEmail(token, row) {
    if (!row) return;
    setViewPo(row);
    setGenerateSendPdfFlag(true);
  }
  const SendPO = async () => {
    const input = pdfRef.current;

    // A4 size dimensions in points (assuming 96 DPI)
    const a4Width = 595.28;
    const a4Height = 841.89;
    const margin = 10;

    // First render the full content to a single canvas
    const fullCanvas = await html2canvas(input, {
      scale: 1,
    });

    const contentHeight = fullCanvas.height;
    const pageHeight = a4Height - margin * 2;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });

    let remainingHeight = contentHeight;
    let position = 0;

    while (remainingHeight > 0) {
      const canvasHeight = Math.min(remainingHeight, pageHeight);
      const canvas = await html2canvas(input, {
        scale: 2,
        y: position,
        height: canvasHeight,
      });

      const pageImgData = canvas.toDataURL("image/JPEG");
      pdf.addImage(
        pageImgData,
        "JPEG",
        margin,
        margin,
        a4Width - margin * 2,
        canvasHeight
      );

      remainingHeight -= canvasHeight;
      position += canvasHeight;

      if (remainingHeight > 0) {
        pdf.addPage();
      }
    }

    // Convert to Blob
    const pdfBlob = pdf.output("blob");
    const file = new File([pdfBlob], "purchase_order.pdf", {
      type: "application/pdf",
      lastModified: new Date().getTime(),
    });
    const formData = new FormData();
    formData.append("PO_token", PO_token);
    formData.append("PDF_file", file);

    axios({
      method: "POST",
      url: API_ENDPOINT.send_email_purchase_order,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        if (res.data.response === "mail send successfully") {
          setOpen(true);
          setSuccessModalContent({
            title: "Mail Send",
            description:
              "The Purchase Order has been successfully sent to the vendor.",
          });
        }
      })
      .catch((error) => {
        setGenerateSendPdfFlag(false);
      });
    setGenerateSendPdfFlag(false);
  };
  const GetAllPoData = (pageNumber, rfq_code) => {
    useFetchData({
      pageNumber: pageNumber,
      endpoint: `${API_ENDPOINT.get_purchase_order_list}`,
      idParams: idParams,
      searchQuery: searchQuery,
      auth_token: auth_token,
      ...(sortingColumnName && { sortingColumnName: sortingColumnName }),
      ...(sortingOrder && { sortingOrder: sortingOrder }),
      setData: setPoData,
      setPaginationData: setPaginationData,
      tableProps,
      setTableProps,
    });
  };
  const onSubmit = async (data) => {
    setIsUpdating(true);
    let itemList = [];
    if (data.item_list.length > 0) {
      itemList = data.item_list?.map((item) => {
        return {
          itemMappedToken: item.itemMappedToken,
          itemQuantity: item.itemQuantity,
        };
      });
    }
    let formData = {
      PO_token: data.poToken,
      payment_terms: data.paymentTerms,
      validity: data.validity,
      mode_of_transportation: data.modeOfTransportation,
      delivery_terms: data.deliveryTerms,
      terms_and_conditions_of_purchase: data.terms_and_condition,
      shipping_address1: data.shippingAddressLine1,
      shipping_address2: data.shippingAddressLine2,
      shipping_country: data.shippingCountry,
      shipping_state: data.shippingState,
      shipping_city: data.shippingCity,
      shipping_pincode: data.shippingPostalCode,
      shipping_mobile: data.shippingMobileNumber,
      // item_token_list: itemList,
    };

    axios({
      method: "POST",
      url: API_ENDPOINT.update_purchase_order,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        if (res.data.response === "purchase_order updated") {
          setOpen(true);
          setSuccessModalContent({
            title: "Purchase Order Updated",
            description: "Purchase order updated successfully.",
          });
        }
        setIsUpdating(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsUpdating(false);
      });
    setIsUpdating(true);
  };
  const onError = (error) => {
    console.log(error);
  };
  // coutry || state || city API call
  const getCountriesList = async () => {
    axios({
      method: "POST",
      url: API_ENDPOINT.view_countries,
    })
      .then((res) => {
        setCountry((prev) => ({
          ...prev,
          countryList: res?.data?.response,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getStatesList = async (country_token) => {
    if (!country_token) return;
    axios({
      method: "POST",
      url: API_ENDPOINT.view_state,
      data: {
        country_token: country_token,
      },
    })
      .then((res) => {
        setStateName((prev) => ({
          ...prev,
          stateList: res?.data?.response,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCityList = async (state_token) => {
    if (!state_token) return;
    axios({
      method: "POST",
      url: API_ENDPOINT.view_cities,
      data: {
        state_token: state_token,
      },
    })
      .then((res) => {
        setCity((prev) => ({
          ...prev,
          cityList: res?.data?.response,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className={classes.users_tab_wrapper}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={generateDownloadPdfFlag || generateSendPdfFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.users_topHeader}>
        <div className={`${classes.lhs} flex`}>
          <HeaderTitle titleText="PO" customclass={classes.dashHead} />
        </div>

        <div className={`${classes.rhs} flex`}>
          <DateBar />
          <NotificationBell />
          <ProfileCard
            buyerBtn={true}
            buyerBtnOutline={true}
            profilePath={"/Buyer_dashboard/My_profile"}
          />
        </div>
      </div>
      <div className={classes.poSection}>
        {!poEdit ? (
          <div className={classes.poListRow}>
            <div className={classes.search_add_wrapper}>
              <div
                className={`${classes.flex} ${classes.flex_align_end}  ${classes.search_add_wrapper_content_left}`}
              >
                <SearchBar
                  value={searchQuery}
                  onChange={(query) => {
                    setSearchQuery(query);
                    setPageNumber(1);
                  }}
                />
              </div>
            </div>
            <div className={`${classes.d_dataTable} `}>
              <DataTable
                headings={headings}
                tableData={poData}
                tableSize={poData?.length === 0 ? "100%" : "100vw"}
                actions={true}
                errorMessage={tableProps.errorMessage}
                isLoading={tableProps.isLoading}
                viewBtn={true}
                editBtn={true}
                deleteBtn={true}
                sorting={false}
                downloadSendmailForPo={true}
                sendMail={true}
                Download={true}
                viewClick={openViewPo}
                editClick={openUpdatePo}
                deleteClick={handleOpenDltConfirmation}
                downloadClick={downloadPOPdf}
                handleClickSendPoEmail={sendPoEmail}
                setSortingColumnName={() => {
                  return null;
                }}
                sortingColumnName={null}
                setSortingOrder={() => {
                  return null;
                }}
                sortingOrder={null}
              />

              <PaginantionComponent
                pageNumber={pageNumber}
                currentPage={currentPage || 0}
                itemsPerPage={itemsPerPage || 0}
                totalItems={totalItems || 0}
                previouPageClick={() => {
                  previouPageClick(pageNumber, setPageNumber);
                }}
                nextPageClick={() => {
                  nextPageClick(pageNumber, setPageNumber, paginationData);
                }}
                setPageNumber={setPageNumber}
                disablePrevious={
                  parseInt(pageNumber) === 1 || pageNumber === ""
                }
                disableNext={
                  parseInt(pageNumber) === paginationData?.page_count ||
                  !paginationData ||
                  pageNumber === ""
                }
              />
            </div>
            {openForm.errorMessage ? (
              <div className={` ${classes.formErrorWrap} ${classes.flex}`}>
                <p className={classes.infoText}> {openForm.errorMessage} </p>
              </div>
            ) : (
              !openForm.open &&
              openForm.loading && (
                <div className={classes.tabsScreens}>
                  <span className={classes.loader}>
                    <CircularProgress
                      thickness={6}
                      variant="indeterminate"
                      disableShrink
                      sx={{
                        animationDuration: "350ms",
                        color: "#55555580",
                      }}
                      size={25}
                    />
                  </span>
                </div>
              )
            )}
          </div>
        ) : (
          <div className={classes.poEditRow}>
            <DButton
              label={"BACK"}
              btnHeight="4rem"
              btnWidth={"12rem"}
              buyerBtn={true}
              onClick={() => {
                reset();
                setPoEdit(false);
              }}
            />
            <div className={classes.tabsScreens}>
              <div className={classes.infoSection}>
                <HeaderTitle
                  customclass={`${classes.tabHeading}`}
                  titleText={"Edit PO"}
                />
                <div className={classes.poFormWrapper}>
                  <form onSubmit={handleSubmit(onSubmit, onError)}>
                    {/*Issuer details*/}
                    <h3 className={classes.subTitle}>Issuer details</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <input
                        type="hidden"
                        name="poToken"
                        {...register("poToken")}
                      />
                      <TextInput
                        type="text"
                        label="Name"
                        placeholder="Enter Name"
                        name="issuerName"
                        register={register}
                        {...register("issuerName", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerName?.message}
                        disabled={true}
                      />
                      <PhoneNumberInput
                        label="Mobile Number"
                        name="issuerMobileNumber"
                        {...register("issuerMobileNumber", {
                          // required: "This field is required",
                        })}
                        onChange={(
                          value,
                          data,
                          isValidMobileNumber,
                          formattedValue
                        ) => {
                          // checking the value only for numbers
                          const numberOnlyRegex = /^[0-9]*$/;
                          if (value === "" || numberOnlyRegex.test(value)) {
                            setValue("issuerMobileNumber", formattedValue);
                          }
                        }}
                        errorMessage={errors.issuerMobileNumber?.message}
                        value={issuerMobileNumber || ""}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Address Line1"
                        placeholder="Enter address line1"
                        name="issuerAddressLine1"
                        register={register}
                        {...register("issuerAddressLine1", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerAddressLine1?.message}
                        disabled={true}
                      />
                      {/* <TextInput
                        type="text"
                        label="Address Line2"
                        placeholder="Enter address line2"
                        name="issuerAddressLine2"
                        register={register}
                        {...register("issuerAddressLine2", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerAddressLine2?.message}
                        disabled={true}
                      /> */}
                      <TextInput
                        type="text"
                        label="Country"
                        placeholder="Enter country"
                        name="issuerCountry"
                        register={register}
                        {...register("issuerCountry", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerCountry?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="State/Provine/Region"
                        placeholder="Enter country"
                        name="issuerState"
                        register={register}
                        {...register("issuerState", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerState?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="City"
                        placeholder="Enter city"
                        name="issuerCity"
                        register={register}
                        {...register("issuerCity", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerCity?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Postal/ZipCode"
                        placeholder="Enter postal/zipCode"
                        name="issuerPostalCode"
                        register={register}
                        {...register("issuerPostalCode", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerPostalCode?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Email"
                        placeholder="Enter Email"
                        name="issuerEmail"
                        register={register}
                        {...register("issuerEmail", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerEmail?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Pan number"
                        placeholder="Enter pan number"
                        name="issuerPan"
                        register={register}
                        {...register("issuerPan", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerPan?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="GSTIN"
                        placeholder="Enter GSTIN"
                        name="issuerGSTIn"
                        register={register}
                        {...register("issuerGSTIn", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerGSTIn?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="CIN NO"
                        placeholder="Enter CIN NO"
                        name="issuerCINNo"
                        register={register}
                        {...register("issuerCINNo", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerCINNo?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Purchase order number"
                        placeholder="Enter purchase order number"
                        name="issuerPurchaseOrderNumber"
                        register={register}
                        {...register("issuerPurchaseOrderNumber", {
                          // required: "This field is required",
                        })}
                        errorMessage={
                          errors?.issuerPurchaseOrderNumber?.message
                        }
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Purchase order Date"
                        placeholder="Enter purchase order Date"
                        name="issuerPurchaseOrderDate"
                        register={register}
                        {...register("issuerPurchaseOrderDate", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerPurchaseOrderDate?.message}
                        disabled={true}
                      />
                    </div>
                    {/*Issuer details*/}

                    {/*Vendor details*/}
                    <h3 className={classes.subTitle}>Vendor details</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextInput
                        type="text"
                        label="Name"
                        placeholder="Enter Name"
                        name="vendorName"
                        register={register}
                        {...register("vendorName", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorName?.message}
                        disabled={true}
                      />
                      <PhoneNumberInput
                        label="Mobile Number"
                        name="vendorMobileNumber"
                        {...register("vendorMobileNumber", {
                          // required: "This field is required",
                        })}
                        onChange={(
                          value,
                          data,
                          isValidMobileNumber,
                          formattedValue
                        ) => {
                          // checking the value only for numbers
                          const numberOnlyRegex = /^[0-9]*$/;
                          if (value === "" || numberOnlyRegex.test(value)) {
                            setValue("vendorMobileNumber", formattedValue);
                          }
                        }}
                        errorMessage={errors.vendorMobileNumber?.message}
                        value={vendorMobileNumber || ""}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Address Line1"
                        placeholder="Enter address line1"
                        name="vendorAddressLine1"
                        register={register}
                        {...register("vendorAddressLine1", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorAddressLine1?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Address Line2"
                        placeholder="Enter address line2"
                        name="vendorAddressLine2"
                        register={register}
                        {...register("vendorAddressLine2", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorAddressLine2?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Country"
                        placeholder="Enter country"
                        name="vendorCountry"
                        register={register}
                        {...register("vendorCountry", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorCountry?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="State/Provine/Region"
                        placeholder="Enter country"
                        name="vendorState"
                        register={register}
                        {...register("vendorState", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorState?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="City"
                        placeholder="Enter city"
                        name="vendorCity"
                        register={register}
                        {...register("vendorCity", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorCity?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Postal/ZipCode"
                        placeholder="Enter postal/zipCode"
                        name="vendorPostalCode"
                        register={register}
                        {...register("vendorPostalCode", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorPostalCode?.message}
                        disabled={true}
                      />
                    </div>
                    {/*vendor details*/}

                    {/*Billing details*/}
                    <h3 className={classes.subTitle}>Billing details</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextInput
                        type="text"
                        label="Name"
                        placeholder="Enter Name"
                        name="billingName"
                        register={register}
                        {...register("billingName", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingName?.message}
                        disabled={true}
                      />
                      <PhoneNumberInput
                        label="Mobile Number"
                        name="billingMobileNumber"
                        {...register("billingMobileNumber", {
                          // required: "This field is required",
                        })}
                        onChange={(
                          value,
                          data,
                          isValidMobileNumber,
                          formattedValue
                        ) => {
                          // checking the value only for numbers
                          const numberOnlyRegex = /^[0-9]*$/;
                          if (value === "" || numberOnlyRegex.test(value)) {
                            setValue("billingMobileNumber", formattedValue);
                          }
                        }}
                        errorMessage={errors.billingMobileNumber?.message}
                        value={billingMobileNumber || ""}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Address Line1"
                        placeholder="Enter address line1"
                        name="billingAddressLine1"
                        register={register}
                        {...register("billingAddressLine1", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingAddressLine1?.message}
                        disabled={true}
                      />
                      {/* <TextInput
                        type="text"
                        label="Address Line2"
                        placeholder="Enter address line2"
                        name="billingAddressLine2"
                        register={register}
                        {...register("billingAddressLine2", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingAddressLine2?.message}
                        disabled={true}
                      /> */}
                      <TextInput
                        type="text"
                        label="Country"
                        placeholder="Enter country"
                        name="billingCountry"
                        register={register}
                        {...register("billingCountry", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingCountry?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="State/Provine/Region"
                        placeholder="Enter country"
                        name="billingState"
                        register={register}
                        {...register("billingState", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingState?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="City"
                        placeholder="Enter city"
                        name="billingCity"
                        register={register}
                        {...register("billingCity", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingCity?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Postal/ZipCode"
                        placeholder="Enter postal/zipCode"
                        name="billingPostalCode"
                        register={register}
                        {...register("billingPostalCode", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingPostalCode?.message}
                        disabled={true}
                      />
                    </div>
                    {/*Billing details*/}

                    {/*Shipping details*/}
                    <h3 className={classes.subTitle}>Shipping details</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextInput
                        type="text"
                        label="Name"
                        placeholder="Enter Name"
                        name="shippingName"
                        register={register}
                        {...register("shippingName", {
                          required: "This field is required",
                        })}
                        errorMessage={errors?.shippingName?.message}
                        disabled={true}
                      />
                      <PhoneNumberInput
                        label="Mobile Number"
                        name="shippingMobileNumber"
                        {...register("shippingMobileNumber", {
                          required: "This field is required",
                        })}
                        onChange={(
                          value,
                          data,
                          isValidMobileNumber,
                          formattedValue
                        ) => {
                          if (!isValidMobileNumber) {
                            setError("shippingMobileNumber", {
                              type: "custom",
                              message: "Invalid phone number",
                            });
                          } else {
                            clearErrors(["shippingMobileNumber"]);
                          }
                          // checking the value only for numbers
                          const numberOnlyRegex = /^[0-9]*$/;
                          if (value === "" || numberOnlyRegex.test(value)) {
                            setValue("shippingMobileNumber", formattedValue);
                          }
                        }}
                        errorMessage={errors.shippingMobileNumber?.message}
                        value={shippingMobileNumber || ""}
                        disabled={isUpdating}
                      />
                      <TextInput
                        type="text"
                        label="Address Line1"
                        placeholder="Enter address line1"
                        name="shippingAddressLine1"
                        register={register}
                        {...register("shippingAddressLine1", {
                          required: "This field is required",
                        })}
                        errorMessage={errors?.shippingAddressLine1?.message}
                        disabled={isUpdating}
                      />
                      <TextInput
                        type="text"
                        label="Address Line2 (Optional)"
                        placeholder="Enter address line2"
                        name="shippingAddressLine2"
                        register={register}
                        {...register("shippingAddressLine2", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.shippingAddressLine2?.message}
                        disabled={isUpdating}
                      />

                      <LocationSelectDropDown
                        label="Country"
                        defaultDropDown={true}
                        value={shippingCountry}
                        noInit={true}
                        name="shippingCountry"
                        register={register}
                        {...register("shippingCountry", {
                          required: "This field is required",
                        })}
                        options={
                          country.countryList?.map((el) => el.name) || [
                            "Select",
                          ]
                        }
                        error={errors?.shippingCountry?.message}
                        errorMessage={errors?.shippingCountry?.message}
                        customClassName={classes.field}
                      />
                      <LocationSelectDropDown
                        label="State/Province/Region"
                        defaultDropDown={true}
                        value={shippingState}
                        disabled={!countryToken}
                        noInit={true}
                        name="shippingState"
                        register={register}
                        {...register("shippingState", {
                          required: "This field is required",
                        })}
                        options={
                          stateName.stateList.length >= 1
                            ? stateName.stateList?.map((el) => el.name)
                            : ["No states available"]
                        }
                        error={errors?.shippingState?.message}
                        errorMessage={errors?.shippingState?.message}
                        customClassName={classes.field}
                      />

                      <LocationSelectDropDown
                        label="City"
                        defaultDropDown={true}
                        value={shippingCity}
                        disabled={!stateToken}
                        noInit={true}
                        name="shippingCity"
                        register={register}
                        {...register("shippingCity", {
                          required: "This field is required",
                        })}
                        options={
                          city.cityList.length >= 1
                            ? city.cityList?.map((el) => el.name)
                            : ["No cities available"]
                        }
                        error={errors?.shippingCity?.message}
                        errorMessage={errors?.shippingCity?.message}
                        customClassName={classes.field}
                      />
                      <TextInput
                        maxLength={50}
                        type="text"
                        label="Postal/ZipCode"
                        placeholder="Enter postal/zipCode"
                        name="shippingPostalCode"
                        register={register}
                        {...register("shippingPostalCode", {
                          required: "This field is required",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Postal/ZipCode must be numeric",
                          },
                        })}
                        onKeyPress={(e) => {
                          const charCode = e.which ? e.which : e.keyCode;
                          if (
                            charCode > 31 &&
                            (charCode < 48 || charCode > 57)
                          ) {
                            e.preventDefault();
                          }
                        }}
                        errorMessage={errors?.shippingPostalCode?.message}
                        disabled={isUpdating}
                      />
                    </div>
                    {/*Shipping details*/}

                    {/*Item details*/}
                    <h3 className={classes.subTitle}>Item details</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom} ${classes.itemParent}`}
                    >
                      {itemList?.length > 0 &&
                        itemList?.map((item, index) => (
                          <>
                            <input
                              type="hidden"
                              name={`item_list[${index}].itemMappedToken`}
                              {...register(
                                `item_list[${index}].itemMappedToken`
                              )}
                            />
                            <div className={classes.itemChildren}>
                              <TextInput
                                type="text"
                                label="Item Code"
                                placeholder="Enter item code"
                                name={`item_list[${index}].itemCode`}
                                register={register}
                                {...register(`item_list[${index}].itemCode`, {
                                  required: "This field is required",
                                })}
                                errorMessage={
                                  errors?.item_list?.[index]?.itemCode?.message
                                }
                                disabled={true}
                              />
                              <TextInput
                                type="text"
                                label="Quantity"
                                placeholder="Enter quantity"
                                name={`item_list[${index}].itemQuantity`}
                                register={register}
                                {...register(
                                  `item_list[${index}].itemQuantity`,
                                  {
                                    required: "This field is required",
                                  }
                                )}
                                errorMessage={
                                  errors?.item_list?.[index]?.itemQuantity
                                    ?.message
                                }
                                disabled={true}
                              />
                              <TextArea
                                type="text"
                                label="Description"
                                placeholder="Enter price"
                                name={`item_list[${index}].itemDescription`}
                                register={register}
                                {...register(
                                  `item_list[${index}].itemDescription`,
                                  {
                                    required: "This field is required",
                                  }
                                )}
                                errorMessage={
                                  errors?.item_list?.[index]?.itemDescription
                                    ?.message
                                }
                                disabled={true}
                              />
                            </div>

                            {index === itemList?.length - 1 && (
                              <TextInput
                                type="text"
                                label="Total Price"
                                placeholder="Enter price"
                                name={`item_list[${index}].itemPrice`}
                                register={register}
                                {...register(`item_list[${index}].itemPrice`, {
                                  required: "This field is required",
                                })}
                                errorMessage={
                                  errors?.item_list?.[index]?.itemPrice?.message
                                }
                                disabled={true}
                                customClass={classes.totalPriceInput}
                              />
                            )}
                          </>
                        ))}
                    </div>
                    {/*Item details*/}

                    {/*Item payment*/}
                    <h3 className={classes.subTitle}>Payment Terms</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextArea
                        type="text"
                        label="Description"
                        placeholder="Enter Description"
                        name="paymentTerms"
                        register={register}
                        {...register("paymentTerms", {
                          required: "This field is required",
                        })}
                        errorMessage={errors?.paymentTerms?.message}
                        disabled={isUpdating}
                      />
                    </div>
                    {/*Item payment*/}
                    {/*Item validity*/}
                    <h3 className={classes.subTitle}>Validity</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextInput
                        type="text"
                        label="Validity"
                        placeholder="Enter validity"
                        name="validity"
                        register={register}
                        {...register("validity", {
                          required: "This field is required",
                        })}
                        errorMessage={errors?.validity?.message}
                        disabled={isUpdating}
                      />
                    </div>
                    {/*Item validity*/}

                    {/*Delivery Terms*/}
                    <h3 className={classes.subTitle}>Delivery Terms</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextArea
                        type="text"
                        label="Delivery Terms"
                        placeholder="Enter delivery terms"
                        name="deliveryTerms"
                        register={register}
                        {...register("deliveryTerms", {
                          required: "This field is required",
                        })}
                        errorMessage={errors?.deliveryTerms?.message}
                        disabled={isUpdating}
                      />
                    </div>
                    {/*Delivery Terms*/}

                    {/*Mode of transportation*/}
                    <h3 className={classes.subTitle}>Mode of transportation</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextInput
                        type="text"
                        label="Mode of Transportation"
                        placeholder="Enter Mode of Transportation"
                        name="modeOfTransportation"
                        register={register}
                        {...register("modeOfTransportation", {
                          required: "This field is required",
                        })}
                        errorMessage={errors?.modeOfTransportation?.message}
                        disabled={isUpdating}
                      />
                    </div>
                    {/*Mode of transportation*/}

                    {/*Terms*/}
                    <h3 className={classes.subTitle}>Terms and Condition</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <div
                        className={`${styles.inputContainer} ${classes.customTextareaSpan}`}
                      >
                        <label className={styles.label}>Description</label>
                        <Controller
                          name="terms_and_condition"
                          control={control}
                          defaultValue=""
                          rules={{ required: "This field is required" }}
                          render={({ field }) => (
                            <ReactQuill
                              theme="snow"
                              value={field.value || ""}
                              onChange={field.onChange}
                            />
                          )}
                        />
                        {errors.terms_and_condition && (
                          <p className={styles.error}>
                            {errors.terms_and_condition.message}
                          </p>
                        )}
                      </div>
                    </div>
                    {/*Terms*/}

                    <div className={classes.poSubmitBtnGroup}>
                      <DButtonOutlined
                        label={"Cancel"}
                        onClick={() => {
                          reset();
                          setPoEdit(false);
                        }}
                        btnWidth={"14rem"}
                        btnHeight={"4.5rem"}
                        disabled={isUpdating}
                      />
                      <DButton
                        iconWidth="1rem"
                        iconHeight="1rem"
                        label={"Save"}
                        disabled={isUpdating}
                        btnHeight="4.5rem"
                        btnWidth="12rem"
                        loadingState={false}
                        buyerBtn={true}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* success modal popup */}
      <Modal open={open}>
        <Fade in={open}>
          <Box>
            <SuccessPopupDashboard
              popupImage={successPopupImg}
              popupHeroText={successModalContent.title}
              popupDescription={successModalContent.description}
              popBtn={true}
              vendorBtn={true}
              btnText={"Okay"}
              btnClick={openPOCancel}
            />
          </Box>
        </Fade>
      </Modal>
      {/* delete error modal two states - confirmation and deletion */}
      {/* confirmation */}
      <Modal open={openDltConfirmation}>
        <Fade in={openDltConfirmation}>
          <Box>
            <SuccessPopupDashboard
              popupImage={deletePopupImg}
              popupHeroText="Are you sure want to delete?"
              popupDescription={""}
              popBtn={true}
              btnText={"Delete"}
              loadingPrimaryBtn={isPoDeleting}
              secondaryBtn={true}
              vendorBtn={true}
              vendorBtnOutlined={true}
              btnTextSecondary={"Cancel"}
              btnClick={handleDeletePO}
              btnClickSecondary={handleCloseDltConfirmation}
            />
          </Box>
        </Fade>
      </Modal>
      {/* pdf preview */}
      <Modal
        open={openPoPdfModal}
        onClose={() => {
          setOpenPoPdfModal(false);
        }}
        aria-labelledby="pdf-preview-title"
        aria-describedby="pdf-preview-description"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              padding: "20px",
              boxShadow: 24,
              width: "80%",
              maxHeight: "90%",
              height: "80%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h4
              style={{
                fontSize: "28px",
                textAlign: "center",
                marginBottom: "10px",
                textTransform: "uppercase",
                fontFamily: "GilroyBold",
              }}
            >
              PDF PO#{PO_number}
            </h4>

            <div className={classes.pdfWrapper}>
              {generateViewPdfFlag ? (
                <span className={`${classes.loader} ${classes.pdfLoader}`}>
                  <CircularProgress
                    thickness={6}
                    variant="indeterminate"
                    disableShrink
                    sx={{
                      animationDuration: "350ms",
                      color: "#55555580",
                    }}
                    size={25}
                  />
                  <p
                    style={{
                      display: "block",
                      fontSize: "12px",
                      marginLeft: "10px",
                      color: "#3c3636",
                    }}
                  >
                    Generating pdf...
                  </p>
                </span>
              ) : (
                pdfData && (
                  <iframe
                    src={pdfData}
                    width="100%"
                    height="100%"
                    title="pdf view"
                    style={{
                      height: "calc(100% - 65px)",
                      padding: "10px",
                      border: "1px solid #f2f2f2",
                      borderRadius: "6px",
                      width: "calc(100% - 20px)",
                      margin: "auto",
                    }}
                  ></iframe>
                )
              )}
              <DButtonOutlined
                label="Close"
                btnWidth={"12rem"}
                btnHeight={"4rem"}
                onClick={() => setOpenPoPdfModal(false)}
                buyerBtn={true}
                vendorBtnOutlined={false}
              />
            </div>
          </Box>
        </Box>
      </Modal>
      {/* PO PDF view */}
      <PoPdfView pdfData={viewPO} ref={pdfRef} />
    </div>
  );
}

export default PoTab;

function TextInput({
  label = "label",
  type = "text",
  placeholder = "",
  disabled = false,
  errorMessage = "",
  customClass = "",
  register,
  name,
  ...rest
}) {
  return (
    <div className={`${styles.inputContainer} ${customClass}`}>
      <label className={styles.label}>{label}</label>
      <input
        type={type}
        name={name}
        className={`${styles.input}`}
        placeholder={placeholder}
        disabled={disabled}
        {...register(name)}
        {...rest}
      />
      {errorMessage && <span className={styles.errorText}>{errorMessage}</span>}
    </div>
  );
}

function TextArea({
  label = "label",
  type = "text",
  placeholder = "",
  disabled = false,
  errorMessage = "",
  register,
  name,
  ...rest
}) {
  return (
    <div className={`${styles.inputContainer} ${classes.customTextareaSpan}`}>
      <label className={styles.label}>{label}</label>
      <textarea
        type={type}
        name={name}
        className={`${styles.inputTextArea} ${classes.resizeNone}`}
        placeholder={placeholder}
        disabled={disabled}
        {...register(name)}
        {...rest}
      ></textarea>
      {errorMessage && <span className={styles.errorText}>{errorMessage}</span>}
    </div>
  );
}

function PhoneNumberInput({
  label,
  customClassName,
  onChange,
  error,
  errorMessage,
  name,
  disabled = false,
  value,
  ...rest
}) {
  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    if (typeof value === "string" && value.includes("+")) {
      let sanitizedValue = value
        .replace(/[^\w\s]/gi, "")
        .split(" ")
        .slice(1)
        .join("");
      setInputValue(sanitizedValue);
    } else {
      setInputValue(value || "");
    }
  }, [value]);

  const handlePhoneNumberChange = (status, number, countryData, fullNumber) => {
    if (onChange) {
      onChange(number, countryData, status, fullNumber);
    }

    // setValue(name, fullNumber);
  };

  const handleSelectFlag = (number, countryData) => {
    let countryCodeSplitted = number.split(" ");
    let countryCode = countryCodeSplitted[0] === `+${countryData.dialCode}`;
    // setValue(name, countryCode === false ? "" : number);
  };
  return (
    <div className={styles.inputContainer}>
      <label className={styles.label}>{label}</label>
      <div className={styles.d_input_wrapper}>
        <IntlTelInput
          onPhoneNumberChange={handlePhoneNumberChange}
          onSelectFlag={handleSelectFlag}
          defaultCountry="in"
          value={inputValue}
          autoHideDialCode={true}
          format={false}
          fieldName={name}
          disabled={disabled}
          disableCountryCode={disabled}
          {...rest}
        />
      </div>
      {errorMessage && <span className={styles.errorText}>{errorMessage}</span>}
    </div>
  );
}

function LocationSelectDropDown({
  defaultDropDown,
  errorMessage,
  placeHolder,
  label,
  value,
  customClassName,
  onChange,
  error,
  options,
  disabled,
  noInit,
  register,
  name,
  ...rest
}) {
  return (
    <div className={`${styles.inputContainer} ${customClassName}`}>
      <label className={styles.label}>{label}</label>
      {defaultDropDown ? (
        <div className={`${styles.select} ${error && styles.error}`}>
          <Select
            value={value}
            onChange={onChange}
            displayEmpty
            disabled={disabled}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
              sx: {
                ".MuiMenu-paper": {
                  bottom: "10px !important",
                  maxHeight: "200px",
                },
              },
            }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                border: "0",
              },
              width: "100%",
              fontSize: "1.35rem",
              height: "46px",
              marginBottom: "1px",
              outline: "none",
              fontFamily: "Source Sans Regular",
            }}
            {...register(name)}
            {...rest}
          >
            <MenuItem value={""} sx={{ fontSize: "1.3rem" }}>
              {noInit ? "Select" : options && options[0]}
            </MenuItem>
            {options &&
              options?.map((option, i) =>
                !noInit && i == 0 ? (
                  ""
                ) : (
                  <MenuItem
                    sx={{ fontSize: "1.3rem" }}
                    key={option}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                )
              )}
          </Select>
        </div>
      ) : (
        ""
      )}
      {error && <span className={styles.errorText}>{errorMessage}</span>}
    </div>
  );
}
