import React, { memo } from "react";
//css
import classes from "./PopupFrame.module.css";
import styles from "../Popup.module.css";
const PopupFrame = ({
  inputChildren,
  poupHeading,
  actionButtons,
  width,
  customClass,
  styleCenterDiv,
}) => {
  return (
    <div
      className={`${classes.addUserPopup} ${styles.absCenter} ${customClass}`}
      style={{ width: width }}
    >
      {poupHeading && poupHeading}

      <div className={`${classes.popup_fields} ${styleCenterDiv}`}>
        {inputChildren &&
          inputChildren?.map((e, i) => {
            return e;
          })}
      </div>

      <div className={classes.popup_action_btns_wrapper}>
        {actionButtons &&
          actionButtons?.map((e) => {
            return e;
          })}
      </div>
    </div>
  );
};

export default memo(PopupFrame);
