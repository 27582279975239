import React, { memo, useEffect, useState } from "react";
import BrandLogo from "../common/BrandLogo/BrandLogo";
import SideBarTab from "./SideBarTab";
import classes from "./Sidebar.module.css";
import Logo from "../../assets/images/svg/Logo.svg";
// Importing the correct image paths
import brandLogo from "../../assets/images/png/Logo@2x.png";
import dashBoardTabActive from "../../assets/images/svg/Dashboard fill.svg";
import dashBoardTab from "../../assets/images/svg/Dashboard Outline.svg";
import userImgActive from "../../assets/images/svg/Users.svg";
import userImg from "../../assets/images/svg/User Outline.svg";
import vendorsImg from "../../assets/images/svg/Vendor Outline.svg";
import vendorsImgActive from "../../assets/images/svg/Vendors.svg";
import itemsImg from "../../assets/images/svg/items outline.svg";
import itemsOutlet from "../../assets/images/svg/items outlinebuyer.svg";
import itemsImgActive from "../../assets/images/svg/Items.svg";
import RFQImg from "../../assets/images/svg/RFQ outline.svg";
import RFQImgActive from "../../assets/images/svg/RFQ filled.svg";
import vendorUsersImg from "../../assets/images/svg/User Outline.svg";
import vendorUsersImgActive from "../../assets/images/svg/user.svg";
import promptImgActive from "../../assets/images/svg/PromptActive.svg";
import promptImg from "../../assets/images/svg/Prompt.svg";

// buyers tabs image
import bdashBoardTab from "../../assets/images/svg/dashboard.svg";
import bvendorsImg from "../../assets/images/svg/vendor.svg";
import bvendorsImgActive from "../../assets/images/svg/Vendor filled.svg";
import bRFQImgActive from "../../assets/images/svg/RFQ filled.svg";
import bRFQImg from "../../assets/images/svg/RFQ.svg";
import ComparisonImg from "../../assets/images/svg/comparision.svg";
import ComparisonActiveImg from "../../assets/images/svg/comparision filled.svg";
import ComparisonAnalyticsImg from "../../assets/images/svg/comparision analstics.svg";
import ComparisonAnalyticsImgActive from "../../assets/images/svg/camparison any outline.svg";

import draftImg from "../../assets/images/svg/draft.svg";
import draftActiveImg from "../../assets/images/svg/draftActive.svg";
import poImageImg from "../../assets/images/svg/poImage.svg";
import poImageActiveImg from "../../assets/images/svg/poImageActive.svg";

//routes and API's
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "@mui/material";
import { ChevronLeft, Close, MenuOpen } from "@mui/icons-material";
import ImgFrame from "../common/ImageFrame/ImgFrame";
import { setSideBarToggle } from "../../features/SideBarToggleSlice";

const Sidebar = () => {
  const location = useLocation();

  //dispatcher
  const dispatch = useDispatch();

  //get data from use selector
  const data = useSelector((state) => state.loginData.value);
  const sideBarToggled = useSelector((state) => state.sideBarToggle.value);

  //roles
  const [roles, setRoles] = useState({
    SuperAdmin: "",
    Admin: "",
    Buyer: "",
    VendorAdmin: "",
    VendorContact: "",
  });

  function checkPathAdmin(pathname) {
    return location.pathname == `/Dialogue_list/${pathname}`;
  }
  function checkPath(pathname) {
    return location.pathname == `/Organization_dashboard/${pathname}`;
  }
  function checkPathBuyer(pathname) {
    return location.pathname == `/Buyer_dashboard/${pathname}`;
  }
  function checkPathVendor(pathname) {
    return location.pathname == `/Vendors_dashboard/${pathname}`;
  }

  let locationName = location.pathname.split("/");
  const [finalPath, setFinalPath] = useState();
  let adminPathInitial = "/Dialogue_list";
  const [superadminDashboard, setSuperAdminDashboard] = useState([
    {
      title: "AI Dialogue",
      tabImg: promptImg,
      activeTabImg: promptImgActive,
      tabActive: checkPathAdmin("Prompt") || finalPath === "Prompt",
      path: `${adminPathInitial}/Prompt`,
    },
    {
      title: "RFQ Category",
      tabImg: promptImg,
      activeTabImg: promptImgActive,
      tabActive:
        checkPathAdmin("Prompt_creation") || finalPath === "Prompt_creation",
      path: `${adminPathInitial}/Prompt_creation`,
    },
  ]);

  let orgPathInitial = "/Organization_dashboard";
  const [orgDashboard, setOrgDashboard] = useState([
    {
      title: "Dashboard",
      tabImg: dashBoardTab,
      activeTabImg: dashBoardTabActive,
      tabActive: checkPath("") || finalPath === "",
      path: `${orgPathInitial}/Dashboard`,
    },
    {
      title: "Buyer",
      tabImg: userImg,
      activeTabImg: userImgActive,
      tabActive: checkPath("Buyers") || finalPath === "Buyers",
      path: `${orgPathInitial}/Buyers`,
    },
    {
      title: "Item Master",
      tabImg: itemsImg,
      activeTabImg: itemsImgActive,
      tabActive: checkPath("Items") || finalPath === "Items",
      path: `${orgPathInitial}/Items`,
    },
    {
      title: "RFQ",
      tabImg: RFQImg,
      activeTabImg: RFQImgActive,
      tabActive: checkPath("RFQ") || finalPath === "RFQ",
      path: `${orgPathInitial}/RFQ`,
    },
    {
      title: "Vendor",
      tabImg: vendorsImg,
      activeTabImg: vendorsImgActive,
      tabActive: checkPath("Vendors") || finalPath === "Vendors",
      path: `${orgPathInitial}/Vendors`,
    },
  ]);

  let buyerPathInitial = "/Buyer_dashboard";
  const [buyerDashboard, setBuyerDashboard] = useState([
    {
      title: "Dashboard",
      tabImg: bdashBoardTab,
      activeTabImg: dashBoardTabActive,
      tabActive: checkPathBuyer(""),
      path: `${buyerPathInitial}/Dashboard`,
    },
    {
      title: "Item Master",
      tabImg: itemsOutlet,
      activeTabImg: itemsImgActive,
      tabActive: checkPathBuyer("Items"),
      path: `${buyerPathInitial}/Items`,
    },
    {
      title: "RFQ",
      tabImg: bRFQImg,
      activeTabImg: bRFQImgActive,
      tabActive: checkPathBuyer("RFQ"),
      path: `${buyerPathInitial}/RFQ`,
    },
    {
      title: "RFQ Drafts",
      tabImg: draftImg,
      activeTabImg: draftActiveImg,
      tabActive: checkPathBuyer("RFQ_Draft"),
      path: `${buyerPathInitial}/RFQ_Draft`,
    },
    {
      title: "PO Management",
      tabImg: poImageImg,
      activeTabImg: poImageActiveImg,
      tabActive: checkPathBuyer("PO"),
      path: `${buyerPathInitial}/PO`,
    },

    // {
    //   title: "Comparison Analytics",
    //   tabImg: ComparisonAnalyticsImg,
    //   activeTabImg: ComparisonAnalyticsImgActive,
    //   tabActive: checkPathBuyer("Comparison Analytics"),
    //   path: `${buyerPathInitial}/Comparison_analytics`,
    // },
    {
      title: "Vendor",
      tabImg: bvendorsImg,
      activeTabImg: bvendorsImgActive,
      tabActive: checkPathBuyer("Vendors"),
      path: `${buyerPathInitial}/Vendors`,
    },
    {
      title: "Comparison",
      tabImg: ComparisonImg,
      activeTabImg: ComparisonActiveImg,
      tabActive: checkPathBuyer("Comparison"),
      path: `${buyerPathInitial}/Comparison`,
    },
  ]);

  let vendorPathInitial = "/Vendor_dashboard";
  const [vendorAdminDashboard, setVendorAdminDashboard] = useState([
    {
      title: "Dashboard",
      tabImg: dashBoardTab,
      activeTabImg: dashBoardTabActive,
      tabActive: checkPathVendor(""),
      path: `${vendorPathInitial}/Dashboard`,
    },
    {
      title: "Bid Submission",
      tabImg: RFQImg,
      activeTabImg: RFQImgActive,
      tabActive: checkPathVendor("RFQ"),
      path: `${vendorPathInitial}/RFQ`,
    },
    {
      title: "Vendor User",
      tabImg: vendorUsersImg,
      activeTabImg: vendorUsersImgActive,
      tabActive: checkPathVendor("Users"),
      path: `${vendorPathInitial}/Users`,
    },
  ]);

  const [vendorContactDashboard, setVendorContactDashboard] = useState([
    {
      title: "Dashboard",
      tabImg: dashBoardTab,
      activeTabImg: dashBoardTabActive,
      tabActive: checkPathVendor(""),
      path: `${vendorPathInitial}/Dashboard`,
    },
    {
      title: "Bid Submission",
      tabImg: RFQImg,
      activeTabImg: RFQImgActive,
      tabActive: checkPathVendor("RFQ"),
      path: `${vendorPathInitial}/RFQ`,
    },
  ]);

  useEffect(() => {
    setRoles({
      SuperAdmin:
        data?.access_type === "superadmin" ||
        data?.access_type === "superAdmin",
      Admin: data?.access_type === "admin" || data?.access_type === "Admin",
      Buyer: data?.access_type === "buyer" || data?.access_type === "Buyer",
      VendorAdmin:
        data?.access_type === "vendor admin" ||
        data?.access_type === "Vendor admin",
      VendorContact:
        data?.access_type === "vendor contact" ||
        data?.access_type === "Vendor contact",
    });
    // const updatedDashboard = orgDashboard.map((e, i) => ({
    //   ...e,
    //   tabActive: e.title === finalPath,
    // }));
    // setOrgDashboard(updatedDashboard);
    setFinalPath(locationName[locationName.length - 1]);
  }, [location.pathname]);

  // Function to toggle the active state of a tab
  const toggleActiveSuperAdmin = (index) => {
    const updatedDashboard = superadminDashboard.map((e, i) => ({
      ...e,
      tabActive: i === index,
    }));

    setSuperAdminDashboard(updatedDashboard);
  };

  const toggleActiveAdmin = (index) => {
    const updatedDashboard = orgDashboard.map((e, i) => ({
      ...e,
      tabActive: i === index,
    }));

    setOrgDashboard(updatedDashboard);
  };

  const togglesActiveBuyer = (index) => {
    const updatedDashboard = buyerDashboard.map((e, i) => ({
      ...e,
      tabActive: i === index,
    }));

    setBuyerDashboard(updatedDashboard);
  };

  const togglesActiveVendor = (index) => {
    const updatedDashboard = vendorAdminDashboard.map((e, i) => ({
      ...e,
      tabActive: i === index,
    }));

    setVendorAdminDashboard(updatedDashboard);
  };

  const togglesActiveVendorContact = (index) => {
    const updatedDashboard = vendorContactDashboard.map((e, i) => ({
      ...e,
      tabActive: i === index,
    }));

    setVendorContactDashboard(updatedDashboard);
  };
  const localeStorageRemove = () => {
    localStorage.setItem("rfq_page_number", 1);
    localStorage.setItem("rfq_organ_page_number", 1);
    localStorage.setItem("rfq_vendor_page_number", 1);
  };
  const sideBarFrame = (
    <>
      <BrandLogo
        imgSrc={brandLogo}
        imgAlt="BrandLogo"
        imgSize="13rem"
        customClass={classes.sideNavLogo}
      />

      {roles.SuperAdmin
        ? superadminDashboard.map((e, i) => (
            <SideBarTab
              key={i}
              roles={roles}
              activeTabImg={e.activeTabImg}
              tabImg={e.tabImg}
              clickFunc={() => {
                handleCloseSideBar(true);
                toggleActiveSuperAdmin(i);
                localeStorageRemove();
              }}
              tabName={e.title}
              active={e.tabActive}
              navigatePath={e.path}
            />
          ))
        : roles.Admin
        ? orgDashboard.map((e, i) => (
            <SideBarTab
              key={i}
              roles={roles}
              activeTabImg={e.activeTabImg}
              tabImg={e.tabImg}
              clickFunc={() => {
                handleCloseSideBar(true);
                toggleActiveAdmin(i);
                localeStorageRemove();
              }}
              tabName={e.title}
              active={e.tabActive}
              navigatePath={e.path}
            />
          ))
        : roles.Buyer
        ? buyerDashboard.map((e, i) => (
            <SideBarTab
              key={i}
              roles={roles}
              activeTabImg={e.activeTabImg}
              tabImg={e.tabImg}
              clickFunc={() => {
                handleCloseSideBar(true);
                togglesActiveBuyer(i);
                localeStorageRemove();
              }}
              tabName={e.title}
              active={e.tabActive}
              navigatePath={e.path}
            />
          ))
        : roles.VendorAdmin
        ? vendorAdminDashboard.map((e, i) => (
            <SideBarTab
              key={i}
              roles={roles}
              activeTabImg={e.activeTabImg}
              tabImg={e.tabImg}
              clickFunc={() => {
                handleCloseSideBar(true);
                togglesActiveVendor(i);
                localeStorageRemove();
              }}
              tabName={e.title}
              active={e.tabActive}
              navigatePath={e.path}
            />
          ))
        : roles.VendorContact &&
          vendorContactDashboard.map((e, i) => (
            <SideBarTab
              key={i}
              roles={roles}
              activeTabImg={e.activeTabImg}
              tabImg={e.tabImg}
              clickFunc={() => {
                handleCloseSideBar(true);
                togglesActiveVendorContact(i);
                localeStorageRemove();
              }}
              tabName={e.title}
              active={e.tabActive}
              navigatePath={e.path}
            />
          ))}
    </>
  );

  // opensidebar
  const [openSideBar, setOpenSideBar] = useState(false);
  const handleOpenSideBar = () => setOpenSideBar(true);
  const handleCloseSideBar = () => setOpenSideBar(false);

  return (
    <div
      className={
        sideBarToggled
          ? classes.sidebar_wrapper_main_closed
          : classes.sidebar_wrapper_main
      }
    >
      <ChevronLeft
        className={
          sideBarToggled
            ? classes.sideBarToggleBtnOpen
            : classes.sideBarToggleBtn
        }
        onClick={() => {
          dispatch(setSideBarToggle(!sideBarToggled));
        }}
      />
      <div
        className={`${
          sideBarToggled
            ? classes.sidebar_wrapper_closed
            : classes.sidebar_wrapper
        } ${roles.Buyer && classes.buyerSideBar} ${
          roles.SuperAdmin && classes.mainSideBar
        } ${roles.Admin && classes.mainSideBar} ${
          roles.VendorAdmin && classes.vendorSideBar
        } ${roles.VendorContact && classes.vendorSideBar} ${
          classes.tempSideBar
        }`}
      >
        {sideBarFrame}
      </div>
      <div className={classes.openSideMenu}>
        <div className={`${classes.prg_sidebar_logo}`}>
          <ImgFrame imgSrc={Logo} />
        </div>
        <div className={classes.menuBar} onClick={handleOpenSideBar}>
          <MenuOpen
            sx={{
              fontSize: window.innerWidth < 480 ? "1.8rem" : "2.1rem",
            }}
          />
          <span>Menu</span>
        </div>
      </div>
      <Drawer open={openSideBar} anchor={"left"} className={classes.menuDraw}>
        <div
          className={`${classes.sidebar_wrapper} ${
            roles.Buyer && classes.buyerSideBar
          } ${roles.SuperAdmin && classes.mainSideBar} ${
            roles.Admin && classes.mainSideBar
          } ${roles.VendorAdmin && classes.vendorSideBar} ${
            roles.VendorContact && classes.vendorSideBar
          }`}
        >
          {sideBarFrame}
          <button onClick={handleCloseSideBar} className={classes.closeBtn}>
            <Close sx={{ fontSize: "2rem" }} />
          </button>
        </div>
      </Drawer>
    </div>
  );
};

export default memo(Sidebar);
