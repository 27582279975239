import React, { memo } from "react";
//css
import classes from "./Dbutton.module.css";
import { CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

const DButton = ({
  buyerBtn,
  vendorBtn,
  label,
  onClick,
  customWrapperClass,
  customClass,
  disabled,
  labelImg,
  iconWidth,
  iconHeight,
  btnHeight,
  btnWidth,
  loadingState,
  error,
  errorMsg,
  tooltipMessage = "",
}) => {
  return (
    <div
      className={`${classes.dashboard_button_wrapper} ${customWrapperClass}`}
    >
      <Tooltip
        title={<span style={{ fontSize: "11px" }}>{tooltipMessage}</span>}
        TransitionComponent={Zoom}
        disableHoverListener={tooltipMessage ? false : true}
        arrow
      >
        <button
          className={` ${!buyerBtn ? classes.d_button : classes.buyerBtn} ${
            vendorBtn && classes.vendorBtn
          } flex ${customClass} ${disabled && classes.disabled} ${
            loadingState && classes.disabled
          }`}
          style={{ width: `${btnWidth}`, height: `${btnHeight}` }}
          onClick={onClick}
          disabled={disabled || loadingState}
        >
          {labelImg && !loadingState && (
            <img
              src={labelImg}
              style={{
                width: `${iconWidth}`,
                height: `${iconHeight}`,
                marginRight: `${label && `1rem`}`,
              }}
              alt=""
            />
          )}

          {loadingState && (
            <CircularProgress
              size={"18px"}
              thickness={6}
              variant="indeterminate"
              disableShrink
              sx={{
                color: "#fff",
                // marginTop: "4px",
                animationDuration: "450ms",
              }}
            />
          )}

          {label && !loadingState && <span>{label}</span>}
        </button>
      </Tooltip>
      {error && <p className={classes.error_message}>{errorMsg}</p>}
    </div>
  );
};

export default memo(DButton);
