import React, { memo } from 'react'
import classes from "./ChartIndicator.module.css"
const ChartIndicator = ({ label, color }) => {
    return (
        <div className={classes.indicatorWrapper} >
            <span style={{
                backgroundColor: `${color}`
            }}></span> <p>{label}</p>
        </div>
    )
}

export default memo(ChartIndicator);