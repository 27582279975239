import React from 'react'
import PrivacyPolicy from './PrivacyPolicy'
import HomeFooter from '../../../../components/Home/HomeFooter/HomeFooter'
import classes from "./PrivacyPolicyLayout.module.css"
import Header from '../../../../components/Home/Header/Header'
import { Element } from 'react-scroll'
const PrivacyPolicyLayout = () => {
    return (
        <>
            {/* scroll element for privacy policy section */}
            <Element name="prp_content"></Element>
            {/* scroll element for privacy policy section */}

            <Header />

            <PrivacyPolicy />

            <div className={`${classes.footer}`}>
                <HomeFooter />
            </div>
        </>
    )
}

export default PrivacyPolicyLayout