import React, { memo } from "react";
import styles from "./SuccessPopupDashboard.module.css";
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DButtonOutlined from "../../../components/Dashboard/DashboardButtons/dButtonOutlined";

const SuccessPopupDashboard = ({
  secondaryBtn,
  btnTextSecondary,
  btnClickSecondary,
  popupImage,
  popupHeroText,
  popupDescription,
  popupDescriptionLG,
  btnText,
  popBtn,
  btnClick,
  btnWidth,
  btnHeight,
  vendorBtn,
  vendorBtnOutlined,
  customClass,
  buyerBtnOutline,
  buyerBtn,
  loadingPrimaryBtn,
  customHeroTextClass,
  customHeroImgClass,
}) => {
  return (
    <div
      className={`${styles.login_success_popup_wrapper} ${styles.flex} ${styles.absCenter} ${customClass}`}
    >
      {popupImage && (
        <div
          className={`${styles.popup_image} ${customHeroImgClass} ${styles.flex}`}
        >
          <img src={popupImage} alt="popup image" />
        </div>
      )}

      <div className={`${styles.popup_text} ${styles.flex}`}>
        {popupHeroText && (
          <span className={`${styles.primary} ${customHeroTextClass}`}>
            {" "}
            {popupHeroText}
          </span>
        )}
        {popupDescription && <p>{popupDescription}</p>}
        {popupDescriptionLG && (
          <p className={styles.pDescLG}>{popupDescriptionLG}</p>
        )}
        <div className="flex">
          {secondaryBtn && (
            <DButtonOutlined
              label={btnTextSecondary}
              btnWidth={btnWidth ? btnWidth : "17rem"}
              btnHeight={btnHeight ? btnHeight : "4.2rem"}
              onClick={btnClickSecondary}
              buyerBtn={buyerBtnOutline}
              vendorBtnOutlined={vendorBtnOutlined}
            />
          )}
          {popBtn && (
            <DButton
              label={btnText}
              loadingState={loadingPrimaryBtn}
              btnWidth={btnWidth ? btnWidth : "17rem"}
              btnHeight={btnHeight ? btnHeight : "4.2rem"}
              onClick={btnClick}
              buyerBtn={buyerBtn}
              vendorBtn={vendorBtn}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(SuccessPopupDashboard);
