import React, { memo } from "react";
//css
import classes from "./Dbutton.module.css";
import { CircularProgress } from "@mui/material";
const DButtonOutlined = ({
  buyerBtn,
  label,
  onClick,
  disabled,
  labelImg,
  vendorBtn,
  vendorBtnOutlined,
  iconWidth,
  iconHeight,
  btnHeight,
  btnWidth,
  loadingState,
}) => {
  return (
    <button
      className={`${
        !buyerBtn ? classes.d_button_outlined : classes.buyerBtnOutlined
      } ${
        !vendorBtnOutlined
          ? classes.d_button_outlined
          : classes.vendorBtnOutlined
      } ${disabled || loadingState ? classes.disabled : ""} ${classes.flex}`}
      style={{ width: `${btnWidth}`, height: `${btnHeight}` }}
      onClick={onClick}
      disabled={disabled || loadingState}
    >
      {labelImg && !loadingState && (
        <img
          src={labelImg}
          style={{
            width: `${iconWidth}`,
            height: `${iconHeight}`,
            marginRight: `${label && `1rem`}`,
          }}
        />
      )}

      {loadingState && (
        <CircularProgress
          size={"18px"}
          thickness={6}
          variant="indeterminate"
          disableShrink
          sx={{
            color: "#3b86c7",
            // marginTop: "4px",
            animationDuration: "450ms",
          }}
        />
      )}

      {label && !loadingState && <span>{label}</span>}
    </button>
  );
};

export default memo(DButtonOutlined);
