import React, { memo, useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  Fade,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
//img
import viewIcon from "../../../assets/images/svg/viewtech.svg";
import fileIcon from "../../../assets/images/svg/file@.svg";
import editIcon from "../../../assets/images/svg/File Edit.svg";
import dltIcon from "../../../assets/images/svg/Delete.svg";
import sortIcon from "../../../assets/images/svg/Sort.svg";
import profileImg from "../../../assets/images/png/profile-pic.jpg";
import ownBidIcon from "../../../assets/images/svg/vendorProfile/satuatoryblack.svg";
import bidSuccessImg from "../../../assets/images/svg/bidSuccessCheck.svg";
import timelineIcon from "../../../assets/images/svg/timeline.svg";
import downloadIcon from "../../../assets/images/svg/download-icon-black.svg";
//css
import styles from "./DataTable.module.css";
//components
import { saveAs } from "file-saver";
import ImgFrame from "../ImageFrame/ImgFrame";
import DButton from "../../Dashboard/DashboardButtons/Dbutton";
import DButtonOutlined from "../../Dashboard/DashboardButtons/dButtonOutlined";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HeaderTitle from "../HeaderTitle/HeaderTitle";
import PopupFrame from "../../Popups/PopupFrame/PopupFrame";
import InfoLabel from "../InfoLabel/InfoLabel";
import SwitchButton from "../../Dashboard/SwitchButton/SwitchButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const DataTable = ({
  headings,
  tableData,
  actions,
  selection,
  viewBtn,
  editBtn,
  deleteBtn,
  viewClick,
  editClick,
  deleteClick,
  downloadClick,
  handleClickSendPoEmail,
  customClass,
  sorting,
  file,
  selectBidBtn,
  onSelectBid,
  onUnSelectBid,
  ownBid,
  vendorConfidential,
  vendorNameConfidential,
  ownBidHeading,
  tableSize,
  org,
  buyer,
  bulkData,
  isLoading,
  errorMessage,
  itemsTable,
  selectedRowsList,
  clearAllSelectedRows,
  hasBidIteration,
  maxBidIterationCount,
  disableSelectAll,
  stickyHead,
  tableFixedHeight,
  toggleOnChange,
  setSortingColumnName,
  sortingColumnName,
  setSortingOrder,
  sortingOrder,
  timeline,
  viewTimeLineClick,
  downloadSendmailForPo = false,
  sendMail = false,
  Download = false,
}) => {
  const [data, setData] = useState(tableData);
  let checkIsBidSelected = bulkData?.map((e) => e.status);
  let quoteOptions = headings.filter((e) => e.label === "Quote Iteration")[0]
    ?.DropdownOptions;
  useEffect(() => {
    tableData && setData(tableData);
  }, [tableData]);

  const location = useLocation();

  const [bidSelected, setBidSelected] = useState(false);
  const [invisible, setInvisible] = useState(true);
  //roles
  const loginData = useSelector((state) => state.loginData.value);

  const [roles, setRoles] = useState({
    Admin: "",
    Buyer: "",
    Vendor: "",
  });
  useEffect(() => {
    if (clearAllSelectedRows) {
      setSelectedRows([]);
      setSelectedRowObj([]);
    }
  }, [clearAllSelectedRows]);

  useEffect(() => {
    setRoles({
      Admin:
        loginData?.access_type === "admin" ||
        loginData?.access_type === "Admin",
      Buyer:
        loginData?.access_type === "buyer" ||
        loginData?.access_type === "Buyer",
      Vendor:
        loginData?.access_type === "vendor admin" ||
        data?.access_type === "Vendor admin" ||
        loginData?.access_type === "vendor contact" ||
        data?.access_type === "Vendor contact",
    });
  }, [location.pathname, data, bidSelected]);

  const [selectedBidRows, setSelectedBidRows] = useState([]);

  const [sortedField, setSortedField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowObj, setSelectedRowObj] = useState([]);

  // const handleRowSelect = (rowId, row) => {
  //   const selectedIndex = selectedRows?.indexOf(rowId);
  //   let newSelectedRows = [];
  //   let newSelectedRowObj = [];

  //   if (selectedIndex === -1) {
  //     newSelectedRows = [...selectedRows, rowId];
  //     newSelectedRowObj.push(row);
  //   } else {
  //     newSelectedRows = selectedRows?.filter((id) => id !== rowId);
  //     newSelectedRowObj = newSelectedRowObj?.filter((e) => e.token !== row.token);
  //   }

  //   // in bid iteration selection have to select the each row using its token but have to send the vendor_id
  //   // of the each row in selection in specifically --> RFQ -> request resubmit quote

  //   // let filteredMain = newSelectedRows.flatMap((el) => (
  //   //   data?.filter((el2) => (el === el2?.token))
  //   // ))

  //   // let filteredVendorId = filteredMain.map((el) => el?.vendor_id);

  //   // selectedRowsList(filteredVendorId);
  //   setSelectedRows(newSelectedRows, newSelectedRowObj);
  //   selectedRowsList(newSelectedRows, newSelectedRowObj);
  // };

  const handleRowSelect = (rowId, row) => {
    const selectedIndex = selectedRows.indexOf(rowId);

    let newSelectedRows = [...selectedRows];
    let newSelectedRowObj = [...selectedRowObj];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
      newSelectedRowObj = [...selectedRowObj, row];
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
      newSelectedRowObj = newSelectedRowObj.filter(
        (selectedRow) => selectedRow.token !== row.token
      );
    }

    setSelectedRowObj(newSelectedRowObj);
    setSelectedRows(newSelectedRows, newSelectedRowObj);
    selectedRowsList(newSelectedRows, newSelectedRowObj);
  };

  const handleSelectAllRows = (event) => {
    if (event.target.checked) {
      const newSelectedRowTokens = data && data?.map((row, i) => row.token);
      const newSelectedRows = data && data?.map((row, i) => row);
      setSelectedRows(newSelectedRowTokens, newSelectedRows);
      selectedRowsList(newSelectedRowTokens, newSelectedRows);

      // in bid iteration selection have to select the each row using its token but have to send the vendor_id
      // of the each row in selection in specifically --> RFQ -> request resubmit quote

      // let filteredMain = newSelectedRows.flatMap((el) => (
      //   data?.filter((el2) => (el === el2?.token))
      // ))

      // let filteredVendorId = filteredMain.map((el) => el?.vendor_id);

      // selectedRowsList(filteredVendorId);
    } else {
      setSelectedRows([]);
      selectedRowsList([]);
    }
  };

  const handleSort = (field) => {
    let newSortOrder = "asc";
    if (sortedField === field && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortedField(field);
    setSortOrder(newSortOrder);

    // const sortedData = [...data];

    // sortedData.sort((a, b) => {
    //     const valueA = a[field];
    //     const valueB = b[field];

    //     if (typeof valueA === "number" && typeof valueB === "number") {
    //         return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
    //     } else {
    //         console.log(valueA);
    //         const compareResult = valueA?.localeCompare(valueB);
    //         return sortOrder === "asc" ? compareResult : -compareResult;
    //     }
    // });

    // setData(sortedData);
  };

  const isSelected = (rowId) => selectedRows.indexOf(rowId) !== -1;
  const isSelectedBid = (rowId) => selectedBidRows.indexOf(rowId) !== -1;

  const handleSelectBid = (rowId, row) => {
    onSelectBid && onSelectBid(row.token);
    setSelectedBidRows([rowId]);
  };

  const handleUnselectBid = (rowId, row) => {
    onUnSelectBid && onUnSelectBid(row.token);
    setSelectedBidRows([]);
  };

  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_URL;

  // view description
  const [bidDescription, setBidDescription] = useState();
  const [descriptionPopup, setDescriptionPopup] = useState({
    open: false,
    close: false,
  });

  const handleOpenViewDescription = (description) => {
    setDescriptionPopup({
      open: true,
      close: false,
    });
    setBidDescription();
    setBidDescription(description);
  };

  const handleCloseViewDescription = () => {
    setDescriptionPopup({
      open: false,
      close: true,
    });
  };

  const viewDescriptionHeading = (
    <HeaderTitle titleText={"Description"} customclass={styles.popupHeading} />
  );

  const viewDescriptionContent = [
    // <p className={styles.bidDescription}>{bidDescription}</p>
    <InfoLabel
      customClass={styles.bidDescription}
      value={bidDescription}
      size={"100%"}
      maxLength={"350"}
    />,
  ];

  const viewDescriptionAction = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={handleCloseViewDescription}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
    />,
  ];

  //open dropdown states and handlers
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenDropdownHeading = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdownHeading = () => {
    setAnchorEl(null);
  };

  const sortfunction = () => {
    setSortingColumnName(sortedField);
    setSortingOrder(sortOrder);
  };
  useEffect(() => {
    sortfunction();
  }, [sortedField, sortOrder]);
  return (
    <div className={styles.container}>
      <div
        className={`${styles.datatable_wrapper} ${customClass}`}
        style={{
          maxHeight: tableFixedHeight && tableFixedHeight,
        }}
      >
        <table
          style={{
            width: tableSize || "100vw",
          }}
        >
          <thead className={`${stickyHead && styles.stickyHead}`}>
            <tr>
              {selection && (
                <th
                  className={`${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                >
                  <Checkbox
                    disabled={disableSelectAll}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: "22px" } }}
                    size="medium"
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < data?.length
                    }
                    checked={
                      selectedRows.length > 0 &&
                      selectedRows.length === data?.length
                    }
                    onChange={handleSelectAllRows}
                  />
                </th>
              )}
              {headings.map((heading) => (
                <th
                  key={heading.field}
                  className={`${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                  style={{
                    textAlign:
                      heading.label === "Status" ||
                      heading.label === "Bid Status"
                        ? "left"
                        : "left",
                    width:
                      heading.label === "Status"
                        ? "15rem"
                        : heading.col_width && heading.col_width,
                  }}
                >
                  {/* show only field heading - default*/}
                  {!heading.selectable_header && heading.label}
                  {/* show only field heading - default*/}

                  {sorting &&
                    !heading.selectable_header &&
                    heading.label !== "Bids Status" && (
                      <button
                        onClick={() => {
                          handleSort(heading.field);
                        }}
                        style={{
                          outline: "none",
                        }}
                      >
                        <img
                          src={sortIcon}
                          className={styles.sortIcon}
                          alt=""
                        />
                      </button>
                    )}

                  {heading.selectable_header && (
                    <span
                      className={`${styles.flex} ${styles.pointer}`}
                      onClick={handleOpenDropdownHeading}
                    >
                      {heading.label}
                      <ArrowDropDownIcon
                        sx={{
                          width: "25px",
                          fontSize: "2rem",
                        }}
                      />
                    </span>
                  )}

                  {heading.selectable_header && (
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      sx={{
                        ".MuiMenu-paper": {
                          marginTop: "0.6rem",
                          marginLeft: "2rem",
                          // top: '886px',
                          // left: '1058px',
                          boxShadow: "0px 6px 24px #00000017",
                        },
                      }}
                      onClose={handleCloseDropdownHeading}
                    >
                      {heading.selectable_header &&
                        quoteOptions?.map((option, index) => {
                          return (
                            <MenuItem
                              sx={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "1.4rem",
                                fontFamily: "gilroyMedium",
                              }}
                              key={index}
                              onClick={() => {
                                handleCloseDropdownHeading();
                                heading.selectable_header &&
                                  heading?.onClick &&
                                  heading?.onClick(
                                    option?.toLowerCase(),
                                    option
                                  );
                              }}
                            >
                              {option || <em></em>}
                            </MenuItem>
                          );
                        })}
                    </Menu>
                  )}
                </th>
              ))}
              {actions && (
                <th
                  className={`${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                  style={{ textAlign: "center" }}
                >
                  Actions
                </th>
              )}
              {downloadSendmailForPo && (
                <>
                  <th
                    className={`${roles.Admin && styles.defaultHead} ${
                      roles.Buyer && styles.buyerHead
                    } ${roles.Vendor && styles.vendorHead}`}
                    style={{ textAlign: "center" }}
                  >
                    File Download
                  </th>
                  <th
                    className={`${roles.Admin && styles.defaultHead} ${
                      roles.Buyer && styles.buyerHead
                    } ${roles.Vendor && styles.vendorHead}`}
                    style={{ textAlign: "center" }}
                  ></th>
                </>
              )}
              {file && (
                <th
                  className={`${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                >
                  File
                </th>
              )}
              {timeline && (
                <th
                  className={`${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                >
                  Time line
                </th>
              )}
              {selectBidBtn && (
                <th
                  className={`${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                ></th>
              )}
              {ownBidHeading && (
                <th
                  className={`${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                ></th>
              )}
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {data &&
                data?.map((row, i) => (
                  <tr
                    key={row.id}
                    className={`${
                      row?.bid_version_int === maxBidIterationCount &&
                      isSelected(row.token)
                        ? styles.activeRow
                        : ""
                    }`}
                    // className={`${
                    //   isSelected(row.token) ? styles.activeRow : ""
                    // }`}
                  >
                    {selection && (
                      <td>
                        <Checkbox
                          sx={{ "& .MuiSvgIcon-root": { fontSize: "22px" } }}
                          checked={
                            row?.bid_version_int === maxBidIterationCount
                              ? isSelected(row.token)
                              : false
                            // isSelected(row.token)
                          }
                          onChange={() => handleRowSelect(row.token, row)}
                          //to disable the old iteration rowsselect
                          disabled={
                            row?.bid_version_int !== maxBidIterationCount
                          }
                        />
                      </td>
                    )}
                    {headings.map((heading) => (
                      <td
                        key={heading.field}
                        style={{
                          width:
                            heading.label === "Status" ||
                            (heading.label === "Bid Status" && "150px"),
                          verticalAlign: heading.field === "role" && "middle",
                        }}
                      >
                        {heading.label === "Status" &&
                        heading.additional_data !== "toggle" ? (
                          <span
                            className={
                              row.bid_status === "open"
                                ? styles.activeStatus
                                : row.bid_status === "closed" ||
                                  row.bid_status === "cancelled" ||
                                  row.bid_status === "failed"
                                ? styles.InActiveStatus
                                : styles.activeStatus
                            }
                          >
                            {row.bid_status || "Active"}
                          </span>
                        ) : heading.label === "Bid Status" ? (
                          <span
                            className={
                              row.status === "success" && styles.successBid
                            }
                          >
                            {row.status && (
                              <img
                                src={bidSuccessImg}
                                className={styles.bidSuccessImg}
                                alt=""
                              />
                            )}{" "}
                            {row.status ? row.status : ""}
                          </span>
                        ) : heading.additional_data === "full_profile" ? (
                          <div className={styles.prName}>
                            <img
                              src={row.profile_image || profileImg}
                              alt={row.first_name}
                              className={styles.profile_image}
                            />
                            <span>{row[heading.field]}</span>
                          </div>
                        ) : heading.label === "Vendor" &&
                          vendorNameConfidential &&
                          ownBid &&
                          ownBid[0]?.vendor !== row?.vendor ? (
                          <span>
                            {typeof row[heading.field] === "string" &&
                              row[heading.field]?.slice(0, 1)}
                            *****
                          </span>
                        ) : vendorConfidential &&
                          ownBid &&
                          ownBid[0]?.vendor !== row?.vendor ? (
                          <span>
                            {typeof row[heading.field] === "string" &&
                              row[heading.field]?.slice(0, 1)}
                            ***
                          </span>
                        ) : heading.additional_data === "toggle" ? (
                          <span className={`${styles.flex}`}>
                            <SwitchButton
                              switchID={i}
                              onToggle={() => toggleOnChange(row.token, row)}
                              colorOn={"#59CE72"}
                              colorOff={"#CC0000"}
                              isOn={row.status}
                            />
                          </span>
                        ) : heading.dowloadFile ? (
                          <span>
                            <a
                              onClick={() => {
                                let fileName = heading.compare_analytics
                                  ? row.file_name
                                  : row[heading.field]?.split("/");
                                saveAs(
                                  row[heading.field],
                                  heading.compare_analytics
                                    ? fileName
                                    : fileName[fileName?.length - 1]
                                );
                              }}
                            >
                              <ImgFrame
                                imgSrc={fileIcon}
                                customClass={styles.fileBtn}
                              />
                            </a>
                          </span>
                        ) : heading.cellType === "view" ? (
                          <span
                            className={styles.viewDescriptionBtn}
                            onClick={() => {
                              handleOpenViewDescription(row[heading.field]);
                            }}
                          >
                            View
                          </span>
                        ) : (
                          <span>
                            {row[heading.field] ||
                              `${
                                (heading.label === "Quote Iteration" &&
                                  row.version === null) ||
                                row.version === 0 ||
                                (row.version === "" && hasBidIteration)
                                  ? "Initial"
                                  : "-"
                              }`}
                          </span>
                        )}
                      </td>
                    ))}

                    {actions && (
                      <td className={styles.action}>
                        {viewBtn && (
                          <ImgFrame
                            imgSrc={viewIcon}
                            ImgClick={() => {
                              viewClick(row.token, row);
                            }}
                            customClass={styles.actBtn}
                          />
                        )}

                        {editBtn && (
                          <ImgFrame
                            imgSrc={editIcon}
                            ImgClick={() =>
                              itemsTable && !row.status
                                ? ""
                                : editClick(row.token, row)
                            }
                            customClass={`${styles.actBtn} ${
                              itemsTable && !row.status && styles.disabled
                            }`}
                          />
                        )}

                        {deleteBtn && (
                          <ImgFrame
                            imgSrc={dltIcon}
                            ImgClick={() => deleteClick(row.token, row)}
                            customClass={styles.actBtn}
                          />
                        )}
                      </td>
                    )}

                    {downloadSendmailForPo && (
                      <>
                        <td style={{ width: "13rem" }}>
                          {sendMail && (
                            <div style={{ textAlign: "center" }}>
                              <ImgFrame
                                imgSrc={downloadIcon}
                                customClass={`${styles.fileBtn} ${styles.marginAuto}`}
                                ImgClick={() => downloadClick(row.token, row)}
                              />
                            </div>
                          )}
                        </td>
                        <td style={{ width: "16rem" }}>
                          {Download && (
                            <DButton
                              label="SEND EMAIL"
                              btnHeight="3.2rem"
                              btnWidth="12rem"
                              vendorBtn={true}
                              customClass={styles.selectBidBtn}
                              onClick={() => {
                                handleClickSendPoEmail(i, row);
                              }}
                              disabled={
                                bulkData &&
                                checkIsBidSelected
                                  ?.map((e) => e === "selected")
                                  ?.filter((e) => e === true)?.length !== 0
                              }
                            />
                          )}
                        </td>
                      </>
                    )}

                    {(file && org) ||
                    buyer ||
                    (ownBid && ownBid[0]?.vendor === row?.vendor) ? (
                      row.document !== "None" &&
                      row.document !== null &&
                      row.document !== "" ? (
                        <td>
                          <span>
                            <a
                              onClick={() => {
                                let fileName = row?.document?.split("/");
                                let replaceStringtoURL = row.document.replace(
                                  "https://teckost.s3.amazonaws.com/",
                                  `${cloudFrontURL}/`
                                );
                                // saveAs(`${cloudFrontURL}/${row.document}`, fileName[fileName?.length - 1])
                                saveAs(
                                  `${replaceStringtoURL}`,
                                  fileName[fileName?.length - 1]
                                );
                              }}
                            >
                              <ImgFrame
                                imgSrc={fileIcon}
                                customClass={styles.fileBtn}
                              />
                            </a>
                          </span>
                        </td>
                      ) : (
                        <td>
                          {" "}
                          <span
                            className={styles.viewDescriptionBtn}
                            onClick={() => {
                              handleOpenViewDescription(row.description);
                            }}
                          >
                            View
                          </span>
                        </td>
                      )
                    ) : (
                      ""
                    )}
                    {timeline && (
                      <td>
                        <Badge
                          color="error"
                          variant="dot"
                          invisible={invisible}
                          size="small"
                          onClick={() => {
                            viewTimeLineClick(row.token, row);
                          }}
                        >
                          <ImgFrame
                            imgSrc={timelineIcon}
                            customClass={styles.timeicon}
                          />
                        </Badge>
                      </td>
                    )}

                    {ownBid && ownBid[0]?.vendor === row?.vendor && (
                      <td className={styles.ownBid}>
                        <ImgFrame
                          imgSrc={ownBidIcon}
                          customClass={styles.ownBidImage}
                        />
                        <span
                          style={{
                            marginLeft: "1rem",
                          }}
                        >
                          Own Bid
                        </span>
                      </td>
                    )}

                    {selectBidBtn && (
                      <td style={{ width: "16rem" }}>
                        <div
                          className={`${styles.flex} ${styles.SelectBidSection}`}
                        >
                          {!isSelectedBid(i) &&
                          (row.status === null ||
                            row.status === "unselected") ? (
                            <DButton
                              label="SELECT BID"
                              btnHeight="3.2rem"
                              btnWidth="12rem"
                              vendorBtn={true}
                              customClass={styles.selectBidBtn}
                              onClick={() => {
                                handleSelectBid(i, row);
                              }}
                              disabled={
                                bulkData &&
                                checkIsBidSelected
                                  ?.map((e) => e === "selected")
                                  ?.filter((e) => e === true)?.length !== 0
                              }
                            />
                          ) : row.status === "selected" ? (
                            <>
                              <span className={styles.bidSelectedText}>
                                SELECTED
                              </span>
                              <DButtonOutlined
                                label="Unselect Bid"
                                btnHeight="3.2rem"
                                btnWidth="14rem"
                                customClass={styles.unSelectBidBtn}
                                onClick={() => handleUnselectBid(i, row)}
                              />
                            </>
                          ) : (
                            <DButton
                              label="SELECT BID"
                              btnHeight="3.2rem"
                              btnWidth="12rem"
                              vendorBtn={true}
                              customClass={styles.selectBidBtn}
                              onClick={() => {
                                handleSelectBid(i, row);
                              }}
                              disabled={
                                bulkData &&
                                checkIsBidSelected
                                  ?.map((e) => e === "selected")
                                  ?.filter((e) => e === true)?.length !== 0
                              }
                            />
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          )}
        </table>
      </div>

      {isLoading && (
        <span className={styles.infoMsg}>
          <CircularProgress
            thickness={6}
            variant="indeterminate"
            disableShrink
            sx={{
              animationDuration: "450ms",
              color: "#55555580",
            }}
            size={25}
          />
        </span>
      )}

      {errorMessage && (
        <span className={styles.infoMsg}>
          {errorMessage || "No data found."}
        </span>
      )}

      <Modal
        open={descriptionPopup.open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
        sx={{
          overflow: "auto",
        }}
      >
        <Fade in={descriptionPopup.open}>
          <div className={styles.flex}>
            <PopupFrame
              customClass={styles.VUPopup}
              inputChildren={viewDescriptionContent}
              poupHeading={viewDescriptionHeading}
              actionButtons={viewDescriptionAction}
              closePopup={handleCloseViewDescription}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default memo(DataTable);
