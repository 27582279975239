import React, { memo, useEffect, useState } from "react";
import styles from "./DashboardInput.module.css";
import { ThemeProvider } from "@emotion/react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { createTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const DDateInput = ({
  label,
  disabledInput,
  fromDate,
  disablePast,
  disableFuture,
  value,
  onChange,
  error,
  errorMsg,
  customClass,
  size,
}) => {
  const theme = createTheme({
    typography: {
      htmlFontSize: 12,
      fontFamily: "gilroyMedium",
    },
  });

  const getFormattedDate = (date) => {
    if (date instanceof dayjs) {
      return date.format("YYYY-MM-DD HH:mm:ss.SSSSSS");
    }
    return "";
  };

  return (
    <div
      className={`${styles.d_input_container} ${customClass} ${
        disabledInput && styles.diabledField
      }`}
      style={{ width: `${size}` }}
    >
      <label>{label}</label>
      <div
        className={`${styles.d_datepicker_wrapper} ${
          error ? styles.error : ""
        }`}
      >
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              disabled={disabledInput}
              value={value.value ? dayjs(value.value) : null}
              minDate={fromDate && dayjs(fromDate)}
              disablePast={disablePast || false}
              disableFuture={disableFuture || false}
              onChange={(date) => {
                const formattedDate = getFormattedDate(date);
                onChange(formattedDate);
              }}
              className={`${styles.d_datepicker}`}
              sx={{
                ".MuiOutlinedInput-root": {
                  height: "4.2rem",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              slotProps={{
                // disabling key press
                textField: {
                  onKeyUp: (e) => {
                    e.preventDefault();
                  },
                  onKeyDown: (e) => {
                    e.preventDefault();
                  },
                  onSelect: (e) => {
                    e.preventDefault();
                  },
                },
              }}
              renderInput={(params) => <input {...params} />}
              inputFormat="yyyy-MM-dd HH:mm:ss.SSSSSS"
            />
          </LocalizationProvider>
        </ThemeProvider>
      </div>

      {error && <p className={styles.error_message}>{errorMsg}</p>}
    </div>
  );
};

export default memo(DDateInput);
