import React, { memo } from "react";
import styles from "./SwitchButton.module.css";
const SwitchButton = ({ isOn, onToggle, colorOff, colorOn, switchID }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={onToggle}
        className={`${styles.switch_checkbox}`}
        id={switchID}
        type="checkbox"
      />
      <label
        style={{ background: isOn ? colorOn : colorOff }}
        className={`${styles.switch_label}`}
        htmlFor={switchID}
      >
        <span className={`${styles.switch_button}`} />
      </label>
    </>
  );
};

export default memo(SwitchButton);
