import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { Box, Modal } from "@mui/material";
import Typography from "@mui/material/Typography";
import { JSONTree } from "react-json-tree";
import html2pdf from "html2pdf.js";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

//component
import useDynamicTable from "../../../hooks/useDynamicTable";
import BrandLogo from "../../../components/common/BrandLogo/BrandLogo";
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DButtonOutlined from "../../Dashboard/DashboardButtons/dButtonOutlined";
//css
import classes from "./comparetable.module.css";
//asset
import logoImg from "../../../assets/images/svg/Logo.svg";
import rightArrowIcon from "../../../assets/images/svg/view-details-right-arrow-blue.svg";
import refreshIcon from "../../../assets/images/svg/refreshIcon.svg";
import DropdownBtnOutlined from "../../Dashboard/DropdownButtons/DropdownBtnOutlined/DropdownBtnOutlined";
import DropdownBtn from "../../Dashboard/DropdownButtons/DropdownBtn/DropdownBtn";
const ViewButtom = ({ label, showIcon, icon, buttonClass, viewDetails }) => {
  return (
    <button className={buttonClass || ""} onClick={viewDetails}>
      {label}
      {showIcon && (
        <img src={icon} className={classes.btnIcon} alt="arrow icon" />
      )}
    </button>
  );
};

const ComparedCard = ({ data, viewDetails }) => {
  const { Input_data, Output_data, Title } = data;
  let title = Title;
  let inputDataParse = Input_data;
  let outputDataParse;
  let outputDataParseError;
  try {
    outputDataParse = JSON.parse(Output_data);
  } catch (error) {
    outputDataParseError = Output_data;
  }
  return (
    <>
      {inputDataParse && (
        <div className={classes.comparedFileItem}>
          <p className={classes.comparedFileTitle}>{data.Title} INPUT</p>
          <ViewButtom
            label="VIEW DETAILS"
            showIcon={true}
            icon={rightArrowIcon}
            buttonClass={classes.viewDetailsBtn}
            viewDetails={() => viewDetails(inputDataParse, "text", title)}
          />
        </div>
      )}
      {outputDataParse ? (
        <div className={classes.comparedFileItem}>
          <p className={classes.comparedFileTitle}>{data.Title} OUTPUT</p>
          <ViewButtom
            label="VIEW DETAILS"
            showIcon={true}
            icon={rightArrowIcon}
            buttonClass={classes.viewDetailsBtn}
            viewDetails={() => viewDetails(outputDataParse, "json", title)}
          />
        </div>
      ) : (
        <div className={classes.comparedFileItem}>
          <p className={classes.comparedFileTitle}>{data.Title} OUTPUT</p>
          <ViewButtom
            label="VIEW DETAILS"
            showIcon={true}
            icon={rightArrowIcon}
            buttonClass={classes.viewDetailsBtn}
            viewDetails={() =>
              viewDetails(outputDataParseError, "error", title)
            }
          />
        </div>
      )}
    </>
  );
};
export default function ComparedTable({
  data,
  compareTitle,
  compareJsonFile,
  backButtonStatus = true,
  showHeader = true,
  isRefetchData = false,
  isLoading = false,
  handleRefetchData,
  isAutoRefresh = false,
}) {
  const customTheme = {
    tree: {
      backgroundColor: "transparent",
      fontSize: "14px",
    },
    nestedNode: {
      color: "#343434", // Color for nested nodes
    },
    value: {
      color: "#343443", // Color for values
    },
    key: {
      color: "#343434", // Color for keys
    },
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "900px",
    height: "calc(70vh - 30px)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
    p: 4,
  };
  //roles
  const loginData = useSelector((state) => state.loginData.value);
  const updatedData = useDynamicTable(data); // key formated hook
  const [loadingState, setLoadingState] = useState(false);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    data: [],
    fileType: "",
    btnLabel: "",
  });
  const [copySuccess, setCopySuccess] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    textMessage: "JSON copied to clipboard!",
  });
  const { vertical, horizontal, open, textMessage } = copySuccess;
  const checkRole = (data) => {
    if (!data) return "admin"; //empty data means return empty
    switch (data?.access_type) {
      case "admin":
      case "Admin":
        return "admin";
      case "buyer":
      case "Buyer":
        return "buyer";
      case "vendor admin":
      case "Vendor admin":
      case "vendor contact":
      case "Vendor contact":
        return "Vendor";
      default:
        return "admin";
    }
  };
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const parts = formatter.formatToParts(date);

    const month = parts.find((part) => part.type === "month").value;
    const day = parts.find((part) => part.type === "day").value;
    const year = parts.find((part) => part.type === "year").value;
    const hour = parts.find((part) => part.type === "hour").value;
    const minute = parts.find((part) => part.type === "minute").value;

    const ampm = date.getHours() >= 12 ? "PM" : "AM";
    const formattedDateString = `${day} ${month} ${year} - ${hour}:${minute} ${ampm}`;

    return formattedDateString;
  }
  useEffect(() => {
    if (!isAutoRefresh) return;
    const intervalId = setInterval(() => {
      handleRefetchData();
    }, 60 * 1000); //hourse * ms
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  let downloadPdf = () => {
    setLoadingState(true);
    // setOrganizationDetailspdfUI(true)
    const element = document.getElementById("compare_result_view");
    let options = {
      margin: [2, 5], // Set the top and bottom margins to 10mm for the first page
      jsPDF: {
        unit: updatedData?.length < 5 ? "" : "",
        format: updatedData?.length < 5 ? "a4" : [element.offsetWidth, 595.28],
        orientation: "landscape",
        margins: {
          top: 5,
          bottom: 5,
          left: 5,
          right: 5,
        },
      },
      // Callback function to modify the jsPDF object before rendering the PDF
      onBeforeSendPDF: function (pdf, renderer, options) {
        // Set the top and bottom margins to 20mm for the second page and beyond
        pdf.setPage(options.pageNumber - 1);
        if (options.pageNumber > 1) {
          pdf.setPageMargins(10, 50, 10);
        }
      },
      filename: `compared.pdf`,
    };
    setTimeout(() => {
      html2pdf().set(options).from(element).save();
      setLoadingState(false);
      // setOrganizationDetailspdfUI(false)
    }, 2000);
  };
  const exportToExcel = () => {
    let data = updatedData;
    if (data.length === 0) return;

    // Extract keys and values
    const headers = Object.keys(data[0]);
    const values = headers.map((key) => data.map((item) => item[key]));

    // Prepare the data for the Excel file with a heading
    const heading = ["TECHNO-COMMERCIAL COMPARISON STATEMENT"]; // Customize your heading text here
    const transformedData = [
      heading,
      ["", "RFQ Specification", ...data.map((item) => item.serial_number)],
    ];

    for (let i = 0; i < headers.length; i++) {
      transformedData.push([headers[i], ...values[i]]);
    }
    // Create the worksheet and workbook
    const ws = XLSX.utils.aoa_to_sheet(transformedData);

    // Apply the bold style to the "RFQ Specification" cell
    const boldStyle = { font: { bold: true } };

    // Set the style for "RFQ Specification" cell and heading
    ws["B2"] = { v: "RFQ Specification", s: boldStyle };
    ws["A1"] = { v: heading[0], s: boldStyle };

    // Merge cells for the heading
    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: headers.length + 1 } }, // Adjust the merge range as needed
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Write the workbook and trigger the download
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, `compared.xlsx`);
    // let data = updatedData;
    // if (data.length === 0) return;

    // // Extract keys and values
    // const headers = Object.keys(data[0]);
    // const values = headers.map((key) => data.map((item) => item[key]));

    // // Prepare the data for the Excel file
    // const transformedData = [];
    // for (let i = 0; i < headers.length; i++) {
    //   transformedData.push([headers[i], ...values[i]]);
    // }
    // // Create the worksheet and workbook
    // const ws = XLSX.utils.aoa_to_sheet(transformedData);

    // // Apply the bold style to the "RFQ Specification" cell
    // const boldStyle = { font: { bold: true } };

    // // Set the style for "RFQ Specification" cell
    // ws["B1"] = { v: "RFQ Specification", s: boldStyle };

    // const wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // // Write the workbook and trigger the download
    // const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // const blob = new Blob([wbout], { type: "application/octet-stream" });
    // saveAs(blob, `compared.xlsx`);
  };
  const handleViewDetails = (data, type, modalTitle) => {
    let title;
    let buttonLabel;
    if (type === "json") {
      title = `${modalTitle} OUTPUT`;
      buttonLabel = "Copy Json";
    } else if (type === "text") {
      title = `${modalTitle} INPUT`;
      buttonLabel = "Copy text";
    } else if (type === "error") {
      title = `${modalTitle} OUTPUT`;
      buttonLabel = "Copy text";
    }
    setShowModal((state) => ({
      ...state,
      open: true,
      data: data,
      fileType: type,
      title: title,
      btnLabel: buttonLabel,
    }));
  };
  const handleCloseDetails = () => {
    setShowModal((state) => ({
      ...state,
      open: false,
      data: [],
      fileType: "",
      title: "",
      btnLabel: "",
    }));
  };
  const handleCopyJson = (type) => {
    let data =
      type === "json"
        ? JSON.stringify(showModal.data, null, 2)
        : showModal.data;
    navigator.clipboard
      .writeText(data)
      .then(() =>
        setCopySuccess((state) => ({
          ...state,
          open: true,
          textMessage: `${
            type === "json" ? "JSON" : "Text"
          } copied to clipboard!`,
        }))
      )
      .catch((err) => console.error("Failed to copy JSON: ", err));
  };
  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={2000}
        onClose={() => setCopySuccess((state) => ({ ...state, open: false }))}
        key={vertical + horizontal}
      >
        <Alert
          onClose={() => setCopySuccess((state) => ({ ...state, open: false }))}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <Typography sx={{ fontSize: "14px" }}>{textMessage}</Typography>
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingState}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {
        <div
          className={`${classes.compairResultCard} ${
            backButtonStatus ? classes.mt30 : ""
          }`}
          id="compare_result_view"
        >
          <div className={classes.compareTitle}>
            {showHeader && (
              <div className={classes.comapreTitleCreatedHistory}>
                {compareTitle.prn_number && (
                  <p className={classes.compareTitlePrnNo}>
                    <span>PRN Number</span> {compareTitle.prn_number}
                  </p>
                )}
                {compareTitle.created_date && (
                  <p className={classes.compareTitleCompareOn}>
                    <span>Compared On</span>{" "}
                    {compareTitle.created_date
                      ? formatDate(compareTitle.created_date)
                      : ""}
                  </p>
                )}
              </div>
            )}
            <div className={classes.compareTitleDownloadIconWrapper}>
              {!loadingState && updatedData?.length > 0 && (
                <div style={{ display: "flex", gap: "20px" }}>
                  <DButton
                    label={"DOWNLOAD AS PDF"}
                    btnHeight="4rem"
                    btnWidth={"18rem"}
                    buyerBtn={true}
                    onClick={downloadPdf}
                    customWrapperClass={
                      classes.compareTitleDownloadPdfBtnWrapper
                    }
                    customClass={classes.compareTitleDownloadPdfBtn}
                  />
                  {/* <DropdownBtn
                    label="DOWNLOAD AS"
                    btnHeight="4rem"
                    btnWidth="18rem"
                    loadingState={loadingState}
                    disabled={updatedData?.length === 0}
                    onClick={(fileFormat) => {
                      let fileType = fileFormat?.toLowerCase();
                      if (fileType === "pdf") {
                        downloadPdf();
                      } else if (fileType === "xlsx") {
                        exportToExcel();
                      }
                    }}
                    options={["PDF", "XLSX"]}
                    buyerBtn={true}
                  /> */}
                  {isRefetchData && (
                    <DButton
                      label={""}
                      labelImg={refreshIcon}
                      btnHeight="4rem"
                      btnWidth={"6rem"}
                      buyerBtn={true}
                      onClick={handleRefetchData}
                      customWrapperClass={
                        classes.compareTitleDownloadPdfBtnWrapper
                      }
                      customClass={classes.compareTitleDownloadPdfBtn}
                      loadingState={isLoading}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {!loadingState && (
            <Accordion
              className={classes.accordianWraper}
              sx={{
                width: "100%",
                marginBottom: "30px",
                border: "1px solid rgba(151, 151, 151, 0.2);",
                backgroundColor: "#fff",
                boxShadow: "none",
                borderRadius: ".6rem",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ fontSize: "2.2rem", color: "#343434" }}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  fontSize: "1.6rem",
                  fontFamily: "gilroySemiBold",
                  border: "none",
                  color: "rgb(58, 58, 60)",
                  minHeight: "4.8rem !important",
                }}
                className={classes.accordionSummaryWrapper}
                classes={{ content: classes.summaryContentExpand }}
              >
                View Comparison Details
              </AccordionSummary>
              <AccordionDetails
                sx={{ backgroundColor: "#fff", border: "none", padding: 0 }}
              >
                <div className={classes.comparedFiles}>
                  {compareJsonFile?.length > 0 ? (
                    compareJsonFile.map((item) => (
                      <ComparedCard
                        data={item}
                        viewDetails={handleViewDetails}
                      />
                    ))
                  ) : (
                    <p className={classes.noCompareDataAvailale}>
                      No data available.
                    </p>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          )}
          <div
            className={`${classes.compareResultCardHeaderWrapper} ${
              loadingState ? classes.removePadding : ""
            }`}
          >
            <div className={classes.compareResultCardHeader}>
              <h4>TECHNO-COMMERCIAL COMPARISON STATEMENT</h4>
              <BrandLogo
                imgSrc={logoImg}
                customClass={classes.compareHeaderlogoWrapper}
                imgClassName={classes.compareHeaderlogo}
              />
            </div>
            <div className={classes.container}>
              <div
                className={`${classes.datatable_wrapper} ${classes.comparisonTableWrapper}`}
                id="compareTableMain"
              >
                {updatedData?.length > 0 ? (
                  <table
                    className={`${classes.compareResultTable} ${
                      classes.processedTable
                    } ${
                      updatedData?.length > 5 ? classes.above5CompareRecord : ""
                    }`}
                  >
                    <tbody>
                      {Object.keys(updatedData[0]).map((key, index) => (
                        <tr key={index}>
                          <td
                            className={`
                                ${classes.stickyHead} 
                                ${classes.buyerHead}`}
                          >
                            {key}
                          </td>

                          {updatedData?.map((data, dataIndex) => (
                            <React.Fragment key={dataIndex}>
                              {dataIndex === 0 && index === 0 ? (
                                <td
                                  key={dataIndex}
                                  style={{
                                    minWidth: `${
                                      updatedData?.length < 5
                                        ? 100 / updatedData.length + "%"
                                        : "200px"
                                    }`,
                                  }}
                                >
                                  RFQ Specification {checkRole(loginData)}
                                </td>
                              ) : (
                                <td
                                  style={{
                                    minWidth: `${
                                      updatedData?.length < 5
                                        ? 100 / updatedData.length + "%"
                                        : "200px"
                                    }`,
                                  }}
                                  key={dataIndex}
                                >
                                  {typeof data[key] === "object"
                                    ? JSON.stringify(data[key])
                                    : data[key]}
                                </td>
                              )}
                            </React.Fragment>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className={classes.compareTableNoDataText}>
                    <p>No data available.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      {/* json view modal popup*/}
      <Modal
        keepMounted
        open={showModal.open}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <div className={classes.jsonViewBox}>
            <Typography variant="h4" component="h4">
              {showModal.title}
            </Typography>
            {showModal.fileType === "json" ? (
              <JSONTree data={showModal.data} theme={customTheme} />
            ) : (
              <div className={classes.viewDetailsText}>
                <p>{showModal.data}</p>
              </div>
            )}
            {/* <pre>{JSON.stringify(json, null, 2)}</pre> */}
          </div>
          <div className={classes.modalFooter}>
            <DButtonOutlined
              label="Cancel"
              btnWidth={"17rem"}
              btnHeight={"4.2rem"}
              onClick={handleCloseDetails}
              buyerBtn={true}
            />
            <DButton
              label={showModal.btnLabel}
              loadingState={false}
              btnWidth={"17rem"}
              btnHeight={"4.2rem"}
              onClick={() =>
                handleCopyJson(showModal.fileType === "json" ? "json" : "text")
              }
              buyerBtn={true}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
}
