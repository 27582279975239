import { Autocomplete, TextField, ThemeProvider, createFilterOptions, createTheme } from '@mui/material';
import React, { memo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styles from './DashboardInput.module.css';

const DFreeSoloInput = ({
    label,
    placeHolder,
    onChange,
    error,
    options,
    disabled,
    size,
    customClass,
    value,
    errorMsg
}) => {
    const theme = createTheme({
        typography: {
            htmlFontSize: 9.8,
        },
    });
    const filterOptions = createFilterOptions({
        ignoreCase: true,
        limit: 50,
    });

    const { control } = useForm();

    return (
        <ThemeProvider theme={theme}>
            <div className={`${styles.select_container} ${customClass} `} style={{ width: `${size}` }}>
                <label>{label}</label>
                <Controller
                    name="autocomplete"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            value={value}
                            freeSolo
                            disablePortal
                            options={options}
                            sx={{
                                height: '4.2rem',
                                fontSize: '1.4rem',
                                transition: "0",
                                ".Mui-expanded": {
                                    transition: "none"
                                },
                                ".MuiOutlinedInput-notchedOutline": {
                                    border: "0",
                                },
                                ".MuiAutocomplete-input": {
                                    fontSize: "1.4rem",
                                    fontFamily: "GilroyMedium",
                                    height: "2.6rem",
                                },
                            }}
                            componentsProps={{
                                popper: {
                                    sx: {
                                        fontFamily: 'gilroyMedium'
                                    },
                                    modifiers: [
                                        {
                                            name: 'flip',
                                            enabled: false
                                        },
                                        {
                                            name: 'preventOverflow',
                                            enabled: true
                                        },
                                    ]
                                }
                            }}
                            size='small'
                            className={`${styles.select_wrapper}  ${error ? styles.error : ''}`}
                            disabled={disabled}
                            filterOptions={filterOptions}
                            onChange={(e, newValue) => {
                                field.onChange(newValue);
                                if (onChange) {
                                    onChange(newValue);
                                }
                            }}
                            onInputChange={(e, newValue) => {
                                field.onChange(newValue);
                                onChange(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    // onChange={(e) => }
                                    error={error}
                                    placeholder={!placeHolder ? label : placeHolder}
                                />
                            )}
                        />
                    )}
                />
                {error && <p className={styles.error_message}>{errorMsg}</p>}

            </div>
        </ThemeProvider>
    );
};

export default memo(DFreeSoloInput);
