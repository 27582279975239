import React, { useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import customStyle from './DSearchInputDropdown.module.css';
import styles from "./DashboardInput.module.css";

const AddressSearch = ({ addresses, selectHandle, hanlderSearch, searchText }) => {

  const seachInputRef = useRef(null)
//   const [searchTerm, setSearchTerm] = useState(searchText.value ? searchText.value : '');
  const [matchedAddresses, setMatchedAddresses] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const handleSearchChange = (event) => {
    const term = event.target.value;
    // setSearchTerm(term);
    hanlderSearch(term)//props

    // Filter addresses based on the search term
    const filteredAddresses = addresses?.filter((address) =>
      address?.shipping_address1?.toLowerCase().includes(term.toLowerCase())
    );

    setMatchedAddresses(filteredAddresses);
    setDropdownOpen(true);
  };

  const handleClickAway = () => {
    setDropdownOpen(false);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={`${styles.d_input_container} ${customStyle.pRelative}`}>
        <label>Address Line 1</label>
        <TextField
          type="text"
          value={searchText.value}
          onChange={handleSearchChange}
          onClick={(e)=>{
            if(searchText.value) {
                const filteredAddresses = addresses?.filter((address) =>
                address?.shipping_address1?.toLowerCase().includes(searchText.value.toLowerCase()))
                setMatchedAddresses(filteredAddresses)
            } else {
                setMatchedAddresses(addresses)
            }
            setDropdownOpen(true)
          }}
          onKeyDown={(e)=>{
            e.keyCode === 9 && setDropdownOpen(false)
          }}
          placeholder="Address Line 1"
          className={`${styles.d_input_wrapper} ${customStyle.searchInput} ${!searchText.isValid ? customStyle.borderRed : ''}`}
          id="searchInputDropdown"
          ref={seachInputRef}
          autoComplete='off'
        />

        {/* Dropdown for matched addresses */}
        {isDropdownOpen && matchedAddresses.length > 0 && (
            <List className={customStyle.dropDown} style={{width: seachInputRef?.current?.offsetWidth, position: 'absolute'}}>
                {matchedAddresses.map((address, index) => (
                <ListItem key={index} tabIndex="-1" role="option" id=":r21:-option-0" data-option-index="0" aria-disabled="false" aria-selected="false" onClick={()=>{
                    selectHandle(address)
                    handleClickAway()
                }}>
                    {address.address}
                </ListItem>
                ))}
            </List>)
        // : 
        //     isDropdownOpen && matchedAddresses.length === 0 &&
        //     <List className={customStyle.dropDown} style={{width: seachInputRef?.current?.offsetWidth, position: 'absolute'}}>
        //         <ListItem tabindex="-1" role="option" id=":r21:-option-0" data-option-index="0" aria-disabled="false" aria-selected="false">
        //             No result found.
        //         </ListItem>
        //     </List>
        }
        {!searchText.isValid && <p className={styles.error_message}>{"Address Line 1 is required"}</p>}
      </div>
    </ClickAwayListener>
  );
};

const DSearchInputDropdown = (props) => {
    const {addresses,selectHandler,hanlderSearch,searchTerm} = props

  return <AddressSearch addresses={addresses} selectHandle={selectHandler} hanlderSearch={hanlderSearch} searchText={searchTerm}/>;
};

export default DSearchInputDropdown;