import axios from "axios";

const useFetchData = ({
  pageNumber,
  sortingColumnName,
  sortingOrder,
  endpoint,
  setData,
  setPaginationData,
  idName,
  idValue,
  method,
  searchQuery,
  startDateQuery,
  endDateQuery,
  auth_token,
  dispatch,
  tableProps,
  setTableProps,
  idParams,
  allData,
  bodyData,
}) => {
  let abortController = new AbortController();

  const fetchData = async () => {
    try {
      abortController.abort();

      const formData = new FormData();
      formData.append("page", pageNumber);
      idParams
        ? idParams?.forEach((e) => formData.append(e.key, e.value))
        : formData.append(idName, idValue);
      searchQuery && formData.append("search", searchQuery);
      startDateQuery && formData.append("start_date", startDateQuery);
      endDateQuery && formData.append("end_date", endDateQuery);
      sortingColumnName &&
        formData.append("sorting_column_name", sortingColumnName);
      sortingOrder &&
        formData.append(
          "sorting_order",
          sortingOrder === "asc" ? "ascending" : "descending"
        );

      const newAbortController = new AbortController();
      abortController = newAbortController;

      setTableProps &&
        setTableProps({
          ...tableProps,
          isLoading: true,
          errorMessage: "",
        });

      await axios({
        method: method || "POST",
        url: endpoint,
        data: bodyData ? bodyData : formData,
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
        signal: newAbortController.signal,
      })
        .then((res) => {
          const responseData = res?.data;
          dispatch
            ? dispatch(setData(responseData?.results))
            : allData
            ? setData(responseData)
            : setData(responseData?.results);
          setPaginationData(responseData);
          setTableProps &&
            setTableProps({
              ...tableProps,
              errorMessage: "",
            });
          setTableProps({
            ...tableProps,
            isLoading: false,
            errorMessage: "",
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch ? dispatch(setData([])) : setData([]);
          setPaginationData();
          setTableProps &&
            setTableProps({
              ...tableProps,
              isLoading: false,
              errorMessage: generateErrorMessage(
                err,
                "Network error! please try again later."
              ),
            });
        });
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted:", error.message);
      } else {
        console.log(error);
        dispatch ? dispatch(setData([])) : setData([]);
        setPaginationData();
        console.log(error?.response?.data?.response);
      }
    }
  };
  fetchData();

  const generateErrorMessage = (error, fallbackMessage) => {
    return typeof error?.response?.data?.response === "object" || searchQuery
      ? `"${searchQuery}" not found!`
      : error?.response?.data?.response || fallbackMessage;
  };

  return () => {
    abortController.abort();
  };
};

export default useFetchData;
