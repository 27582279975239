import React, { memo, useEffect, useState } from "react";
import styles from "./DashboardInput.module.css";
import ImgFrame from "../../common/ImageFrame/ImgFrame";
import passwordEye from "../../../assets/images/svg/Password eye.svg";
const DPasswordInput = ({
  label,
  type,
  value,
  onChange,
  error,
  errorMsg,
  customClassName,
  size,
  info,
  customWrapperClass,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div
      className={`${styles.d_input_container} ${customWrapperClass}`}
      style={{ width: `${size}` }}
    >
      <label>{label}</label>
      <div
        className={`${styles.d_input_wrapper} ${styles.d_pwd_container} ${
          error ? styles.error : ""
        } `}
      >
        <input
          type={showPassword ? "text" : type}
          value={value}
          onChange={onChange}
          className={`${customClassName}`}
          placeholder={`Enter ${label} `}
        />
        {type === "password" && (
          <button
            className={styles.password_toggle_button}
            onClick={togglePasswordVisibility}
          >
            <ImgFrame imgSrc={passwordEye} />
          </button>
        )}
      </div>
      {error && <p className={styles.error_message}>{errorMsg}</p>}
      {info && (
        <p className={styles.info}>
          NOTE : password must contain atleast <br /> 8 charcters, 1 uppercase,
          1 special character, <br /> 1 number
        </p>
      )}
    </div>
  );
};

export default memo(DPasswordInput);
