import React, { memo } from "react";
import classes from "./DetailsTab.module.css";
const DetailsTab = ({
  small,
  large,
  tabName,
  currentTab,
  clickFn,
  activeColor,
  customContainerClass,
}) => {
  return (
    <>
      {large && (
        <div
          className={`${classes.tab_wrapper} ${classes.flex} ${customContainerClass} `}
        >
          <div
            className={`${
              currentTab ? classes.tabActive : classes.tabDefault
            } ${classes.flex} ${classes.cup}`}
          >
            <span
              className={`${
                currentTab ? classes.tabTextActive : classes.tabText
              } ${classes.cup}`}
              onClick={clickFn}
            >
              {tabName}
            </span>
          </div>
        </div>
      )}

      {small && (
        <>
          <div className={`${classes.tab_wrapper_sm} ${classes.flex} `}>
            <div
              className={`${
                currentTab ? classes.tabActive_sm : classes.tabDefault_sm
              } ${classes.flex} ${classes.cup}`}
            >
              <span
                className={`${
                  currentTab ? classes.tabTextActive_sm : classes.tabText_sm
                } ${classes.cup}`}
                onClick={clickFn}
              >
                {tabName}
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default memo(DetailsTab);
