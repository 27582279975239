import React, { memo, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import timeReminder from '../../../assets/images/svg/timeReminder.svg';

const DaysReminder = ({ startDate, endDate, endTime }) => {
    const [daysRemaining, setDaysRemaining] = useState(0);

    const calculateDaysRemaining = () => {
        const currentDate = dayjs(startDate);
        const examDateTime = dayjs(`${endDate} ${endTime}`, 'YYYY-MM-DD HH:mm:ss.SSS');
        const examDate = new Date(examDateTime);
        const timeDifference = Math.abs(examDate - currentDate);
        const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        setDaysRemaining(daysRemaining);
    };

    useEffect(() => {
        calculateDaysRemaining();
    }, [startDate, endDate, endTime]);

    const formattedEndDateTime = dayjs(`${endDate} ${endTime}`, 'YYYY-MM-DD HH:mm:ss.SSS');
    const formattedTime = dayjs(`${endTime}`).format('h:mm A');

    return (
        <div>
            <p style={styles.bid_closing_text}>
                <img src={timeReminder} style={styles.bid_closing_image} />
                This bid will close on {formattedEndDateTime.format('D MMM YY')} at {formattedTime},
                in {daysRemaining} {daysRemaining === 1 ? 'day' : 'days'}
            </p>
        </div>
    );
};

const styles = {
    bid_closing_text: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'gilroyMedium',
        fontSize: '16px',
        color: '#3A3A3C',
        textAlign: 'left',
        margin: '1rem',
    },
    bid_closing_image: {
        paddingRight: '1rem',
    },
};

export default memo(DaysReminder);
