import React, { useState } from "react";
import classes from "./Header.module.css";
import { Drawer } from "@mui/material";
import { Close, MenuOpen } from "@mui/icons-material";
import { Link } from "react-scroll";
import DropDownButton from "../HomeButtons/DropDownButton";
import { NavLink, useNavigate } from "react-router-dom";
import SignupModal from "../SignupModal/SignupModal";

const NavLinks = () => {
  const [open, setOpen] = useState(false);

  const [selectedRole, setSelectedRole] = useState("");

  const handleRoleChange = (role) => {
    setSelectedRole(role);
  };
  const [openSignupOptions, setOpenSignupOptions] = useState(false);
  const handleCloseSignupOptions = () => {
    setOpenSignupOptions(false);
    setSelectedRole("");
  };
  const navigate = useNavigate();

  const loginOptions = [
    {
      path: "login",
      path_name: "Buyer login",
    },
    {
      path: "login",
      path_name: "Vendor login",
    },
  ];
  const signupOptions = [
    {
      path: "buyer_organization_signup",
      path_name: "Buyer Organization Signup",
    },
    {
      path: "vendor_signup",
      path_name: "Vendor Signup",
    },
  ];
  const linkActiveStyles = {
    color: "#3D4BD6",
    borderBottom: "2px solid #3D4BD6",
  };
  return (
    <div>
      <div className={`${classes.links} ${classes.flex}`}>
        <li>
          <Link
            onClick={() => navigate("/")}
            spy={true}
            activeStyle={linkActiveStyles}
            to="heroContent"
            duration={500}
            offset={-120}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            onClick={() => navigate("/")}
            spy={true}
            activeStyle={linkActiveStyles}
            to="features"
            duration={500}
            offset={-120}
          >
            Features
          </Link>
        </li>

        <li>
          <Link
            onClick={() => navigate("/")}
            spy={true}
            activeClass={classes.active_link}
            activeStyle={linkActiveStyles}
            to="contact_us"
            duration={500}
            offset={-100}
          >
            Contact Us
          </Link>
        </li>
        <DropDownButton
          label={"Signup"}
          noOptions={true}
          mainClick={() => {
            setOpenSignupOptions(true);
          }}
        />
        <DropDownButton
          label={"Login"}
          noOptions={true}
          mainClick={() => navigate("/login")}
        />
      </div>

      <button onClick={() => setOpen(true)} className={classes.menuOpener}>
        <MenuOpen className={classes.menuOpenerIcon} />{" "}
      </button>

      <Drawer open={open} anchor={"right"} className={classes.menuDraw}>
        <div className={classes.menuTab}>
          <div className={`${classes.MenuLinks} ${classes.flex}`}>
            <li>
              <Link
                onClick={() => navigate("/")}
                spy={true}
                to="heroContent"
                duration={500}
                offset={-120}
                activeStyle={linkActiveStyles}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="features"
                spy={true}
                onClick={() => navigate("/")}
                duration={500}
                offset={-120}
                activeStyle={linkActiveStyles}
              >
                Features
              </Link>
            </li>
            {/* <li><Link to="about_us">About Us</Link></li> */}
            <li>
              <Link
                to="contact_us"
                onClick={() => navigate("/")}
                spy={true}
                duration={500}
                offset={-100}
                activeStyle={linkActiveStyles}
              >
                Contact Us
              </Link>
            </li>
          </div>
          <div className={classes.lgBtn}>
            <DropDownButton
              label={"Signup"}
              noOptions={true}
              mainClick={() => {
                setOpenSignupOptions(true);
              }}
            />
            <DropDownButton
              label={"Login"}
              noOptions={true}
              mainClick={() => navigate("/login")}
            />
          </div>
        </div>
        <button onClick={() => setOpen(false)} className={classes.closeButton}>
          <Close className={classes.closeButtonIcon} />
        </button>
      </Drawer>

      <SignupModal
        openSignupOptions={openSignupOptions}
        selectedRole={selectedRole}
        handleCloseSignupOptions={handleCloseSignupOptions}
        setOpenSignupOptions={setOpenSignupOptions}
        handleRoleChange={handleRoleChange}
      />
    </div>
  );
};

export default NavLinks;
