//compared response object flatten
function flattenNestedObjects(data) {
  const newData = [];
  data.forEach((item) => {
    const flattenedItem = {};
    Object.keys(item).forEach((key) => {
      if (typeof item[key] === "object" && item[key] !== null) {
        Object.keys(item[key]).forEach((subKey) => {
          if (
            typeof item[key][subKey] === "object" &&
            item[key][subKey] !== null
          ) {
            Object.keys(item[key][subKey]).forEach((innerKey) => {
              const newKey = `${key} ${subKey} ${innerKey}`;
              flattenedItem[newKey] = item[key][subKey][innerKey];
            });
          } else {
            const newKey = `${key} ${subKey}`;
            flattenedItem[newKey] = item[key][subKey];
          }
        });
      } else {
        flattenedItem[key] = item[key];
      }
    });
    newData.push(flattenedItem);
  });
  return newData;
}
export { flattenNestedObjects };
