import React, { memo, useEffect, useState } from "react";
import styles from "./DashboardInput.module.css";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

const DPhoneNumberInput = ({
  label,
  type,
  value,
  placeholder,
  customClassNameForInput,
  disabled,
  onChange,
  error,
  errorMsg,
  customClassName,
  size,
}) => {
  const [inputValue, setInputValue] = useState();
  const [onchanged, setOnchanged] = useState(false);
  // const [isValidMobileNumber, setIsValidMobileNumber] = useState(false);
  // const [isCleared, setIsCleared] = useState();

  // useEffect(() => {
  //   setIsCleared(value && value.length === 0 ? true : false);
  // }, []);

  // console.log(value);

  let splitVal = value && value?.split(" ");

  useEffect(() => {
    if (value && value?.includes("+") && onchanged) {
      let valueShallowCopy = "";
      splitVal &&
        splitVal?.map((e, i) => {
          if (i !== 0) {
            valueShallowCopy += e;
          }
        });
      let sanitizedValue = valueShallowCopy.replace(/[^\w\s]/gi, "");
      setInputValue(sanitizedValue);
    } else {
      setInputValue(value);
    }
  }, [value]);

  return (
    <div
      className={`${styles.d_input_container} ${
        disabled ? styles.diabledField : ""
      } ${customClassName}`}
      style={{ width: `${size}` }}
    >
      <label>{label}</label>
      <div className={`${styles.d_input_wrapper} ${error ? styles.error : ""}`}>
        <IntlTelInput
          onPhoneNumberChange={(
            isValidMobileNumber,
            value,
            countryObject,
            valueWithCode
          ) => {
            // console.log(
            //   isValidMobileNumber,
            //   value,
            //   countryObject,
            //   valueWithCode
            // );
            setOnchanged(true);
            // setIsValidMobileNumber(isValidMobileNumber);
            onChange &&
              onChange(
                value,
                countryObject,
                isValidMobileNumber,
                valueWithCode
              );
          }}
          onSelectFlag={(
            value,
            countryObject,
            valueWithCode,
            isValidMobileNumber
          ) => {
            let countryCodeSplitted = value && value?.split(" ");
            let countryCode =
              countryCodeSplitted[0] === `+${countryObject.dialCode}`;

            onChange(
              value,
              countryObject,
              isValidMobileNumber,
              countryCode === false ? "" : value
            );
          }}
          defaultCountry="in"
          value={inputValue}
          autoHideDialCode={true}
          format={false}
          // separateDialCode={true}
        />

        {/* <PhoneInput
          value={value}
          onChange={onChange}
          enableSearch={false}
          copyNumbersOnly={false}
          autoFormat={true}
          disableSearchIcon={true}
          autocompleteSearch={true}
          disableInitialCountryGuess={false}
          disableCountryGuess={false}
          enableAreaCodes={true}
          disableCountryCode={false}
          country={"in"}
          countryCodeEditable={true}
          specialLabel=""
          searchNotFound="No country codes found."
          placeholder={`Enter ${label}`}
          // containerClass={!error ? styles.phoneInputContainerStyle : styles.phoneInputStyleError}
          inputClass={styles.phoneInputStyle}
          dropdownClass={styles.phoneInputDropDownStyle}
          inputProps={{ required: true }}
          searchStyle={{
            fontFamily: "Source Sans Regular",
            width: "95%",
            border: "1px solid #adadad50",
            padding: ".6rem 1.5rem",
          }}
        /> */}
      </div>

      {error && <p className={styles.error_message}>{errorMsg}</p>}
    </div>
  );
};

export default memo(DPhoneNumberInput);
