import React, { memo } from 'react'
import classes from '../ChartStyles.module.css'
import LinearProgressBar from '../../LinearProgress/LinearProgressBar';
import ChartIndicator from '../../ChartIndicator/ChartIndicator';
import ReactApexChart from 'react-apexcharts';
import { Skeleton } from '@mui/material';

const DonutChart = ({ chartValues, loadingState, chartDataSet, orgDashboard, customCardClass }) => {

    let plotOptionsForBuyers = {
        pie: {
            startAngle: -140,
            endAngle: 140,
            expandOnClick: false,
            customScale: 1,
            donut: {
                size: '52%',
            }
        }
    }
    let plotOptionsForOrg = {
        pie: {
            startAngle: -90,
            endAngle: 90,
            expandOnClick: false,
            customScale: 1.1,
            offsetY: 20,
            donut: {
                size: '68%',
            }
        }
    }

    let donutChartOptions = {
        series: chartValues,
        options: {
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                }
            },
            chart: {
                type: 'donut',
                offsetY: 3,
                sparkline: {
                    enabled: true
                }
            },
            labels: chartDataSet?.chartLabels,
            plotOptions: orgDashboard ? plotOptionsForOrg : plotOptionsForBuyers,
            style: {
                fontSize: '14px',
                fontFamily: 'gilroyBold',
                colors: ['#333'],
            },
            background: {
                enabled: true,
                borderRadius: 4,
                padding: 4,
                opacity: 0.8,
                color: '#fff'
            },
            fill: {
                colors: chartDataSet?.barColors,
                type: 'solid'
            },
            legend: {
                show: false
            }
        }
    };

    return (
        <div className={`${classes.graphCard} ${customCardClass}`} >
            {
                !loadingState ?
                    <>

                        <div className={classes.linearProgress}>
                            <span>{chartDataSet?.cardHeading}</span>
                            <div className={classes.indicators}>
                                {
                                    chartDataSet?.chartIndicators?.map((e) => (
                                        <span key={e.value}><ChartIndicator label={e.value} color={e.color} /></span>
                                    ))
                                }
                            </div>

                            {
                                chartDataSet?.progressLines?.map((e) => (
                                    <span key={e.value}><LinearProgressBar label={e.label} progress={e.value} color={e.color} /></span>
                                ))
                            }
                        </div>

                        <div className={classes.chartWrap}>

                            <ReactApexChart options={donutChartOptions.options} series={donutChartOptions.series} type="donut" />

                            <span className={classes.info}>{chartDataSet?.chartText}</span>

                        </div>
                    </>
                    :
                    <>

                        <div className={classes.linearProgress} >
                            <span><Skeleton /></span>
                            <div className={classes.indicators}>
                                <Skeleton width={"70%"} />
                            </div>

                            <span><Skeleton width={"90%"} height={'150px'} /></span>
                        </div>

                        <div className={`${classes.flex} ${classes.roundSkeleton}`}>

                            <Skeleton width={"150px"} height={"150px"} variant="circular" />

                        </div>
                    </>
            }
        </div>
    )
}

export default memo(DonutChart);