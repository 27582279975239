import React, { memo, useState } from "react";
//img
import searchImg from "../../../assets/images/svg/Searchtech.svg";
//css
import classes from "./SearchBar.module.css";
import ImgFrame from "../ImageFrame/ImgFrame";

const SearchBar = ({ onChange, value, customClass }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={`${classes.search_wrapper} ${customClass} flex`}>
      <input
        type="text"
        value={value}
        placeholder="Search"
        onChange={handleChange}
      />
      <ImgFrame imgSrc={searchImg} imgSize={"2rem"} />
    </div>
  );
};

export default memo(SearchBar);
