import React, { useEffect, useState } from "react";
//img
import btnImg from "../../../assets/images/svg/Search.svg";
import plusIcon from "../../../assets/images/svg/plusIcon.png";
import { Box, CircularProgress, Fade, Modal } from "@mui/material";
//css
import classes from "./RFQTab.module.css";
import styles from "../../TabStyles/DashboardTab.module.css";
//components
import SearchBar from "../../../components/common/SearchBar/SearchBar";
import DateBar from "../../../components/common/DateBar/DateBar";
import NotificationBell from "../../../components/common/NotificationBell/NotificationBell";
import ProfileCard from "../../../components/common/ProfileCard/ProfileCard";
import HeaderTitle from "../../../components/common/HeaderTitle/HeaderTitle";
import PaginantionComponent from "../../../components/common/Pagination/PaginationComponent";
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DataTable from "../../../components/common/DataTable/DataTableSorting";
import InfoLabel from "../../../components/common/InfoLabel/InfoLabel";
import SuccessPopupDashboard from "../../../components/Popups/PopupFrame/SuccessPopupDashboard";
import bidUnSelectedImg from "../../../assets/images/png/dislike@2x.png";
import bidSelectedImg from "../../../assets/images/png/like@2x.png";

//api and redux
import { API_ENDPOINT } from "../../../services/api/resources";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { nextPageClick, previouPageClick } from "../../../utils/pagination";
import { setBuyerRFQData } from "../../../features/BuyerRFQDataSlice";
import dayjs from "dayjs";
import DownloadFileButton from "../../../components/Dashboard/DownloadFileButton/DownloadFileButton";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";

//custom hooks
import useFetchData from "../../../hooks/useFetchData";
import DropdownBtnOutlined from "../../../components/Dashboard/DropdownButtons/DropdownBtnOutlined/DropdownBtnOutlined";
import { downloadFile } from "../../../utils/downloadFile";
import DSelectInput from "../../../components/Dashboard/DashboardInputs/DSelectInput";
import Dbutton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DDateInput from "../../../components/Dashboard/DashboardInputs/DDateInput";
import { emptyCheck } from "../../../utils/validations";

const BuyerRFQTab = () => {
  //pages
  const [RFQMain, setRFQMain] = useState(true);

  const [viewRFQ, setViewRFQ] = useState(false);

  const [rfqToken, setRFQToken] = useState();

  const [tableProps, setTableProps] = useState({
    isLoading: false,
    errorMessage: "",
  });
  const [bulkBidsData, setBulkBidsData] = useState();
  const [sortingColumnName, setSortingColumnName] = useState(null);
  const [sortingOrder, setSortingOrder] = useState(null);
  //if selected bid logic
  const checkIsBidSelected = bulkBidsData && bulkBidsData?.map((e) => e.status);
  const hasSelectedBid =
    checkIsBidSelected &&
    checkIsBidSelected?.map((e) => e === "selected")?.filter((e) => e === true)
      ?.length !== 0;

  const storedValue = localStorage.getItem("rfq_organ_page_number");
  // Convert the string to a number using parseInt or parseFloat
  const pageNumbers = parseInt(storedValue); // or parseFloat(storedValue) for floating point numbers

  const openRFQMain = () => {
    setRFQMain(true);
    setViewRFQ(false);
    setUniqueData();
    setPageNumber(pageNumbers);
    setBidsData();
    setPaginationData();
    setSearchQuery2("");
  };

  const openViewRFQ = (unique_id) => {
    getIndividualData(unique_id);
    GetAllBidsData(pageNumber, unique_id);
    setViewRFQ(true);
    setRFQToken(unique_id);
    setRFQMain(false);
    setPageNumber(1);
    setPaginationData();
    getEveryBidsData(unique_id);
  };

  //dispatcher
  const dispatch = useDispatch();

  //get data from use selector
  const data = useSelector((state) => state.loginData.value);
  const RFQData = useSelector((state) => state.buyerRFQData.value);

  //search filtered data
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");

  // date filters

  const [startDateSearchQuery, setStartDateSearchQuery] = useState({
    value: "",
    isValid: true,
  });

  const [endDateSearchQuery, setEndDateSearchQuery] = useState({
    value: "",
    isValid: true,
  });

  //unique user
  const [uniqueData, setUniqueData] = useState();

  //pagenumber
  const [pageNumber, setPageNumber] = useState(1);

  //pagination data
  const [paginationData, setPaginationData] = useState();

  const [loadingState, setLoadingState] = useState(false);

  //credentials
  const [auth_token, setAuthToken] = useState(data?.auth_token);
  const [org_token, setOrgToken] = useState(data?.user?.token);
  const [buyer_token, setBuyerToken] = useState(data?.user?.token);
  const [bidsData, setBidsData] = useState();
  const [itemsListDataViewRFQ, setItemsListDataViewRFQ] = useState();

  //pagination logics
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = paginationData?.current_page_count;
  const totalItems = paginationData?.page_count;

  const onLocaleStoreg = (data) => {
    localStorage.setItem(
      "rfq_organ_page_number",
      `${data === "prev" ? parseInt(pageNumber) - 1 : parseInt(pageNumber) + 1}`
    );
  };
  useEffect(() => {
    // Clear the local storage value when the page is reloaded
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("rfq_organ_page_number", 1);
    });
  }, []);

  // const [enableSelect, setEnableSelect] = useState(false);
  // const [selectedRows, setSelectedRows] = useState();
  // const [selectedRowForComparison, setSelectedRowForComparison] = useState([]);

  // const handleSeletedRowsList = (rowList, row) => {
  //   const uniqueList = [...new Set(row?.map((e, i) => e?.vendor_token))];
  //   setSelectedRows(uniqueList);
  //   setSelectedRowForComparison(row);
  // };
  const [bidToken, setBidToken] = useState("");
  const [bidSelection, setBidSelection] = useState({
    select: false,
    unselect: false,
  });

  const [openBidConfirmation, setOpenBidConfirmation] = useState({
    selectBid: false,
    unSelectBid: false,
  });

  const selectBid = async (bid_token) => {
    setLoadingState(true);
    await axios({
      url: `${API_ENDPOINT.select_bid}`,
      method: "POST",
      data: {
        bid_token: bid_token,
        bid_status: "selected",
        buyer_token: buyer_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setLoadingState(false);
        setOpenBidConfirmation({
          selectBid: false,
          unSelectBid: false,
        });
        setBidSelection({
          select: true,
          unselect: false,
        });
        GetAllBidsData(pageNumber, rfqToken);
      })
      .catch((err) => {
        setLoadingState(false);
        setOpenBidConfirmation({
          selectBid: false,
          unSelectBid: false,
        });
        GetAllBidsData(pageNumber, rfqToken);
        console.log(err);
      });
  };

  const unSelectBid = async (bid_token) => {
    setLoadingState(true);
    await axios({
      url: `${API_ENDPOINT.select_bid}`,
      method: "POST",
      data: {
        bid_token: bid_token,
        bid_status: "unselected",
        buyer_token: buyer_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setLoadingState(false);
        GetAllBidsData(pageNumber, rfqToken);
        localStorage.setItem("hasSelectedBid", false);
        setOpenBidConfirmation({
          selectBid: false,
          unSelectBid: false,
        });
        setBidSelection({
          select: false,
          unselect: true,
        });
      })
      .catch((err) => {
        setLoadingState(false);
        GetAllBidsData(pageNumber, rfqToken);
        setOpenBidConfirmation({
          selectBid: false,
          unSelectBid: false,
        });
        console.log(err);
      });
  };

  const closeSelectBid = () => {
    getEveryBidsData(rfqToken);
    // GetAllBidsData(pageNumber, rfqToken);
    setBidToken("");
    setOpenBidConfirmation({
      selectBid: false,
      unSelectBid: false,
    });
    setBidSelection({
      select: false,
      unselect: false,
    });
  };

  const getEveryBidsData = async (rfq_code) => {
    const formData = new FormData();
    formData.append("rfq_token", rfq_code);
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.get_org_bid_view}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setBulkBidsData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [buyerList, setBuyersList] = useState({
    values: {
      tokens: [],
      visibleOption: [],
    },
  });

  const [buyerDetails, setBuyerDetails] = useState({
    buyerName: "",
    buyerID: "",
  });

  const GetAllData = (org_token) => {
    useFetchData({
      pageNumber: pageNumber ? pageNumber : 1,
      endpoint: `${API_ENDPOINT.get_all_org_RFQ}`,
      idName: "org_token",
      idValue: org_token,
      ...(searchQuery && { searchQuery: searchQuery }),
      ...(startDateSearchQuery.value && {
        startDateQuery: startDateSearchQuery.value,
      }),
      ...(endDateSearchQuery.value && {
        endDateQuery: endDateSearchQuery.value,
      }),
      ...(sortingColumnName && { sortingColumnName: sortingColumnName }),
      ...(sortingOrder && { sortingOrder: sortingOrder }),
      auth_token: auth_token,
      setData: setBuyerRFQData,
      setPaginationData: setPaginationData,
      dispatch: dispatch,
      tableProps,
      setTableProps,
    });
  };

  const GetAllRFQByBuyers = (org_token) => {
    useFetchData({
      pageNumber: pageNumber ? pageNumber : 1,
      endpoint: `${API_ENDPOINT.get_all_buyer_RFQ}`,
      idName: "org_token",
      idValue: org_token,
      searchQuery: searchQuery,
      auth_token: auth_token,
      setData: setBuyerRFQData,
      setPaginationData: setPaginationData,
      dispatch: dispatch,
      tableProps,
      setTableProps,
    });
  };

  let delay = 300;
  const GetAllBidsData = (pageNumber, rfq_code) => {
    useFetchData({
      pageNumber: pageNumber,
      endpoint: `${API_ENDPOINT.get_org_bid_view}`,
      idName: "rfq_token",
      idValue: rfq_code,
      searchQuery: searchQuery2,
      auth_token: auth_token,
      setData: setBidsData,
      setPaginationData: setPaginationData,
      tableProps,
      setTableProps,
    });
  };

  const [datafiltered, setDataFiltered] = useState(false);
  useEffect(() => {
    let timeoutId = null;

    if (RFQMain) {
      if (datafiltered) {
        GetAllRFQByBuyers(buyerDetails.buyerID);
      } else {
        timeoutId = setTimeout(() => {
          if (pageNumber) {
            GetAllData(org_token);
          }
        }, delay);
      }
    } else if (viewRFQ) {
      if (datafiltered) {
        GetAllRFQByBuyers(buyerDetails.buyerID);
      } else {
        timeoutId = setTimeout(() => {
          GetAllBidsData(pageNumber, rfqToken);
        }, delay);
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    pageNumber,
    searchQuery,
    RFQMain,
    startDateSearchQuery.value,
    endDateSearchQuery.value,
    sortingColumnName,
    sortingOrder,
    searchQuery2,
  ]);

  useEffect(() => {
    getAllBuyersData();
  }, []);

  const [fileName, setFileName] = useState();

  //get individual data
  const getIndividualData = async (unique_id, query) => {
    setLoadingState(true);

    await axios({
      method: "POST",
      url: `${API_ENDPOINT.get_individual_RFQ}${unique_id}`,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setLoadingState(false);
        setUniqueData(res?.data?.response[0]);

        let itemsData = res?.data?.items;

        setItemsListDataViewRFQ(itemsData);

        let fileObj =
          res?.data?.response &&
          res.data.response[0]?.proposal_document?.split("/");
        let fileName = fileObj && fileObj[fileObj?.length - 1];
        setFileName(fileName);

        setRFQToken(res?.data?.response[0]?.token);
      })
      .catch((err) => {
        setLoadingState(false);
        console.log(err);
      });
  };

  //getAll buyers names
  const getAllBuyersData = async () => {
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.getBuyerList}`,
      data: {
        org_token: org_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setBuyersList({
          values: {
            tokens: res?.data?.response?.map((e) => {
              return e?.token;
            }),
            visibleOption: res?.data?.response?.map((e) => {
              return e?.full_name;
            }),
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setBuyersList({
          values: {
            tokens: [null],
            visibleOption: ["No options"],
          },
        });
      });
  };

  // data for the datatable
  const headings = [
    { label: "RFQ Code", field: "rfq_code" },
    { label: "Item Name", field: "item_name" },
    { label: "Item Code", field: "item_code" },
    { label: "Quantity", field: "quantity" },
    { label: "Bid Start Date", field: "bid_from_date" },
    { label: "Bid End Date", field: "bid_to_date" },
    { label: "Status", field: "bid_status" },
  ];

  // data for the view datatable
  const headingsForViewRFQ = [
    { label: "Vendor", field: "vendor" },
    { label: "Quantity", field: "quantity" },
    { label: "Unit Price", field: "unit_price" },
    { label: "Total Price in Global Currency", field: "total_price" },
    { label: "Total Price in Local Currency", field: "converted_currency" },
    // {
    //   label: "Created Date", field: "created_date",
    // },
  ];

  const RFQitemsListMapped = itemsListDataViewRFQ?.map((e, i) => {
    return {
      item_name: e?.item_name,
      item_code: e?.item_code,
      hsn_code: e?.hsn_code?.split("-")[0],
      unspsc_code: e?.unspsc_code?.split("-")[0],
      unit: e?.unit,
      quantity: e?.quantity,
      description: e?.description,
    };
  });

  // data for the view datatable
  const headingsForViewItemsRFQ = [
    { label: "Item Name", field: "item_name", col_width: "150px" },
    { label: "Item Code", field: "item_code", col_width: "150px" },
    { label: "HSN Code", field: "hsn_code", col_width: "150px" },
    { label: "UNSPSC Code", field: "unspsc_code", col_width: "150px" },
    { label: "Unit", field: "unit", col_width: "100px" },
    { label: "Quantity", field: "quantity", col_width: "100px" },
    {
      label: "Description",
      field: "description",
      cellType: "view",
      col_width: "150px",
    },
  ];

  //view RFQ data
  const org_details = [
    {
      label: "RFQ Code",
      value: uniqueData?.rfq_code,
      size: "20rem",
    },
    {
      label: "Bid Type",
      value: !uniqueData?.bidding_type ? "Closed" : "Auction",
      size: "10rem",
    },
    {
      label: "Proposal Document",
      value: uniqueData?.proposal_document ? "Yes" : "No",
      size: "14rem",
    },
    {
      label: "Purchase Request Number",
      value: uniqueData?.purchase_request_number,
      size: "20rem",
    },
  ];

  // const proposal_description = [
  //   {
  //     label: `${
  //       uniqueData?.proposal_description ? "Description" : "Document"
  //     } `,
  //     value: uniqueData?.proposal_description || (
  //       <DownloadFileButton
  //         file={uniqueData?.proposal_document}
  //         fileName={fileName}
  //       />
  //     ),
  //   },
  // ];

  const proposal_description = [
    {
      label: `Document`,
      value: uniqueData?.proposal_document ? (
        <DownloadFileButton
          file={uniqueData?.proposal_document}
          fileName={fileName}
        />
      ) : (
        "-"
      ),
    },
  ];

  // const shippingDetails = [
  //   {
  //     label: "Shipping Details",
  //     value: `${uniqueData?.shipping_address1}, ${uniqueData?.shipping_address2}, ${uniqueData?.city}, ${uniqueData?.state} - ${uniqueData?.pincode}, ${uniqueData?.country}`,
  //   },
  // ];

  const shippingDetails = [
    {
      label: "Shipping Details",
      value: `${uniqueData?.shipping_address1}, ${`${
        uniqueData?.shipping_address2 && uniqueData?.shipping_address2 + ","
      }`} ${uniqueData?.city + ","} ${uniqueData?.state + " - "}  ${
        uniqueData?.pincode + ","
      } ${uniqueData?.country}`,
    },
  ];

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);

  const localTime = dayjs.utc(uniqueData?.bid_time).local().format("h:mm A");

  const bidding_details = [
    {
      label: "Bid Start Date",
      value: uniqueData?.bid_from_date,
    },
    {
      label: "Bid End Date",
      value: uniqueData?.bid_to_date,
    },
    {
      label: "Bid End Time",
      value: localTime,
    },
  ];

  let isValidFilterValue =
    buyerDetails.buyerID !== "" &&
    buyerDetails.buyerID?.length !== 0 &&
    buyerDetails.buyerID !== null &&
    buyerDetails.buyerID !== undefined;
  const handleFilterSearch = () => {
    if (isValidFilterValue) {
      setDataFiltered(true);
      localStorage.setItem("rfq_organ_page_number", 1); //reset page number as 1
      setPageNumber(1);
      if (pageNumber) {
        GetAllRFQByBuyers(buyerDetails.buyerID);
      }
    } else {
      setDataFiltered(false);
    }
  };

  return (
    <div className={classes.users_tab_wrapper}>
      <div className={classes.users_topHeader}>
        <div className={`${classes.lhs} flex`}>
          <HeaderTitle titleText="RFQ" customclass={classes.dashHead} />
        </div>

        <div className={`${classes.rhs} flex`}>
          <DateBar />
          {/* <NotificationBell /> */}
          <ProfileCard
            buyerBtn={false}
            buyerBtnOutline={false}
            profilePath={"/Organization_dashboard/My_profile"}
          />
        </div>
      </div>

      <div className={classes.search_add_wrapper}>
        <div
          className={`${classes.flex} ${classes.flex_align_end} ${classes.search_add_wrapper_content_left}`}
        >
          {
            !viewRFQ && (
              <SearchBar
                value={searchQuery}
                onChange={(query) => {
                  setSearchQuery(query);
                  setPageNumber(1);
                }}
              />
            )
            // :
            // viewRFQ && <SearchBar
            //   value={searchQuery2}
            //   onChange={(query) => {
            //     setSearchQuery2(query);
            //     setPageNumber(1);
            //   }}
            // />
          }

          {!viewRFQ && (
            <div className={`${classes.flex}`}>
              <DDateInput
                customClass={classes.dateFilters}
                type={"date"}
                label={"Start Date"}
                disableFuture={true}
                onChange={(e) => {
                  setPageNumber(1);
                  setStartDateSearchQuery({
                    isValid: emptyCheck(e),
                    value: e,
                  });
                }}
                value={startDateSearchQuery}
                error={!startDateSearchQuery?.isValid}
                errorMsg={"From date is required"}
              />
              <DDateInput
                customClass={classes.dateFilters}
                type={"date"}
                label={"End Date"}
                disableFuture={true}
                onChange={(e) => {
                  setPageNumber(1);
                  setEndDateSearchQuery({
                    isValid: emptyCheck(e),
                    value: e,
                  });
                }}
                value={endDateSearchQuery}
                error={!endDateSearchQuery?.isValid}
                errorMsg={"To date is required"}
              />
            </div>
          )}
        </div>

        <div className={classes.flex}>
          {!viewRFQ && (
            <div className={classes.searchFiltersWrapper}>
              <div style={{ transform: "translateY(-5px)" }}>
                <DropdownBtnOutlined
                  label="DOWNLOAD AS"
                  btnHeight="4.5rem"
                  btnWidth="20rem"
                  loadingState={loadingState}
                  disabled={RFQData?.length === 0 || loadingState}
                  onClick={(fileFormat) => {
                    downloadFile({
                      key: "org_token",
                      value: datafiltered ? buyerDetails.buyerID : org_token,
                      fileNameProp: datafiltered
                        ? `${buyerDetails.buyerName} - RFQ Data`
                        : "RFQ Data",
                      outputFormat: fileFormat,
                      API_ENDPOINT: datafiltered
                        ? API_ENDPOINT.buyer_rfq_csv
                        : API_ENDPOINT.csv_view_org_rfq,
                      authToken: auth_token,
                      setLoadingState: setLoadingState,
                    });
                  }}
                  options={["CSV", "PDF"]}
                />
              </div>

              <div className={classes.searchFilters}>
                <DSelectInput
                  options={buyerList.values}
                  getIDValue={true}
                  customClass={classes.filterFields}
                  customInputWrapperClass={classes.selectFilterInput}
                  label={"Filter By"}
                  placeHolder={"Buyer"}
                  size={"20rem"}
                  value={buyerDetails.buyerName}
                  onChange={(e) => {
                    const selectedTokens =
                      buyerList?.values.tokens[
                        buyerList?.values?.visibleOption?.indexOf(e)
                      ];
                    setBuyerDetails({
                      buyerID: selectedTokens,
                      buyerName: e,
                    });
                  }}
                />
                <div
                  className={classes.flex}
                  style={{
                    transform: "translateY(-5px)",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "-20px",
                    }}
                  >
                    <Dbutton
                      labelImg={btnImg}
                      customWrapperClass={classes.filterBtn}
                      customClass={classes.clearBtn}
                      iconWidth={"2rem"}
                      iconHeight={"2rem"}
                      btnHeight={"4.5rem"}
                      disabled={!isValidFilterValue}
                      onClick={handleFilterSearch}
                    />
                  </div>

                  {datafiltered && (
                    <Dbutton
                      labelImg={plusIcon}
                      customWrapperClass={classes.filterBtn}
                      customClass={classes.filterBtnEl}
                      iconWidth={"2rem"}
                      iconHeight={"2rem"}
                      btnHeight={"4.5rem"}
                      onClick={() => {
                        setBuyerDetails({
                          buyerID: "",
                          buyerName: "",
                        });
                        setDataFiltered(false);
                        GetAllData(org_token);
                        localStorage.setItem("rfq_organ_page_number", 1); //reset page number as 1
                        setPageNumber(1);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {viewRFQ ? (
            <DButton
              label={"BACK"}
              btnHeight="4rem"
              btnWidth={"12rem"}
              buyerBtn={true}
              onClick={() => {
                openRFQMain();
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>

      {RFQMain && (
        <>
          <div className={`${classes.d_dataTable} `}>
            <DataTable
              headings={headings}
              tableData={RFQData}
              actions={true}
              errorMessage={tableProps.errorMessage}
              isLoading={tableProps.isLoading}
              tableSize={RFQData?.length === 0 ? "100%" : "130vw"}
              viewBtn={true}
              sorting={true}
              viewClick={openViewRFQ}
              setSortingColumnName={setSortingColumnName}
              sortingColumnName={sortingColumnName}
              setSortingOrder={setSortingOrder}
              sortingOrder={sortingOrder}
            />

            {/* pagination component */}
            <PaginantionComponent
              pageNumber={pageNumber}
              currentPage={currentPage || 0}
              itemsPerPage={itemsPerPage || 0}
              totalItems={totalItems || 0}
              localStorageKey={"rfq_organ_page_number"}
              previouPageClick={() => {
                previouPageClick(pageNumber, setPageNumber);
                onLocaleStoreg("prev");
              }}
              nextPageClick={() => {
                nextPageClick(pageNumber, setPageNumber, paginationData);
                onLocaleStoreg("next");
              }}
              setPageNumber={setPageNumber}
              disablePrevious={parseInt(pageNumber) === 1 || pageNumber === ""}
              disableNext={
                parseInt(pageNumber) === paginationData?.page_count ||
                !paginationData ||
                pageNumber === ""
              }
            />
          </div>
        </>
      )}

      {viewRFQ && (
        <>
          <div className={classes.tabsScreens}>
            <div className={classes.infoSection}>
              <HeaderTitle
                customclass={classes.tabHeading}
                titleText={"RFQ Details"}
              />

              <div className={classes.info}>
                {org_details.map((e, i) => (
                  <InfoLabel
                    isLoading={loadingState}
                    label={e.label}
                    value={e.value}
                    size={e.size}
                  />
                ))}
              </div>
            </div>

            <div className={`${classes.d_dataTable_items}`}>
              <DataTable
                headings={headingsForViewItemsRFQ}
                stickyHead={true}
                tableFixedHeight={"400px"}
                tableData={RFQitemsListMapped}
                tableSize={"100%"}
                customClass={classes.dTable}
                setSortingColumnName={setSortingColumnName}
                sortingColumnName={sortingColumnName}
                setSortingOrder={setSortingOrder}
                sortingOrder={sortingOrder}
              />
            </div>

            {shippingDetails.map((e, i) => (
              <InfoLabel
                isLoading={loadingState}
                // customClass={classes.desc}
                label={e.label}
                value={e.value}
                size={"98%"}
                maxLength={"350"}
              />
            ))}

            <div className={classes.infoSection}>
              {proposal_description.map((e, i) => (
                <InfoLabel
                  isLoading={loadingState}
                  // customClass={classes.desc}
                  label={e.label}
                  value={e.value}
                  size={"98%"}
                  maxLength={"350"}
                />
              ))}
            </div>

            <div className={`${classes.infoSection}`}>
              <HeaderTitle
                customclass={classes.tabHeading}
                titleText={"Bidding Detalis"}
              />
              <div className={`${classes.compareSection}`}>
                <div className={`${classes.flex}`}>
                  {bidding_details.map((e, i) => {
                    return (
                      <InfoLabel
                        label={e.label}
                        value={e.value}
                        size={"20rem"}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className={`${classes.d_dataTable_sm}`}>
            <DataTable
              headings={headingsForViewRFQ}
              tableData={bidsData}
              bulkData={bulkBidsData}
              tableSize={"100%"}
              errorMessage={tableProps.errorMessage}
              isLoading={tableProps.isLoading}
              selection={false}
              customClass={classes.dTable}
              file={true}
              org={true}
              hasBidIteration={true}
              // disableSelectAll={enableSelect}
              // selectedRowsList={handleSeletedRowsList}
              selectBidBtn={
                uniqueData?.bid_status?.toLowerCase() !== "cancelled" &&
                uniqueData?.bid_status?.toLowerCase() !== "failed"
              }
              onSelectBid={(bid_token) => {
                setOpenBidConfirmation({
                  selectBid: true,
                  unSelectBid: false,
                });
                setBidToken(bid_token);
              }}
              onUnSelectBid={(bid_token) => {
                setOpenBidConfirmation({
                  selectBid: false,
                  unSelectBid: true,
                });
                setBidToken(bid_token);
              }}
              setSortingColumnName={setSortingColumnName}
              sortingColumnName={sortingColumnName}
              setSortingOrder={setSortingOrder}
              sortingOrder={sortingOrder}
            />

            <PaginantionComponent
              pageNumber={pageNumber}
              currentPage={currentPage || 0}
              itemsPerPage={itemsPerPage || 0}
              totalItems={totalItems || 0}
              previouPageClick={() => {
                previouPageClick(pageNumber, setPageNumber);
              }}
              nextPageClick={() => {
                nextPageClick(pageNumber, setPageNumber, paginationData);
              }}
              disablePrevious={pageNumber === 1}
              disableNext={
                pageNumber === paginationData?.page_count || !paginationData
              }
            />
          </div>
        </>
      )}

      {/* bid select or unselect confirmation popup */}
      <Modal
        open={openBidConfirmation.selectBid || openBidConfirmation.unSelectBid}
      >
        <Box>
          <SuccessPopupDashboard
            popupHeroText={
              openBidConfirmation.selectBid
                ? "Are you sure want to select this Bid ?"
                : "Are you sure want to unselect this Bid ?"
            }
            customClass={classes.confimationPopup}
            popBtn={true}
            buyerBtn={true}
            buyerBtnOutlined={true}
            btnText={"Yes"}
            loadingPrimaryBtn={loadingState}
            btnClick={() => {
              openBidConfirmation.selectBid
                ? selectBid(bidToken)
                : unSelectBid(bidToken);
            }}
            secondaryBtn={true}
            btnTextSecondary={"No"}
            btnClickSecondary={closeSelectBid}
          />
        </Box>
      </Modal>

      {/* bid Selection */}
      <Modal open={bidSelection.select || bidSelection.unselect}>
        <Box>
          <SuccessPopupDashboard
            popupImage={bidSelection.select ? bidSelectedImg : bidUnSelectedImg}
            popupHeroText={
              bidSelection.select ? "Bid Selected!" : "Bid Unselected!"
            }
            popupDescription={
              bidSelection.select
                ? "The vendor will be notified via email."
                : "The vendor will be notified this outcome via email."
            }
            popBtn={true}
            vendorBtn={true}
            vendorBtnOutlined={true}
            btnText={"Okay"}
            btnClick={closeSelectBid}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default BuyerRFQTab;
