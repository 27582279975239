import React, { memo, useState } from 'react';
import { Menu, MenuItem, CircularProgress } from '@mui/material';

import classes from '../DropdownButton.module.css';

const DropdownButtonOutlined = ({
    buyerBtn,
    label,
    onClick,
    disabled,
    labelImg,
    vendorBtn,
    vendorBtnOutlined,
    iconWidth,
    iconHeight,
    btnHeight,
    btnWidth,
    loadingState,
    options,
    custom,// New prop to pass dropdown options
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <button
                className={`${!buyerBtn ? classes.d_button_outlined : classes.buyerBtnOutlined} ${!vendorBtnOutlined ? classes.d_button_outlined : classes.vendorBtnOutlined
                    } ${disabled ? classes.disabled : ''} ${classes.flex} ${custom}`}
                style={{ width: `${btnWidth}`, height: `${btnHeight}` }}
                onClick={handleClick} // Open the menu on button click
                disabled={disabled}
            >
                {labelImg && !loadingState && (
                    <img
                        src={labelImg}
                        style={{ width: `${iconWidth}`, height: `${iconHeight}`, marginRight: `${label && `1rem`}` }}
                    />
                )}

                {loadingState && (
                    <CircularProgress
                        size={'18px'}
                        thickness={6}
                        variant="indeterminate"
                        disableShrink
                        sx={{
                            color: '#555',
                            animationDuration: '450ms',
                        }}
                    />
                )}

                {label && !loadingState && <span>{label}</span>}
            </button>

            {/* Menu component for the dropdown */}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} sx={{
                ".MuiMenu-paper": {
                    marginTop: '0.4rem',
                    boxShadow: "0px 6px 24px #00000017",
                }
            }} onClose={handleClose}>
                {options.map((option, index) => (
                    <MenuItem sx={{
                        width: btnWidth || "100%",
                        textAlign: 'center',
                        fontSize: '1.4rem',
                        fontFamily: 'gilroyMedium'
                    }} key={index} onClick={() => { handleClose(); onClick(option?.toLowerCase()); }}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default memo(DropdownButtonOutlined);
