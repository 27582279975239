import React, { useState, useEffect, memo } from "react";
import ChartIndicator from "../../ChartIndicator/ChartIndicator";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";
import classes from "../ChartStyles.module.css";
import { Skeleton } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { monthlyReportObjFormate } from "../../../../utils/chartKeyFormat";

const LineChart = ({
  dataLabel,
  dataValues,
  chartDataSet,
  loadingState,
  filterOnchange,
  vendorChart,
  customCardClass,
  durationValue,
  chooseChartDuration,
  disableDuration
}) => {
  // const [selectedOption, setSelectedOption] = useState(durationValue);
  const [formattedLabels, setFormattedLabels] = useState([]);

  let normalScales = {
    x: {
      grid: {
        display: true,
      },
      border: {
        dash: [6],
      },
      ticks: {
        beginAtZero: true,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        stepSize: Math.ceil(Math.max(...dataValues?.map((item)=>Number(item))) / 5),
        beginAtZero: true,
        callback: function (value, index, values) {
            return value.toLocaleString('en-IN');
        }
      },
    },
  };

  let vendorScales = {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        stepSize: Math.ceil(Math.max(...dataValues?.map((item)=>Number(item))) / 5),
        beginAtZero: true
      },
    },
    y: {
      grid: {
        display: true,
      },
      border: {
        dash: [6],
      },
      ticks: {
        beginAtZero: true,
        callback: function (value, index, values) {
            return value.toLocaleString('en-IN');
        }
      },
    },
  };

  useEffect(() => {
    let newFormattedLabels = [];
    switch (durationValue) {
      case "monthly":
        newFormattedLabels = monthlyReportObjFormate(dataLabel).reverse() //convertedResult?.reverse();
        break;
      case "weekly":
        newFormattedLabels = dataLabel;
        break;
      default:
        break;
    }

    setFormattedLabels(newFormattedLabels);
  }, [durationValue, dataLabel]);

  const handleOptionChange = (event) => {
    chooseChartDuration(event.target.value)
    // setSelectedOption(event.target.value);
    filterOnchange && filterOnchange(event.target.value);
  };

  const dataSets = {
    labels: formattedLabels?formattedLabels:[],
    datasets: [
      {
        label: chartDataSet?.label,
        borderWidth: 1,
        // data: dataValues,
        data: dataValues,
        borderColor: chartDataSet?.barColor,
        tension: .3,
        pointRadius: 1.5,
        backgroundColor: chartDataSet?.barColor,
        fill: {
          target: "origin",
          above: vendorChart ? `${chartDataSet?.barColor}30` : "transparent",
        },
      },
    ],
    plugins: {
      legend: false,
      tooltip: {
        callbacks: {
          label: (context)=> context?.parsed?.y?.toLocaleString('en-IN')
        },
      },
    },
  };

  const dataSetOpt = {
    plugins: {
      legend: false,
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context)=> context?.parsed?.y?.toLocaleString('en-IN')
        },
      },
    },
    scales: vendorChart ? vendorScales : normalScales,
  };

  return (
    <div className={`${customCardClass} ${classes.barsCard} `}>
      {!loadingState ? (
        <>
          <div className={classes.linearProgressInline}>
            <div>
              <span>{chartDataSet?.cardHeading}</span>
              <div className={classes.indicators}>
                {chartDataSet?.chartIndicators?.map((e) => (
                  <span key={e.value}>
                    <ChartIndicator label={e.value} color={e.color} />
                  </span>
                ))}
              </div>
            </div>
            <div className={classes.monthPicker}>
                {
                  !disableDuration && 
                  <FormControl variant="standard" sx={{ m: 0, minWidth: 'auto' }} size="small">
                    {/* <InputLabel id="demo-select-small-label">Option</InputLabel> */}
                    <Select
                      labelId="report_option"
                      id="report_option"
                      className={classes.filterDropdown}
                      value={durationValue ? durationValue : 'monthly'}
                      label="Option"
                      onChange={handleOptionChange}
                    >
                      <MenuItem value={'monthly'}>Monthly</MenuItem>
                      <MenuItem value={'weekly'}>Weekly</MenuItem>
                    </Select>
                  </FormControl>
                }
            </div>
          </div>

          <Line
            data={dataSets}
            options={dataSetOpt}
            style={{ maxHeight: "160px" }}
          />
        </>
      ) : (
        <>
          <div
            className={classes.linearProgress}
            style={{
              marginBottom: "0",
            }}
          >
            <span>
              <Skeleton />
            </span>
            <div className={classes.indicators}>
              <Skeleton width={"70%"} />
            </div>
          </div>

          <div className={`${classes.flex} ${classes.lineSkeleton}`}>
            <Skeleton width={"100%"} height={"180px"} />
          </div>
        </>
      )}
    </div>
  );
};

export default memo(LineChart);
