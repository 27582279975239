import React, { memo, useEffect } from "react";
import styles from "./DashboardInput.module.css";
import {
  Autocomplete,
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  ThemeProvider,
  createFilterOptions,
  createTheme,
} from "@mui/material";
import {
  CitySelector,
  CountrySelector,
  StateSelector,
} from "volkeno-react-country-state-city";
import "volkeno-react-country-state-city/dist/index.css";

const DSelectInput = ({
  label,
  customInputWrapperClass,
  defaultDropdown,
  noInit,
  textFieldValue,
  countryDropDown,
  stateDropDown,
  cityDropDown,
  placeHolder,
  regionOnchange,
  value,
  existingVendorList1,
  isEdit,
  countryid,
  stateid,
  onChange,
  error,
  options,
  disabled,
  multiselect,
  size,
  errorMsg,
  customClass,
  setIDValue,
  getIDValue,
  id,
}) => {
  useEffect(() => {
    const selectedTokens = Array.isArray(value)
      ? value.map(
          (selectedValue) =>
            options?.tokens[options?.visibleOption?.indexOf(selectedValue)]
        )
      : [];
    setIDValue && setIDValue(selectedTokens);
  }, [options, value]);

  const getID = (event) => {
    const selectedValues = event;
    const selectedTokens =
      selectedValues && selectedValues?.length === 1
        ? [options?.tokens[options?.visibleOption?.indexOf(selectedValues[0])]]
        : selectedValues?.map(
            (selectedValue) =>
              options?.tokens[options?.visibleOption?.indexOf(selectedValue)]
          );
    setIDValue(selectedTokens);
  };

  const getTokenSingleSelect = (event) => {
    const selectedTokens =
      options?.tokens[options?.visibleOption?.indexOf(event)];
    setIDValue && setIDValue(selectedTokens);
  };

  const theme = createTheme({
    typography: {
      // fontSize: "1.4rem"
      htmlFontSize: 9.8,
    },
  });

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 50,
    matchFrom: "start",
  });

  return (
    <ThemeProvider theme={theme}>
      {/* ${disabled && styles.diabledField} */}
      <div
        className={`${styles.select_container} ${customClass} `}
        style={{ width: `${size}` }}
      >
        <label>{label}</label>
        {multiselect ? (
          <Autocomplete
            multiple
            options={options?.visibleOption}
            // getOptionLabel={(option) => option.title}
            // defaultValue={[top100Films[13]]}
            // filterSelectedOptions
            autoHighlight={true}
            autoComplete={true}
            onChange={(event, value) => {
              onChange(
                isEdit
                  ? [
                      ...existingVendorList1,
                      ...value.filter(
                        (option) => existingVendorList1.indexOf(option) === -1
                      ),
                    ]
                  : value
              );
              getID(
                isEdit
                  ? [
                      ...existingVendorList1,
                      ...value.filter(
                        (option) => existingVendorList1.indexOf(option) === -1
                      ),
                    ]
                  : value
              );
            }}
            sx={{
              minHeight: "4.2rem",
              height: "auto",
              fontSize: "1.4rem",
              transition: "0",
              ".Mui-expanded": {
                transition: "none",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: "0",
              },
              ".MuiAutocomplete-input": {
                fontSize: "1.4rem",
                fontFamily: "GilroyMedium",
                height: "2.6rem",
              },
              ".MuiFormLabel-root": {
                display: "none",
              },
              ".MuiChip-root": {
                height: "30px",
                padding: "5px",
              },
            }}
            size="small"
            placeholder={label}
            value={value}
            className={`${styles.select_wrapper_multiSelect}  ${
              error && styles.error
            }`}
            disabled={disabled}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  disabled={
                    isEdit ? existingVendorList1?.indexOf(option) !== -1 : false
                  }
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={!placeHolder ? label && label : placeHolder}
              />
            )}
            disableClearable={isEdit}
          />
        ) : (
          <Autocomplete
            options={!getIDValue ? options : options?.visibleOption}
            sx={{
              height: "4.2rem",
              fontSize: "1.4rem",
              transition: "0",
              ".Mui-expanded": {
                transition: "none",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: "0",
              },
              ".MuiAutocomplete-input": {
                fontSize: "1.4rem",
                fontFamily: "GilroyMedium",
                height: "2.6rem",
              },
            }}
            componentsProps={{
              popper: {
                sx: {
                  fontFamily: "gilroyMedium",
                },
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: true,
                  },
                ],
              },
            }}
            size="small"
            className={`${styles.select_wrapper} ${customInputWrapperClass}  ${
              error ? styles.error : ""
            }`}
            id={id ? id : ""}
            disabled={disabled}
            value={value}
            onChange={(event, newValue) => {
              onChange(newValue, event);
              getIDValue && getTokenSingleSelect(newValue);
            }}
            filterOptions={filterOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error}
                value={textFieldValue}
                placeholder={!placeHolder ? label && label : placeHolder}
              />
            )}
          />
        )}

        {error && <p className={styles.error_message}>{errorMsg}</p>}
      </div>
    </ThemeProvider>
  );
};

export default memo(DSelectInput);
