import React from "react";
import "../PrivacyPolicy/PrivacyPolicy.css"

const CookiePolicy = () => {
    return (
        <div className="prp_wrapper">
            <div className="privacy_content row">
                <div className="privacy_header mx_auto pb_5 col_12 col_md_9">
                    <h1 className="privacy_title">Cookie Policy</h1>
                </div>
                <div className="textWrap">
                    <p>
                        <b>Cookies</b> These are small files of information that a web server generates and sends to a web browser. If you grant permission, web browser will store such cookies in your computer’s hard drive. This allows the cookies to capture and remember certain information about you and provide such information to that site or service provider.
                    </p>
                </div>
                <h2>Why do we use cookies?</h2>
                <p>
                    BidToaster uses cookies to Administer and secure this website
                </p>
                <div className="pl_4">
                    <h2>Persistent cookies vs Session cookies</h2>
                    <p>Persistent cookies are stored on your device to hold usage information, settings, personalizations, or sign-in credentials. They will stay on your device after you close your browser. These types of cookies will expire according to the time specified in the cookie.

                        Session cookies only last as long as your online session. This means that they will disappear from your computer or device when you close your browser.</p>
                </div>
                <h2>
                    First party cookies vs Third party cookies
                </h2>
                <p>
                    First party cookies are cookies that are set by the website that you are visiting and it's only this website that can access and read these cookies.

                    Third party cookies are set by someone other than the owner of the website you’re visiting. They are commonly used for advertising.
                </p>
                <p>
                    This Privacy Statement doesn’t apply to any of our websites,
                    products or services that have a separate Privacy Statement.
                </p>
                <div>
                    <h2>Necessary cookies</h2>
                    <p>
                        Our website uses only Necessary cookies. These cookies are necessary for the website to function and cannot be switched off in our systems. For example, we use necessary cookies to make it possible for you to enter your login credentials only once during a visit to our website.
                    </p>
                    <p>If you disable the cookies, you will not be able to access this website.</p>
                </div>
                <p>Personal Data submitted by you while using BidToaster is not stored in cookies.</p>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>NAME</th>
                        <th>SERVICE</th>
                        <th>PURPOSE</th>
                        <th>COOKIE TYPE AND DURATION</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>ECAS_PREFS</td>
                        <td>Identity Management Service</td>
                        <td>Remembers your settings and preferences:
                            user id, username, acknowledged cookie policy,
                            high contrast mode, view account details after login.</td>
                        <td>First-party persistent cookie
                            Present until you delete the cookie.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default CookiePolicy;
