import React, { useEffect, useState } from "react";
//img
import profileImg from "../../../assets/images/png/Profile.png";
import successPopupImg from "../../../assets/images/png/Group 2 2@2x.png";
import deletePopupImg from "../../../assets/images/png/Group 2@2x.png";
import plusIcon from "../../../assets/images/svg/plusIcon.png";
//css
import classes from "./ItemsTab.module.css";
//components
import SearchBar from "../../../components/common/SearchBar/SearchBar";
import DateBar from "../../../components/common/DateBar/DateBar";
import NotificationBell from "../../../components/common/NotificationBell/NotificationBell";
import HeaderTitle from "../../../components/common/HeaderTitle/HeaderTitle";
import PaginantionComponent from "../../../components/common/Pagination/PaginationComponent";
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DataTable from "../../../components/common/DataTable/DataTableSorting";
import { Box, Fade, Modal, Zoom } from "@mui/material";
import DTextInput from "../../../components/Dashboard/DashboardInputs/DTextInput";
import DButtonOutlined from "../../../components/Dashboard/DashboardButtons/dButtonOutlined";
import DSelectInput from "../../../components/Dashboard/DashboardInputs/DSelectInput";
import PopupFrame from "../../../components/Popups/PopupFrame/PopupFrame";
import InfoLabel from "../../../components/common/InfoLabel/InfoLabel";
import SuccessPopupDashboard from "../../../components/Popups/PopupFrame/SuccessPopupDashboard";
//API
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_ENDPOINT } from "../../../services/api/resources";
import { setItemsData } from "../../../features/ItemsDataSlice";
import { emptyCheck, validateFile } from "../../../utils/validations";
import { MoreHoriz } from "@mui/icons-material";
import { nextPageClick, previouPageClick } from "../../../utils/pagination";
//custom hooks
import useFetchData from "../../../hooks/useFetchData";
import DFileUpload from "../../../components/Dashboard/DashboardInputs/DFileUpload";
import DefaultLabel from "../../../components/Dashboard/DefaultLabel/DefaultLabel";
//smaple xl/ csv file for download
import sampleCSV from "../../../assets/files/Sample Items CSV.xlsx";
import { saveAs } from "file-saver";
import unspscCodes from "../../../data/unspsc.json";
import hsnCodes from "../../../data/hsn.json";
import { getUniqueData } from "../../../utils/getUniqueData";
import { downloadFile } from "../../../utils/downloadFile";
import DropdownBtnOutlined from "../../../components/Dashboard/DropdownButtons/DropdownBtnOutlined/DropdownBtnOutlined";
import ProfileCard from "../../../components/common/ProfileCard/ProfileCard";

const ItemsTab = () => {
  //dispatcher
  const dispatch = useDispatch();

  //get data from use selector
  const data = useSelector((state) => state.loginData.value);
  const itemsData = useSelector((state) => state.itemsData.value);

  //popuptext
  const [popupHeroText, setPopupHeroText] = useState("");
  const [popupMessage, setPopupMessage] = useState("");

  //search filtered data
  const [searchQuery, setSearchQuery] = useState("");

  const [sortingColumnName, setSortingColumnName] = useState(null);
  const [sortingOrder, setSortingOrder] = useState(null);
  const order = sortingOrder === null || sortingOrder === "asc" ? "desc" : "asc";
  //unique user
  const [uniqueData, setUniqueData] = useState();
  const [uniqueDataID, setUniqueDataID] = useState("");

  //credentials
  const [auth_token, setAuthToken] = useState(data?.auth_token);
  const [user_token, setUserToken] = useState(data?.user?.user_token);
  const [org_token, setOrgToken] = useState(data?.user?.token);
  const [pageNumber, setPageNumber] = useState(1);

  //pagination data
  const [paginationData, setPaginationData] = useState();

  //pagination logics
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = paginationData?.current_page_count;
  const totalItems = paginationData?.page_count;

  //btn label
  const [loadingState, setLoadingState] = useState(false);
  const [loadingStateDwnload, setLoadingStateDwnload] = useState(false);

  //button label
  const [primaryBtnLabel, setPrimaryBtnLabel] = useState();

  //toggle add/update actions
  const [itemsAction, setItemsAction] = useState({
    addItem: false,
    updateItem: false,
    addBulkItem: false,
  });

  const [UNSPSCList, setUNSPSCList] = useState(
    unspscCodes?.map((e) => e.commodity + " - " + e.commodity_name)
  );
  const [HSNList, setHSNList] = useState(
    hsnCodes?.map((e) => e.hsn_code + " - " + e.description)
  );

  // error message
  const [primaryBtnError, setPrimaryBtnError] = useState({
    error: false,
    errorMsg: "",
  });
  const [tableProps, setTableProps] = useState({
    isLoading: false,
    errorMessage: "",
  });

  const regexForAlphabets = /[^a-zA-Z\s]/g;
  const regexForAlphaNumeric = /[^a-zA-Z0-9\s]+/g;
  const specialAlphaNumericRegex = /^[a-zA-Z0-9\s\W]+$/;
  const regexForNumber = /[^\d\s]/g;

  const GetAllData = () => {
    useFetchData({
      pageNumber: pageNumber,
      endpoint: `${API_ENDPOINT.get_all_items}`,
      idName: "org_token",
      idValue: org_token,
      searchQuery: searchQuery,
      ...(sortingColumnName && { sortingColumnName: sortingColumnName }),
      ...(sortingOrder && { sortingOrder: order }),
      auth_token: auth_token,
      setData: setItemsData,
      setPaginationData: setPaginationData,
      dispatch: dispatch,
      tableProps,
      setTableProps,
    });
  };

  let delay = 300;

  //get users data for table view
  useEffect(() => {
    let timeoutId = null;

    timeoutId = setTimeout(() => {
      if (pageNumber) {
        GetAllData();
      }
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [pageNumber, searchQuery, sortingColumnName, sortingOrder]);

  useEffect(() => {
    setPrimaryBtnLabel(
      itemsAction.addItem
        ? "Add"
        : itemsAction.updateItem
          ? "Update"
          : itemsAction.addBulkItem
            ? "Add"
            : ""
    );
  }, [itemsAction]);

  const updateInputs = (data) => {
    setItemName((prev) => ({
      ...prev,
      value: data?.item_name,
    }));

    setItemCode((prev) => ({
      ...prev,
      value: data?.item_code,
    }));

    setHSNCode((prev) => ({
      ...prev,
      value: data?.hsn_code,
    }));

    setUNSPSCCode((prev) => ({
      ...prev,
      value: data?.unspsc_code,
    }));

    setItemCategory((prev) => ({
      ...prev,
      value: data?.category,
    }));
  };

  //demo data for the datatable
  const headings = [
    { label: "Item Name", field: "item_name" },
    { label: "Item Code", field: "item_code" },
    { label: "Category", field: "category" },
    { label: "Status", field: "status", additional_data: "toggle" },
  ];

  // modal states
  const [open, setOpen] = useState(false);

  const handleOpenAddItem = () => {
    setOpen(true);
    resetForm();
    setItemsAction({
      addItem: true,
      updateItem: false,
    });
    // addCodesList(hsnCodes, setHSNList);
  };

  // const addCodesList = (listData, setData) => {
  //   setData(listData?.map(e => e.hsn_code))
  // }

  const handleOpenAddBulkItem = () => {
    setOpen(true);
    resetForm();
    setItemsAction({
      addItem: false,
      updateItem: false,
      addBulkItem: true,
    });
  };
  const handleOpenEditItem = (unique_id) => {
    setOpen(true);
    resetForm();

    let rowData = getUniqueData(itemsData, "token", unique_id);
    updateInputs(rowData);

    setUniqueDataID(unique_id);
    setItemsAction({
      addItem: false,
      updateItem: true,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setPrimaryBtnError({
      error: false,
      errorMsg: "",
    });
  };

  // S - success popup
  const [openS, setOpenS] = useState(false);
  const handleOpenS = () => {
    setOpenS(true);
  };
  const handleCloseS = () => {
    setOpenS(false);
  };

  // VU - View User popup
  const [openViewItemPopup, setOpenViewItemPopup] = useState(false);
  const handleOpenViewItemPopup = (unique_id) => {
    let rowData = getUniqueData(itemsData, "token", unique_id);
    setUniqueData(rowData);
    setOpenViewItemPopup(true);
  };
  const handleCloseViewItemPopup = () => {
    setOpenViewItemPopup(false);
  };

  // viewItemsActionButtons
  const viewItemsActionButtons = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={handleCloseViewItemPopup}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
    />,
    <DButton
      label={"OK"}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      onClick={handleCloseViewItemPopup}
    />,
  ];

  const viewUserChildren = [
    <InfoLabel
      size={"44%"}
      label={"Item Name"}
      value={uniqueData?.item_name}
    />,
    <InfoLabel
      size={"44%"}
      label={"Item Code"}
      value={uniqueData?.item_code}
    />,
    <InfoLabel
      size={"44%"}
      label={"Item Category"}
      value={uniqueData?.category}
    />,
    <InfoLabel
      size={"44%"}
      label={"UNSPSC Code"}
      value={uniqueData?.unspsc_code}
    />,
    <InfoLabel size={"100%"} label={"HSN Code"} value={uniqueData?.hsn_code} />,
  ];

  const viewUserHeading = (
    <HeaderTitle
      titleText={
        uniqueData?.item_name || <MoreHoriz sx={{ fontSize: "30px" }} />
      }
      customclass={classes.VUpopupHeading}
    />
  );

  //common states for CRUD
  const [itemName, setItemName] = useState({
    value: "",
    isValid: true,
  });
  const [itemCode, setItemCode] = useState({
    value: "",
    isValid: true,
  });
  const [HSNCode, setHSNCode] = useState({
    value: "",
    isValid: true,
  });
  const [UNSPSCCode, setUNSPSCCode] = useState({
    value: "",
    isValid: true,
  });
  const [ItemCategory, setItemCategory] = useState({
    value: "",
    isValid: true,
  });
  const [csvFile, setCsvFile] = useState({
    value: "",
    isValid: true,
  });

  const resetForm = () => {
    setItemCode({
      value: "",
      isValid: true,
    });
    setItemCategory({
      value: "",
      isValid: true,
    });
    setItemName({
      value: "",
      isValid: true,
    });
    setHSNCode({
      value: "",
      isValid: true,
    });
    setUNSPSCCode({
      value: "",
      isValid: true,
    });
    setCsvFile({
      value: "",
      isValid: true,
    });
  };

  const validateForm = (query) => {
    const isItemName = emptyCheck(itemName.value);
    const isItemCode = emptyCheck(itemCode.value);
    const isItemCategory = emptyCheck(ItemCategory.value);
    const isHSNCode = HSNCode.value?.trim() !== "" && HSNCode.value !== null;
    // const isUNSPSCCode = emptyCheck(UNSPSCCode.value) && UNSPSCCode.value !== null;
    const isCsvFile = validateFile(
      csvFile.value,
      false,
      ["csv", "xlsx"],
      5
    ).isValid;

    // Update the validity state
    setItemName((prev) => ({
      ...prev,
      isValid: isItemName,
    }));

    setItemCode((prev) => ({
      ...prev,
      isValid: isItemCode,
    }));

    setItemCategory((prev) => ({
      ...prev,
      isValid: isItemCategory,
    }));

    setHSNCode((prev) => ({
      ...prev,
      isValid: isHSNCode,
    }));

    // setUNSPSCCode((prev) => ({
    //   ...prev,
    //   isValid: isUNSPSCCode,
    // }));

    if (query === "bulkData") {
      setCsvFile((prev) => ({
        ...prev,
        isValid: isCsvFile,
      }));

      return isCsvFile;
    } else {
      return isItemName && isItemCode && isItemCategory && isHSNCode;
    }
  };

  // ADD Item function
  const addItem = async () => {
    const formData = new FormData();
    formData.append("item_name", itemName.value);
    formData.append("hsn_code", HSNCode.value);
    formData.append("item_code", itemCode.value);
    UNSPSCCode.value && formData.append("unspsc_code", UNSPSCCode.value);
    formData.append("category", ItemCategory.value);
    formData.append("organization_token", org_token);

    await axios({
      url: `${API_ENDPOINT.add_item}`,
      method: "POST",
      data: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        GetAllData();
        setPopupHeroText("Item Added!");
        setPopupMessage(res?.data?.response);
        handleOpenS();
        handleClose();
        resetForm();
        setLoadingState(false);
      })
      .catch((err) => {
        setPrimaryBtnError({
          error: true,
          errorMsg: Object.values(err?.response?.data)[0],
        });
        setLoadingState(false);
      });
  };

  // ADD Bulk Items function
  const addBulkItems = async () => {
    const formData = new FormData();

    formData.append("org_token", org_token);
    formData.append("csv_file", csvFile.value);

    await axios({
      url: `${API_ENDPOINT.add_bulk_items}`,
      method: "POST",
      data: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        GetAllData();
        setPopupHeroText("Items Added!");
        setPopupMessage(res?.data?.response);
        handleOpenS();
        handleClose();
        resetForm();
        setLoadingState(false);
      })
      .catch((err) => {
        setPrimaryBtnError({
          error: true,
          errorMsg:
            (typeof err?.response?.data?.response !== "object" &&
              err?.response?.data?.response) ||
            "Something went wrong! please check the file values & try again.",
        });
        setLoadingState(false);
      });
  };

  // Update item submit click
  const updateItem = async () => {
    const formData = new FormData();
    formData.append("item_name", itemName.value);
    formData.append("hsn_code", HSNCode.value);
    formData.append("unspsc_code", UNSPSCCode.value);
    formData.append("category", ItemCategory.value);
    formData.append("organization_token", org_token);

    await axios({
      url: `${API_ENDPOINT.update_item}${uniqueDataID}`,
      method: "POST",
      data: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        GetAllData();
        setPopupHeroText("Item Updated!");
        setPopupMessage(res?.data?.response);
        handleClose();
        handleOpenS();
        resetForm();
        setLoadingState(false);
      })
      .catch((err) => {
        console.log(err);
        setPrimaryBtnError({
          error: true,
          errorMsg: Object.values(err?.response?.data)[0],
        });
        setLoadingState(false);
      });
  };

  //submit click ADD/UPDATE item
  const handleSubmit = () => {
    const validation = itemsAction.addBulkItem
      ? validateForm("bulkData")
      : validateForm();
    if (validation) {
      if (itemsAction.addItem) {
        setLoadingState(true);
        addItem();
      } else if (itemsAction.updateItem) {
        setLoadingState(true);
        updateItem();
      } else if (itemsAction.addBulkItem) {
        setLoadingState(true);
        addBulkItems();
      }
    } else {
      setItemName((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      setItemCode((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      setItemCategory((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      setHSNCode((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      setUNSPSCCode((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      setCsvFile((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
    }
  };

  //category select options
  const selectCategory = ["Select", "IT", "Stationary"];

  // INPUTS
  const InputsHeading = (
    <HeaderTitle
      titleText={
        itemsAction.addItem
          ? "Add Item"
          : itemsAction.updateItem
            ? "Update Item"
            : itemsAction.addBulkItem
              ? "Add Bulk Items"
              : ""
      }
      customclass={classes.popupHeading}
    />
  );

  const ItemInputs = [
    <DTextInput
      label="Item Name"
      type="text"
      maxLength={250}
      value={itemName.value}
      onChange={(e) => {
        let val = e.target.value;
        setItemName({
          value: val,
          isValid: emptyCheck(e.target.value),
        });
      }}
      error={!itemName.isValid}
      customClassName={classes.popupFields}
      errorMsg={"Item Name is required"}
      size={"44%"}
    />,

    <DTextInput
      label="Item Code"
      type="text"
      maxLength={250}
      value={itemCode.value}
      disabled={itemsAction.updateItem}
      onChange={(e) => {
        const {
          target: { value },
        } = e;
        if (value === "" || specialAlphaNumericRegex.test(value)) {
          setItemCode({
            value: value,
            isValid: emptyCheck(value),
          });
        }
      }}
      error={!itemCode.isValid}
      customClassName={classes.popupFields}
      errorMsg={"Item Code is required"}
      size={"44%"}
    />,

    <DTextInput
      label="Category"
      type="text"
      placeholder={"(Example - IT, Stationary, etc.)"}
      maxLength={250}
      value={ItemCategory.value}
      onChange={(e) => {
        const {
          target: { value },
        } = e;
        if (value === "" || specialAlphaNumericRegex.test(value)) {
          setItemCategory({
            value: value,
            isValid: emptyCheck(value),
          });
        }
      }}
      error={!ItemCategory.isValid}
      customClassName={classes.popupFields}
      errorMsg={"Category is required"}
      size={"44%"}
    />,

    <DSelectInput
      label="UNSPSC Code"
      placeHolder={"Search UNSPSC Code"}
      maxLength={250}
      value={UNSPSCCode.value}
      options={UNSPSCList}
      onChange={(e) => {
        setUNSPSCCode({
          value: e === null ? "" : e,
          // isValid: emptyCheck(e) && e !== null,
        });
      }}
      // error={!UNSPSCCode.isValid}
      customClass={classes.popupFields}
      // errorMsg={"UNSPSC Code is required"}
      size={"44%"}
    />,

    <DSelectInput
      label="HSN Code"
      placeHolder={"Search HSN Code"}
      maxLength={250}
      options={HSNList}
      value={HSNCode.value}
      onChange={(e) => {
        setHSNCode({
          value: e === null ? "" : e,
          isValid: e !== "" && e !== null,
        });
      }}
      error={!HSNCode.isValid}
      customClassName={classes.popupFields}
      errorMsg={"HSN Code is required"}
      size={"100%"}
    />,
  ];

  const BulkItemInputs = [
    <div className={classes.input_container}>
      <DefaultLabel label={"Upload CSV file"} />
      <DFileUpload
        fileTypes={".xlsx,.csv"}
        customFileTypeText={"(Supported format CSV)"}
        onFileUpload={(file) => {
          let isFileValid = validateFile(
            file,
            false,
            ["csv", "xlsx"],
            5
          ).isValid;
          setCsvFile({
            value: file,
            isValid: isFileValid,
          });
        }}
        error={!csvFile.isValid}
        errorMsg={
          validateFile(csvFile.value, false, ["csv", "xlsx"], 5, "CSV file")
            .errorMessage
        }
        size={"100%"}
      />
      <p
        className={classes.linkBtn}
        onClick={() => {
          saveAs(sampleCSV, "Sample CSV");
        }}
      >
        Sample CSV
      </p>
    </div>,
  ];

  const itemsActionsButton = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={handleClose}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
    />,
    <DButton
      label={primaryBtnLabel}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      loadingState={loadingState}
      error={primaryBtnError.error}
      errorMsg={primaryBtnError.errorMsg}
      onClick={handleSubmit}
    />,
  ];

  //deletion popups and functions
  const deleteItem = async (deletionType) => {
    let isHardDelete = deletionType && deletionType === "hard delete";
    setLoadingState(true);

    setPopupMessage("");

    if (isHardDelete) {
      await axios({
        method: "POST",
        url: `${API_ENDPOINT.permanent_delete_item}`,
        data: {
          item_token: uniqueDataID,
        },
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      })
        .then((res) => {
          GetAllData();
          handleCloseDltConfirmation();
          setPopupHeroText(`Item Deleted Successfully!`);
          setPopupMessage(res?.data?.response);
          setLoadingState(false);
        })
        .catch((err) => {
          setPopupHeroText("OOPS! Something went wrong");
          setPopupMessage(err?.response?.data?.response);
          console.log(err);
          setLoadingState(false);
        });
    } else {
      await axios({
        method: "POST",
        url: `${API_ENDPOINT.delete_item}`,
        data: {
          token: uniqueDataID,
          status: itemStatus,
        },
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      })
        .then((res) => {
          GetAllData();
          handleCloseDltConfirmation();
          setPopupHeroText(
            `Item ${itemStatus ? "Activated!" : "Deactivated!"}`
          );
          setPopupMessage(res?.data?.response);
          setLoadingState(false);
        })
        .catch((err) => {
          setPopupHeroText("Something went wrong!");
          setPopupMessage(err?.response?.data?.response);
          console.log(err);
          setLoadingState(false);
        });
    }
  };

  //delete data
  const [openDlt, setOpenDlt] = useState(false);
  const handleOpenDlt = () => setOpenDlt(true);
  const handleCloseDlt = () => {
    setOpenDlt(false);
  };
  const [openDltConfirmation, setOpenDltConfirmation] = useState(false);
  const [itemStatus, setItemStatus] = useState();

  const [itemHardDelete, setItemHardDelete] = useState(false);

  const handleOpenDltConfirmation = (deletion_id, rowData, deletionType) => {
    let isHardDelete = deletionType && deletionType === "hard delete";
    setItemHardDelete(isHardDelete);
    let popupMsg = rowData.status
      ? "Are you sure want to deactivate"
      : "Are you sure want to activate";
    let status = !rowData.status;
    let uniqueData = getUniqueData(itemsData, "token", deletion_id);
    setPopupHeroText(
      `${isHardDelete ? "Are you sure want to delete" : popupMsg} "${uniqueData?.item_name
      }"?`
    );
    setOpenDltConfirmation(true);
    setUniqueDataID(deletion_id);
    setItemStatus(status);
  };
  const handleCloseDltConfirmation = () => {
    setOpenDltConfirmation(false);
    setItemHardDelete(false);
  };
  const handleDeleteUser = () => {
    handleOpenDlt();
    itemHardDelete ? deleteItem("hard delete") : deleteItem();
    handleCloseDltConfirmation();
  };

  return (
    <div className={classes.users_tab_wrapper}>
      <div className={classes.users_topHeader}>
        <div className={`${classes.lhs} ${classes.flex}`}>
          <HeaderTitle titleText="Item Master" customclass={classes.dashHead} />
        </div>

        <div className={`${classes.rhs} ${classes.flex}`}>
          <DateBar />
          {/* <NotificationBell /> */}
          <ProfileCard
            imageSrc={profileImg}
            profilePath={"/Organization_dashboard/My_profile"}
          />
        </div>
      </div>

      <div className={classes.search_add_wrapper}>
        <SearchBar
          onChange={(query) => {
            setSearchQuery(query);
            setPageNumber(1);
          }}
        />

        <div
          className={classes.flex}
          style={{
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          <DropdownBtnOutlined
            label="DOWNLOAD AS"
            btnHeight="4rem"
            btnWidth="18rem"
            loadingState={loadingStateDwnload}
            disabled={itemsData?.length === 0}
            onClick={(fileFormat) => {
              downloadFile({
                key: "org_token",
                value: org_token,
                fileNameProp: "Items Data",
                outputFormat: fileFormat,
                API_ENDPOINT: API_ENDPOINT.csv_org_item_view,
                authToken: auth_token,
                setLoadingState: setLoadingStateDwnload,
              });
            }}
            options={["CSV", "PDF"]}
          />

          <DButtonOutlined
            iconWidth="1rem"
            iconHeight="1rem"
            label="UPLOAD"
            btnHeight="4rem"
            onClick={handleOpenAddBulkItem}
          />

          <DButton
            labelImg={plusIcon}
            iconWidth="1rem"
            iconHeight="1rem"
            label="ADD ITEM"
            btnHeight="4rem"
            onClick={handleOpenAddItem}
          />
        </div>
      </div>

      <div className={`${classes.d_dataTable}`}>
        <DataTable
          headings={headings}
          tableData={itemsData}
          actions={true}
          tableSize={itemsData?.length === 0 ? "100%" : "100%"}
          viewBtn={true}
          deleteBtn={true}
          editBtn={true}
          sorting={true}
          viewClick={handleOpenViewItemPopup}
          editClick={handleOpenEditItem}
          toggleOnChange={handleOpenDltConfirmation}
          deleteClick={(id, row) => {
            handleOpenDltConfirmation(id, row, "hard delete");
          }}
          errorMessage={tableProps.errorMessage}
          isLoading={tableProps.isLoading}
          itemsTable={true}
          setSortingColumnName={setSortingColumnName}
          sortingColumnName={sortingColumnName}
          setSortingOrder={setSortingOrder}
          sortingOrder={sortingOrder}
        />

        <PaginantionComponent
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          currentPage={currentPage || 0}
          itemsPerPage={itemsPerPage || 0}
          totalItems={totalItems || 0}
          localStorageKey={"rfq_organ_page_number"}
          previouPageClick={() => {
            previouPageClick(pageNumber, setPageNumber);
          }}
          nextPageClick={() => {
            nextPageClick(pageNumber, setPageNumber, paginationData);
          }}
          disablePrevious={parseInt(pageNumber) === 1 || pageNumber === ""}
          disableNext={
            parseInt(pageNumber) === paginationData?.page_count ||
            !paginationData ||
            pageNumber === ""
          }
        />
      </div>

      {/* modal popups */}

      {/* add/update item modal */}
      <Modal
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
        sx={{
          overflow: "auto",
        }}
      >
        <Fade in={open}>
          <div
            className={classes.flex}
            style={{
              height: "130vh",
            }}
          >
            <PopupFrame
              inputChildren={
                itemsAction.addItem
                  ? ItemInputs
                  : itemsAction.updateItem
                    ? ItemInputs
                    : itemsAction.addBulkItem
                      ? BulkItemInputs
                      : ItemInputs
              }
              poupHeading={InputsHeading}
              actionButtons={itemsActionsButton}
              closePopup={handleClose}
              customClass={
                itemsAction.updateItem ? classes.updatePopup : classes.addPopup
              }
            // styleCenterDiv={classes.centerDiv}
            />
          </div>
        </Fade>
      </Modal>

      {/* view user modal */}
      <Modal
        open={openViewItemPopup}
        closeAfterTransition
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={openViewItemPopup}>
          <div
            className={classes.flex}
            style={{
              height: "130vh",
            }}
          >
            <PopupFrame
              inputChildren={viewUserChildren}
              poupHeading={viewUserHeading}
              actionButtons={viewItemsActionButtons}
              closePopup={handleCloseViewItemPopup}
              width={"auto"}
              customClass={classes.addPopup}
              styleCenterDiv={classes.centerDiv}
            />
          </div>
        </Fade>
      </Modal>

      {/* success modal */}
      <Modal open={openS}>
        <Fade in={openS}>
          <Box>
            <SuccessPopupDashboard
              popupImage={successPopupImg}
              popupHeroText={popupHeroText}
              popupDescription={popupMessage}
              popBtn={true}
              btnText={"Okay"}
              btnClick={handleCloseS}
            />
          </Box>
        </Fade>
      </Modal>

      {/* delete error modal two states - confirmation and deletion */}

      {/* confirmation */}
      <Modal open={openDltConfirmation}>
        <Fade in={openDltConfirmation}>
          <Box>
            <SuccessPopupDashboard
              popupImage={deletePopupImg}
              popupHeroText={popupHeroText}
              popBtn={true}
              btnText={"Yes"}
              secondaryBtn={true}
              btnTextSecondary={"No"}
              btnClick={handleDeleteUser}
              customHeroTextClass={classes.popupTxtSm}
              btnClickSecondary={handleCloseDltConfirmation}
            />
          </Box>
        </Fade>
      </Modal>

      {/* deletion */}
      <Modal open={openDlt}>
        <Fade in={openDlt}>
          <Box>
            <SuccessPopupDashboard
              popupImage={deletePopupImg}
              popupHeroText={popupHeroText}
              popupDescription={popupMessage}
              popBtn={true}
              btnText={"Okay"}
              loadingPrimaryBtn={loadingState}
              btnClick={handleCloseDlt}
              customHeroTextClass={classes.popupTxtSm}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ItemsTab;
