// import React, { useState, useRef, memo, useEffect } from "react";
// import classes from "./DFileUpload.module.css";

// const DFileUpload = ({
//   onFileUpload,
//   label,
//   fileName,
//   type,
//   value,
//   containerClassName,
//   customFileTypeText,
//   customClassNameForInput,
//   disabled,
//   onChange,
//   error,
//   fileTypes,
//   errorMsg,
//   customClassName,
//   size,
// }) => {
//   const [isDragOver, setIsDragOver] = useState(false);
//   const [selectedFileName, setSelectedFileName] = useState(
//     value ? value.name : null
//   );
//   const [fileInputKey, setFileInputKey] = useState(0);
//   const fileInputRef = useRef(null);

//   const handleDragOver = (e) => {
//     e.preventDefault();
//     setIsDragOver(true);
//   };

//   const handleDragLeave = () => {
//     setIsDragOver(false);
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     setIsDragOver(false);
//     const file = e?.dataTransfer?.files[0];
//     onFileUpload(file);
//     setSelectedFileName(file?.name);
//   };

//   const handleBrowseClick = () => {
//     fileInputRef.current.click();
//   };

//   const handleFileInputChange = (e) => {
//     const file = e.target.files[0];
//     onFileUpload(file);
//     setSelectedFileName(file?.name);
//   };

//   useEffect(() => {
//     if (value && value.name) {
//       setSelectedFileName(value.name);
//     } else {
//       setSelectedFileName(null);
//     }
//     setFileInputKey((prevKey) => prevKey + 1);
//   }, [value]);

//   return (
//     <div className={`${classes.fileUploadWrapper} ${containerClassName}`}>
//       <div
//         className={`${classes.file_upload} ${
//           isDragOver ? classes.drag_over : ""
//         } ${disabled ? classes.diabledField : ""} ${customClassName}`}
//         style={{ width: `${size}` }}
//         onDragOver={handleDragOver}
//         onDragLeave={handleDragLeave}
//         onDrop={handleDrop}
//         disabled={disabled}
//       >
//         <label>{label}</label>
//         <label className={classes.DocText}>
//           {selectedFileName ? (
//             <span>{selectedFileName}</span>
//           ) : fileName ? (
//             <span>{fileName}</span>
//           ) : (
//             <span>Drag and drop file here </span>
//           )}
//           <input
//             key={fileInputKey}
//             type="file"
//             onChange={handleFileInputChange}
//             ref={fileInputRef}
//             style={{ display: "none" }}
//             accept={fileTypes}
//           />
//           <p>{customFileTypeText || "Upload here"}</p>
//         </label>
//         <button onClick={handleBrowseClick}>Browse</button>
//       </div>
//       {error && <p className={classes.error_message}>{errorMsg}</p>}
//     </div>
//   );
// };

// export default memo(DFileUpload);
import React, { useState, useRef, memo, useEffect } from "react";
import classes from "./DFileUpload.module.css";

const DFileUpload = ({
  onFileUpload,
  label,
  fileName,
  type,
  value,
  containerClassName,
  customFileTypeText,
  customClassNameForInput,
  disabled,
  onChange,
  error,
  fileTypes,
  errorMsg,
  customClassName,
  size,
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState(
    value ? value.name : null
  );
  const [fileInputKey, setFileInputKey] = useState(0);
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e?.dataTransfer?.files[0];
    onFileUpload(file);
    setSelectedFileName(file?.name);
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    onFileUpload(file);
    setSelectedFileName(file?.name);
  };

  const handleClearClick = () => {
    onFileUpload(null);
    setSelectedFileName("Drag and drop file here");
    handleReset();
  };

  const handleReset = () => {
    fileInputRef.current.value = null;
  };

  useEffect(() => {
    if (value && value.name) {
      setSelectedFileName(value.name);
    } else {
      setSelectedFileName(null);
    }
    setFileInputKey((prevKey) => prevKey + 1);
  }, [value]);

  return (
    <div className={`${classes.fileUploadWrapper} ${containerClassName}`}>
      <div
        className={`${classes.file_upload} ${
          isDragOver ? classes.drag_over : ""
        } ${disabled ? classes.diabledField : ""} ${customClassName}`}
        style={{ width: `${size}` }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        disabled={disabled}
      >
        <label>{label}</label>
        <label className={classes.DocText}>
          {selectedFileName ? (
            <span>{selectedFileName}</span>
          ) : fileName ? (
            <span>{fileName}</span>
          ) : (
            <span>Drag and drop file here </span>
          )}
          <input
            key={fileInputKey}
            type="file"
            onChange={handleFileInputChange}
            ref={fileInputRef}
            style={{ display: "none" }}
            accept={fileTypes}
          />
          <p>{customFileTypeText || "Upload here"}</p>
        </label>

        {selectedFileName === "Drag and drop file here" || !selectedFileName ? (
          <button onClick={handleBrowseClick}>Browse</button>
        ) : (
          <button onClick={handleClearClick} className={classes.clearButton}>
            <span>Clear</span>
          </button>
        )}
      </div>
      {error && <p className={classes.error_message}>{errorMsg}</p>}
    </div>
  );
};

export default memo(DFileUpload);
