import React, { memo, useState } from "react";
import styles from "./InfoLabel.module.css";
import { Skeleton } from "@mui/material";

const InfoLabel = ({
  label,
  isLoading,
  value,
  size,
  customClass,
  maxLength,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  let displayValue;

  if (typeof value === "string") {
    displayValue = expanded ? value : value.slice(0, maxLength);
  } else {
    displayValue = value;
  }

  return (
    <div
      className={`${styles.label_container} ${customClass}`}
      style={{ width: `${size}` }}
    >
      {!isLoading ? (
        <>
          <label>{label}</label>
          <p>
            {displayValue}
            {typeof value === "string" && value.length > maxLength && (
              <span>
                {expanded ? " " : "... "}
                <span className={styles.readMore} onClick={toggleReadMore}>
                  {expanded ? "Read less" : "Read more"}
                </span>
              </span>
            )}
          </p>
        </>
      ) : (
        <>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.6rem", width: "50%", animationDuration: "0.6s" }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.4rem", width: "80%", animationDuration: "0.6s" }}
          />
        </>
      )}
    </div>
  );
};

export default memo(InfoLabel);
