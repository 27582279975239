import React, { memo } from 'react'
import classes from '../ChartStyles.module.css'
import LinearProgressBar from '../../LinearProgress/LinearProgressBar';
import ChartIndicator from '../../ChartIndicator/ChartIndicator';
import ReactApexChart from 'react-apexcharts';
import { Skeleton } from '@mui/material';

const FullDonutChart = ({ chartValues, loadingState, chartDataSet, textSm, customCardClass }) => {
    const fullDonut = {
        series: chartValues,
        options: {
            chart: {
                type: 'donut',
                offsetY: -5,
                sparkline: {
                    enabled: true
                }
            },
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                }
            },
            labels: chartDataSet?.chartLabels,
            plotOptions: {
                pie: {
                    startAngle: 180,
                    endAngle: -180,
                    expandOnClick: false,
                    customScale: .9,
                    donut: {
                        size: '70%',
                    }
                }
            },
            style: {
                fontSize: '14px',
                fontFamily: 'gilroyBold',
                colors: ['#333'],
            },
            background: {
                enabled: true,
                borderRadius: 4,
                padding: 4,
                opacity: 0.8,
                color: '#fff'
            },
            fill: {
                colors: chartDataSet?.barColors,
                type: 'solid'
            },
            legend: {
                show: false
            }
        }
    };

    return (
        <div className={`${classes.graphCard} ${customCardClass}`}>
            {!loadingState ? <>
                <div className={classes.linearProgress}>
                    <span>{chartDataSet?.cardHeading}</span>
                    <div className={classes.indicators}>
                        {
                            chartDataSet?.chartIndicators?.map((e) => (
                                <span key={e.value}><ChartIndicator label={e.value} color={e.color} /></span>
                            ))
                        }
                    </div>

                    {
                        chartDataSet?.progressLines?.map((e) => (
                            <span key={e.value}><LinearProgressBar label={e.label} progress={e.value} color={e.color} /></span>
                        ))
                    }
                </div>

                <div className={classes.chartWrapDonut}>
                    <ReactApexChart options={fullDonut.options} series={fullDonut.series} type="donut" />
                    <span className={`${textSm ? classes.infoSm : classes.info}`}>{chartDataSet?.chartText}</span>
                </div></>
                :
                <>

                    <div className={classes.linearProgress} >
                        <span><Skeleton /></span>
                        <div className={classes.indicators}>
                            <Skeleton width={"70%"} />
                        </div>

                        <span><Skeleton width={"90%"} height={'150px'} /></span>
                    </div>

                    <div className={`${classes.flex} ${classes.roundSkeleton}`}>

                        <Skeleton width={"150px"} height={"150px"} variant="circular" />

                    </div>
                </>}
        </div>
    )
}

export default memo(FullDonutChart);