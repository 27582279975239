import React, { memo } from 'react';
import classes from './HeaderTitle.module.css';

const HeaderTitle = ({ titleText, customclass }) => {
    return (
        <div className={`${classes.header_title_wrapper} ${customclass}`}>
            <span>{titleText}</span>
        </div>
    );
}

export default memo(HeaderTitle);
