import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: []
}

export const vendorRFQDataSlice = createSlice({
    name: 'vendorRFQData',
    initialState,
    reducers: {
        setVendorRFQData: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setVendorRFQData } = vendorRFQDataSlice.actions;
export default vendorRFQDataSlice.reducer;